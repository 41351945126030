import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Divider, Row, Button } from 'antd';
import DropdownReadMappingFields from './DropdownReadMappingFields';
import ReadMappingContext from './ReadMappingContext';

const ReadFieldMappingComp = () => {
  const {
    defaultIconCompanyMatchback,
    defaultIconContactMatchback,
    showSelectedFieldsStatus,
    matchBackBlock,
    mappedFields,
    getFilterHeaderDataValue,
    handleSelect,
    isButtonDisable,
  } = useContext(ReadMappingContext);
  return (
    <div style={{ marginLeft: '24px', width: '90%' }}>
      <Row>
        <Col span={12}>
          <Card bodyStyle={{ padding: '0px' }} bordered={false}>
            <p
              style={{
                width: '100%',
                fontSize: '14px',
                display: 'flex',
              }}>
              <span style={{ marginLeft: '-15px' }}>
                {defaultIconCompanyMatchback ? (
                  matchBackBlock[0].status ? (
                    <span
                      className="feildsLableMatchBackStatus"
                      style={{ background: '#1ADE80' }}
                    />
                  ) : (
                    <span
                      className="feildsLableMatchBackStatus"
                      style={{ background: '#bbb' }}
                    />
                  )
                ) : (
                  <span
                    className="feildsLableMatchBackStatus"
                    style={{ background: '#E64E31' }}
                  />
                )}
              </span>
              &nbsp;
              <span style={{ marginLeft: '15px' }}>
                One of below is mandatory input for <b>Company Matchback</b>
              </span>
            </p>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconCompanyMatchback ? (
                  showSelectedFieldsStatus('Email ID')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Email</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Email ID')}
                  mappedFields={mappedFields}
                  fieldName={'Email ID'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Email ID');
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconCompanyMatchback ? (
                  showSelectedFieldsStatus('Company Name')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Company Name</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Company Name')}
                  mappedFields={mappedFields}
                  fieldName={'Company Name'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Company Name');
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconCompanyMatchback ? (
                  showSelectedFieldsStatus('Website')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Website</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Website')}
                  mappedFields={mappedFields}
                  fieldName={'Website'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Website');
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card bodyStyle={{ padding: '0px' }} bordered={false}>
            <p
              style={{
                width: '85%',
                fontSize: '14px',
                display: 'flex',
              }}>
              <span style={{ marginLeft: '-15px' }}>
                {defaultIconContactMatchback ? (
                  matchBackBlock[1].status ? (
                    <span
                      className="feildsLableMatchBackStatus"
                      style={{ background: '#1ADE80' }}
                    />
                  ) : (
                    <span
                      className="feildsLableMatchBackStatus"
                      style={{ background: '#bbb' }}
                    />
                  )
                ) : (
                  <span
                    className="feildsLableMatchBackStatus"
                    style={{ background: '#E64E31' }}
                  />
                )}
              </span>
              &nbsp;
              <span style={{ marginLeft: '15px' }}>
                One of below is mandatory input for <b>Contact Matchback</b>
              </span>
            </p>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconContactMatchback ? (
                  showSelectedFieldsStatus('Email ID')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Email</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Email ID')}
                  mappedFields={mappedFields}
                  fieldName={'Email ID'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Email ID');
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconContactMatchback ? (
                  showSelectedFieldsStatus('Full Name')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Full Name</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Full Name')}
                  mappedFields={mappedFields}
                  fieldName={'Full Name'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Full Name');
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconContactMatchback ? (
                  showSelectedFieldsStatus('First Name')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">First Name</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('First Name')}
                  mappedFields={mappedFields}
                  fieldName={'First Name'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'First Name');
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {defaultIconContactMatchback ? (
                  showSelectedFieldsStatus('Last Name')
                ) : (
                  <span
                    className="feildsLableStatus"
                    style={{ border: '1.5px solid #E64E31' }}
                  />
                )}{' '}
                <span className="feildsLable">Last Name</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Last Name')}
                  mappedFields={mappedFields}
                  fieldName={'Last Name'}
                  handleChange={(selectedItem) => {
                    handleSelect(selectedItem, 'Last Name');
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Divider style={{ margin: '10px 0' }} />
      <div
        style={{
          color: '#545454',
          fontSize: '12px',
          lineHeight: '25px',
        }}>
        <span
          className="feildsLableMatchBackStatus"
          style={{ background: '#1ADE80', marginLeft: '-15px' }}
        />
        <span className="feildsLable" style={{ marginLeft: '5px' }}>
          {' '}
          Additional Fields to map
        </span>
      </div>
      <Card bodyStyle={{ padding: '5px 2px' }} bordered={false}>
        <Row>
          <Col span={11}>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {showSelectedFieldsStatus('Country')}{' '}
                <span className="feildsLable">Country</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Country')}
                  mappedFields={mappedFields}
                  fieldName={'Country'}
                  handleChange={(selectedItem) =>
                    handleSelect(selectedItem, 'Country')
                  }
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {showSelectedFieldsStatus('Record ID')}{' '}
                <span className="feildsLable">Record ID</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Record ID')}
                  mappedFields={mappedFields}
                  fieldName={'Record ID'}
                  handleChange={(selectedItem) =>
                    handleSelect(selectedItem, 'Record ID')
                  }
                />
              </Col>
            </Row>
            <Row gutter={16} className="feildsRowMargin">
              <Col span={6}>
                {showSelectedFieldsStatus('Job Title')}
                <span className="feildsLable"> Job Title</span>
              </Col>
              <Col span={16}>
                <DropdownReadMappingFields
                  headerFeildsOptions={getFilterHeaderDataValue('Job Title')}
                  mappedFields={mappedFields}
                  fieldName={'Job Title'}
                  handleChange={(selectedItem) =>
                    handleSelect(selectedItem, 'Job Title')
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ float: 'right', marginRight: '15%' }}>
          <Button
            type="primary"
            style={{ marginLeft: '15px' }}
            disabled={isButtonDisable}>
            Save
          </Button>
        </Row>
      </Card>
    </div>
  );
};

ReadFieldMappingComp.propTypes = {};

export default ReadFieldMappingComp;
