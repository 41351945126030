export const internalRecordStatus = {
  stats: [
    {
      name: 'Active',
      count: 0,
      percentage: '0.0',
      subStatsList: [
        {
          name: 'DB Match',
          internalSubStats: [
            { name: 'DB Match with JT', count: 0, percentage: '0.0' },
            { name: 'DB Match without JT', count: 0, percentage: '0.0' },
          ],
        },
        {
          name: 'Company Match',
          internalSubStats: [
            { name: 'Company Match with JT', count: 0, percentage: '0.0' },
            { name: 'Company Match without JT', count: 0, percentage: '0.0' },
          ],
        },

        {
          name: 'Algorithm Match',
          internalSubStats: [
            { name: 'Algorithm Match with JT', count: 0, percentage: '0.0' },
            {
              name: 'Algorithm Match without JT',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
    {
      name: 'No Longer',
      count: 0,
      percentage: '0.0',
      subStatsList: [
        {
          name: 'DB Match',
          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'Algorithm Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
    {
      name: 'No Match',
      count: 0,
      percentage: '0.0',
      subStatsList: [
        {
          name: 'DB Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'No Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
    {
      name: 'Possible Junk',
      count: 0,
      percentage: '0.0',

      subStatsList: [
        {
          name: 'Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'No Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
    {
      name: 'Company Match',
      count: 0,
      percentage: '0.0',

      subStatsList: [
        {
          name: 'Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'No Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
    {
      name: 'Junk or Ineligible',
      count: 0,
      percentage: '0.0',
      subStatsList: [
        {
          name: 'Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'No Company Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
        {
          name: 'Algorithm Match',

          internalSubStats: [
            {
              name: 'NA',
              count: 0,
              percentage: '0.0',
            },
          ],
        },
      ],
    },
  ],
};
