import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Divider, Row, Select } from 'antd';
import DropdownListViewFields from './DropdownListViewFields';
import { AuthContext } from '../../../shared/auth/authContext';

const ListViewStep = (props) => {
  if (props.step !== 1) {
    return null;
  }
  if (props.step === 1) {
    const { isAllowed } = useContext(AuthContext);
    const [headerDataArray, setHeaderDataArray] = useState([]);
    const [headerDataModifyArray, setHeaderDataModifyArray] = useState([]);

    useEffect(() => {
      setHeaderDataArray(props.headerData);

      const result = props.headerData.map((item, i) => {
        let obj = {
          key: i,
          value: item,
        };
        return obj;
      });
      setHeaderDataModifyArray(result);
    }, []);

    const handleSelect = (selectedItem, fieldName) => {
      props.handleChange(selectedItem, fieldName);
    };

    // filter field pass to dropdown list
    function checkNotMapped(item, fieldName) {
      let fieldBelongsToDropDown = false;
      let fieldIsNotMapped = false;
      let isMappedthenDisgradFlag = false;

      props.fields.length > 0 &&
        props.fields.forEach((element) => {
          let fieldBelongsToCurrentDropDown =
            element.mappedBy === item.value && element.name === fieldName;
          if (fieldBelongsToCurrentDropDown) {
            fieldBelongsToDropDown = true;
          }

          let isMappedthenDisgrad = element.mappedBy === item.value;
          if (isMappedthenDisgrad) {
            isMappedthenDisgradFlag = true;
          }
          let notmappedFeilds = element.mappedBy !== item.value;
          if (notmappedFeilds) {
            fieldIsNotMapped = true;
          }
        });
      if (fieldBelongsToDropDown) {
        return true;
      } else if (isMappedthenDisgradFlag) {
        return false;
      } else if (fieldIsNotMapped) {
        return true;
      }
      return false;
    }
    const getFilterFieldsValue = (fieldName) => {
      let mappingData = headerDataModifyArray.filter((item) =>
        checkNotMapped(item, fieldName)
      );
      return mappingData;
    };

    const showSelectedFieldsStatus = (fieldName) => {
      const selectedOption = props.mappedFields.find(
        (item) => item.name == fieldName
      );

      return selectedOption !== undefined ? (
        <span
          className="feildsLableStatus"
          style={{ border: '1.5px solid #1ADE80' }}
        />
      ) : (
        <span className="feildsLableStatus" style={{ background: '#bbb' }} />
      );
    };
    return (
      <>
        <div
          style={{
            width: '100%',
            boxSizing: 'border-box',
            paddingTop: 20,
          }}>
          <Row>
            <Col span={12}>
              <Card
                bodyStyle={{ padding: '0px' }}
                bordered={false}
                className="listViewCards">
                <p
                  style={{
                    width: '85%',
                    fontSize: '14px',
                    display: 'flex',
                  }}>
                  <span style={{ marginLeft: '-15px' }}>
                    {props.defaultIconCompanyMatchback ? (
                      props.matchBackBlock[0].status ? (
                        <span
                          className="feildsLableMatchBackStatus"
                          style={{ background: '#1ADE80' }}
                        />
                      ) : (
                        <span
                          className="feildsLableMatchBackStatus"
                          style={{ background: '#bbb' }}
                        />
                      )
                    ) : (
                      <span
                        className="feildsLableMatchBackStatus"
                        style={{ background: '#E64E31' }}
                      />
                    )}
                  </span>
                  &nbsp;
                  <span style={{ marginLeft: '15px' }}>
                    One of below is mandatory input for <b>Company Matchback</b>
                  </span>
                </p>
                <div>
                  {/* {isAllowed('Email Id') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={6}>
                        {props.defaultIconCompanyMatchback ? (
                          showSelectedFieldsStatus('Email ID')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable">Email</span>
                      </Col>
                      <Col span={16}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue('Email ID')}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Email ID'}
                          handleChange={(selectedItem) => {
                            handleSelect(selectedItem, 'Email ID');
                          }}
                        />
                      </Col>
                    </Row>
                  )} */}
                  {isAllowed('Company Name') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={6}>
                        {props.defaultIconCompanyMatchback ? (
                          showSelectedFieldsStatus('Company Name')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable">Company</span>
                      </Col>
                      <Col span={16}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue(
                            'Company Name'
                          )}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Company Name'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'Company Name')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {isAllowed('Website') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={6}>
                        {props.defaultIconCompanyMatchback ? (
                          showSelectedFieldsStatus('Website')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable"> Website</span>
                      </Col>
                      <Col span={16}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue('Website')}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Website'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'Website')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                bodyStyle={{ padding: '0px' }}
                bordered={false}
                className="listViewCards">
                <p
                  style={{
                    width: '85%',
                    fontSize: '14px',
                    display: 'flex',
                  }}>
                  <span style={{ marginLeft: '-15px' }}>
                    {props.defaultIconContactMatchback ? (
                      props.matchBackBlock[1].status ? (
                        <span
                          className="feildsLableMatchBackStatus"
                          style={{ background: '#1ADE80' }}
                        />
                      ) : (
                        <span
                          className="feildsLableMatchBackStatus"
                          style={{ background: '#bbb' }}
                        />
                      )
                    ) : (
                      <span
                        className="feildsLableMatchBackStatus"
                        style={{ background: '#E64E31' }}
                      />
                    )}
                  </span>
                  &nbsp;
                  <span style={{ marginLeft: '15px' }}>
                    One of below is mandatory input for <b>Contact Matchback</b>
                  </span>
                </p>

                <div>
                  {isAllowed('Email Id') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={7}>
                        {props.defaultIconContactMatchback ? (
                          showSelectedFieldsStatus('Email ID')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable"> Email</span>
                      </Col>
                      <Col span={15}>
                        <DropdownListViewFields
                          disabled={true}
                          headerFeildsOptions={getFilterFieldsValue('Email ID')}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Email ID'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'Email ID')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {isAllowed('Full Name') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={7}>
                        {props.defaultIconContactMatchback ? (
                          showSelectedFieldsStatus('Full Name')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable">Full Name</span>
                      </Col>
                      <Col span={15}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue(
                            'Full Name'
                          )}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Full Name'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'Full Name')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {isAllowed('First Name') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={7}>
                        {props.defaultIconContactMatchback ? (
                          showSelectedFieldsStatus('First Name')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable">First Name</span>
                      </Col>
                      <Col span={15}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue(
                            'First Name'
                          )}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'First Name'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'First Name')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {isAllowed('Last Name') && (
                    <Row gutter={16} className="listFeildsMargin">
                      <Col span={7}>
                        {props.defaultIconContactMatchback ? (
                          showSelectedFieldsStatus('Last Name')
                        ) : (
                          <span
                            className="feildsLableStatus"
                            style={{ border: '1.5px solid #E64E31' }}
                          />
                        )}{' '}
                        <span className="feildsLable"> Last Name</span>
                      </Col>
                      <Col span={15}>
                        <DropdownListViewFields
                          headerFeildsOptions={getFilterFieldsValue(
                            'Last Name'
                          )}
                          // headerFeildsOptions={headerDataArray}
                          mappedFields={props.mappedFields}
                          fieldName={'Last Name'}
                          handleChange={(selectedItem) =>
                            handleSelect(selectedItem, 'Last Name')
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Divider style={{ margin: '10px 0' }} />
          <div
            style={{
              color: '#545454',
              fontSize: '12px',
              lineHeight: '25px',
            }}>
            <span
              className="feildsLableMatchBackStatus"
              style={{ background: '#1ADE80', marginLeft: '-15px' }}
            />
            <span className="feildsLable" style={{ marginLeft: '5px' }}>
              {' '}
              Additional Fields to map
            </span>
          </div>
          <Card
            bodyStyle={{ padding: '5px 2px' }}
            bordered={false}
            className="listViewCards">
            <Row>
              <Col span={12}>
                {isAllowed('Country') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={6}>
                      {showSelectedFieldsStatus('Country')}{' '}
                      <span className="feildsLable">Country</span>
                    </Col>
                    <Col span={16}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue('Country')}
                        // headerFeildsOptions={headerDataArray}
                        mappedFields={props.mappedFields}
                        fieldName={'Country'}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Country')
                        }
                      />
                    </Col>
                  </Row>
                )}
                {isAllowed('Record ID') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={6}>
                      {showSelectedFieldsStatus('Record ID')}{' '}
                      <span className="feildsLable">Record ID</span>
                    </Col>
                    <Col span={16}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue('Record ID')}
                        // headerFeildsOptions={headerDataArray}
                        mappedFields={props.mappedFields}
                        fieldName={'Record ID'}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Record ID')
                        }
                      />
                    </Col>
                  </Row>
                )}
                {isAllowed('Job Title') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={6}>
                      {showSelectedFieldsStatus('Job Title')}
                      <span className="feildsLable"> Job Title</span>
                    </Col>
                    <Col span={16}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue('Job Title')}
                        // headerFeildsOptions={headerDataArray}
                        mappedFields={props.mappedFields}
                        fieldName={'Job Title'}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Job Title')
                        }
                      />
                    </Col>
                  </Row>
                )}
                {isAllowed('Company GUID') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={7} style={{ paddingRight: 0 }}>
                      {showSelectedFieldsStatus('Company GUID')}{' '}
                      <span className="feildsLable">Company GUID</span>
                    </Col>
                    <Col span={15}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue(
                          'Company GUID'
                        )}
                        // headerFeildsOptions={headerDataArray}
                        mappedFields={props.mappedFields}
                        fieldName={'Company GUID'}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Company GUID')
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={12}>
                {isAllowed('Contact GUID') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={7} style={{ paddingRight: 0 }}>
                      {showSelectedFieldsStatus('Contact GUID')}{' '}
                      <span className="feildsLable">Contact GUID</span>
                    </Col>
                    <Col span={15}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue(
                          'Contact GUID'
                        )}
                        // headerFeildsOptions={headerDataArray}
                        mappedFields={props.mappedFields}
                        fieldName={'Contact GUID'}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Contact GUID')
                        }
                      />
                    </Col>
                  </Row>
                )}
                {isAllowed('Contact LinkedIn URL') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={7}>
                      {showSelectedFieldsStatus('Contact LinkedIn URL')}{' '}
                      <span className="feildsLable">Contact LinkedIn URL</span>
                    </Col>
                    <Col span={15}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue(
                          'Contact LinkedIn URL'
                        )}
                        // headerFeildsOptions={headerDataArray}
                        fieldName={'Contact LinkedIn URL'}
                        mappedFields={props.mappedFields}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Contact LinkedIn URL')
                        }
                      />
                    </Col>
                  </Row>
                )}
                {isAllowed('Company LinkedIn URL') && (
                  <Row gutter={16} className="listFeildsMargin">
                    <Col span={7}>
                      {showSelectedFieldsStatus('Company LinkedIn URL')}{' '}
                      <span className="feildsLable">Company LinkedIn URL</span>
                    </Col>
                    <Col span={15}>
                      <DropdownListViewFields
                        headerFeildsOptions={getFilterFieldsValue(
                          'Company LinkedIn URL'
                        )}
                        // headerFeildsOptions={headerDataArray}
                        fieldName={'Company LinkedIn URL'}
                        mappedFields={props.mappedFields}
                        handleChange={(selectedItem) =>
                          handleSelect(selectedItem, 'Company LinkedIn URL')
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
};

ListViewStep.propTypes = {
  step: PropTypes.number,
  matchBackBlock: PropTypes.array,
  defaultIconCompanyMatchback: PropTypes.bool,
  defaultIconContactMatchback: PropTypes.bool,
  duplicateElementArray: PropTypes.array,
  companyMatchback: PropTypes.array,
};

export default ListViewStep;
