import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Highcharts
import Highcharts from 'highcharts/highstock';
import drilldow from 'highcharts/modules/drilldown';
import PieChart from 'highcharts-react-official';
drilldow(Highcharts);

const PieChartStats = ({ pieData }) => {
  const [pieChartData, setPieChartData] = useState([]);

  useEffect(() => {
    let pieChartdata = pieData.filter((item) => item && item.name !== 'Total');
    setPieChartData(pieChartdata);
  }, [pieData]);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const options = {
    chart: {
      type: 'pie',
      reflow: true,
      height: 300,
      style: {
        fontFamily: 'inherit',
      },
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },
    colorAxis: [{}],

    plotOptions: {
      pie: {
        slicedOffset: 0,
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        shadow: false,
        dataLabels: {
          connectorWidth: 1,
          enabled: true,
          padding: 0,
          style: {
            textOutline: false,
            fontWeight: 'normal',
            textDecoration: 'none',
            fontSize: '13px',
            fontFamily: 'Roboto',
          },
          formatter: function () {
            var max = 20;
            var val = this.point.name;
            return val.length > max ? val.slice(0, max) + '...' : val;
          },
          fontWeight: 'normal',
          color: '#666666',
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: true,
          },
        },
        //show name and color of records
        showInLegend: true,
        borderWidth: 0,
      },
    },

    legend: {
      //show name and color of records
      enabled: false,
      itemStyle: {
        fontWeight: 'normal',
      },
    },

    tooltip: {
      style: {
        textOutline: false,
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: '13px',
        fontFamily: 'Roboto',
      },
      headerFormat:
        '{point.point.name}: {point.percentage:.1f}% | {point.y:,.0f}',
      pointFormat: '',
    },

    series: [
      {
        name: 'previous',
        colorByPoint: true,
        data: pieChartData,
        dataLabels: {
          enabled: true,
          padding: 0,
          distance: 10,
          fontWeight: 'normal',
        },
        size: '80%',
        cursor: 'pointer',
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [
              {
                id: 'versions',
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },

    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  return (
    <div className="dashbord-piechart">
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
};

PieChartStats.propTypes = {
  pieData: PropTypes.array,
};

export default PieChartStats;
