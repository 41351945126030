import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MarketoContext from '../../../context/MarketoContext';
import MarketoScreen from './MarketoScreen';
import { connectorService } from '../../../service/connector.service';
import ConnectorContext from '../../../context/ConnectorContext';

const MarketoLayer = () => {
  const { getConnectionStatus } = useContext(ConnectorContext);

  const [isConnected, setIsConnected] = useState(false);

  const connectConnection = (values, callback) => {
    connectorService
      .connectConnection({
        clientId: values.clientId,
        clientSecret: values.clientSecret,
        url: values.url,
      })
      .then((resp) => {
        setIsConnected(true);
        callback(resp);
      })
      .catch((err) => {
        console.log('in marketo layer connect', err);
        setIsConnected(false);
        callback(err.response);
      });
  };

  const testConnection = (callback) => {
    connectorService
      .testConnection()
      .then((resp) => {
        setIsConnected(true);
        callback(resp);
      })
      .catch((err) => {
        console.log('in marketo layer testConnection', err);
        setIsConnected(false);
        callback(err.response);
      });
  };

  const disconnectConnection = (callback) => {
    connectorService
      .disconnectConnection()
      .then((resp) => {
        console.log('disconnect succes', resp);
        setIsConnected(false);
        callback(resp);
      })
      .catch((err) => {
        console.log('in marketo layer disconnectConnection', err);
        callback(err.response);
      });
  };

  const getConnectionDetails = (callback) => {
    connectorService
      .getDetails()
      .then((resp) => {
        console.log('connect succes', resp);
        setIsConnected(true);
        callback(resp);
      })
      .catch((err) => {
        console.log('in marketo layer getConnection', err);
        setIsConnected(false);
        callback(err.response);
      });
  };

  // const getConnectionStatus = (status) => {
  //   return status;
  // };

  return (
    <>
      <MarketoContext.Provider
        value={{
          connectConnection,
          testConnection,
          disconnectConnection,
          getConnectionDetails,
        }}>
        <MarketoScreen isConnected={isConnected} />
      </MarketoContext.Provider>
    </>
  );
};

MarketoLayer.propTypes = {};

export default MarketoLayer;
