import axios from 'axios';
import config from '../config/constants';
const taskUrl = config.env.taskServerUrl;

const userUrl = config.env.userServerUrl;

export const creditService = {
  getCreditBalance,
  addedCredit,
  getCreditUsed,
  getClientCreditBalance,
};

function getCreditBalance(key) {
  return axios.get(`${userUrl}/v1/clients/subscriptions/balance`);
}

function getClientCreditBalance(clientId) {
  return axios.get(`${userUrl}/v1/clients/subscriptions/${clientId}/balance`);
}

function addedCredit(clientId, credits) {
  return axios.post(
    `${userUrl}/v1/clients/subscriptions/${clientId}/add-credits/${credits}`
  );
}

function getCreditUsed(id) {
  return axios.get(`${taskUrl}/api/v1/task/${id}/credits`);
}
