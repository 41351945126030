import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Modal, Row } from 'antd';
import FileUploadImg from '../../assets/images/fileuploadImg.png';
import marketo from '../../assets/images/marketo-logo.png';
import { RightOutlined } from '@ant-design/icons';
import AddTaskWizard from './AddTask/AddTaskWizard';
import AddMarketoTask from './AddConnectorTask/MarketoTask/AddMarketoTask';

const TaskSelectionComp = (props) => {
  const [taskSelectionType, setTaskSelectionType] = useState(null);

  useLayoutEffect(() => {
    if (props.dragFile != null) {
      setTaskSelectionType('fileUpload');
    } else {
      setTaskSelectionType(
        props.fileUploadType !== null ? props.fileUploadType : null
      );
    }
  }, []);

  const onTaskSelection = (type) => {
    setTaskSelectionType(type);
  };
  return (
    <div>
      <Modal
        className="taskSelectionCard"
        visible={props.open}
        onCancel={
          taskSelectionType == null
            ? props.onCancelUploadTask
            : props.closeUploadTask
        }
        maskClosable={false}
        width={'65%'}
        destroyOnClose={true}
        title={taskSelectionType == null && 'Create Task'}
        centered
        footer={
          taskSelectionType == null
            ? [
                <Button
                  type="link"
                  key="cancel"
                  onClick={props.onCancelUploadTask}>
                  Cancel
                </Button>,
              ]
            : false
        }>
        {taskSelectionType == null ? (
          <TaskSelectionStep onTaskSelection={onTaskSelection} />
        ) : taskSelectionType === 'fileUpload' ? (
          <AddTaskWizard
            className="add-task-popup"
            steps={props.steps}
            dragFile={props.dragFile}
            open={props.open}
            closeUploadTask={props.closeUploadTask}
            submitUploadFile={props.submitUploadFile}
            isLoading={props.isLoading}
          />
        ) : taskSelectionType === 'MarketoUpload' ? (
          <AddMarketoTask
            closeUploadTask={props.closeUploadTask}
            onCancelUploadTask={props.onCancelUploadTask}
            refetchTaskList={props.refetchTaskList}
          />
        ) : (
          ''
        )}
      </Modal>
    </div>
  );
};

TaskSelectionComp.propTypes = {
  open: PropTypes.bool,
  closeUploadTask: PropTypes.func,
  steps: PropTypes.func,
  submitUploadFile: PropTypes.func,
  isLoading: PropTypes.bool,
  dragFile: PropTypes.bool,
  fileUploadType: PropTypes.string,
  onCancelUploadTask: PropTypes.func,
  refetchTaskList: PropTypes.func,
};

export default TaskSelectionComp;

const TaskSelectionStep = (props) => {
  return (
    // <div style={{ width: '100%', margin: 'auto' }}>
    <Row gutter={32} style={{ padding: '16px' }}>
      <Col span={10} offset={1}>
        <Card
          className="connectorCards"
          bodyStyle={{ padding: '15px', height: '200px' }}>
          <div style={{ textAlign: 'left' }}>File Based Enrichment</div>
          <img
            src={FileUploadImg}
            alt="Marketo"
            height="auto"
            width="49%"
            style={{ margin: '10px 15%', textAlign: 'center' }}
          />
          <Button
            type="primary"
            className="taskSelectionBtn"
            onClick={() => props.onTaskSelection('fileUpload')}>
            Next <RightOutlined />
          </Button>
        </Card>
      </Col>
      <Col span={2}></Col>
      <Col span={10}>
        <Card
          className="connectorCards"
          bodyStyle={{ padding: '15px', height: '200px' }}>
          <div style={{ textAlign: 'left' }}>Marketo Based Enrichment</div>
          <img
            src={marketo}
            alt="Marketo"
            height="auto"
            width="70%"
            style={{ margin: '12px 15%', textAlign: 'center' }}
          />
          <Button
            type="primary"
            className="taskSelectionBtn"
            onClick={() => props.onTaskSelection('MarketoUpload')}>
            Next <RightOutlined />
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

TaskSelectionStep.propTypes = {
  onTaskSelection: PropTypes.func,
};
