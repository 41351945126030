import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './login.css';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { MailOutlined, UnlockFilled } from '@ant-design/icons';
import ForwordPassword from '../../assets/images/reset-pwd.png';
function LoginForm({
  onSubmit,
  setLoading,
  loading,
  invalidUserErr,
  invalidUserErrMsg,
}) {
  // const { isLoading, isError, error, run } = useAsync();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    const { username, password } = values;
    setLoading(true);
    // run(
    onSubmit(
      {
        userName: username.replace(/\s+/g, ' ').trim(),
        password: password,
      },
      false
    );
    // );
  };

  // useEffect(() => {
  //   window.location = process.env.REACT_APP_API_IG_DOMAIN;
  // }, []);

  return (
    <>
      <div>
        <div>
          <div className="loginBox">
            <Form name="login" form={form} onFinish={onFinish}>
              <div className="loginContent">
                <div className="titleText">Sign in</div>
                <div>
                  {invalidUserErr && (
                    <span className="errorMsg">{invalidUserErrMsg}</span>
                  )}
                </div>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide a valid Username!',
                      whitespace: true,
                    },
                  ]}>
                  <Input
                    className="login-input"
                    id="login-username"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide a valid Password!',
                    },
                  ]}>
                  <Input
                    className="login-input"
                    id="login-password"
                    prefix={<UnlockFilled className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Link
                    to="/forgot-password"
                    style={{ float: 'right', color: 'var(--text-color)' }}>
                    <img src={ForwordPassword} width="15px" height="15px" />
                    &nbsp; Forgot Password?
                  </Link>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="layout-btn"
                    type="primary"
                    htmlType="submit"
                    style={{ width: '40%', margin: ' 0px 30%' }}
                    loading={loading}>
                    Log in
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.boolean,
  invalidUserErr: PropTypes.boolean,
  invalidUserErrMsg: PropTypes.string,
};

export default LoginForm;
