import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { internalRecordStatus } from './constant';

const InternalRecordStatusComp = ({ dataSource }) => {
  const [recordStatusData, setRecordStatusData] = useState(
    internalRecordStatus.stats
  );

  let statsResponse =
    dataSource !== null && dataSource.stats !== null ? dataSource.stats : {};

  useEffect(() => {
    removeAllSetData();
    modifyDataAsResponse();
  }, [dataSource]);

  const removeAllSetData = () => {
    internalRecordStatus.stats.forEach((item) => {
      (item.count = 0), (item.percentage = '0.0');
      for (let subItem of item.subStatsList) {
        for (let internalItem of subItem.internalSubStats) {
          (internalItem.count = 0), (internalItem.percentage = '0.0');
        }
      }
    });
  };

  const modifyDataAsResponse = () => {
    let modifiedData = internalRecordStatus.stats.map((item, i) => {
      for (let status of statsResponse) {
        let subItem = status;
        if (item.name == status.name) {
          (item.count = status.count), (item.percentage = status.percentage);
          item.subStatsList.map((match, i) => {
            if (
              status.subStatsList !== undefined &&
              status.subStatsList.length > 0
            ) {
              for (let matchType of subItem.subStatsList) {
                if (matchType.name == match.name) {
                  (match.count =
                    matchType.count !== undefined ? matchType.count : 0),
                    (match.percentage =
                      matchType.percentage !== undefined
                        ? matchType.percentage
                        : '0.0');
                  if (
                    matchType.internalSubStats !== undefined &&
                    matchType.internalSubStats.length > 0
                  ) {
                    match.internalSubStats.map((jtValue, i) => {
                      for (let jtItem of matchType.internalSubStats) {
                        if (jtValue.name == jtItem.name) {
                          (jtValue.count =
                            jtItem.count !== undefined ? jtItem.count : 0),
                            (jtValue.percentage =
                              jtItem.percentage !== undefined
                                ? jtItem.percentage
                                : '0.0');
                        }
                      }
                      return jtValue;
                    });
                  } else {
                    let obj = {
                      name: 'NA',
                      count:
                        matchType.count !== undefined ? matchType.count : 0,
                      percentage:
                        matchType.percentage !== undefined
                          ? matchType.percentage
                          : '0.0',
                    };
                    match.internalSubStats[0] = obj;
                  }
                } else {
                  (match.count = 0), (match.percentage = '0.0');
                }
              }
            }
            return match;
          });
        }
      }
      return item;
    });

    setRecordStatusData(modifiedData);
  };

  let totalReacord = statsResponse.filter(
    (item) => item && item.name == 'Total'
  );

  let ErrorRecord = statsResponse.find((status) => status.name == 'Error');

  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== undefined ? obj[key] : '0.0'),
      nestedObj
    );
  };

  return (
    <div>
      <table border="1" className="internalRecordstats">
        <thead>
          <tr style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
            <th>Record Status</th>
            <th className="textAlignRight">Records</th>
            <th className="textAlignRight">Percentage</th>
            <th>Match Type</th>
            <th>Comp Info Availability</th>
            <th>Input JT Availability</th>
            <th className="textAlignRight">Records</th>
            <th className="textAlignRight">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {/* Active */}
          <tr>
            <td rowSpan="6" className="textAlignLeft">
              Active
            </td>
            <td rowSpan="6" className="textAlignRight">
              {recordStatusData[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="6" className="textAlignRight">
              {getNestedObject(recordStatusData[0], ['percentage'])}%
            </td>
            <td rowSpan="2">DB Match</td>
            <td rowSpan="2">Yes</td>
            <td>
              {recordStatusData[0].subStatsList[0].internalSubStats[0].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>
              {recordStatusData[0].subStatsList[0].internalSubStats[1].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[0].internalSubStats[1].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[0].internalSubStats[1],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td rowSpan="2">Company Match</td>
            <td rowSpan="2">Yes</td>
            <td>
              {recordStatusData[0].subStatsList[1].internalSubStats[0].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>
              {recordStatusData[0].subStatsList[1].internalSubStats[1].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[1].internalSubStats[1].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[1].internalSubStats[1],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td rowSpan="2">No Company Match</td>
            <td rowSpan="2">No</td>
            <td>
              {recordStatusData[0].subStatsList[2].internalSubStats[0].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[2].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[2].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>
              {recordStatusData[0].subStatsList[2].internalSubStats[1].name.includes(
                'without'
              )
                ? 'No'
                : 'Yes'}
            </td>
            <td className="textAlignRight">
              {recordStatusData[0].subStatsList[2].internalSubStats[1].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[0].subStatsList[2].internalSubStats[1],
                ['percentage']
              )}
              %
            </td>
          </tr>
          {/* No Longer  */}
          <tr>
            <td rowSpan="3" className="textAlignLeft">
              No Longer
            </td>
            <td rowSpan="3" className="textAlignRight">
              {recordStatusData[1].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="3" className="textAlignRight">
              {getNestedObject(recordStatusData[1], ['percentage'])}%
            </td>
            <td>DB Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[1].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[1].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>Company Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[1].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[1].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>No Company Match</td>
            <td>No</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[1].subStatsList[2].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[1].subStatsList[2].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          {/* No Match  */}
          <tr>
            <td rowSpan="3" className="textAlignLeft">
              No Match
            </td>
            <td rowSpan="3" className="textAlignRight">
              {recordStatusData[2].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="3" className="textAlignRight">
              {getNestedObject(recordStatusData[2], ['percentage'])}%
            </td>
            <td>DB Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[2].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[2].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>Company Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[2].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[2].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>No Company Match</td>
            <td>No</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[2].subStatsList[2].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[2].subStatsList[2].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          {/*  Possible Junk */}
          <tr>
            <td rowSpan="2" className="textAlignLeft">
              Possible Junk
            </td>
            <td rowSpan="2" className="textAlignRight">
              {recordStatusData[3].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="2" className="textAlignRight">
              {getNestedObject(recordStatusData[3], ['percentage'])}%
            </td>
            <td>Company Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[3].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[3].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>No Company Match</td>
            <td>No</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[3].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[3].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          {/* Company Match  */}
          <tr>
            <td rowSpan="2" className="textAlignLeft">
              Company Match
            </td>
            <td rowSpan="2" className="textAlignRight">
              {recordStatusData[4].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="2" className="textAlignRight">
              {getNestedObject(recordStatusData[4], ['percentage'])}%
            </td>
            <td>Company Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[4].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[4].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>No Company Match</td>
            <td>No</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[4].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[4].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>

          {/* Junk or Ineligible  */}
          <tr>
            <td rowSpan="2" className="textAlignLeft">
              Junk or Ineligible
            </td>
            <td rowSpan="2" className="textAlignRight">
              {recordStatusData[5].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td rowSpan="2" className="textAlignRight">
              {getNestedObject(recordStatusData[5], ['percentage'])}%
            </td>
            <td>Company Match</td>
            <td>Yes</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[5].subStatsList[0].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[5].subStatsList[0].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          <tr>
            <td>No Company Match</td>
            <td>No</td>
            <td>NA</td>
            <td className="textAlignRight">
              {recordStatusData[5].subStatsList[1].internalSubStats[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td className="textAlignRight">
              {getNestedObject(
                recordStatusData[5].subStatsList[1].internalSubStats[0],
                ['percentage']
              )}
              %
            </td>
          </tr>
          {/* Error */}
          <tr>
            <td className="textAlignLeft">Error</td>
            <td className="textAlignRight">
              {ErrorRecord !== undefined && ErrorRecord.count
                ? ErrorRecord.count
                : 0}
            </td>
            <td className="textAlignRight">
              {ErrorRecord !== undefined && ErrorRecord.percentage
                ? ErrorRecord.percentage
                : '0.0'}
              %
            </td>
            <td>NA</td>
            <td>NA</td>
            <td>NA</td>
            <td className="textAlignRight">
              {ErrorRecord !== undefined && ErrorRecord.count
                ? ErrorRecord.count
                : 0}
            </td>
            <td className="textAlignRight">
              {ErrorRecord !== undefined && ErrorRecord.percentage
                ? ErrorRecord.percentage
                : '0.0'}
              %
            </td>
          </tr>
          {/* Total */}
          <tr style={{ backgroundColor: '#fafafa' }}>
            <th>Grand Total</th>
            <th className="textAlignRight">
              {totalReacord[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th className="textAlignRight">
              {totalReacord[0].count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </th>
            <th className="textAlignRight">
              {Number(totalReacord[0].percentage)}%
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

InternalRecordStatusComp.propTypes = {
  dataSource: PropTypes.object,
};

export default InternalRecordStatusComp;
