import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

function ResolutionInfoComp(rops) {
  const [showResolutionInfo, setShowResolutionInfo] = useState(false);

  React.useEffect(() => {
    let screenResolutionH = window.screen.availHeight;
    let screenResolutionW = window.screen.availWidth;

    if (screenResolutionH < 768 && screenResolutionW < 1366) {
      setShowResolutionInfo(true);
    } else {
      setShowResolutionInfo(false);
    }
  }, [window.screen.availHeight, window.screen.availWidth]);

  if (showResolutionInfo) {
    return (
      <div className="resolutionInfoText">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="boldtext">Screen Resolution Status</div>
          <div>
            <CloseOutlined
              onClick={() => {
                setShowResolutionInfo(false);
              }}
            />{' '}
          </div>
        </div>
        This site is best viewed at resolution 1366 X 768.
      </div>
    );
  } else {
    return null;
  }
}

export default ResolutionInfoComp;
