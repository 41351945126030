import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Popover, Dropdown, Typography, Menu } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import { APP_SHORT_DATE_FORMAT } from '../../../config/constants';
import img from '../../../assets/images/reset-pass.png';
import resetPassDisable from '../../../assets/images/resetPassDisable.png';
import editIcon from '../../../assets/images/edit.png';

const { Link, Text } = Typography;

const ClientTable = ({ clients, showEditForm, update, sendMail }) => {
  const columns = [
    //client name
    {
      key: 'name',
      title: 'Org Name',
      width: '20%',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          if (a.clientName === b.clientName) {
            return 0;
          } // isNaN sort after anything else
          else if (a.clientName == undefined) {
            return 1;
          } else if (b.clientName == undefined) {
            return -1;
          } else {
            return a.clientName.localeCompare(b.clientName);
          }
        },
      },
      // showSorterTooltip: true,
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const nameparts = [record.clientName];
        const profileName = nameparts[0].substring(0, 2).toUpperCase();

        return (
          <>
            <Avatar className="clientProfileImage">{profileName}</Avatar>
            &nbsp;&nbsp;
            <Link
              className="client-name"
              onClick={(e) => openEditForm(record.id)}>
              {record.clientName}
            </Link>
          </>
        );
      },
    },
    //client status
    {
      key: 'clientStatus',
      title: 'Org Status',
      dataIndex: 'clientStatus',
      width: '10%',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          if (a.clientStatus === b.clientStatus) {
            return 0;
          } // isNaN sort after anything else
          else if (a.clientStatus == undefined) {
            return 1;
          } else if (b.clientStatus == undefined) {
            return -1;
          } else {
            return a.clientStatus.localeCompare(b.clientStatus);
          }
        },
      },
      render: (text) => {
        // return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        if (text === 'ACTIVE') {
          return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        } else if (text === 'DEACTIVATED') {
          return <span>Deactivated</span>;
        } else {
          return <span>Activation pending</span>;
        }
      },
    },
    //Contract start Date
    {
      key: 'startDate',
      title: 'Start Date',
      dataIndex: 'startDate',
      width: '15%',
      align: 'center',
      ellipsis: true,
      render: (text) => {
        if (text) {
          return moment.utc(text).local().format(APP_SHORT_DATE_FORMAT);
        } else {
          return <span>&#x268A;</span>;
        }
      },
    },
    //Contract Expiry Date
    {
      key: 'expiryDate',
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      width: '15%',
      align: 'center',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          var dateA = new Date(a.expiryDate).getTime();
          var dateB = new Date(b.expiryDate).getTime();
          if (dateA === dateB) {
            return 0;
          }
          // isNaN sort after anything else
          else if (isNaN(dateA)) {
            return 1;
          } else if (isNaN(dateB)) {
            return -1;
          } else {
            return dateA >= dateB ? 1 : -1;
          }
        },
      },

      render: (text) => {
        if (text) {
          return moment.utc(text).local().format(APP_SHORT_DATE_FORMAT);
        } else {
          return <span>&#x268A;</span>;
        }
      },
    },
    // admin email id
    {
      key: 'adminEmail',
      title: 'CSM Email',
      dataIndex: 'adminEmail',
      width: '30%',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          if (a.adminEmail === b.adminEmail) {
            return 0;
          } // isNaN sort after anything else
          else if (a.adminEmail == undefined) {
            return 1;
          } else if (b.adminEmail == undefined) {
            return -1;
          } else {
            return a.adminEmail.localeCompare(b.adminEmail);
          }
        },
      },
      render: (text) => {
        if (text) {
          return text;
        } else {
          return <span>&#x268A;</span>;
        }
      },
    },
    // action perform
    {
      title: 'Actions',
      dataIndex: '',
      key: 'action',
      align: 'center',
      ellipsis: true,
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const content = (
          <Menu className="client-list-action-menu">
            {update ? (
              <Menu.Item onClick={(e) => openEditForm(record.id)}>
                <span className="linkIcon" style={{ paddingRight: '5px' }}>
                  <img src={editIcon} width="14px" />
                </span>
                Edit
              </Menu.Item>
            ) : null}
            {record.clientStatus !== 'DEACTIVATED' ? (
              <Menu.Item onClick={(e) => sendMailToUser(record.adminEmail)}>
                <span className="linkIcon" style={{ paddingRight: '5px' }}>
                  <img src={img} width="15px" />
                </span>
                Reset Password
              </Menu.Item>
            ) : (
              <Menu.Item>
                <span
                  className="linkIconDisabled"
                  style={{ paddingRight: '5px' }}>
                  <img src={resetPassDisable} width="15px" />
                </span>
                <Text disabled>Reset Password</Text>
              </Menu.Item>
            )}
          </Menu>
        );
        return (
          <Dropdown
            overlay={content}
            placement="bottomCenter"
            overlayClassName="clientListDropDownMenu">
            <span>
              <span className="threeDotsClient"></span>&nbsp;
              <span className="threeDotsClient"></span>&nbsp;
              <span className="threeDotsClient"></span>
            </span>
          </Dropdown>
        );
      },
    },
  ];

  //open edit form with id
  const openEditForm = (id) => {
    showEditForm(id);
  };

  //get user adminEmail to send mail
  const sendMailToUser = (adminEmail) => {
    sendMail(adminEmail);
  };

  return (
    <Table
      rowKey="id"
      className="clientListTable"
      pagination={{ defaultPageSize: 20, position: ['bottomCenter'] }}
      showHeader={true}
      dataSource={clients.data}
      columns={columns}
      size="medium"
    />
  );
};

ClientTable.propTypes = {
  update: PropTypes.bool,
  showEditForm: PropTypes.func,
  sendMail: PropTypes.func,
  clients: PropTypes.object,
};

export default ClientTable;
