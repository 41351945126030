import axios from 'axios';
import config from '../config/constants';

const taskUrl = config.env.taskServerUrl;

export const taskService = {
  getAll,
  getById,
  getCreditsBalance,
  getCreditsUsage,
};

function getAll(key, page = 0, columnName, sortValue) {
  return axios.get(
    `${taskUrl}/api/v1/tasks?page=${page}&size=20&sort=${columnName},${sortValue}`
  );
}

function getById(id) {
  return axios.get(`${taskUrl}/api/v1/tasks/${id}`);
}

function getCreditsBalance(recordCount) {
  return axios.get(`${taskUrl}/api/v1/lowCreditAlert/${recordCount}`);
}

function getCreditsUsage(months) {
  return axios.get(`${taskUrl}/api/v1/subscriptions/usage?months=${months}`);
}
