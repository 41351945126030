// pretend this is firebase, netlify, or auth0's code.
// you shouldn't have to implement something like this in your own app

import config from '../../config/constants';
import axios from 'axios';

const localStorageKey = '__dg_token';
const isLoggedFromIg = '_IS_FROM_IG';
const userUrl = config.env.userServerUrl;

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return window.localStorage.getItem(localStorageKey);
}

function handleUserResponse(response) {
  window.localStorage.setItem(localStorageKey, JSON.stringify(response));
  return response;
}

function login(payload) {
  return client('/v1/accounts/login', payload).then(handleUserResponse);
}

function logout() {
  return axios.get(`${userUrl}/v1/accounts/logout`).then((res) => {
    window.localStorage.removeItem(localStorageKey);
    window.localStorage.removeItem('_ENRICH_AUTH_TOKEN');
    window.localStorage.removeItem(isLoggedFromIg);
    window.location = '/login';
  });
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
// const authURL = process.env.REACT_APP_AUTH_URL;
const authURL = config.env.userServerUrl;

async function client(endpoint, data) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  };

  return window
    .fetch(`${authURL}${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

export { getToken, login, logout, localStorageKey, isLoggedFromIg };
