import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileImageOutlined,
  InboxOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  Upload,
  message,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Button,
  Card,
  Popover,
} from 'antd';
import axios from 'axios';
import config from '../../../config/constants';
import { parse } from 'papaparse';
import imgXlsx from '../../../assets/images/xlsx-img.png';
import imgCsv from '../../../assets/images/csv-img.png';
import {
  ShowOopsMsg,
  msgConfig,
  errorMessage,
} from '../../../common/messageComp';

const { Dragger } = Upload;

const taskUrl = config.env.taskServerUrl;

//we send 7 data to AddTaskWizard
const FileUploadStep = (props) => {
  const [fullspinner, setFullspinner] = useState(false);
  const [fileName, setFileName] = useState('');
  const [taskName, setTaskName] = useState('');
  const [fileTask, setFileTask] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [isEmptyHeaderElement, setIsEmptyHeaderElement] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  const [columnLength, setColumnLength] = useState(0);
  const [excelFileInfo, setExcelFileInfo] = useState();
  // sheet selection
  const [showSelectSheetName, setShowSelectSheetName] = useState(false);
  const [dragFileFunction, setDragFileFunction] = useState(false);
  const [workBookSheet, setWorkBookSheet] = useState([]);
  const [sheetName, setSheetName] = useState('Select Sheet Name');
  const [serverExcelName, setServerExcelName] = useState('');
  const [dragFile, setDragFile] = useState(
    props.dragFile ? props.dragFile : null
  );
  const [taskNameLimit, setTaskNameLimit] = useState(false);

  useEffect(() => {
    if (!dragFileFunction && props.dragFile !== null) {
      let files = dragFile[0];
      uploadedFileReader(files);
    }
  }, []);

  const uploadedFileReader = (files) => {
    setShowSelectSheetName(false);
    setTaskNameLimit(false);
    let newFileName = '';
    setServerExcelName(''); //set default excel server file name
    //set total record for per sheet initial 0
    setTotalRecords(0);
    let sheetName = 'Select Sheet is csv';
    setSheetName(sheetName);
    //to reset value which is set in FieldmappingSteps
    props.removeAllSetData();

    let fileName = files.name;
    setFileName(fileName); //set file name

    let taskName = files.name.replace(/\.[^/.]+$/, '').substr(0, 50);
    setTaskName(taskName); //set task name

    let taskNameExtension = files.name.split('.').pop().toLowerCase(); //file extension to check which file is upload

    setFileTask(files); //set file object
    setFileSize(files.size); //set file size
    setFileList([files]); //we want to show uploaded file

    //check file type csv,xlsx or not
    if (
      files.type !== '' &&
      files.type !== 'text/csv' &&
      files.type !== 'application/vnd.ms-excel' &&
      files.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      message.destroy();
      message.error(
        msgConfig(errorMessage(`${taskName} is not a csv/excel file`))
      );
      setFileTask(null);
      setFileList([]);
      setTaskName('');
      props.disableNext();
      return false;
    }
    //check file size is grater than 100MB
    if (files.size > 104857600) {
      message.destroy();
      message.error(
        msgConfig(
          errorMessage`Kindly upload the file having less than 100MB size`
        )
      );
      setFileTask(null);
      setFileList([]);
      setTaskName('');
      props.disableNext();
      return false;
    }

    if (taskNameExtension === 'csv') {
      //set spinner to page
      setFullspinner(true);
      //to read csv file
      const reader = new FileReader();
      reader.readAsText(files, 'UTF-8');
      reader.onload = (e) => {
        const text = e.target.result;
        const result = parse(text); //data read csv , { header: true }
        //stop spinner to page
        setFullspinner(false);
        setFileData(result.data); //set file data(records)
        setHeaderData(result.data[0]); // to get 1st row as header
        //check all header elements present or not
        let isEmptyElement = result.data[0].some(
          (items) => items === '' || items.trim().length === 0
        );
        setIsEmptyHeaderElement(isEmptyElement);

        const colLength = result.data[0].length;
        setColumnLength(colLength); //to set total no of records.
        const records = result.data.length - 2;
        setTotalRecords(records); //to set total no of records.
        //send data to AddTaskWizard
        if (
          !isEmptyElement &&
          taskName &&
          taskName.length > 1 &&
          taskName.length <= 50 &&
          files &&
          records <= 500000 &&
          records > 1 &&
          colLength <= 150
        ) {
          console.log('Task Name and File uploaded as csv - upload');
          props.submittedValues([
            fileName,
            taskName,
            files,
            result.data,
            records,
            result.data[0],
            sheetName,
            newFileName,
          ]);
        } else {
          console.log('disable next during csv upload');
          props.disableNext();
        }
        console.log('records', records);
        //check file contains 0 records
        if (records <= 1) {
          message.destroy();
          message.error(
            msgConfig(
              errorMessage(
                `Kindly upload the file having more than one records`
              )
            )
          );
          return false;
        }
        //check file header contains non empty elements
        if (isEmptyElement) {
          message.destroy();
          message.error(
            `Column contains blank header. Kindly add column header and re-upload the file.`
          );
          return false;
        }
        //check file has contains more than 500000
        if (records > 500000) {
          message.destroy();
          message.error(
            `Kindly upload the file having less than 500000 records`
          );
          return false;
        }
        //check file has column contains less than or equal to 150
        if (colLength > 150) {
          message.destroy();
          message.error(
            msgConfig(
              errorMessage(
                `Kindly upload the file having less than 150 columns`
              )
            )
          );
          return false;
        }
        if (taskName.length > 50) {
          setTaskNameLimit(true);
          props.disableNext();
          return false;
        }
      };
    } else if (taskNameExtension === 'xlsx') {
      //set spinner to page
      setFullspinner(true);

      let sheetNameArray = [];
      let data;
      let newFileName;
      let totalColumnLength;
      let totalRow;

      let formData = new FormData();
      formData.append('file', files);
      formData.append('noOfRowsRequired', 11);
      axios({
        method: 'POST',
        url: `${taskUrl}/api/v1/tempUpload`,
        headers: {
          'content-type': 'multipart/form-data',
        },
        data: formData,
        timeout: 1000 * 60 * 5,
      })
        .then((res) => {
          const excelFileInfo = res.data;
          setExcelFileInfo(excelFileInfo);
          //to get all sheet name in array
          excelFileInfo.map((items) => {
            items.map((item) => {
              sheetNameArray.push(item.sheetName);
              newFileName = item.newFileName;
            });
          });

          setServerExcelName(newFileName); //set excel server file name

          let sheetNameList = sheetNameArray.filter(
            (item) => item !== undefined
          );
          //to check more than one sheet present or not
          if (sheetNameList.length > 1) {
            setWorkBookSheet(sheetNameList);
            setDragFileFunction(true);
            setSheetName('Select Sheet Name');
            setShowSelectSheetName((preState) => !preState);
            setFullspinner(false);
            if (taskName.length > 50) {
              setTaskNameLimit(true);
            }
            props.disableNext();
            return false;
          }

          //get by default sheet name
          const workSheetName = sheetNameList[0];
          setSheetName(workSheetName);
          setWorkBookSheet(sheetNameList);
          setDragFileFunction(true);
          setShowSelectSheetName((preState) => !preState);
          //to fetch data with by default sheetname
          const getObjectOfSelectedSheet = excelFileInfo.map((items) => {
            return items.filter((item) => {
              return item.sheetName === workSheetName;
            });
          });
          const getDataArray = getObjectOfSelectedSheet.map((items) => {
            return items.map((item) => {
              totalColumnLength = item.columns;
              totalRow = item.rows;
              return item.data;
            });
          });
          //to store data in array
          getDataArray.map((items) => {
            items.map((item) => {
              data = item;
            });
          });
          //stop spinner to page
          setFullspinner(false);
          //check file contains 0 or 1 records
          if (totalRow <= 2) {
            message.destroy();
            message.error(
              msgConfig(
                errorMessage(
                  `Kindly upload the file having more than one records`
                )
              )
            );
            props.disableNext();
            return false;
          }

          setFileData(data); //set file data(records)
          setHeaderData(data[0]); // to get 1st row as header
          //check all header elements present or not
          let isEmptyElement = data[0].some(
            (items) => items === '' || items.trim().length === 0
          );
          setIsEmptyHeaderElement(isEmptyElement);

          const colLength = totalColumnLength;
          setColumnLength(colLength); //to set total no of records.
          const records = totalRow - 1;
          setTotalRecords(records); //to set total no of records.

          //send data to AddTaskWizard
          if (
            !isEmptyElement &&
            taskName &&
            taskName.length > 1 &&
            taskName.length <= 50 &&
            files &&
            records <= 500000 &&
            records > 1 &&
            colLength <= 150
          ) {
            console.log('Task Name and File uploaded as xlsx - upload');
            props.submittedValues([
              fileName,
              taskName,
              files,
              data,
              records,
              data[0],
              workSheetName,
              newFileName,
            ]);
          } else {
            console.log('disable next during xlsx upload');
            props.disableNext();
          }
          //check file header contains non empty elements
          if (isEmptyElement) {
            message.destroy();
            message.error(
              `Column contains blank header. Kindly add column header and re-upload the file.`
            );
            return false;
          }
          //check file has contains more than 500000
          if (records > 500000) {
            message.destroy();
            message.error(
              `Kindly upload the file having less than 500000 records`
            );
            return false;
          }
          //check file has column contains less than or equal to 150
          if (colLength > 150) {
            message.destroy();
            message.error(
              `Kindly upload the file having less than 150 columns`
            );
            return false;
          }
          if (taskName.length > 50) {
            setTaskNameLimit(true);
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
          //stop spinner to page
          setFullspinner(false);
          props.disableNext();
          setDragFileFunction(true);

          let errorMessage = err.message;
          if (errorMessage.includes('timeout')) {
            message.destroy();
            message.error('Your connection timeout.');
          } else {
            message.destroy();
            ShowOopsMsg();
          }
        })
        .finally(() => {
          // always executed
        });
    } else {
      message.destroy();
      message.error(
        msgConfig(errorMessage(`Kindly upload the csv or xlsx file`))
      );
      setFileTask(null);
      setFileList([]);
      setTaskName('');
      setServerExcelName(''); //set default excel server file name
      props.disableNext();
      return false;
    }
    // Prevent upload
    return false;
    // }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  if (props.step !== 0) {
    return null;
  } else {
    //set task name and check all values present or not
    const handleOnChange = (event) => {
      message.destroy();
      let inputTaskName = event.target.value;
      setTaskName(inputTaskName);
      if (inputTaskName.length > 50) {
        setTaskNameLimit(true);
        props.disableNext();
      } else {
        setTaskNameLimit(false);
        props.enableNext();
      }
      // scrollToBottom();
    };

    const handleOnBlur = (event) => {
      console.log('handleOnBlur');
      message.destroy();
      let inputTaskName = event.target.value;
      let taskName = inputTaskName.trim();
      setTaskName(inputTaskName);
      if (fileTask === null) {
        message.destroy();
        message.error(msgConfig(errorMessage(`Kindly upload the file`)));
        props.disableNext();
      } else if (isEmptyHeaderElement) {
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(
              `Column contains blank header. Kindly add column header and re-upload the file.`
            )
          )
        );
        props.disableNext();
      } else if (fileSize > 104857600) {
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(`Kindly upload the file having less than 100MB size`)
          )
        );
        props.disableNext();
      } else if (taskName.length > 50) {
        props.disableNext();
      } else if (sheetName === 'Select Sheet Name') {
        message.destroy();
        message.error(msgConfig(errorMessage(`Select sheet name`)));
        props.disableNext();
      } else if (columnLength > 150) {
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(`Kindly upload the file having less than 150 columns`)
          )
        );
        props.disableNext();
      } else if (totalRecords <= 1) {
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(`Kindly upload the file having more than one records`)
          )
        );
        props.disableNext();
      } else if (totalRecords > 500000) {
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(
              `Kindly upload the file having less than 500000 records`
            )
          )
        );
        props.disableNext();
      } else if (taskName.length === 1) {
        message.destroy();
        message.error(
          msgConfig(errorMessage(`Task name must be at least two characters`))
        );
        props.disableNext();
      } else if (taskName.length < 1) {
        message.destroy();
        message.error(msgConfig(errorMessage(`Task name must be filled out`)));
        props.disableNext();
      } else if (
        taskName &&
        taskName.length > 1 &&
        fileTask &&
        sheetName !== 'Select Sheet Name' &&
        !isEmptyHeaderElement
      ) {
        console.log('File Name set with file in place');
        props.submittedValues([
          fileName,
          taskName,
          fileTask,
          fileData,
          totalRecords,
          headerData,
          sheetName,
          serverExcelName,
        ]);
      }
    };

    const handleRemove = () => {
      setShowSelectSheetName(false);
      setDragFile(null);
      setSheetName('Select Sheet Name');
      setFileTask(null);
      setFileList([]); //we want to remove uploaded file
      setTaskName('');
      setServerExcelName(''); //set default excel server file name
      setTaskNameLimit(false);
      //to reset value which is set in FieldmappingSteps
      props.removeAllSetData();
      props.disableNext();
    };

    //Dragger send attributes
    const fileProps = {
      accept: '.xlsx, .csv',
      name: 'file',
      multiple: false,
      // listType: 'picture',
      customRequest: dummyRequest,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onRemove: (files) => {
        handleRemove();
      },

      beforeUpload: (files) => {
        // console.log('befor upload..................', files);
        uploadedFileReader(files);
      },
      fileList,
      iconRender: (files, listType) => {
        let taskNameExtension = files.name.split('.').pop().toLowerCase(); //file extension to check which file is upload
        if (files) {
          return (
            <img
              src={
                taskNameExtension === 'xlsx'
                  ? imgXlsx
                  : taskNameExtension === 'csv'
                  ? imgCsv
                  : imgCsv
              }
              width="20px"
            />
          );
        }
      },
    };

    const selectSheetName = (selectedItem) => {
      // console.log(selectedItem);
      // console.log(excelFileInfo);
      if (selectedItem === null) {
        setSheetName('Select Sheet Name');
        props.disableNext();
        return false;
      } else {
        //set spinner to page
        setFullspinner(true);
        //set total record for per sheet initial 0
        setTotalRecords(0);
        //to reset value which is set in FieldmappingSteps
        props.removeAllSetData();
        //to read xlsx file
        let data;
        let newFileName;
        let totalColumnLength;
        let totalRow;
        //to get all sheet name in array
        excelFileInfo.map((items) => {
          items.map((item) => {
            newFileName = item.newFileName;
          });
        });
        setServerExcelName(newFileName); //set excel server file name

        //get by default sheet name
        setSheetName(selectedItem);
        //to fetch data with by default sheetname
        const getObjectOfSelectedSheet = excelFileInfo.map((items) => {
          return items.filter((item) => {
            return item.sheetName === selectedItem;
          });
        });
        const getDataArray = getObjectOfSelectedSheet.map((items) => {
          return items.map((item) => {
            totalColumnLength = item.columns;
            totalRow = item.rows;
            return item.data;
          });
        });
        //to store data in array
        getDataArray.map((items) => {
          items.map((item) => {
            data = item;
          });
        });
        //stop spinner to page
        setFullspinner(false);
        // console.log(data);
        // console.log('header data', data[0]);
        // console.log('total column', totalColumnLength);
        // console.log('total record', totalRow - 1);

        //check file contains 0 or 1 records
        if (totalRow <= 2) {
          message.destroy();
          message.error(
            `Kindly select the sheet having 1 or more than a records`
          );
          props.disableNext();
          return false;
        }

        setFileData(data); //set file data(records)
        setHeaderData(data[0]); // to get 1st row as header
        //check all header elements present or not
        let isEmptyElement = data[0].some(
          (items) => items === '' || items.trim().length === 0
        );
        setIsEmptyHeaderElement(isEmptyElement);

        const colLength = totalColumnLength;
        setColumnLength(colLength); //to set total no of records.
        const records = totalRow - 1;
        setTotalRecords(records); //to set total no of records.

        //send data to AddTaskWizard
        if (
          !isEmptyElement &&
          taskName &&
          taskName.length > 1 &&
          taskName.length <= 50 &&
          fileTask &&
          records <= 500000 &&
          records > 1 &&
          colLength <= 150
        ) {
          console.log('File uploaded as xlsx with sheet- upload');
          props.submittedValues([
            fileName,
            taskName,
            fileTask,
            data,
            records,
            data[0],
            selectedItem,
            newFileName,
          ]);
        } else {
          console.log('disable next during xlsx with sheet upload');
          props.disableNext();
        }
        //check file header contains non empty elements
        if (isEmptyElement) {
          message.destroy();
          message.error(
            `Column contains blank header. Kindly add column header and re-upload the file.`
          );
          return false;
        }
        //check file has contains more than 500000
        if (records > 500000) {
          message.destroy();
          message.error(
            `Kindly select the sheet having less than 500000 records`
          );
          return false;
        }
        //check file has column contains less than or equal to 150
        if (colLength > 150) {
          message.destroy();
          message.error(
            msgConfig(
              errorMessage(
                `Kindly select the sheet having less than 150 columns`
              )
            )
          );
          return false;
        }
        // to check length of task name
        if (taskName.length > 50) {
          setTaskNameLimit(true);
          return false;
        }
      }
    };

    return (
      <Spin tip="Processing..." spinning={fullspinner} size="large">
        <div className="uploadForm">
          <span className="uploadBox">
            <Dragger
              {...fileProps}
              className="parent"
              style={{ padding: fileList.length > 0 ? '0px' : '40px' }}>
              {/* <p className="ant-upload-drag-icon">
                <img src={uploadImg} width="40px" />
              </p> */}

              <p className="ant-upload-hint" style={{ margin: '20px 0px' }}>
                <p>
                  <span className="text-align-grid">
                    <img src={imgXlsx} width="50px" />
                  </span>
                  <span className="text-align-grid">
                    <img
                      src={imgCsv}
                      width="50px"
                      style={{ marginLeft: '35px' }}
                    />
                  </span>
                </p>
                <p className="ant-upload-hint">
                  <span>.xlsx</span>
                  <span> or </span>
                  <span> .csv</span>
                </p>
                <p className="ant-upload-hint">
                  Supports upto 100MB or 500,000 records
                </p>
              </p>
              {/* <br /> */}
              <p className="upload-add-text">
                Drag &#38; Drop &nbsp;&nbsp;&nbsp; or &nbsp;&nbsp;&nbsp;
                <span className="linkText" style={{ color: '#1E5AD0' }}>
                  Browse &#38; Select
                </span>
              </p>
            </Dragger>
          </span>
          <Row
            style={{
              margin: 0,
              padding: '10px 20px',
              width: '70%',
              margin: 'auto',
            }}
            gutter={16}
            justify="space-between"
            align="middle">
            {fileList.length > 0 && (
              <>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'normal',
                    margin: '0px 10px 10px 0px',
                  }}>
                  <label>
                    <span className="upload-file-lable">
                      File Name&nbsp;&nbsp;{' '}
                    </span>
                  </label>
                  <Popover
                    content={<span className="gray">{fileName}</span>}
                    placement="top"
                    color={'white'}>
                    <div className="uploaded-file-name">{fileName}</div>
                  </Popover>
                  <CloseCircleOutlined
                    style={{ marginLeft: '10px', fontSize: '20px' }}
                    onClick={() => {
                      handleRemove();
                    }}
                  />
                </Col>
              </>
            )}
            {showSelectSheetName ? (
              <>
                <Col span={24}>
                  <div>
                    <label>
                      <span className="upload-file-lable">
                        Task name&nbsp;{' '}
                      </span>
                      <Input
                        placeholder="Task name"
                        style={{
                          width: '80%',
                          marginBottom: '10px',
                          marginLeft: '10px',
                        }}
                        maxLength="50"
                        value={taskName}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                      />
                      {/* {taskNameLimit && (
                        <div className="taskNameValidation">
                          Task name exceeds 50 characters.
                        </div>
                      )} */}
                      {fileName.replace(/\.[^/.]+$/, '').length > 50 && (
                        <div className="taskNameValidation">
                          Task name restricted to 50 characters
                        </div>
                      )}
                    </label>
                  </div>
                </Col>
                <Col span={24}>
                  <div>
                    <label className="upload-file-lable">
                      Select Sheet&nbsp;{' '}
                    </label>
                    <Select
                      showSearch
                      style={{ width: '80%' }}
                      defaultValue={sheetName}
                      onChange={selectSheetName}
                      placeholder="Type to Search"
                      listHeight={150}>
                      <Select.Option>Select Sheet Name</Select.Option>
                      {workBookSheet.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </>
            ) : fileList && fileList.length > 0 ? (
              <Col span={24}>
                <div>
                  <label>
                    <span className="upload-file-lable">Task Name&nbsp; </span>
                    <Input
                      placeholder="Task Name"
                      style={{ width: '80%' }}
                      maxLength="50"
                      value={taskName}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                    />
                    {/* {taskNameLimit && (
                      <div className="taskNameValidation">
                        Task name exceeds 50 characters.
                      </div>
                    )} */}
                    {fileName.replace(/\.[^/.]+$/, '').length > 50 && (
                      <div className="taskNameValidation">
                        Task name restricted to 50 characters
                      </div>
                    )}
                  </label>
                </div>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>
      </Spin>
    );
  }
};

export default FileUploadStep;
