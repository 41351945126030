import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Popover } from 'antd';
import { numFormatter, numberWithCommas } from '../../config/constantMethod';
import coinStack from '../../assets/images/coins.png';
import { creditService } from '../../service/credit.service';
import { AuthContext } from '../../shared/auth/authContext';

const CreditAvailbleComp = (props) => {
  const { loggedInUser } = useContext(AuthContext);
  const [creditsAvailable, setCreditsAvailable] = useState(0);

  useEffect(() => {
    fetchCreditInfo();
  }, []);

  const fetchCreditInfo = () => {
    creditService
      .getClientCreditBalance(loggedInUser.clientId)
      .then((response) => {
        if (response.status === 200) {
          setCreditsAvailable(response.data.creditsAvailable);
        }
      })
      .catch((err) => {
        console.log('error in getCreditsBalance', err);
      })
      .finally(() => {});
  };
  const onHoverContent = (val) => (
    <div style={{ width: '115px' }}>
      <div>Credits Available</div>
      <span style={{ fontWeight: 600, textAlign: 'right' }}>
        {numberWithCommas(val)}
      </span>
    </div>
  );

  return (
    <div className="creditCardInfo" style={{ float: 'right' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <img src={coinStack} style={{ width: 'auto', height: '40px' }} />
        <Popover
          style={{ width: '150px' }}
          content={onHoverContent(creditsAvailable)}
          trigger="hover"
          placement="top">
          <div>
            <div className="creditsCount">{numFormatter(creditsAvailable)}</div>
            <div style={{ fontSize: '12px' }}>Credits Available</div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

CreditAvailbleComp.propTypes = {
  creditsAvailable: PropTypes.string,
};
export default CreditAvailbleComp;
