import React, { useState, useEffect } from 'react';
import {
  Spin,
  Button,
  Layout,
  Typography,
  notification,
  message,
  Empty,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './client.css';
import ClientTable from './components/ClientTable';
import ClientCreateForm from './components/ClientCreateForm';
import axios from 'axios';
import config, { roleIdOnClientTypes } from '../../config/constants';
import { AuthContext } from '../../shared/auth/authContext';
import ClientEditForm from './components/ClientEditForm';
import moment from 'moment';
import { Error_500 } from '../errorHandler';
import { useQueryCache, useQuery } from 'react-query';
import { clientService } from '../../service/client.service';
import emptyData from '../../assets/images/emptyData.png';
import { ShowOopsMsg } from '../../common/messageComp';

const { Title } = Typography;

const ClientList = () => {
  const { isAllowed, adminUserNameOld } = React.useContext(AuthContext);
  const queryCache = useQueryCache();
  const [loading, setLoading] = useState(false);
  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [displayEditForm, setDisplayEditForm] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [licenseList, setLicenseList] = useState([]);
  const userUrl = config.env.userServerUrl;

  //get all clients
  const fetchclients = (key) => clientService.getAll(key);
  const { isLoading, isError, isSuccess, data: resolvedData } = useQuery(
    ['clients'],
    fetchclients,
    {
      onSuccess: (resp) => {
        setIsDataPresent(true);
      },
    }
  );

  useEffect(() => {
    getLicenseList();
  }, []);

  //to add new client
  const addClient = (values) => {
    console.log(values.users);
    setLoading(true);
    let allowedroleid = 0;
    let roleAssigned = 0;

    //to set allowedroleid and roleAssigned on the basis of clientType
    roleIdOnClientTypes.forEach((roleAssignItem) => {
      if (roleAssignItem.label === values.clientType) {
        roleAssigned = roleAssignItem.roleId;
        allowedroleid = roleAssignItem.allowedRoleId;
      }
    });

    clientService
      .addClient({
        adminEmail: values.adminEmail,
        adminFirstName: values.adminFirstName,
        adminLastName: values.adminLastName,
        allowedroleid: allowedroleid,
        bccEmail: values.bccEmail,
        billerName: values.billerName,
        billingAddress: values.billingAddress,
        billingEmail: values.billingEmail,
        ccEmail: values.ccEmail,
        clientName: values.clientName,
        expiryDate: values.subscriptionEndDate,
        startDate: values.subscriptionStartDate,
        adminUsername: values.adminUsername,
        roleAssigned: roleAssigned,
        directDialAccess:
          values.SubscriptionType == 'Enterprise Standard' ? false : true,
        emailRefresherAccess:
          values.emailRefresherAccess == 'Yes' ? true : false,
        clientType: values.clientType,
        salesforceOrgId: values.salesforceOrgId,
        selectedProduct: values.selectedProduct,
        licenses: values.users,
        subscriptionType: values.SubscriptionType,
        smarteCrmId: values.SmarteCRMID,
        rateLimit:
          values.rateLimit !== undefined ? parseInt(values.rateLimit) : 1,
      })
      .then((res) => {
        console.log('add client response', res);
        setDisplayCreateForm(false);
        setLoading(false);
        message.destroy();
        message.success(
          `Client is registered. Admin can check email for account activation.`
        );
        queryCache.invalidateQueries(['clients']);
      })
      .catch((err) => {
        console.log('add client error', err.response.status);
        setLoading(false);
        if (err.response.status === 409) {
          message.destroy();
          message.error(
            `Duplicate Org Name/Email ID exists. Kindly enter unique values to create the account.`
          );
        } else {
          message.destroy();
          ShowOopsMsg();
        }
      });
  };

  //update client
  const updateClient = (values, clientStatus) => {
    console.log('Received values of form: ', values);
    setLoading(true);
    let status;
    //set status of client
    if (clientStatus === 'Active') {
      status = 'ACTIVE';
    } else {
      status = 'DEACTIVATED';
    }

    clientService
      .updateClient(
        {
          adminEmail: values.adminEmail,
          adminFirstName: values.adminFirstName,
          adminLastName: values.adminLastName,
          bccEmail: values.bccEmail,
          billerName: values.billerName,
          billingAddress: values.billingAddress,
          billingEmail: values.billingEmail,
          ccEmail: values.ccEmail,
          clientStatus: status,
          clientName: values.clientName,
          expiryDate: values.subscriptionEndDate,
          startDate: values.subscriptionStartDate,
          clientType: values.clientType,
          selectedProduct: values.selectedProduct,
          rateLimit:
            values.rateLimit !== undefined ? parseInt(values.rateLimit) : 1,
          licenses: values.users,
          adminUsername: values.adminUsername,
          directDialAccess: values.directDialAccess == 'Yes' ? true : false,
          emailRefresherAccess:
            values.emailRefresherAccess == 'Yes' ? true : false,
          clientType: values.clientType,
          salesforceOrgId: values.salesforceOrgId,
          subscriptionType: values.SubscriptionType,
          smarteCrmId: values.SmarteCRMID,
          adminUserNameOld: adminUserNameOld,
        },
        selectedClientId
      )
      .then((res) => {
        console.log('update user response', res);
        setDisplayEditForm(false);
        setLoading(false);
        message.destroy();
        message.success(`Client saved successfully.`);
        queryCache.invalidateQueries(['clients']);
      })
      .catch((err) => {
        console.log('update clinet', err);
        setLoading(false);
        if (err.response.status === 409) {
          message.destroy();
          message.error(
            `Duplicate Org Name exists. Kindly enter unique values to update the account.`
          );
        } else {
          message.destroy();
          ShowOopsMsg();
        }
      });
  };

  // to render all the license type
  const getLicenseList = () => {
    console.log('GetLicenseList called');
    clientService
      .getLicensesType()
      .then((res) => {
        console.log(res);
        setLicenseList(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
      })
      .finally(() => {
        // always executed
      });
  };

  //get client id and show edit form
  const showEditForm = (id) => {
    setSelectedClientId(id);
    setDisplayEditForm(true);
  };

  //reset password (mail send to client admin for activation/setpassword)
  const sendMail = (adminEmail) => {
    // console.log('send mail to user:', adminEmail);
    axios
      .get(`${userUrl}/v1/accounts/reset-password-link/${adminEmail}`)
      .then((resp) => {
        // console.log('send mail response', resp);
        message.destroy();
        message.success(`Password reset link sent to Client Admin`);
      })
      .catch((err) => {
        console.log('In Summary fetch error', err);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  //show when error is occurred
  if (isError) {
    return (
      <>
        <div style={{ padding: '10px' }}>
          <div>
            <Title className="sectionHeading" level={4}>
              <span>Manage Org</span>
            </Title>
          </div>
        </div>
        <div>
          <Error_500 />
        </div>
      </>
    );
  }

  //to show when list API successfully but no data is present
  if (isSuccess && resolvedData.data.length === 0) {
    return (
      <>
        <div
          style={{
            padding: '10px',
          }}>
          <div>
            <Title className="sectionHeading" level={4}>
              <span>Manage Org</span>
            </Title>
          </div>
        </div>
        <div style={{ paddingTop: '100px' }}>
          <Empty
            image={emptyData}
            imageStyle={{
              height: 100,
            }}
            description={<span>No Data</span>}>
            {isAllowed('CREATE_CLIENT_ACCOUNT') && (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    setDisplayCreateForm(true);
                  }}
                  icon={<PlusOutlined />}>
                  Create Org
                </Button>
              </div>
            )}
          </Empty>
        </div>
        {displayCreateForm && (
          <ClientCreateForm
            visible={displayCreateForm}
            licenseList={licenseList}
            onCreate={addClient}
            onCancel={() => {
              setDisplayCreateForm(false);
            }}
            isLoading={loading}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div>
          <Title className="sectionHeading" level={4}>
            <span>Manage Org</span>
          </Title>
        </div>
        {isAllowed('CREATE_CLIENT_ACCOUNT') && isDataPresent && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setDisplayCreateForm(true), getLicenseList();
              }}
              icon={<PlusOutlined />}>
              Create Org
            </Button>
          </div>
        )}
      </div>
      <Layout.Content className="layout" style={{ padding: '1px 1px' }}>
        <Spin tip="Loading..." spinning={isLoading} size="large">
          {displayCreateForm && (
            <ClientCreateForm
              visible={displayCreateForm}
              onCreate={addClient}
              licenseList={licenseList}
              onCancel={() => {
                setDisplayCreateForm(false);
              }}
              isLoading={loading}
            />
          )}

          {selectedClientId && displayEditForm && (
            <ClientEditForm
              visible={displayEditForm}
              clientId={selectedClientId}
              onUpdate={updateClient}
              licenseList={licenseList}
              onCancel={() => {
                setDisplayEditForm(false);
              }}
              isLoading={loading}
            />
          )}

          {resolvedData && (
            <ClientTable
              clients={resolvedData}
              showEditForm={showEditForm}
              sendMail={sendMail}
              update={true}
            />
          )}
        </Spin>
      </Layout.Content>
    </>
  );
};

export default ClientList;
