import React, { useEffect } from 'react';
import { Row, Col, Modal, Form, Input, DatePicker, Button, Select } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
const { Option } = Select;
const UserCreateForm = ({
  visible,
  onCreate,
  onCancel,
  isLoading,
  roleList,
  clientList,
  onClientSelect,
  disableRoleList,
  isSuperAdmin,
}) => {
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  //when open form then clear all fields
  useEffect(() => {
    // console.log('first time render stage');
    form.resetFields();

    return () => {
      // console.log('unmounting...stage');
      form.resetFields();
    };
  }, []);

  const handleSelect = (clientId) => {
    form.setFieldsValue({
      selectedRoleId: null,
    });
    onClientSelect(clientId);
  };

  return (
    <Modal
      visible={visible}
      title="Create User"
      destroyOnClose={true}
      width={'65%'}
      centered
      maskClosable={false}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
          loading={isLoading}>
          Save
        </Button>,
      ]}
      bodyStyle={{
        maxHeight: 'calc(100vh - 140px)',
        overflow: 'auto',
        padding: '20px',
      }}>
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        className="userForm"
        colon={false}
        validateTrigger="onBlur">
        <Row gutter={16}>
          {isSuperAdmin && (
            <Col span={12}>
              <Form.Item
                name="selectedClientId"
                label="Client"
                rules={[
                  {
                    required: true,
                    message: 'Please select client',
                  },
                ]}>
                <Select
                  placeholder="Select client"
                  onChange={handleSelect}
                  listHeight={140}>
                  {clientList !== undefined && clientList.length > 0
                    ? clientList
                        .sort((a, b) => {
                          return a.clientName.localeCompare(b.clientName);
                        })
                        .map((client, i) => {
                          return (
                            <Select.Option value={client.id} key={i}>
                              {client.clientName}
                            </Select.Option>
                          );
                        })
                    : ''}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="selectedRoleId"
              label="Role"
              rules={[
                {
                  required: true,
                  message: 'Please select role',
                },
              ]}>
              <Select placeholder="Select role" disabled={disableRoleList}>
                {roleList !== undefined && roleList.length > 0
                  ? roleList
                      .sort((a, b) => {
                        return a.name.localeCompare(b.name);
                      })
                      .map((role, i) => {
                        return (
                          <Select.Option value={role.id} key={i}>
                            {role.name}
                          </Select.Option>
                        );
                      })
                  : ''}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Enter your first name',
                },
                {
                  min: 2,
                  message: 'First name atleast 2 characters',
                },
                // {
                //   max: 50,
                //   message: 'First Name maximum 50 characters',
                // },
                {
                  pattern: new RegExp(/^[a-zA-Z]+$/),
                  message: 'First name must be alphabetic',
                },
              ]}>
              <Input placeholder="First Name" maxlength="50" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Enter your last name',
                },
                {
                  min: 2,
                  message: 'Last name atleast 2 characters',
                },
                // {
                //   max: 50,
                //   message: 'Last Name maximum 50 characters',
                // },
                {
                  pattern: new RegExp(/^[a-zA-Z]+$/),
                  message: 'Last name must be alphabetic',
                },
              ]}>
              <Input placeholder="Last Name" maxlength="50" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              autocomplete="off"
              name="userName"
              label="Username"
              rules={[
                {
                  type: 'email',
                  message: 'The username is not valid e-mail id!',
                },
                {
                  required: true,
                  message: 'Enter your username',
                },
              ]}>
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="userName" label="Email ID">
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email ID"
                readOnly
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

UserCreateForm.propTypes = {
  visible: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  roleList: PropTypes.array,
  clientList: PropTypes.array,
  onClientSelect: PropTypes.func,
  disableRoleList: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
};

export default UserCreateForm;
