import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Highcharts
import Highcharts from 'highcharts/highstock';
import drilldow from 'highcharts/modules/drilldown';
import ReactHighcharts from 'highcharts-react-official';
drilldow(Highcharts);

const ColumnChartStats = ({ columnData, reportId }) => {
  const [columnChartData, setColumnChartData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let chartData = columnData
      .filter((item) => item.name !== 'Total')
      .map((element) => {
        delete element['drilldown'];
        element.y = element.percentage;
        return element;
      });
    let categories = columnData.map((element) => {
      return element.name;
    });
    setColumnChartData(chartData);
    setCategories(categories);
  }, [columnData]);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  var labels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const options = {
    chart: {
      type: 'column',
      reflow: true,
      height: 300,
      stroke: 'transperant',
      style: {
        fontFamily: 'inherit',
      },
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },

    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          style: {
            textOutline: false,
            fontWeight: 'normal',
            textDecoration: 'none',
          },
        },
      },
    },

    xAxis: {
      categories: categories,
      labels: {
        skew3d: false,
        style: {
          fontSize: '10px',
          fontFamily: 'Roboto',
        },
      },
    },

    yAxis: {
      //show label and title of records
      title: {
        text: '% of Records',
      },
      min: 0,
      max: 100,
      opposite: false,
      labels: {
        formatter: function () {
          return labels[this.pos / 100];
        },
      },
      labels: {
        format: '{value} %',
      },
      skew3d: false,
    },

    legend: {
      //show name and color of records
      enabled: false,
      itemStyle: {
        fontWeight: 'normal',
      },
    },

    tooltip: {
      headerFormat:
        '{point.point.name}: {point.percentage:.1f}% | {point.point.count:,.0f}',
      pointFormat: '',
    },

    series: [
      {
        // name: reportId.charAt(0).toUpperCase() + reportId.slice(1).toLowerCase(),
        name: 'previous',
        colorByPoint: true,
        data: columnChartData,
        dataLabels: {
          enabled: true,
          padding: 0,
          distance: 10,
          fontWeight: 'normal',
        },
        size: '80%',
        cursor: 'pointer',
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [
              {
                id: 'versions',
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },

    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  return (
    <div style={{ padding: '20px 0px' }}>
      <ReactHighcharts highcharts={Highcharts} options={options} />
    </div>
  );
};

ColumnChartStats.propTypes = {
  columnData: PropTypes.array,
  reportId: PropTypes.string,
};

export default ColumnChartStats;
