export const autoFieldValue = [
  //Record ID
  {
    name: 'Record ID',
    values: [
      'con_id',
      'conid',
      'con-id',
      'contact id',
      'contact_id',
      'contactid',
      'contact-id',
      'crm id',
      'crm_id',
      'crmid',
      'crm-id',
      'customer id',
      'customer_id',
      'customerid',
      'customer-id',
      'id',
      'lead id',
      'lead_id',
      'leadid',
      'lead-id',
      'person_id',
      'personid',
      'person-id',
      'record id',
      'record_id',
      'record-id',
      'recordid',
      'salesforce id',
      'salesforce_id',
      'salesforceid',
      'salesforce-id',
      'unique id',
      'unique_id',
      'uniqueid',
      'unique-id',
      'user id',
      'user_id',
      'userid',
      'user-id',
    ],
  },
  //First Name
  {
    name: 'First Name',
    values: [
      'first name',
      'first_name',
      'firstname',
      'first-name',
      'fname',
      'contactfirstname',
    ],
  },
  // Middle Name
  {
    name: 'Middle Name',
    values: [
      'middle name',
      'middle_name',
      'middle-name',
      'middlename',
      'contactmiddlename',
    ],
  },
  // Last Name
  {
    name: 'Last Name',
    values: ['last name', 'last_name', 'lastname', 'lname', 'contactlastname'],
  },
  // Full Name
  {
    name: 'Full Name',
    values: [
      'con name',
      'cont name',
      'cont_name',
      'contact name',
      'contact_name',
      'contactfullname',
      'contactname',
      'contact-name',
      'contname',
      'cont-name',
      'full name',
      'full_name',
      'fullname',
      'full-name',
      'person name',
      'person_name',
      'personal_name',
      'personalname',
      'personname',
      'person-name',
    ],
  },
  // Email Id
  {
    name: 'Email ID',
    values: [
      'con_email',
      'con_e-mail',
      'conemail',
      'cone-mail',
      'contact email',
      'contact e-mail',
      'contact_email',
      'contact_e-mail',
      'contactemail',
      'contacte-mail',
      'contact-email',
      'electronic mail',
      'electronic_mail',
      'electronicmail',
      'electronic-mail',
      'email',
      'e-mail',
      'emails',
      'email address',
      'email id',
      'email_address',
      'email_id',
      'emailaddress',
      'email-address',
      'emailid',
      'email-id',
      'lead email',
      'lead e-mail',
      'lead_email',
      'lead_e-mail',
      'leademail',
      'leade-mail',
      'lead-email',
      'lead-e-mail',
      'mail',
      'mail_id',
      'mailid',
      'mail-id',
      'person_email',
      'person_e-mail',
      'personemail',
      'persone-mail',
    ],
  },
  // Job Title
  {
    name: 'Job Title',
    values: [
      'contactjobtitle',
      'job role',
      'job title',
      'job_role',
      'job_title',
      'jobrole',
      'job-role',
      'jobtitle',
      'job-title',
      'official title',
      'official_title',
      'officialtitle',
      'official-title',
      'professional title',
      'role',
      'title',
      'titles',
      'jt',
    ],
  },
  // Company Name
  {
    name: 'Company Name',
    values: [
      'account',
      'account name',
      'account_name',
      'accountname',
      'account-name',
      'business name',
      'business_name',
      'businessname',
      'business-name',
      'commercial name',
      'commercial_name',
      'commercialname',
      'commercial-name',
      'comp',
      'comp name',
      'comp_name',
      'company',
      'company name',
      'company name',
      'company_name',
      'companyname',
      'company-name',
      'compname',
      'comp-name',
      'corporate',
      'firm name',
      'firm_name',
      'firmname',
      'firm-name',
      'org',
      'org name',
      'org_name',
      'organisation',
      'organisation name',
      'organisation_name',
      'organisationname',
      'organisation-name',
      'organization',
      'organization name',
      'organization_name',
      'organization_name',
      'organizationname',
      'organization-name',
      'orgname',
      'org-name',
    ],
  },
  // Country
  {
    name: 'Country',
    values: [
      'billing country',
      'billing_country',
      'billingcountry',
      'billing-country',
      'client country',
      'client_country',
      'clientcountry',
      'client-country',
      'con country',
      'con_country',
      'concountry',
      'con-country',
      'contact country',
      'contact_country',
      'contactcountry',
      'contact-country',
      'country',
      'country name',
      'country/region',
      'country_name',
      'countryname',
      'country-name',
      'mailing country',
      'mailing_country',
      'mailingcountry',
      'mailing-country',
      'shipping country',
      'shipping_country',
      'shippingcountry',
      'shipping-country',
      'work location country',
      'work_location',
      'work_location_country',
      'work-location',
      'worklocationcountry',
      'work-location-country',
    ],
  },
  // Website
  {
    name: 'Website',
    values: [
      'webpage',
      'website',
      'account website',
      'account_website',
      'accountwebsite',
      'account-website',
      'business url',
      'business_url',
      'businessurl',
      'business-url',
      'comp website',
      'comp_website',
      'company url',
      'company website',
      'company_url',
      'company_website',
      'companyurl',
      'company-url',
      'companywebaddress',
      'companywebsite',
      'company-website',
      'compwebsite',
      'comp-website',
      'organizationurl',
      'organizationwebsite',
      'orgwebsite',
      'site',
      'sites',
      'url',
      'web address',
      'web_address',
      'webaddress',
      'web-address',
      'weburl',
    ],
  },
  //Contact LinkedIn Url
  {
    name: 'Contact LinkedIn URL',
    values: ['contact linkedin url', 'linkedin', 'contacturl'],
  },
  // Contact GUID
  {
    name: 'Contact GUID',
    values: [
      'con guid',
      'con_guid',
      'conguid',
      'con-guid',
      'contact guid',
      'contact_guid',
      'contactguid',
      'contact-guid',
      'person guid',
      'person_guid',
      'person-guid',
    ],
  },
  //Company LinkedIn Url
  {
    name: 'Company LinkedIn URL',
    values: ['companylnurl', 'comp_ln_url', 'company linkedin url', 'linkedin'],
  },
  // Company GUID
  {
    name: 'Company GUID',
    values: [
      'comp guid',
      'comp_guid',
      'compguid',
      'comp-guid',
      'company guid',
      'company_guid',
      'companyguid',
      'company-guid',
      'companyguid',
    ],
  },
];
