import axios from 'axios';
import config from '../config/constants';

const taskUrl = config.env.taskServerUrl;

export const apiService = {
  getContactInfo,
  getMobileNumber,
};

function getContactInfo(data) {
  return axios.post(`${taskUrl}/api/v1/gateway/peapi`, data);
}

function getMobileNumber(conGUID, lnurl) {
  return axios({
    method: 'GET',
    url: `${taskUrl}/api/v1/gateway/directdial?linkedinURL=${lnurl}&conGUID=${conGUID}`,
  });
}
