import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

function Error_404() {
  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <Result
      status="404"
      title="We're Sorry!"
      subTitle="We can’t find the page you were trying to reach."
      extra={
        <Button type="primary" onClick={goToPreviousPath}>
          Back
        </Button>
      }
    />
  );
}

export default Error_404;
