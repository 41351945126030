import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './authContext';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const { isAllowed } = useContext(AuthContext);
  return (
    // Show the component only when the user is admin
    // Otherwise, redirect the user to /dashboard page
    <Route
      {...rest}
      render={(props) =>
        isAllowed(rest.userPermissions) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

export default PrivateRoute;
