import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  AutoComplete,
  Button,
  Select,
  Switch,
  Divider,
  Radio,
  Space,
} from 'antd';

import EnrichContext from '../../context/EnrichContext';
import JsonResultComp from './JsonResultComp';
import { AuthContext } from '../../shared/auth/authContext';

const { Option } = Select;

const InputFormComp = ({ enrichLocalData }) => {
  const [form] = Form.useForm();
  const context = useContext(EnrichContext);
  const { isUserInRole } = useContext(AuthContext);
  const [disableButton, setDisableButton] = useState(true);
  const [localData, setLocalData] = useState(enrichLocalData);
  const [showError, setShowError] = useState(false);
  const [contactInput, setContactInput] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    // * previous enter data store in local storage
    setLocalData(enrichLocalData);

    // Set individual form value
    form.setFields([
      {
        name: 'enrichType',
        value: 'lite',
      },
    ]);
  }, []);

  // * Enrich button Summit
  const onCheck = async () => {
    setDisableButton(true);
    try {
      const values = await form.validateFields();

      // * send To JSON comp
      setContactInput(values);

      // * Submit form
      context.onSubmit(values);

      // if (
      //   values.contactEmail ||
      //   (values.contactFirstName &&
      //     values.contactLastName &&
      //     values.companyName) ||
      //   (values.contactFirstName &&
      //     values.contactLastName &&
      //     values.companyWebAddress)
      // ) {
      //   context.onSubmit(values);
      //   setShowError(false);
      // } else {
      //   setShowError(true);
      //   context.setIsLoading(false);
      // }
    } catch (errorInfo) {
      // setShowError(true);
      context.setIsLoading(false);
    }
  };

  /** check one of field is fill or not.
   *  If filled then button is enable for enrich
   *  if not filled  then button is disable
   **/
  const onChangeField = async (e) => {
    let value = e.target.value;
    if (value.length > 0 && disableButton) {
      setDisableButton(false);
    }
    const values = await form.validateFields();
    let status = Object.keys(values).some(
      (item, i) => values[item] !== undefined && values[item] !== ''
    );
    if (status) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  // * Clear form and response (Reset everything)
  const onClearResponse = () => {
    form.resetFields();
    setShowError(false);
    setLocalData(enrichLocalData);
    setSearchTerm('');
    setSearchResult([]);
    context.clearResponse();
    setDisableButton(true);
    // Set individual form value
    form.setFields([
      {
        name: 'enrichType',
        value: 'lite',
      },
    ]);
  };

  // * get search result when form is filling
  const getResult = (val, data, name) => {
    setSearchTerm(val);
    if (val !== '') {
      let array = [];
      array = data.filter((d) => {
        if (val) {
          if (d.toLowerCase().trim().includes(val.trim().toLowerCase())) {
            return d;
          }
        }
      });
      // * Deep cloning So that original array (localData) not affecting
      const previousData = JSON.parse(JSON.stringify(localData));
      previousData.forEach((item, i) => {
        if (item.name == name) {
          item.data = array;
        }
      });
      setSearchResult(previousData);
    } else {
      setSearchResult(localData);
    }
  };

  // * show in drop down available data in AutoComplete
  const renderOption = (item) => {
    return (
      <Option id={item} value={item}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            lineHeight: '25px',
          }}>
          <span style={{ fontSize: '14px' }}>{item}</span>
        </div>
      </Option>
    );
  };

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        name="input_form"
        className="inputForm"
        colon={false}
        validateTrigger="onBlur">
        <Row>
          <Col span={16}>
            <Card className="white-block-card">
              {/* {showError && <ValidationComp />} */}
              {!context.jsonView ? (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="contactFirstName"
                        label="First Name"
                        rules={[
                          {
                            required: false,
                            message: 'Enter First Name',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[0].data.map(renderOption)
                              : localData[0].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(
                                val,
                                localData[0].data,
                                'contactFirstName'
                              );
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="contactLastName"
                        label="Last Name"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Last Name',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[1].data.map(renderOption)
                              : localData[1].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(
                                val,
                                localData[1].data,
                                'contactLastName'
                              );
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="contactEmail"
                        label="Email ID"
                        rules={[
                          {
                            pattern: new RegExp(
                              /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*)+$/
                            ),
                            message: 'Enter valid email address',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[2].data.map(renderOption)
                              : localData[2].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(val, localData[2].data, 'contactEmail');
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="contactJobTitle"
                        label="Job Title"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Job Title',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[3].data.map(renderOption)
                              : localData[3].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(
                                val,
                                localData[3].data,
                                'contactJobTitle'
                              );
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="companyName"
                        label="Company Name"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Company Name',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[4].data.map(renderOption)
                              : localData[4].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(val, localData[4].data, 'companyName');
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="contactCountry"
                        label="Country"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Country',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[5].data.map(renderOption)
                              : localData[5].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(
                                val,
                                localData[5].data,
                                'contactCountry'
                              );
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="companyWebAddress"
                        label="Company Website"
                        rules={[
                          {
                            required: false,
                            message: 'Enter Company Website',
                          },
                        ]}>
                        <AutoComplete
                          size="large"
                          style={{ width: '100%' }}
                          dataSource={
                            searchTerm.length > 1
                              ? searchResult[6].data.map(renderOption)
                              : localData[6].data.map(renderOption)
                          }
                          onSelect={() => setDisableButton(false)}
                          onSearch={(val) => {
                            if (val.length > 1) {
                              getResult(
                                val,
                                localData[6].data,
                                'companyWebAddress'
                              );
                            } else {
                              setLocalData(enrichLocalData);
                              setSearchTerm(val);
                            }
                          }}
                          optionLabelProp="text">
                          <Input
                            maxLength="255"
                            style={{ width: '100%' }}
                            onChange={onChangeField}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                    {(isUserInRole('Premium Admin with Direct Dial') ||
                      isUserInRole('Internal Business User with Direct Dial') ||
                      isUserInRole('Client Admin with Direct Dial') ||
                      isUserInRole('Business User with Direct Dial')) && (
                      <Col span={24}>
                        <Form.Item
                          name="contactUrl"
                          label="Contact LinkedIn URL"
                          rules={[
                            {
                              pattern: new RegExp(
                                /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/
                              ),
                              message:
                                'Please input valid Contact LinkedIn URL',
                            },
                          ]}>
                          <AutoComplete
                            size="large"
                            style={{ width: '100%' }}
                            dataSource={
                              searchTerm.length > 1
                                ? searchResult[7].data.map(renderOption)
                                : localData[7].data.map(renderOption)
                            }
                            onSelect={() => setDisableButton(false)}
                            onSearch={(val) => {
                              if (val.length > 1) {
                                getResult(val, localData[7].data, 'contactUrl');
                              } else {
                                setLocalData(enrichLocalData);
                                setSearchTerm(val);
                              }
                            }}
                            optionLabelProp="text">
                            <Input
                              maxLength="255"
                              style={{ width: '100%' }}
                              onChange={onChangeField}
                            />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    )}
                    {(isUserInRole('Premium Admin with Direct Dial') ||
                      isUserInRole(
                        'Internal Business User with Direct Dial'
                      )) && (
                      <Col span={24}>
                        <Form.Item
                          name="contactGuid"
                          label="Contact GUID"
                          rules={[
                            {
                              validator: (rule, value, cb) => {
                                if (value) {
                                  if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
                                    cb('Please input valid Contact GUID');
                                  } else if (value.length !== 32) {
                                    cb('Please input valid Contact GUID');
                                  }
                                }
                                cb();
                              },
                            },
                          ]}>
                          <AutoComplete
                            size="large"
                            style={{ width: '100%' }}
                            dataSource={
                              searchTerm.length > 1
                                ? searchResult[8].data.map(renderOption)
                                : localData[8].data.map(renderOption)
                            }
                            onSelect={() => setDisableButton(false)}
                            onSearch={(val) => {
                              if (val.length > 1) {
                                getResult(
                                  val,
                                  localData[8].data,
                                  'contactGuid'
                                );
                              } else {
                                setLocalData(enrichLocalData);
                                setSearchTerm(val);
                              }
                            }}
                            optionLabelProp="text">
                            <Input
                              maxLength="255"
                              style={{ width: '100%' }}
                              onChange={onChangeField}
                            />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    )}
                    {(isUserInRole('Premium Admin with Direct Dial') ||
                      isUserInRole('Internal Business User with Direct Dial') ||
                      isUserInRole('Client Admin with Direct Dial') ||
                      isUserInRole('Business User with Direct Dial')) && (
                      <Col span={24}>
                        <Form.Item
                          name="companyLnUrl"
                          label="Company LinkedIn URL"
                          rules={[
                            {
                              pattern: new RegExp(
                                /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com/
                              ),
                              message:
                                'Please input valid Company LinkedIn URL',
                            },
                          ]}>
                          <AutoComplete
                            size="large"
                            style={{ width: '100%' }}
                            dataSource={
                              searchTerm.length > 1
                                ? searchResult[9].data.map(renderOption)
                                : localData[9].data.map(renderOption)
                            }
                            onSelect={() => setDisableButton(false)}
                            onSearch={(val) => {
                              if (val.length > 1) {
                                getResult(
                                  val,
                                  localData[9].data,
                                  'companyLnUrl'
                                );
                              } else {
                                setLocalData(enrichLocalData);
                                setSearchTerm(val);
                              }
                            }}
                            optionLabelProp="text">
                            <Input
                              maxLength="255"
                              style={{ width: '100%' }}
                              onChange={onChangeField}
                            />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    )}
                    {(isUserInRole('Premium Admin with Direct Dial') ||
                      isUserInRole(
                        'Internal Business User with Direct Dial'
                      )) && (
                      <Col span={24}>
                        <Form.Item
                          name="companyGuid"
                          label="Company GUID"
                          rules={[
                            {
                              validator: (rule, value, cb) => {
                                if (value) {
                                  if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
                                    cb('Please input valid Company GUID');
                                  } else if (value.length !== 16) {
                                    cb('Please input valid Company GUID');
                                  }
                                }
                                cb();
                              },
                            },
                          ]}>
                          <AutoComplete
                            size="large"
                            style={{ width: '100%' }}
                            dataSource={
                              searchTerm.length > 1
                                ? searchResult[10].data.map(renderOption)
                                : localData[10].data.map(renderOption)
                            }
                            onSelect={() => setDisableButton(false)}
                            onSearch={(val) => {
                              if (val.length > 1) {
                                getResult(
                                  val,
                                  localData[10].data,
                                  'companyGuid'
                                );
                              } else {
                                setLocalData(enrichLocalData);
                                setSearchTerm(val);
                              }
                            }}
                            optionLabelProp="text">
                            <Input
                              maxLength="255"
                              style={{ width: '100%' }}
                              onChange={onChangeField}
                            />
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <div>
                  <JsonResultComp contact={contactInput} type={'request'} />
                </div>
              )}
            </Card>
          </Col>
          <Col span={8}>
            <div className="action-btn">
              <Button
                htmlType="submit"
                type="primary"
                onClick={onCheck}
                disabled={disableButton ? true : false}
                loading={context.isLoading ? true : false}>
                Enrich
              </Button>
              <Button type="ghost" onClick={onClearResponse}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
InputFormComp.contextType = EnrichContext;

InputFormComp.propTypes = {
  enrichLocalData: PropTypes.array,
};

export default InputFormComp;

const ValidationComp = () => {
  return (
    <div className="validation-Comp">
      <div className="">
        One of below is mandatory input for <strong>Company Matchback</strong>
      </div>
      <div className="combination-ul mTop5">
        <ul className="margin0 padding0">
          <li>Email OR</li>
          <li>Company Name OR</li>
          <li>Website </li>
        </ul>
      </div>
      <div className="">
        One of below is mandatory input for <strong>Contact Matchback</strong>
      </div>
      <div className="combination-ul mTop5">
        <ul className="margin0 padding0">
          <li>Email OR</li>
          <li>First Name and Last Name </li>
        </ul>
      </div>
    </div>
  );
};
