import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Radio,
  Row,
  Select,
  message,
  Spin,
  Input,
} from 'antd';
import CreditAvailbleComp from '../../CreditAvailble';
import { connectorService } from '../../../../service/connector.service';
import {
  msgConfig,
  taskSubmitMessage,
  ShowOopsMsg,
} from '../../../../common/messageComp';
import '../connectorTask.css';

const { Option } = Select;
const AddMarketoTask = (props) => {
  const [form] = Form.useForm();
  const [staticList, setStaticList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [errorThrow, setErrorThrow] = useState(false);
  const [taskName, setTaskName] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  const submitTask = (values) => {
    let staticListObject = values.staticList.split(/_(.+)/);
    let ruleMapping = [];
    let submitData = {
      staticListInfo: {
        id: staticListObject[0],
        name: staticListObject[1],
      },
      taskName: values.taskName,
      priority: 'HIGH',
      ruleMapping,
    };
    connectorService
      .submitConnectorTask(submitData)
      .then((response) => {
        setIsLoading(false);
        message.destroy();
        props.onCancelUploadTask();
        message.success(msgConfig(taskSubmitMessage()));
        props.refetchTaskList();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        let errorMessage = err.message;
        if (errorMessage.includes('timeout')) {
          message.destroy();
          message.error('Your connection timeout.');
        } else {
          message.destroy();
          ShowOopsMsg();
          props.onCancelUploadTask();
        }
      });
  };

  useLayoutEffect(() => {
    getStaticList();
  }, []);

  const getStaticList = () => {
    setListLoading(true);
    connectorService
      .fetchStaticList('LEAD')
      .then((response) => {
        setStaticList(response.data);
        setListLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // TODO need to handle error
        setStaticList([]);
        ShowOopsMsg();
        setListLoading(false);
      });
  };

  const handleSelect = (val) => {
    if (val) {
      let taskNameVal = val.split(/_(.+)/);
      setFileName(taskNameVal[1]);
      let taskName = taskNameVal[1]
        .toString()
        .replace(/\.[^/.]+$/, '')
        .substr(0, 50);
      setTaskName(taskName);
      form.setFieldsValue({
        taskName,
      });
    }
  };

  return (
    <div>
      <Card
        className="addTaskCardLayout"
        bodyStyle={{ height: '420px' }}
        bordered={false}
        title={
          <span>
            <div style={{ padding: '10px', borderBottom: '1px solid #f0f0f0' }}>
              Create Task
            </div>
          </span>
        }
        actions={[
          <Button
            style={{ width: '100px' }}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  setIsLoading(true);
                  submitTask(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}>
            Submit
          </Button>,
          <Button
            type="link"
            key="cancel"
            className="footer-cancel-btn"
            onClick={props.closeUploadTask}>
            Cancel
          </Button>,
        ]}>
        <Spin tip="Loading..." spinning={listLoading} size="large">
          <Form
            form={form}
            layout="horizontal"
            name="marketo-task"
            validateTrigger="onBlur"
            colon={false}>
            <Row style={{ paddingTop: '10px' }}>
              <Col span={19}>
                <span>Marketo List Enrichment</span>
                <Form.Item
                  name="staticList"
                  label={<span>Static List&nbsp;&nbsp;&nbsp;</span>}
                  style={{ marginTop: '10px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Select static list',
                    },
                  ]}>
                  <Select
                    style={{ width: '15rem' }}
                    showSearch
                    listHeight={140}
                    onChange={handleSelect}
                    placeholder="Type to search">
                    {staticList.length > 0
                      ? staticList
                          .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                          })
                          .map((item, i) => {
                            return (
                              <Option value={`${item.id}_${item.name}`} key={i}>
                                {item.name}
                              </Option>
                            );
                          })
                      : []}
                  </Select>
                </Form.Item>
                <Form.Item>
                  Records present in this static list will be fetched & enriched
                </Form.Item>
              </Col>
              <div>
                <CreditAvailbleComp creditsAvailable={14945} />
              </div>
              {taskName !== undefined && taskName && (
                <Col span={24}>
                  <Form.Item
                    name="taskName"
                    rules={[
                      {
                        required: true,
                        message: 'Enter task name',
                      },
                    ]}
                    label="Task Name">
                    <Input
                      placeholder="Task Name"
                      style={{ width: '62%' }}
                      maxLength="50"
                    />
                  </Form.Item>
                  {fileName.length > 50 && (
                    <div className="taskNameRestriction">
                      Task name restricted to 50 characters
                    </div>
                  )}
                </Col>
              )}
            </Row>
            {/* <Row style={{ height: '100px' }}>
              <Col>
                <Form.Item
                  name="ddEnrich"
                  label={
                    <span style={{ marginLeft: '10px' }}>
                      Mobile Number Enrichment?
                    </span>
                  }>
                  <Radio.Group
                    onChange={(val) => setErrorThrow(false)}
                    defaultValue="with-direct-dial">
                    <Radio value="with-direct-dial">With Mobile Number</Radio>
                    <Radio value="without-direct-dial">
                      Without Mobile Number
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

AddMarketoTask.propTypes = {
  closeUploadTask: PropTypes.func,
  onCancelUploadTask: PropTypes.func,
  refetchTaskList: PropTypes.func,
};

export default AddMarketoTask;
