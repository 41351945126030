import React from 'react';
import { Spin } from 'antd';

function Spinner() {
  return (
    <div
      style={{
        fontSize: '4em',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spin size="large" />
    </div>
  );
}

export { Spinner };
