import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Spin } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import moment from 'moment';
import PieChartStats from './PieChartStats';
import StatsTable from './StatsTable';
import config from '../../../config/constants';
import ColumnChartStats from './ColumnChartStats';
import {
  email_deliverability_score,
  record_status,
  employee_range,
  revenue_range,
  level,
  function_stats,
  level_function_derivable_stats,
  level_function_derivable,
  color_code_stats,
  com_matchback,
  mobileno_enrichment,
} from '../../../config/constants';
import { sortFunction, sortFunByField } from '../../../config/constantMethod';
import EmptyData from '../../../assets/images/DashboardEmptyData.png';

const taskUrl = config.env.taskServerUrl;

function StatsTask({ taskId, reportId, statsName, tableName, taskName, key }) {
  //table icon
  const [showTableIcon, setShowTableIcon] = useState(true);
  //show chart or no data text
  const [showCharts, setShowCharts] = useState(true);
  //loading status
  const [isLoading, setIsLoading] = useState(false);
  //to show table
  const [showTable, setShowTable] = useState(false);
  //to store pieChart data
  const [pieData, setPieData] = useState([]);
  //to store drilldown data
  const [drillDownData, setDrillDownData] = useState([]);
  //last update time
  const [statsLastUpdatedOn, setStatsLastUpdatedOn] = useState('');
  //to store Internal Record status data
  const [recordStatus, setRecordStatus] = useState([]);
  //To open popup to show table
  const showStatsTable = () => {
    setShowTable((preState) => !preState);
  };

  //fetch stats of specific task
  const fetchStatsData = (taskId, reportId) => {
    let url = `${taskUrl}/api/v1/tasks/${taskId}/stats/${reportId}`;

    if (
      reportId === 'statsRegion' ||
      reportId === 'statsFunction' ||
      reportId === 'statsLevel'
    ) {
      url = `${taskUrl}/api/v1/tasks/${taskId}/${reportId}`;
    }
    setShowCharts(false);
    setIsLoading(true);
    axios
      .get(url)
      .then((resp) => {
        // console.log(resp.data);
        if (statsName == 'Internal Record Status') {
          setRecordStatus(resp.data);
        }
        convertResponseIntoArray(resp.data);
        setStatsLastUpdatedOn(resp.data.attributes.lastUpdatedOn);
        setShowCharts(true);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {});
    // }
  };

  //convert response into required JSON
  const convertResponseIntoArray = (responseObject) => {
    let chartData = [];
    let chartdrilldownData = [];
    //response object const stats data is iterate
    responseObject.stats
      .sort(function (a, b) {
        if (statsName == 'Region & Country') {
          if (a.name !== 'Total' && a.name !== 'Unavailable') {
            return b.count - a.count;
          }
        }
      })
      .map((items, index) => {
        if (Object.keys(items).length !== 0 && items.constructor === Object) {
          let objPieChart = {};
          let drillDownArray = [];
          let objDrillDownData = {};
          //piechart JSON array
          let name = items.name;
          objPieChart.key = index;
          objPieChart.name = items.name;
          objPieChart.y = items.count;
          objPieChart.count = items.count;
          objPieChart.percentage = Number(items.percentage);
          objPieChart.lastUpdatedDate = items.lastUpdatedDate;
          // color apply according to requirement
          if (statsName == 'Region & Country') {
            color_code_stats.map((obj, i) => {
              if (index == i) {
                if (items.name == 'Unavailable') {
                  objPieChart.color = 'rgb(237,125,49)';
                } else {
                  objPieChart.color = obj.color;
                }
              }
            });
          } else if (statsName == 'Function') {
            function_stats.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else if (statsName == 'Level') {
            level.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else if (statsName == 'Email Deliverability Score') {
            email_deliverability_score.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else if (
            statsName == 'Record Status' ||
            statsName == 'Internal Record Status'
          ) {
            record_status.map((obj, i) => {
              if (
                obj.name.toLocaleLowerCase() == items.name.toLocaleLowerCase()
              ) {
                objPieChart.color = obj.color;
              }
            });
          } else if (
            statsName == 'Revenue Range' ||
            statsName == 'Employee Size'
          ) {
            objPieChart.color =
              items.name == 'Unavailable' ? 'rgb(237,125,49)' : '#4472c4';
          } else if (statsName == 'Level Function Availability') {
            level_function_derivable.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else if (statsName == 'Company Matchback') {
            com_matchback.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else if (statsName == 'Mobile Number Enrichment') {
            mobileno_enrichment.map((obj, i) => {
              if (obj.name == items.name) {
                objPieChart.color = obj.color;
              }
            });
          } else {
            // constant color for unavailable in any stats
            objPieChart.color =
              items.name == 'Unavailable' ? 'rgb(237,125,49)' : undefined;
          }

          //drilldown JSON array
          if (Array.isArray(items.subStatsList)) {
            objDrillDownData.name = name;
            objDrillDownData.id = name;
            //drilldown for Internal Record Status as per JSON array
            if (statsName == 'Internal Record Status') {
              items.subStatsList.map((internalItem, i) => {
                if (Array.isArray(internalItem.internalSubStats)) {
                  if (items.name !== 'Error') {
                    objPieChart.drilldown = name;
                    objPieChart.tableDrilldown = name;
                  }
                  if (internalItem.internalSubStats.length > 0) {
                    internalItem.internalSubStats.map((item, index) => {
                      let drillObject = {};
                      drillObject.key = index;
                      drillObject.name = item.name;
                      drillObject.y = item.count;
                      drillObject.count = item.count;
                      drillObject.percentage = Number(item.percentage);

                      drillDownArray.push(drillObject);
                    });
                  } else {
                    let drillObject = {};
                    drillObject.key = index;
                    drillObject.name = internalItem.name;
                    drillObject.y = internalItem.count;
                    drillObject.count = internalItem.count;
                    drillObject.percentage = Number(internalItem.percentage);
                    drillDownArray.push(drillObject);
                  }
                }
              });

              drillDownArray.length > 0 &&
                drillDownArray.map((a, index) => {
                  color_code_stats.map((obj, i) => {
                    if (index == i) {
                      a.color = obj.color;
                    }
                  });
                });
            } else {
              //drilldown for other stats as per JSON array
              objPieChart.drilldown = name;
              objPieChart.tableDrilldown = name;
              items.subStatsList.map((item, index) => {
                let drillObject = {};
                drillObject.key = index;
                drillObject.name = item.name;
                drillObject.y = item.count;
                drillObject.count = item.count;
                drillObject.percentage = Number(item.percentage);
                if (
                  items.name == 'Unavailable' ||
                  items.name == 'Non-Enriched' ||
                  items.name == 'Enriched'
                ) {
                  record_status.map((obj, i) => {
                    if (obj.name.toLowerCase() == item.name.toLowerCase()) {
                      drillObject.color = obj.color;
                    }
                  });
                } else {
                  color_code_stats.map((obj, i) => {
                    if (index == i) {
                      drillObject.color = obj.color;
                    }
                  });
                }

                drillDownArray.push(drillObject);
              });
            }
            objDrillDownData.data = drillDownArray;
            // }
          }
          // setPieData((oldArray) => [...oldArray, objPieChart]);
          chartData.push(objPieChart);
          chartdrilldownData.push(objDrillDownData);
        }
      });
    // sort function for align chart data
    sortChartData(chartData, chartdrilldownData);
  };

  // sort function for align chart data
  const sortChartData = (chartData, chartdrilldownData) => {
    setIsLoading(false);

    if (statsName == 'Record Status') {
      let data = sortFunByField(chartData, record_status);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else if (statsName == 'Revenue Range') {
      let data = sortFunction(chartData, revenue_range);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else if (statsName == 'Employee Size') {
      let data = sortFunction(chartData, employee_range);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else if (statsName == 'Level Function Availability') {
      let data = sortFunction(chartData, level_function_derivable_stats);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else if (statsName == 'Level') {
      let data = sortFunByField(chartData, level);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else if (statsName == 'Function') {
      let data = sortFunByField(chartData, function_stats);
      setPieData(data);
      setDrillDownData(chartdrilldownData);
    } else {
      // if (
      //   statsName == 'Region & Country' ||
      //   statsName == 'Email Deliverability Score' ||
      //   statsName == 'Internal Record Status'
      // ) {
      setPieData(chartData);
      setDrillDownData(chartdrilldownData);
    }
  };

  //execute when record id is get
  useEffect(() => {
    setPieData([]);
    setDrillDownData([]);
    fetchStatsData(taskId, reportId);
  }, [taskId]);

  return (
    <>
      {/* first block */}
      <div className="mainFrameBody">
        <div className="mainFrameHeader">
          <div className="mainFrameTitle">{statsName}</div>
        </div>
        <Spin tip="Loading..." spinning={isLoading} size="large">
          <div className="mainFrameStats">
            {showCharts ? (
              <>
                {statsName == 'Revenue Range' ||
                statsName == 'Employee Size' ? (
                  //  show Columncharts table
                  <ColumnChartStats columnData={pieData} reportId={reportId} />
                ) : (
                  //  show Piecharts table
                  <PieChartStats
                    pieData={pieData}
                    drillDownData={drillDownData}
                    reportId={reportId}
                    key={key}
                    statsName={statsName}
                  />
                )}

                {showTableIcon ? (
                  <div className="toggle-chart">
                    <TableOutlined
                      className="table-icon"
                      onClick={showStatsTable}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="NDTD">
                {!isLoading ? (
                  <span>
                    <img src={EmptyData} />
                    <br />
                    <br />
                    <div>No Data Available</div>
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          {/* {statsLastUpdatedOn ? (
            <div className="lastBox">
              <span className="lastUpdateText">
                Last Refreshed On{' '}
                {moment.utc(statsLastUpdatedOn).local().format('lll')}
              </span>
            </div>
          ) : null} */}
        </Spin>
      </div>

      {/*  showing stats table modal*/}
      {showTable ? (
        <>
          <StatsTable
            statsName={statsName}
            visible={showTable}
            onCancel={() => {
              setShowTable((preState) => !preState);
            }}
            statsLastUpdatedOn={statsLastUpdatedOn}
            statsTableData={pieData}
            statsDrillDownData={drillDownData}
            tableName={tableName}
            taskName={taskName}
            data={
              statsName == 'Internal Record Status'
                ? recordStatus
                : // recordStatus
                  undefined
            }
          />
        </>
      ) : null}
    </>
  );
}

StatsTask.propTypes = {
  statsName: PropTypes.string,
  reportId: PropTypes.string,
  taskId: PropTypes.number,
  tableName: PropTypes.string,
  taskName: PropTypes.string,
  key: PropTypes.string,
};

// export default StatsTask;
export default React.memo(StatsTask);
