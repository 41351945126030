import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Input, Modal, Spin, Tag } from 'antd';

const { TextArea } = Input;
const { CheckableTag } = Tag;

const tagsData = [
  { name: '20K', value: '20000' },
  { name: '50K', value: '50000' },
  { name: '100K', value: '100000' },
  { name: '500K', value: '500000' },
  { name: '1M', value: '1000000' },
];

const CreditsRequestComp = ({
  onCancel,
  clientId,
  onSubmit,
  isLoading,
  visible,
}) => {
  const [form] = Form.useForm();

  const [errorShow, setErrorShow] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  const onFinish = (values) => {
    if (selectedTags) {
      onSubmit({
        credit: selectedTags,
      });
      resetFields();
    } else {
      setErrorShow(true);
    }
  };

  const onSelectTag = (tag, status) => {
    setErrorShow(false);
    resetFields();
    setSelectedTags(tag);
  };

  const resetFields = () => {
    form.resetFields();
    setSelectedTags(null);
    setErrorShow(false);
  };

  return (
    <Modal
      title="Get More Credits"
      className="getMoreCreditModal"
      visible={visible}
      onCancel={() => {
        onCancel();
        resetFields();
      }}
      footer={false}>
      <div className="CreditsRequestComp">
        <div style={{ padding: '10px', height: '200px' }}>
          <Form form={form} onFinish={onFinish} validateTrigger="onBlur">
            <Row gutter={16}>
              <Col span={24}>
                <div>
                  <h4>How many credits do you need? </h4>
                  {tagsData.map((tag) => (
                    <CheckableTag
                      className="checkableTagCredits"
                      key={tag}
                      style={{
                        backgroundColor:
                          selectedTags !== undefined &&
                          selectedTags == tag.value
                            ? 'var(--blue)'
                            : '',
                        color:
                          selectedTags !== undefined &&
                          selectedTags == tag.value
                            ? 'white'
                            : 'black',
                      }}
                      checked={selectedTags}
                      onChange={(checked) => onSelectTag(tag.value, checked)}>
                      {tag.name}
                    </CheckableTag>
                  ))}
                </div>
                <Form.Item
                  name="credit"
                  style={{ marginBottom: '5px' }}
                  rules={[
                    {
                      required: false,
                      message: 'Enter credits',
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value) {
                          if (
                            !value.match(
                              /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
                            )
                          ) {
                            cb('Enter a numeric value greater than zero');
                          } else if (value % 1 !== 0 || value.includes('.')) {
                            cb('Decimal value is not allowed');
                          } else if (value > 10000000) {
                            cb('Credits should not more than 10 millions');
                          }
                        }
                        cb();
                      },
                    },
                  ]}>
                  <Input
                    style={{
                      border: 'none',
                      borderBottom: ' 0.5px solid #bdbdbd',
                      width: '120px',
                      marginTop: '28px',
                    }}
                    placeholder="or Enter Credits"
                    onChange={(val) => {
                      setSelectedTags(val.target.value);
                      setErrorShow(false);
                    }}
                  />
                </Form.Item>
                {errorShow && (
                  <span style={{ color: 'var(--danger)' }}>
                    Please select or enter credits
                  </span>
                )}
              </Col>
              {/* <Col span={19}>
              <Form.Item
                name="comments"
                label={<span style={{ fontWeight: '600' }}>Comments</span>}>
                <TextArea
                  placeholder="Enter comments"
                  style={{ width: '100%' }}
                  rows={2}
                />
              </Form.Item>
            </Col> */}
            </Row>
            <Row>
              <div className="editClientFooter">
                <Button
                  type="default"
                  onClick={() => {
                    resetFields();
                    onCancel();
                  }}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{ marginLeft: '5px' }}>
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

CreditsRequestComp.propTypes = {
  onCancel: PropTypes.func,
  clientId: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
};

export default CreditsRequestComp;
