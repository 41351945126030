import React, { useState } from 'react';
import { Table, Tooltip, Alert, Radio } from 'antd';
import DropdownFields from './DropdownFields';
import {
  InfoCircleOutlined,
  UnorderedListOutlined,
  TableOutlined,
} from '@ant-design/icons';
import ListViewStep from './ListViewStep';
import GridViewMappingStep from './GridViewMappingStep';
import ListViewGrey from '../../../assets/images/Field grey.png';
import ListViewBlue from '../../../assets/images/field blue.png';
import GridViewGrey from '../../../assets/images/Gird grey.png';
import GridViewBlue from '../../../assets/images/Grid_blue.png';

const duplicateText = 'Duplicate column';
function FieldMappingStep(props) {
  const [dataindex, setDataIndex] = useState();
  const [mappingView, setMappingView] = useState('GRID_VIEW');

  // filter field pass to dropdown list
  function checkNotMapped(item, dropDownIndex) {
    let fieldNotMapped = item.position === null; // Available for selection
    if (fieldNotMapped) {
      return true; //allow mapping of this entry
    }
    //if mapped but belongs to current drop down itself then also allow mapping
    let fieldBelongsToCurrentDropDown = item.position == dropDownIndex;
    if (fieldBelongsToCurrentDropDown) {
      return true;
    }
    return false;
  }
  const getFilterFields = (dropDownIndex) => {
    return props.fields.filter((item) => checkNotMapped(item, dropDownIndex));
  };

  //filter field pass to show selected value in dropdown
  const mappedFields = props.fields.filter((item) => {
    return item.position !== null;
  });

  //to store select dropdown value and update items array
  const handleChange = (selectedItem, id) => {
    let divElement = document.getElementById(id);
    let element = divElement
      ? divElement.getElementsByClassName('ant-select-selector')
      : null;
    if (
      element !== null &&
      element !== undefined &&
      element[0] !== null &&
      element[0] !== undefined
    ) {
      if (selectedItem !== null) {
        element[0].style.color = 'var(--primary)';
        element[0].style.setProperty(
          'border',
          '1px solid var(--primary)',
          'important'
        );
      } else {
        element[0].style.color = '#d9d9d9';
        element[0].style.setProperty(
          'border',
          '1px solid #d9d9d9',
          'important'
        );
      }
    }

    props.fields.forEach((item) => {
      if (item.position === dataindex) {
        item.position = null;
        item.mappedBy = null;
        console.log('clear', item);
      }
      if (item.name === selectedItem || item.apiFieldName === selectedItem) {
        item.position = dataindex;
        item.mappedBy = props.headerData[dataindex];
        console.log('set', item);
      }
    });
    props.feildValidation(selectedItem);
  };

  const handleChangeListView = (dataIndex, fieldName) => {
    // let dataIndex =
    //   selectedItem !== null
    //     ? props.headerData.findIndex((x) => x == selectedItem)
    //     : null;
    let dataIndexList = dataIndex !== null ? dataIndex.toString() : dataIndex;
    let dataIndexValue =
      dataIndexList !== null ? props.headerData[dataIndexList] : '';

    props.fields.forEach((item) => {
      if (item.mappedBy === dataIndexValue) {
        item.position = null;
        item.mappedBy = null;
        console.log('clearList', item);
      }
      if (item.name.toLowerCase().trim() === fieldName.toLowerCase().trim()) {
        item.position = dataIndexList;
        item.mappedBy = props.headerData[dataIndexList]
          ? props.headerData[dataIndexList]
          : null;
        console.log('setList', item);
      }
    });

    props.feildValidation();
  };
  if (props.step !== 1) {
    return null;
  }

  //to get all records dynamically
  const getHeaders = () => {
    const formattedTasks = [];
    const tableHeader = props.fileData[0] ? Object.keys(props.fileData[0]) : [];
    //we create columns
    tableHeader.map((val, index) =>
      formattedTasks.push({
        title: (
          <DropdownFields
            filteredOptions={getFilterFields(index)}
            mappedFields={mappedFields}
            handleChange={handleChange}
            index={index}
            id={`${index}-DropdownFields`}
          />
        ),
        dataIndex: val,
        key: val,
        width: 185,
        // align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (text) => {
          if (props.duplicateElementArray.includes(text)) {
            return (
              <div className="displayFlex">
                <span>{text}</span>
                <Tooltip placement="bottom" title={duplicateText}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              </div>
            );
          } else {
            return text;
          }
        },
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              // console.log("Onheader click dataindex",column)
              setDataIndex(column.dataIndex);
            },
          };
        },
      })
    );

    return formattedTasks;
  };

  // const getSpecificNumberOfRows = (fileData) => {
  //   let lowerLimit = 0;
  //   let upperLimit = 11;

  //   return fileData.slice(lowerLimit, upperLimit);
  // };

  if (props.step === 1) {
    // console.log('mappingView', props);
    return (
      <div>
        <div className="taskViewRadio">
          <Radio.Group
            value={mappingView}
            onChange={(e) => {
              // console.log(e.target.value);
              setMappingView(e.target.value);
            }}>
            <Tooltip
              title={<span className="gray">GRID VIEW</span>}
              color={'white'}
              placement="bottom">
              <Radio.Button
                value="GRID_VIEW"
                style={{ padding: '0 7px', borderRadius: '8px 0px 0px 8px' }}>
                {mappingView == 'GRID_VIEW' ? (
                  <img src={GridViewBlue} />
                ) : (
                  <img src={GridViewGrey} />
                )}
              </Radio.Button>
            </Tooltip>
            <Tooltip
              title={<span className="gray">LIST VIEW</span>}
              color={'white'}
              placement="bottom">
              <Radio.Button
                value="LIST_VIEW"
                style={{ padding: '0 7px', borderRadius: '0px 8px 8px 0px' }}>
                {mappingView == 'LIST_VIEW' ? (
                  <img src={ListViewBlue} />
                ) : (
                  <img src={ListViewGrey} />
                )}
              </Radio.Button>
            </Tooltip>
            s
          </Radio.Group>
        </div>
        {mappingView == 'GRID_VIEW' && (
          <GridViewMappingStep
            step={props.step}
            fileData={props.fileData}
            fields={props.fields}
            defaultIconCompanyMatchback={props.defaultIconCompanyMatchback}
            defaultIconContactMatchback={props.defaultIconContactMatchback}
            companyMatchback={props.companyMatchback}
            contactMatchback={props.contactMatchback}
            matchBackBlock={props.matchBackBlock}
            isDuplicate={props.isDuplicate}
            duplicateElementArray={props.duplicateElementArray}
            getHeaders={getHeaders}
          />
        )}
        {mappingView == 'LIST_VIEW' && (
          <div>
            <ListViewStep
              step={props.step}
              fields={props.fields}
              headerData={props.headerData}
              fileData={props.fileData}
              defaultIconCompanyMatchback={props.defaultIconCompanyMatchback}
              defaultIconContactMatchback={props.defaultIconContactMatchback}
              companyMatchback={props.companyMatchback}
              contactMatchback={props.contactMatchback}
              matchBackBlock={props.matchBackBlock}
              getFilterFields={getFilterFields}
              handleChange={handleChangeListView}
              mappedFields={mappedFields}
              duplicateElementArray={props.duplicateElementArray}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FieldMappingStep;
