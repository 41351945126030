import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Modal, Table, Collapse, Typography, Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import {
  APP_STANDARD_DATE_FORAMT,
  enrichModeArray,
} from '../../../config/constants';
import { numberWithCommas } from '../../../config/constantMethod';
import {
  CaretRightOutlined,
  DownloadOutlined,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { AuthContext } from '../../../shared/auth/authContext';
import EnrichModeDetails from './EnrichModeDetails';
import { creditService } from '../../../service/credit.service';
import config from '../../../config/constants';

const { Column } = Table;
const { Panel } = Collapse;
const { Link } = Typography;

const taskUrl = config.env.taskServerUrl;

function TaskSummary(props) {
  const { isAllowed, isUserInRole, loggedInUser } = useContext(AuthContext);
  const [expand, setExpand] = useState('Field Mapping : Expand');
  const [isActive, setIsActive] = useState(undefined);
  const [inputFileName, setInputFileName] = useState('');
  const [record, setRecord] = useState();
  const [userName, setUserName] = useState('');
  const [isEnrichMode, setIsEnrichMode] = useState(false);
  const [creditsUsed, setCreditsUsed] = useState(null);
  const [taskId, setTaskId] = useState();
  const {
    createdOn,
    fileName,
    orgFileName,
    name,
    records,
    fieldMapping,
    fileType,
    sheetName,
    internalId,
    updatedOn,
    completedOn,
    status,
    createdBy,
    id,
    apiHeaderValues,
    clientName,
  } = props.specificTaskDetails;
  const taskCreatedDate = moment
    .utc(createdOn)
    .local()
    .format(APP_STANDARD_DATE_FORAMT);
  const taskCompletedDate = moment
    .utc(completedOn)
    .local()
    .format(APP_STANDARD_DATE_FORAMT);

  //check file type and change according to file name
  useEffect(() => {
    setIsActive(undefined);
    // console.log('props', props.specificTaskDetails);
    if (fileType) {
      setInputFileName(fileName.replace(/\.[^.]+$/, fileType));
    } else {
      setInputFileName(fileName);
    }
    if (createdBy) {
      let uploadUserName = createdBy.firstName + ' ' + createdBy.lastName;
      setUserName(uploadUserName);
    }
    if (records) {
      //to convert no into string with comma e.g 1000 into 1,000
      setRecord(records.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }
    if (apiHeaderValues) {
      if (apiHeaderValues.length !== 0) {
        setIsEnrichMode(true);
      } else {
        setIsEnrichMode(false);
      }
    }

    if (id) {
      setTaskId(id);
      getUserCreditsUsed(id);
    }
  }, [props.specificTaskDetails]);

  //upload file details
  const uploadFileDetails = () => {
    //upload details
    const uploadDetails = [
      {
        key: 1,
        name: 'Task ID',
        value: internalId,
      },
      {
        key: 2,
        name: 'File Name',
        value: orgFileName,
      },
      {
        key: 3,
        name: 'Task Name',
        value: name,
      },
      {
        key: 4,
        name: 'Sheet Name',
        value: sheetName !== '' ? sheetName : null,
      },
      {
        key: 5,
        name: 'Total Record Count',
        value: record,
      },
      {
        key: 6,
        name: 'Credits used',
        value: creditsUsed !== null ? creditsUsed : '---',
      },
    ];
    //filter array whose value is null that column is not shown in table
    return uploadDetails.filter((item) => {
      return (
        item.value !== null && item.value !== undefined && item.value !== ''
      );
    });
  };

  // Render Upload Details :
  const renderContent = (item, row, index) => {
    if (item.name == 'File Name') {
      return (
        <div>
          {isAllowed('DOWNLOAD_IN_FILE') ? (
            <>
              <Link
                className="actionBtn"
                style={{ color: 'var(--text-color)' }}
                href={`${taskUrl}/api/v1/downloadInput?id=${taskId}&authorization=${loggedInUser.jwtToken}`}
                target="_blank">
                {item.value} &nbsp;
                <DownloadOutlined /> &nbsp;
              </Link>
            </>
          ) : (
            <>{item.value}</>
          )}
        </div>
      );
    } else if (item.name == 'Credits used') {
      if (creditsUsed !== null) {
        return (
          <div>
            <Row>
              <Col
                span={creditsUsed.taskType === 'dd' ? 9 : 6}
                className={
                  creditsUsed.taskType !== 'dd'
                    ? 'boldText textOverFlow'
                    : 'textOverFlow'
                }>
                Record Enrichment
              </Col>
              <Col span={4}>{numberWithCommas(creditsUsed.peApiCredits)}</Col>
            </Row>
            {creditsUsed.taskType === 'dd' && (
              <>
                <Divider style={{ margin: '2px' }} />
                <Row>
                  <Col span={9} className="textOverFlow">
                    Mobile Number Enrichment
                  </Col>
                  <Col span={4}>{numberWithCommas(creditsUsed.ddCredits)}</Col>
                </Row>
                <Divider style={{ margin: '2px' }} />

                <Row>
                  <Col span={9} className="boldText textOverFlow">
                    Total
                  </Col>
                  <Col span={2} className="boldText">
                    {numberWithCommas(creditsUsed.total)}
                  </Col>
                </Row>
              </>
            )}
          </div>
        );
      } else {
        return '---';
      }
    } else {
      return item.value !== null ? item.value : '-';
    }
  };

  const getUserCreditsUsed = (id) => {
    creditService
      .getCreditUsed(id)
      .then((res) => {
        let responseData = res.data;
        if (responseData !== null && responseData !== '') {
          if (res.status === 200) {
            setCreditsUsed(responseData);
          } else {
            setCreditsUsed(null);
          }
        } else {
          setCreditsUsed(null);
        }
      })
      .catch((err) => {
        setCreditsUsed(null);
      })
      .finally(() => {
        // always executed
      });
  };
  //expand and collapse field mapping
  const toggle = (key) => {
    console.log('toggle', key);
    setIsActive(key);
  };

  //Task User Details
  const userDetails = [
    {
      key: 'createdBy',
      name: 'Created By',
      value: userName,
    },
    {
      key: 'clientName',
      name: 'Org Name',
      value: clientName !== null ? clientName : '---',
    },
    {
      key: 'createdOn',
      name: 'Created On',
      value: taskCreatedDate,
    },
    {
      key: 'completedOn',
      name: 'Completed On',
      value: taskCompletedDate,
    },
  ];

  //get userDetails by it's status
  const getUserDetails = () => {
    if (isUserInRole('Super Admin')) {
      if (status === 'COMPLETE') {
        return userDetails;
      } else {
        return userDetails.filter((item) => item.key !== 'completedOn');
      }
    } else {
      // for normal user
      if (status === 'COMPLETE') {
        return userDetails.filter((item) => item.key !== 'clientName');
      } else {
        return userDetails
          .filter((item) => item.key !== 'completedOn')
          .filter((item) => item.key !== 'clientName');
      }
    }
  };

  //feild mapping by default
  const fieldMap = [
    {
      key: 1,
      name: 'Record ID ',
      value: 'recordId',
    },
    {
      key: 2,
      name: 'First Name ',
      value: 'firstName',
    },
    {
      key: 3,
      name: 'Middle Name ',
      value: 'middleName',
    },
    {
      key: 4,
      name: 'Last Name ',
      value: 'lastName',
    },
    {
      key: 5,
      name: 'Full Name ',
      value: 'fullName',
    },
    {
      key: 6,
      name: 'Email ID ',
      value: 'email',
    },
    {
      key: 7,
      name: 'Job Title ',
      value: 'jobTitle',
    },
    {
      key: 8,
      name: 'Company Name ',
      value: 'companyName',
    },
    {
      key: 9,
      name: 'Country ',
      value: 'contactCountry',
    },
    {
      key: 10,
      name: 'Website ',
      value: 'companyWebAddress',
    },
  ];

  //data Source for Field Mapping table
  const getFieldMap = () => {
    if (fieldMapping && fieldMapping.length) {
      // to reduce array into key value pair
      const mapFieldName = fieldMapping.reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur.mappedBy };
      }, {});

      //mapping field name
      const matchFieldValue = Object.values(mapFieldName);

      //to get keys of match field
      const matchFieldKey = Object.keys(mapFieldName);

      //default field mapping
      const fieldMap = [
        {
          key: 1,
          name: 'Record ID ',
          value: matchFieldValue[0] !== 'null' ? matchFieldValue[0] : '--',
        },
        {
          key: 2,
          name: 'First Name ',
          value: matchFieldValue[1] !== 'null' ? matchFieldValue[1] : '--',
        },
        {
          key: 3,
          name: 'Middle Name ',
          value: matchFieldValue[2] !== 'null' ? matchFieldValue[2] : '--',
        },
        {
          key: 4,
          name: 'Last Name ',
          value: matchFieldValue[3] !== 'null' ? matchFieldValue[3] : '--',
        },
        {
          key: 5,
          name: 'Full Name ',
          value: matchFieldValue[4] !== 'null' ? matchFieldValue[4] : '--',
        },
        {
          key: 6,
          name: 'Email ID ',
          value: matchFieldValue[5] !== 'null' ? matchFieldValue[5] : '--',
        },
        {
          key: 7,
          name: 'Job Title ',
          value: matchFieldValue[6] !== 'null' ? matchFieldValue[6] : '--',
        },
        {
          key: 8,
          name: 'Company Name ',
          value: matchFieldValue[7] !== 'null' ? matchFieldValue[7] : '--',
        },
        {
          key: 9,
          name: 'Country ',
          value: matchFieldValue[8] !== 'null' ? matchFieldValue[8] : '--',
        },
        {
          key: 10,
          name: 'Website ',
          value: matchFieldValue[9] !== 'null' ? matchFieldValue[9] : '--',
        },
        {
          key: 11,
          name: 'Contact LinkedIn URL ',
          value: matchFieldValue[10] !== 'null' ? matchFieldValue[10] : '--',
        },
        {
          key: 12,
          name: 'Contact GUID ',
          value: matchFieldValue[11] !== 'null' ? matchFieldValue[11] : '--',
        },
        {
          key: 13,
          name: 'Company LinkedIn URL ',
          value: matchFieldValue[12] !== 'null' ? matchFieldValue[12] : '--',
        },
        {
          key: 14,
          name: 'Company GUID ',
          value: matchFieldValue[13] !== 'null' ? matchFieldValue[13] : '--',
        },
      ];

      //to show mapped field in summary
      const summaryFieldMap = fieldMap.filter((fieldMapItem) => {
        let name = fieldMapItem.name.replace(':', '');
        let fieldName = name.trim();
        return matchFieldKey.some(
          (items) =>
            items.toLowerCase().trim() === fieldName.toLowerCase().trim()
        );
      });

      return summaryFieldMap;
    } else return fieldMap;
  };

  return (
    <>
      <div>
        <Modal
          title="Task Summary"
          visible={props.showSummary}
          onCancel={props.closeSummary}
          maskClosable={false}
          footer={null}
          width={'60%'}
          centered
          destroyOnClose={true}
          bodyStyle={{
            maxHeight: 'calc(100vh - 140px)',
            overflow: 'auto',
          }}>
          <div>
            <div
              className="labelBoldText"
              style={{ color: '#454545', paddingLeft: '15px' }}>
              Upload Details
            </div>
            <div style={{ padding: '20px' }}>
              <Table
                rowKey="id"
                dataSource={uploadFileDetails()}
                size="small"
                showHeader={false}
                pagination={false}>
                <Column
                  title="name"
                  dataIndex="name"
                  key="name"
                  ellipsis={true}
                  className="fieldLabel"
                />
                <Column
                  title="value"
                  width="75%"
                  // dataIndex="value"
                  key="value"
                  ellipsis={true}
                  render={renderContent}
                />
              </Table>
            </div>
            <Collapse
              className="taskSummaryCollapse"
              onChange={toggle}
              accordion
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpOutlined style={{ fontSize: '16px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '16px' }} />
                )
              }
              activeKey={isActive}
              ghost>
              {fieldMapping && fieldMapping.length && (
                <Panel
                  header={<span className="labelBoldText">Field Mapping</span>}
                  key="FIELD_MAPPING">
                  <Table
                    rowKey="id"
                    dataSource={getFieldMap()}
                    size="small"
                    // showHeader={false}
                    pagination={false}>
                    <Column
                      title="SMARTe Fields"
                      dataIndex="name"
                      key="name"
                      className="fieldLabel"
                      ellipsis={true}
                    />
                    <Column
                      title="File Mapped Fields"
                      width="75%"
                      dataIndex="value"
                      key="value"
                      ellipsis={true}
                    />
                  </Table>
                </Panel>
              )}

              {isEnrichMode && loggedInUser.clientType != 'Regular' ? (
                <Panel
                  header={<span className="labelBoldText">Enrich Mode</span>}
                  key="ENRICH_MODE">
                  <EnrichModeDetails
                    apiHeaderValues={apiHeaderValues}
                    enrichModeValue={enrichModeArray}
                  />
                </Panel>
              ) : null}
            </Collapse>
            {/* <div style={{ paddingLeft: '20px' }}>
              {expand === 'Field Mapping : Collapse' ? '' : '...'}
            </div> */}
            <div style={{ padding: '5px' }}></div>
            <div
              className="labelBoldText"
              style={{ color: '#454545', paddingLeft: '15px' }}>
              Task User Details
            </div>
            <div style={{ padding: '20px' }}>
              <Table
                rowKey="id"
                dataSource={getUserDetails()}
                size="small"
                showHeader={false}
                pagination={false}>
                <Column
                  title="name"
                  dataIndex="name"
                  key="name"
                  ellipsis={true}
                  className="fieldLabel"
                />
                <Column
                  title="value"
                  width="75%"
                  dataIndex="value"
                  key="value"
                  ellipsis={true}
                />
              </Table>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

TaskSummary.propTypes = {
  specificTaskDetails: PropTypes.object,
  showSummary: PropTypes.bool,
  closeSummary: PropTypes.func,
};

export default TaskSummary;
