import React, { useLayoutEffect } from 'react';
import './forgotPassword.css';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useState } from 'react';
import axios from 'axios';
import config from '../../config/constants';
import logo from '../../assets/images/outer-logo-small.png';
import { CheckCircleTwoTone } from '@ant-design/icons';

const userUrl = config.env.userServerUrl;

function ForgotPassword() {
  const [form] = Form.useForm();
  const year = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const submitForm = (userName) => {
    console.log('username is:', userName);
    setIsLoading(true);
    axios
      .get(`${userUrl}/v1/accounts/reset-password-link/${userName}`)
      .then((res) => {
        let responseData = res;
        console.log('username is verify', responseData.status);
        if (responseData.status === 200) {
          {
            Modal.success({
              icon: false,
              title: (
                <span style={{ textAlign: 'center' }}>
                  <h3>
                    <CheckCircleTwoTone
                      twoToneColor="#1ade80"
                      style={{
                        float: 'left',
                        fontSize: '25px',
                      }}
                    />
                    Your password reset email has been sent!
                  </h3>
                </span>
              ),
              content: (
                <span style={{ textAlign: 'center' }}>
                  <div>
                    If you have an active account, you will receive password
                    reset email in your email address.
                  </div>
                  <div>Please check your inbox to continue.</div>
                </span>
              ),
              centered: true,
              width: '50%',
              onOk() {
                window.location = '/login';
              },
            });
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('username is not verify');
        console.log(err);
        Modal.error({
          title: 'Your account is deactivated.',
          content: 'Please contact your Administrator',
          centered: true,
          width: '30%',
          onOk() {
            window.location = '/login';
          },
        });
        setIsLoading(false);
      })
      .finally(() => {
        // always executed
      });
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    const { userName } = values;
    console.log('your username:', userName);
    submitForm(userName);
  };

  return (
    <>
      <div className="loginBox">
        <div id="centerBox-forgot-page">
          <div className="loginBox">
            <Form name="login" form={form} onFinish={onFinish}>
              <div className="loginContent">
                <div className="forgot-header">
                  <span className="titleText">Reset Password</span>
                </div>
                <div style={{ margin: '20px 0px' }}>
                  <Form.Item
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: 'Please provide a valid Username!',
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid email!',
                      },
                    ]}>
                    <Input
                      className="login-input"
                      id="login-username"
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="layout-btn"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      style={{ width: '50%', margin: '0px 25%' }}>
                      Reset Password
                    </Button>
                  </Form.Item>
                </div>
                <div className="forgot-footer">
                  <span className="light">
                    Already have login and password ?&nbsp;
                  </span>
                  <Link to="/login">Sign in</Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
