import fieldMappingimg from '../assets/images/Field-Mapping-blue.png';
import DfieldMappingimg from '../assets/images/Field-Mapping-grey.png';
import enrichModeImg from '../assets/images/Enrich-blue.png';
import DenrichModeImg from '../assets/images/Enrich-grey.png';
import taskSummaryImg from '../assets/images/Task-complete-blue.png';
import DtaskSummaryImg from '../assets/images/Dtasksummary.png';
import FileUpload from '../assets/images/Upload-blue.png';

const config = {
  env: {
    taskServerUrl: process.env.REACT_APP_API_TASK,
    userServerUrl: process.env.REACT_APP_API_USER,
    apiUrl: {
      fetchTaskListApi: '',
      fetchSpecificTaskApi: '',
      uploadTaskApi: '',
      downloadTaskApi: '',
    },
    maxRecordCount: 500000,
  },
};

export default config;

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_STANDARD_DATE_FORAMT = 'ddd, DD-MMM-YYYY hh:mm A';
export const APP_SHORT_DATE_FORMAT = 'DD-MMM-YYYY';

//give to client for assign role
export const roleAssign = [
  {
    roleId: 2,
    name: 'Client Admin',
    allowedRoleId: 3,
  },
  {
    roleId: 4,
    name: 'Premium Admin',
    allowedRoleId: 5,
  },
  {
    roleId: 6,
    name: 'Premium Admin with Direct Dial	',
    allowedRoleId: 7,
  },
  {
    roleId: 8,
    name: 'Client Admin with Direct Dial',
    allowedRoleId: 9,
  },
];

export const roleIdOnClientTypes = [
  {
    roleId: 6,
    label: 'Premium',
    name: 'Premium Admin with Direct Dial	',
    allowedRoleId: 7,
  },
  {
    roleId: 8,
    label: 'Regular',
    name: 'Client Admin with Direct Dial',
    allowedRoleId: 9,
  },
];

export const productTypes = [
  {
    label: 'Enrich OnDemand',
    value: 1,
  },
  {
    label: 'Enrich API',
    value: 2,
  },
];

// field that give to user for mapping
export const fieldMappingArray = [
  // Record ID
  {
    name: 'Record ID',
    position: null,
    mappedBy: null,
    apiFieldName: 'recordId',
  },
  // First Name
  {
    name: 'First Name',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactFirstName',
  },
  // Middle Name
  {
    name: 'Middle Name',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactMiddleName',
  },
  // Last Name
  {
    name: 'Last Name',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactLastName',
  },
  // Full Name
  {
    name: 'Full Name',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactFullName',
  },
  // Email Id
  {
    name: 'Email ID',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactEmail',
  },
  // Job Title
  {
    name: 'Job Title',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactJobTitle',
  },
  // Company Name
  {
    name: 'Company Name',
    position: null,
    mappedBy: null,
    apiFieldName: 'companyName',
  },
  // Country
  {
    name: 'Country',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactCountry',
  },
  // Website
  {
    name: 'Website',
    position: null,
    mappedBy: null,
    apiFieldName: 'companyWebAddress',
  },
  // Contact LinkedIn Url
  {
    name: 'Contact LinkedIn URL',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactUrl',
  },
  // Contact GUID
  {
    name: 'Contact GUID',
    position: null,
    mappedBy: null,
    apiFieldName: 'contactGuid',
  },
  // Company LinkedIn URL
  {
    name: 'Company LinkedIn URL',
    position: null,
    mappedBy: null,
    apiFieldName: 'companyLnUrl',
  },
  // Company GUID
  {
    name: 'Company GUID',
    position: null,
    mappedBy: null,
    apiFieldName: 'companyGuid',
  },
];

//enrich mode value
export const enrichModeArray = [
  //Email Verifier Run
  {
    name: 'Email Verifier Run',
    value: 'enl',
    headerName: 'ev-value',
    option: [
      {
        name: 'Yes',
        value: 'enl',
      },
      {
        name: 'Yes - Limited',
        value: 'enl-ltd',
      },
      {
        name: 'No',
        value: 'dsl',
      },
    ],
  },
  //Company Match Process
  {
    name: 'Company Match Process',
    value: 'EXT',
    headerName: 'compmb-value',
    option: [
      {
        name: 'Exact Only',
        value: 'EXT',
      },
      {
        name: 'Exact + Fuzzy',
        value: 'EFZ',
      },
    ],
  },
  //Company Index Reach
  {
    name: 'Company Index Reach',
    value: 'FUL',
    headerName: 'index-value',
    option: [
      {
        name: 'Full',
        value: 'FUL',
      },
      {
        name: 'Prod',
        value: 'PRD',
      },
    ],
  },
  //Export Fields
  {
    name: 'Export Fields',
    value: 'LTD',
    headerName: 'output-value',
    option: [
      {
        name: 'Limited',
        value: 'LTD',
      },
      {
        name: 'All',
        value: 'All',
      },
    ],
  },
  //Direct Dial Run
  // {
  //   name: 'Enrich Type',
  //   value: 'without-direct-dial',
  //   headerName: 'direct-dial-run',
  //   option: [
  //     {
  //       name: 'Lite',
  //       value: 'without-direct-dial',
  //     },
  //     {
  //       name: 'Premium',
  //       value: 'with-direct-dial',
  //     },
  //   ],
  // },
];

//Company Matchback
export const com_matchback = [
  { key: 0, name: 'Enriched', color: 'rgb(112,173,71)' },
  { key: 2, name: 'Non-Enriched', color: '#FFC001' },
  { key: 5, name: 'Unavailable', color: 'rgb(237,125,49)' },
];

//Mobile Number Enrichment
export const mobileno_enrichment = [
  { key: 0, name: 'Enriched', color: 'rgb(112,173,71)' },
  { key: 2, name: 'Non-Enriched', color: '#FFC001' },
  { key: 5, name: 'Unavailable', color: 'rgb(237,125,49)' },
];

export const email_deliverability_score = [
  { key: 0, name: '100', color: 'rgb(112,173,71)' },
  { key: 1, name: '70-99', color: 'rgb(144,237,125)' },
  { key: 2, name: '60-69', color: '#FFC001' },
  { key: 3, name: '50-59', color: 'rgb(247,163,92)' },
  { key: 4, name: '0-49', color: '#FEBDB4' },
  { key: 5, name: 'Unavailable', color: 'rgb(237,125,49)' },
];

export const record_status = [
  { key: 0, name: 'Active', color: 'rgb(112,173,71)' },
  { key: 1, name: 'No Longer', color: 'rgb(169,209,142)' },
  { key: 2, name: 'Company Match', color: 'rgb(209,244,185)' },
  { key: 3, name: 'No Match', color: 'rgb(255,192,0)' },
  { key: 4, name: 'Possible Junk', color: 'rgb(237,125,49)' },
  { key: 5, name: 'Junk Or Ineligible', color: 'rgb(221, 117, 117)' },
  { key: 6, name: 'Error', color: 'rgb(230,78,49)' },
  { key: 7, name: 'Total', color: '' },
];

export const employee_range = [
  '10,001+',
  '5001 to 10,000',
  '1001 to 5000',
  '501 to 1000',
  '201 to 500',
  '51 to 200',
  '11 to 50',
  '1 to 10',
  'Unavailable',
  'Total',
];

export const revenue_range = [
  '$1B+',
  '$500 - 1B',
  '$250 - 500M',
  '$100 - 250M',
  '$50 - 100M',
  '$10 - 50M',
  '$1 - 10M',
  '$0 - 1M',
  'Unavailable',
  'Total',
];

export const level = [
  { name: 'Board Members/Trustee', color: '#3437ed' },
  { name: 'Founder/CXO', color: '#ffc000' },
  { name: 'MD', color: '#40C4FF' },
  { name: 'VP', color: '#E040FB' },
  { name: 'Head', color: '#fb7764' },
  { name: 'Director', color: '#c9d2d2' },
  { name: 'Manager', color: '#619fd2' },
  { name: 'Staff/Principal', color: '#f19f6d' },
  { name: 'Other', color: '#9ba3b9' },
  { name: 'Retired', color: '#fa5356' },
  { name: 'Below Manager', color: '#f6ff2e' },
  { name: 'Unavailable', color: '#ed7d31' },
  { name: 'Total', color: '#00CCFF' },
];

export const function_stats = [
  { name: 'Top Management', color: '#3437ed' },
  { name: 'Administration', color: '#ffc000' },
  { name: 'Legal /Regulatory & Compliance', color: '#FFEB3B' },
  { name: 'Accounting and related', color: '#FF4081' },
  { name: 'Sales', color: '#40C4FF' },
  { name: 'Marketing', color: '#9344C3' },
  { name: 'Information Technology', color: '#8768E6' },
  { name: 'Engineering and Development', color: '#c9d2d2' },
  { name: 'Miscellaneous', color: '#E040FB' },
  { name: 'Operations and related', color: '#619fd2' },
  { name: 'Supply Chain Management', color: '#ff8b67' },
  { name: 'Facility Operations', color: '#ff754f' },
  { name: 'Finance/Banking/Insurance', color: '#d7bb10' },
  { name: 'Human Resources and related', color: '#ff5935' },
  { name: 'Communications/Public Relations', color: '#ffeb3b' },
  { name: 'Medical & Wellness', color: '#fa5356' },
  { name: 'Education / Teaching', color: '#f6ff2e' },
  { name: 'Other', color: '#9ba3b9' },
  { name: 'Unavailable', color: 'rgb(237,125,49)' },
  { name: 'Total', color: '#00CCFF' },
];

export const level_function_derivable_stats = [
  'Derivable Level & Function',
  'Only Function Derivable',
  'Only Level Derivable',
  'Non-derivable Level & Function',
  'Unavailable',
  'Total',
];

export const level_function_derivable = [
  { name: 'Derivable Level & Function', color: '#48cf5c' },
  { name: 'Only Function Derivable', color: '#8cdc8e' },
  { name: 'Only Level Derivable', color: '#c5c700' },
  { name: 'Non-derivable Level & Function', color: '#e6a000' },
  { name: 'Unavailable', color: 'rgb(237,125,49)' },
  { name: 'Total', color: '#00CCFF' },
];
// #40C4FF ,#00d100
export const color_code = [
  '#4D9AAB',
  '#BACE3B',
  '#CE703B',
  '#2CBF78',
  '#A368C8',
  '#4D9AAB',
  '#8768E6',
  '#c9d2d2',
  '#E040FB',
  '#619fd2',
  '#ff8b67',
  '#ff754f',
  '#d7bb10',
  '#ff5935',
  '#ffeb3b',
  '#fa5356',
  '#f6ff2e',
  '#9ba3b9',
  'rgb(237,125,49)',
  '#00CCFF',
];

export const color_code_stats = [
  { color: '#3437ed' },
  { color: '#ffc000' },
  { color: '#FFEB3B' },
  { color: '#40C4FF' },
  { color: '#9344C3' },
  { color: '#8768E6' },
  { color: '#c9d2d2' },
  { color: '#E040FB' },
  { color: '#619fd2' },
  { color: '#ff8b67' },
  { color: '#ff754f' },
  { color: '#d7bb10' },
  { color: '#ff5935' },
  { color: '#ffeb3b' },
  { color: '#fa5356' },
  { color: '#f6ff2e' },
  { color: '#9ba3b9' },
  { color: 'rgb(237,125,49)' },
  { color: '#00CCFF' },
];

export const statsNameList = [
  {
    reportId: 'MATCHBACK',
    statsName: 'Record Status',
    tableName: 'Record Status',
  },
  {
    reportId: 'DIRECT_DIAL',
    statsName: 'Mobile Number Enrichment',
    tableName: 'Mobile Number Enrichment',
  },
  {
    reportId: 'REGION',
    statsName: 'Region & Country',
    tableName: 'Region & Country',
  },
  {
    reportId: 'COMPANY_MATCHBACK',
    statsName: 'Company Matchback',
    tableName: 'Company Matchback',
  },
  {
    reportId: 'REVENUE',
    statsName: 'Revenue Range',
    tableName: 'Revenue Range',
  },
  {
    reportId: 'EMPLOYEE',
    statsName: 'Employee Size',
    tableName: 'Employee Range',
  },
  {
    reportId: 'EMAIL',
    statsName: 'Email Deliverability Score',
    tableName: 'Email Deliverability Score %',
  },
  {
    reportId: 'LEVEL_FUNCTION',
    statsName: 'Level Function Availability',
    tableName: 'Level & Function Derivable Stats',
  },
  { reportId: 'LEVEL', statsName: 'Level', tableName: 'Level' },
  { reportId: 'FUNCTION', statsName: 'Function', tableName: 'Function' },
  {
    reportId: 'RECORD_STATUS',
    statsName: 'Internal Record Status',
    tableName: 'Internal Record Status',
  },
];

export const authURL = [
  '/dashboard',
  '/task-list',
  '/manage-users',
  '/manage-client',
  '/enrich',
  '/integrations',
];

export const unAuthURL = [
  '/',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/account-activation',
];

export const COMPETITOR_DOMAINS_LIST = [
  '6sense.com',
  'aberdeen.com',
  'abstractapi.com',
  'acceligize.com',
  'adapt.io',
  'aeroleads.com',
  'agsalesworks.com',
  'apollo.io',
  'ascentrik.com',
  'avention.com',
  'b2bdatasolutions.com',
  'b2binfosoft.com',
  'baoinc.com',
  'bdex.com',
  'bisnode.com',
  'bizo.com',
  'builtwith.com',
  'buzzinglab.com',
  'bvdinfo.com',
  'catapultworks.com',
  'cheersin.com',
  'ciradar.com',
  'circleback.com',
  'clearbit.com',
  'cliently.com',
  'clientsfirstconsulting.com',
  'cognism.com',
  'connect.data.com',
  'connectleader.com',
  'corp.owler.com',
  'corporate360.us',
  'crunchbase.com',
  'dataaxlegenie.com',
  'datamaticsbpo.com',
  'datanyze.com',
  'dealsignal.com',
  'demandbase.com',
  'demandscience.com',
  'denave.com',
  'discoverorg.com',
  'discoveryco.com',
  'dnb.com',
  'edq.com',
  'esgglobalservices.com',
  'evolvebpm.com',
  'exactdata.com',
  'firstrain.com',
  'fortella.ai',
  'fullcontact.com',
  'growbots.com',
  'hgdata.com',
  'hiplead.com',
  'hoovers.com',
  'hunter.io',
  'idatalabs.com',
  'iko-system.com',
  'infoanalytica.com',
  'infogroup.com',
  'infotelligent.com',
  'infousa.com',
  'infusemedia.com',
  'insideview.com',
  'inspirebeats.com',
  'integrate.com',
  'iprofiledata.com',
  'lattice-engines.com',
  'lead411.com',
  'leadgenius.com',
  'leadiq.com',
  'leadlake.com',
  'leadspace.com',
  'limeleads.com',
  'linkedin.com',
  'lusha.com',
  'lynda.com',
  'mattermark.com',
  'memosift.com',
  'meritdirect.com',
  'metadata.io',
  'microlabzrs.com',
  'mixrank.com',
  'mountaintopdata.com',
  'netprospex.com',
  'nexsales.com',
  'oceanosinc.com',
  'openprisetech.com',
  'orb-intelligence.com',
  'peopledatalabs.com',
  'pipecandy.com',
  'proleads.io',
  'qedbaton.com',
  'rainkingonline.com',
  'reachforce.com',
  'readycontacts.com',
  'reintent.com',
  'rightwave.com',
  'ringlead.com',
  'sagacitysolutions.co.uk',
  'salescoutdata.com',
  'salesify.com',
  'salesintel.io',
  'salestools.io',
  'seamless.ai',
  'sellingsimplified.com',
  'slintel.com',
  'sln-solutions.com',
  'snov.io',
  'socedo.com',
  'social123.com',
  'spiderbook.com',
  'spoke.com',
  'stirista.com',
  'synthio.com',
  'techtarget.com',
  'thecompaniesapi.com',
  'thomsondata.com',
  'thomsonlocal.com',
  'tslmarketing.com',
  'unomy.com',
  'uplead.com',
  'usergems.com',
  'vsynergizeoutsourcing.com',
  'xdbscorp.com',
  'xdbscorp.net',
  'yesmarketing.com',
  'zeniq.io',
  'zenprospect.com',
  'ziffdavis.com',
  'zoominfo.com',
];

export const GENERIC_DOMAINS_LIST = [
  '0-mail',
  '007addict',
  '20',
  '27168',
  '815',
  '0clickemail',
  '0sg',
  '0wnd',
  '1033edge',
  '10mail',
  '10minutemail',
  '11mail',
  '123-m',
  '123',
  '123box',
  '123india',
  '123mail',
  '123qwe',
  '126',
  '138mail',
  '139',
  '150mail',
  '150ml',
  '15meg4free',
  '163',
  '16mail',
  '188',
  '189',
  '1auto',
  '1ce',
  '1chuan',
  '1colony',
  '1coolplace',
  '1email',
  '1freeemail',
  '1fsdfdsfsdf',
  '1funplace',
  '1internetdrive',
  '1mail',
  '1me',
  '1mum',
  '1musicrow',
  '1netdrive',
  '1nsyncfan',
  '1pad',
  '1under',
  '1webave',
  '1webhighway',
  '1zhuan',
  '2-mail',
  '20email',
  '20mail',
  '20minutemail',
  '212',
  '21cn',
  '247emails',
  '24horas',
  '2911',
  '2980',
  '2bmail',
  '2coolforyou',
  '2d2i',
  '2die4',
  '2fdgdfgdfgdf',
  '2hotforyou',
  '2mydns',
  '2net',
  '2prong',
  '2trom',
  '3000',
  '30minutemail',
  '30minutesmail',
  '3126',
  '321media',
  '33mail',
  '360',
  '37',
  '3ammagazine',
  '3dmail',
  '3email',
  '3g',
  '3mail',
  '3trtretgfrfe',
  '3xl',
  '444',
  '4email',
  '4gfdsgfdgfd',
  '4mg',
  '4newyork',
  '4warding',
  '4x4fan',
  '4x4man',
  '50mail',
  '5fm',
  '5ghgfhfghfgh',
  '5iron',
  '5star',
  '60minutemail',
  '6hjgjhgkilkj',
  '6ip',
  '6mail',
  '6paq',
  '702mail',
  '74',
  '7mail',
  '7tags',
  '88',
  '8848',
  '888',
  '8mail',
  '97rock',
  '99experts',
  '9ox',
  'a-bc',
  'a-player',
  'a2z4u',
  'a45',
  'aaamail',
  'aahlife',
  'aamail',
  'aapt',
  'aaronkwok',
  'abbeyroadlondon',
  'abcflash',
  'abdulnour',
  'aberystwyth',
  'abolition-now',
  'about',
  'absolutevitality',
  'abusemail',
  'abv',
  'abwesend',
  'abyssmail',
  'ac20mail',
  'academycougars',
  'acceso',
  'access4less',
  'accessgcc',
  'accountant',
  'acdcfan',
  'acdczone',
  'ace-of-base',
  'acmecity',
  'acmemail',
  'acninc',
  'acrobatmail',
  'activatormail',
  'activist',
  'adam',
  'add3000',
  'addcom',
  'address',
  'adelphia',
  'adexec',
  'adfarrow',
  'adinet',
  'adios',
  'admin',
  'administrativos',
  'adoption',
  'ados',
  'adrenalinefreak',
  'adres',
  'advalvas',
  'advantimo',
  'aeiou',
  'aemail4u',
  'aeneasmail',
  'afreeinternet',
  'africa-11',
  'africamail',
  'africamel',
  'africanpartnersonline',
  'afrobacon',
  'ag',
  'agedmail',
  'agelessemail',
  'agoodmail',
  'ahaa',
  'ahk',
  'aichi',
  'aim',
  'aircraftmail',
  'airforce',
  'airforceemail',
  'airpost',
  'aiutamici',
  'ajacied',
  'ajaxapp',
  'ak47',
  'aknet',
  'akphantom',
  'albawaba',
  'alecsmail',
  'alex4all',
  'alexandria',
  'algeria',
  'algeriamail',
  'alhilal',
  'alibaba',
  'alice',
  'aliceadsl',
  'aliceinchainsmail',
  'alivance',
  'alive',
  'aliyun',
  'allergist',
  'allmail',
  'alloymail',
  'allracing',
  'allsaintsfan',
  'alltel',
  'alpenjodel',
  'alphafrau',
  'alskens',
  'altavista',
  'alternativagratis',
  'alumni',
  'alumnidirector',
  'alvilag',
  'ama-trade',
  'amail',
  'amazonses',
  'amele',
  'america',
  'ameritech',
  'amilegit',
  'amiri',
  'amiriindustries',
  'amnetsal',
  'amorki',
  'amrer',
  'amuro',
  'amuromail',
  'ananzi',
  'ancestry',
  'andreabocellimail',
  'andylau',
  'anfmail',
  'angelfan',
  'angelfire',
  'angelic',
  'animail',
  'animal',
  'animalhouse',
  'animalwoman',
  'anjungcafe',
  'anniefans',
  'annsmail',
  'ano-mail',
  'anonmails',
  'anonymbox',
  'anonymous',
  'anote',
  'another',
  'anotherdomaincyka',
  'anotherwin95',
  'anti-ignorance',
  'anti-social',
  'antichef',
  'antiqueemail',
  'antireg',
  'antisocial',
  'antispam',
  'antispam24',
  'antispammail',
  'antongijsen',
  'antwerpen',
  'anymoment',
  'anytimenow',
  'aol',
  'aon',
  'apexmail',
  'apmail',
  'aport',
  'aport2000',
  'apple',
  'appraiser',
  'approvers',
  'aquaticmail',
  'arabia',
  'arabtop',
  'arcademaster',
  'archaeologist',
  'archerymail',
  'arcor',
  'arcotronics',
  'arcticmail',
  'argentina',
  'arhaelogist',
  'aristotle',
  'army',
  'armyspy',
  'arnet',
  'art-en-ligne',
  'artistemail',
  'artlover',
  'artman-conception',
  'as-if',
  'asdasd',
  'asean-mail',
  'asheville',
  'asia-links',
  'asia-mail',
  'asia',
  'asiafind',
  'asianavenue',
  'asiancityweb',
  'asiansonly',
  'asianwired',
  'asiapoint',
  'askaclub',
  'ass',
  'assala',
  'assamesemail',
  'astroboymail',
  'astrolover',
  'astrosfan',
  'asurfer',
  'atheist',
  'athenachu',
  'atina',
  'atl',
  'atlas',
  'atlaswebmail',
  'atlink',
  'atmc',
  'ato',
  'atozasia',
  'atrus',
  'att',
  'attglobal',
  'attymail',
  'au',
  'auctioneer',
  'aufeminin',
  'aus-city',
  'ausi',
  'aussiemail',
  'austin',
  'australia',
  'australiamail',
  'austrosearch',
  'autoescuelanerja',
  'autograf',
  'automail',
  'automotiveauthority',
  'autorambler',
  'aver',
  'avh',
  'avia-tonic',
  'avtoritet',
  'awayonvacation',
  'awholelotofamechi',
  'awsom',
  'axoskate',
  'ayna',
  'azazazatashkent',
  'azimiweb',
  'azmeil',
  'bachelorboy',
  'bachelorgal',
  'backfliper',
  'backpackers',
  'backstreet-boys',
  'backstreetboysclub',
  'backtothefuturefans',
  'backwards',
  'badtzmail',
  'bagherpour',
  'bahrainmail',
  'bakpaka',
  'baldmama',
  'baldpapa',
  'ballerstatus',
  'ballyfinance',
  'balochistan',
  'baluch',
  'bangkok',
  'bangkok2000',
  'bannertown',
  'baptistmail',
  'baptized',
  'barcelona',
  'bareed',
  'barid',
  'barlick',
  'bartender',
  'baseball-email',
  'baseballmail',
  'basketballmail',
  'batuta',
  'baudoinconsulting',
  'baxomale',
  'bboy',
  'bcvibes',
  'beddly',
  'beeebank',
  'beefmilk',
  'beenhad',
  'beep',
  'beer',
  'beerandremotes',
  'beethoven',
  'beirut',
  'belice',
  'belizehome',
  'belizemail',
  'belizeweb',
  'bell',
  'bellair',
  'bellsouth',
  'berkscounty',
  'berlin',
  'berlinexpo',
  'bestmail',
  'betriebsdirektor',
  'bettergolf',
  'bharatmail',
  'big1',
  'big5mail',
  'bigassweb',
  'bigblue',
  'bigboab',
  'bigfoot',
  'bigger',
  'biggerbadder',
  'bigmailbox',
  'bigmir',
  'bigpond',
  'bigramp',
  'bigstring',
  'bikemechanics',
  'bikeracer',
  'bikeracers',
  'bikerider',
  'billsfan',
  'bimamail',
  'bimla',
  'bin-wieder-da',
  'binkmail',
  'bio-muesli',
  'biologyfan',
  'birdfanatic',
  'birdlover',
  'birdowner',
  'bisons',
  'bitmail',
  'bitpage',
  'bizhosting',
  'bk',
  'bkkmail',
  'bla-bla',
  'blackburnfans',
  'blackburnmail',
  'blackplanet',
  'blader',
  'bladesmail',
  'blazemail',
  'bleib-bei-mir',
  'blink182',
  'blockfilter',
  'blogmyway',
  'blondandeasy',
  'bluebottle',
  'bluehyppo',
  'bluemail',
  'bluesfan',
  'bluewin',
  'blueyonder',
  'blumail',
  'blushmail',
  'blutig',
  'bmlsports',
  'boardermail',
  'boarderzone',
  'boatracers',
  'bobmail',
  'bodhi',
  'bofthew',
  'bol',
  'bolando',
  'bollywoodz',
  'bolt',
  'boltonfans',
  'bombdiggity',
  'bonbon',
  'boom',
  'bootmail',
  'bootybay',
  'bornagain',
  'bornnaked',
  'bossofthemoss',
  'bostonoffice',
  'boun',
  'bounce',
  'bounces',
  'bouncr',
  'box',
  'boxbg',
  'boxemail',
  'boxformail',
  'boxfrog',
  'boximail',
  'boyzoneclub',
  'bradfordfans',
  'brasilia',
  'bratan',
  'brazilmail',
  'breadtimes',
  'breakthru',
  'breathe',
  'brefmail',
  'brennendesreich',
  'bresnan',
  'brestonline',
  'brew-master',
  'brew-meister',
  'brfree',
  'briefemail',
  'bright',
  'britneyclub',
  'brittonsign',
  'broadcast',
  'broadwaybuff',
  'broadwaylove',
  'brokeandhappy',
  'brokenvalve',
  'brujula',
  'brunetka',
  'brusseler',
  'bsdmail',
  'bsnow',
  'bspamfree',
  'bt',
  'btcc',
  'btcmail',
  'btconnect',
  'btinternet',
  'btopenworld',
  'buerotiger',
  'buffymail',
  'bugmenot',
  'bulgaria',
  'bullsfan',
  'bullsgame',
  'bumerang',
  'bumpymail',
  'bumrap',
  'bund',
  'bunita',
  'bunko',
  'burnthespam',
  'burntmail',
  'burstmail',
  'buryfans',
  'bushemail',
  'business-man',
  'businessman',
  'businessweekmail',
  'bust',
  'busta-rhymes',
  'busymail',
  'butch-femme',
  'butovo',
  'buyersusa',
  'buymoreplays',
  'buzy',
  'bvimailbox',
  'byke',
  'byom',
  'byteme',
  'c2',
  'c2i',
  'c3',
  'c4',
  'c51vsgq',
  'cabacabana',
  'cable',
  'cableone',
  'caere',
  'cairomail',
  'calcuttaads',
  'calendar-server',
  'calidifontain',
  'californiamail',
  'callnetuk',
  'callsign',
  'caltanet',
  'camidge',
  'canada-11',
  'canada',
  'canadianmail',
  'canoemail',
  'cantv',
  'canwetalk',
  'caramail',
  'card',
  'care2',
  'careceo',
  'careerbuildermail',
  'carioca',
  'cartelera',
  'cartestraina',
  'casablancaresort',
  'casema',
  'cash4u',
  'cashette',
  'casino',
  'casualdx',
  'cataloniamail',
  'cataz',
  'catcha',
  'catchamail',
  'catemail',
  'catholic',
  'catlover',
  'catsrule',
  'ccnmail',
  'cd2',
  'cek',
  'celineclub',
  'celtic',
  'center-mail',
  'centermail',
  'centoper',
  'centralpets',
  'centrum',
  'centurylink',
  'centurytel',
  'certifiedmail',
  'cfl',
  'cgac',
  'cghost',
  'chacuo',
  'chaiyo',
  'chaiyomail',
  'chalkmail',
  'chammy',
  'chance2mail',
  'chandrasekar',
  'channelonetv',
  'charityemail',
  'charmedmail',
  'charter',
  'chat',
  'chatlane',
  'chattown',
  'chauhanweb',
  'cheatmail',
  'chechnya',
  'check',
  'check1check',
  'cheeb',
  'cheerful',
  'chef',
  'chefmail',
  'chek',
  'chello',
  'chemist',
  'chequemail',
  'cheshiremail',
  'cheyenneweb',
  'chez',
  'chickmail',
  'chil-e',
  'childrens',
  'childsavetrust',
  'china',
  'chinalook',
  'chinamail',
  'chinesecool',
  'chirk',
  'chocaholic',
  'chocofan',
  'chogmail',
  'choicemail1',
  'chong-mail',
  'christianmail',
  'chronicspender',
  'churchusa',
  'cia-agent',
  'cia',
  'ciaoweb',
  'cicciociccio',
  'cincinow',
  'cirquefans',
  'citeweb',
  'citiz',
  'citlink',
  'city-of-bath',
  'city-of-birmingham',
  'city-of-brighton',
  'city-of-cambridge',
  'city-of-coventry',
  'city-of-edinburgh',
  'city-of-lichfield',
  'city-of-lincoln',
  'city-of-liverpool',
  'city-of-manchester',
  'city-of-nottingham',
  'city-of-oxford',
  'city-of-swansea',
  'city-of-westminster',
  'city-of-york',
  'city2city',
  'citynetusa',
  'cityofcardiff',
  'cityoflondon',
  'ciudad',
  'ckaazaza',
  'claramail',
  'classicalfan',
  'classicmail',
  'clear',
  'clearwire',
  'clerk',
  'clickforadate',
  'cliffhanger',
  'clixser',
  'close2you',
  'clrmail',
  'club-internet',
  'club4x4',
  'clubalfa',
  'clubbers',
  'clubducati',
  'clubhonda',
  'clubmember',
  'clubnetnoir',
  'clubvdo',
  'cluemail',
  'cmail',
  'cmpmail',
  'cmpnetmail',
  'cnegal',
  'cnnsimail',
  'cntv',
  'codec',
  'coder',
  'coid',
  'coldemail',
  'coldmail',
  'collectiblesuperstore',
  'collector',
  'collegebeat',
  'collegeclub',
  'collegemail',
  'colleges',
  'columbus',
  'columbusrr',
  'columnist',
  'comast',
  'comcast',
  'comic',
  'communityconnect',
  'complxmind',
  'comporium',
  'comprendemail',
  'compuserve',
  'computer-expert',
  'computer-freak',
  'computer4u',
  'computerconfused',
  'computermail',
  'computernaked',
  'conexcol',
  'cong',
  'conk',
  'connect4free',
  'connectbox',
  'conok',
  'consultant',
  'consumerriot',
  'contractor',
  'contrasto',
  'cookiemonster',
  'cool',
  'coole-files',
  'coolgoose',
  'coolkiwi',
  'coollist',
  'coolmail',
  'coolrio',
  'coolsend',
  'coolsite',
  'cooooool',
  'cooperation',
  'cooperationtogo',
  'copacabana',
  'copper',
  'copticmail',
  'cornells',
  'cornerpub',
  'corporatedirtbag',
  'correo',
  'corrsfan',
  'cortinet',
  'cosmo',
  'cotas',
  'counsellor',
  'countrylover',
  'courriel',
  'courrieltemporaire',
  'cox',
  'coxinet',
  'cpaonline',
  'cracker',
  'craftemail',
  'crapmail',
  'crazedanddazed',
  'crazy',
  'crazymailing',
  'crazysexycool',
  'crewstart',
  'cristianemail',
  'critterpost',
  'croeso',
  'crosshairs',
  'crosswinds',
  'crunkmail',
  'crwmail',
  'cry4helponline',
  'cryingmail',
  'cs',
  'csinibaba',
  'cubiclink',
  'cuemail',
  'cumbriamail',
  'curio-city',
  'curryworld',
  'curtsmail',
  'cust',
  'cute-girl',
  'cuteandcuddly',
  'cutekittens',
  'cutey',
  'cuvox',
  'cww',
  'cyber-africa',
  'cyber-innovation',
  'cyber-matrix',
  'cyber-phone',
  'cyber-wizard',
  'cyber4all',
  'cyberbabies',
  'cybercafemaui',
  'cybercity-online',
  'cyberdude',
  'cyberforeplay',
  'cybergal',
  'cybergrrl',
  'cyberinbox',
  'cyberleports',
  'cybermail',
  'cybernet',
  'cyberservices',
  'cyberspace-asia',
  'cybertrains',
  'cyclefanz',
  'cymail',
  'cynetcity',
  'd3p',
  'dabsol',
  'dacoolest',
  'dadacasa',
  'daha',
  'dailypioneer',
  'dallas',
  'dallasmail',
  'dandikmail',
  'dangerous-minds',
  'dansegulvet',
  'dasdasdascyka',
  'data54',
  'date',
  'daum',
  'davegracey',
  'dawnsonmail',
  'dawsonmail',
  'dayrep',
  'dazedandconfused',
  'dbzmail',
  'dcemail',
  'dcsi',
  'ddns',
  'deadaddress',
  'deadlymob',
  'deadspam',
  'deafemail',
  'deagot',
  'deal-maker',
  'dearriba',
  'death-star',
  'deepseafisherman',
  'deforestationsucks',
  'degoo',
  'dejanews',
  'delikkt',
  'deliveryman',
  'deneg',
  'depechemode',
  'deseretmail',
  'desertmail',
  'desertonline',
  'desertsaintsmail',
  'desilota',
  'deskmail',
  'deskpilot',
  'despam',
  'despammed',
  'destin',
  'detik',
  'deutschland-net',
  'devnullmail',
  'devotedcouples',
  'dezigner',
  'dfgh',
  'dfwatson',
  'dglnet',
  'dgoh',
  'di-ve',
  'diamondemail',
  'didamail',
  'die-besten-bilder',
  'die-genossen',
  'die-optimisten',
  'die',
  'diehardmail',
  'diemailbox',
  'digibel',
  'digital-filestore',
  'digitalforeplay',
  'digitalsanctuary',
  'digosnet',
  'dingbone',
  'diplomats',
  'directbox',
  'director-general',
  'diri',
  'dirtracer',
  'dirtracers',
  'discard',
  'discardmail',
  'disciples',
  'discofan',
  'discovery',
  'discoverymail',
  'disign-concept',
  'disign-revelation',
  'disinfo',
  'dispomail',
  'disposable',
  'disposableaddress',
  'disposableemailaddresses',
  'disposableinbox',
  'dispose',
  'dispostable',
  'divismail',
  'divorcedandhappy',
  'dm',
  'dmailman',
  'dmitrovka',
  'dmitry',
  'dnainternet',
  'dnsmadeeasy',
  'doar',
  'doclist',
  'docmail',
  'docs',
  'doctor',
  'dodgeit',
  'dodgit',
  'dodo',
  'dodsi',
  'dog',
  'dogit',
  'doglover',
  'dogmail',
  'dogsnob',
  'doityourself',
  'domforfb1',
  'domforfb2',
  'domforfb3',
  'domforfb4',
  'domforfb5',
  'domforfb6',
  'domforfb7',
  'domforfb8',
  'domozmail',
  'doneasy',
  'donegal',
  'donemail',
  'donjuan',
  'dontgotmail',
  'dontmesswithtexas',
  'dontreg',
  'dontsendmespam',
  'doramail',
  'dostmail',
  'dotcom',
  'dotmsg',
  'dotnow',
  'dott',
  'download-privat',
  'dplanet',
  'dr',
  'dragoncon',
  'dragracer',
  'drdrb',
  'drivehq',
  'dropmail',
  'dropzone',
  'drotposta',
  'dubaimail',
  'dublin',
  'dump-email',
  'dumpandjunk',
  'dumpmail',
  'dumpyemail',
  'dunlopdriver',
  'dunloprider',
  'duno',
  'duskmail',
  'dustdevil',
  'dutchmail',
  'dvd-fan',
  'dwp',
  'dygo',
  'dynamitemail',
  'dyndns',
  'e-apollo',
  'e-hkma',
  'e-mail',
  'e-mailanywhere',
  'e-mails',
  'e-tapaal',
  'e-webtec',
  'e4ward',
  'earthalliance',
  'earthcam',
  'earthdome',
  'earthling',
  'earthlink',
  'earthonline',
  'eastcoast',
  'eastlink',
  'eastmail',
  'eastrolog',
  'easy',
  'easypeasy',
  'easypost',
  'easytrashmail',
  'eatmydirt',
  'ebprofits',
  'ec',
  'ecardmail',
  'ecbsolutions',
  'echina',
  'ecolo-online',
  'ecompare',
  'edmail',
  'ednatx',
  'edtnmail',
  'educacao',
  'educastmail',
  'eelmail',
  'ehmail',
  'einmalmail',
  'einrot',
  'eintagsmail',
  'eircom',
  'ekidz',
  'elisanet',
  'elitemail',
  'elsitio',
  'eltimon',
  'elvis',
  'elvisfan',
  'email-fake',
  'email-london',
  'email-value',
  'email',
  'email2me',
  'email4u',
  'email60',
  'emailacc',
  'emailaccount',
  'emailaddresses',
  'emailage',
  'emailasso',
  'emailchoice',
  'emailcorner',
  'emailem',
  'emailengine',
  'emailer',
  'emailforyou',
  'emailgaul',
  'emailgo',
  'emailgroups',
  'emailias',
  'emailinfive',
  'emailit',
  'emaillime',
  'emailmiser',
  'emailoregon',
  'emailpinoy',
  'emailplanet',
  'emailplus',
  'emailproxsy',
  'emails',
  'emailsensei',
  'emailservice',
  'emailsydney',
  'emailtemporanea',
  'emailtemporar',
  'emailtemporario',
  'emailthe',
  'emailtmp',
  'emailto',
  'emailuser',
  'emailwarden',
  'emailx',
  'emailxfer',
  'emailz',
  'emale',
  'ematic',
  'embarqmail',
  'emeil',
  'emil',
  'eml',
  'empereur',
  'emptymail',
  'emumail',
  'emz',
  'end-war',
  'enel',
  'enelpunto',
  'engineer',
  'england',
  'englandmail',
  'epage',
  'epatra',
  'ephemail',
  'epiqmail',
  'epix',
  'epomail',
  'epost',
  'eposta',
  'eprompter',
  'eqqu',
  'eramail',
  'eresmas',
  'eriga',
  'ero-tube',
  'eshche',
  'esmailweb',
  'estranet',
  'ethos',
  'etoast',
  'etrademail',
  'etranquil',
  'eudoramail',
  'europamel',
  'europe',
  'europemail',
  'euroseek',
  'eurosport',
  'evafan',
  'evertonfans',
  'every1',
  'everyday',
  'everymail',
  'everyone',
  'everytg',
  'evopo',
  'examnotes',
  'excite',
  'execs',
  'execs2k',
  'executivemail',
  'exemail',
  'exg6',
  'explodemail',
  'express',
  'expressasia',
  'extenda',
  'extended',
  'extremail',
  'eyepaste',
  'eyou',
  'ezagenda',
  'ezcybersearch',
  'ezmail',
  'ezrs',
  'f-m',
  'f1fans',
  'facebook-email',
  'facebook',
  'facebookmail',
  'fadrasha',
  'fahr-zur-hoelle',
  'fake-email',
  'fake-mail',
  'fakeinbox',
  'fakeinformation',
  'fakemailz',
  'falseaddress',
  'fan',
  'fannclub',
  'fansonlymail',
  'fansworldwide',
  'fantasticmail',
  'fantasymail',
  'farang',
  'farifluset',
  'faroweb',
  'fast-email',
  'fast-mail',
  'fastacura',
  'fastchevy',
  'fastchrysler',
  'fastem',
  'fastemail',
  'fastemailer',
  'fastemailextractor',
  'fastermail',
  'fastest',
  'fastimap',
  'fastkawasaki',
  'fastmail',
  'fastmailbox',
  'fastmazda',
  'fastmessaging',
  'fastmitsubishi',
  'fastnissan',
  'fastservice',
  'fastsubaru',
  'fastsuzuki',
  'fasttoyota',
  'fastyamaha',
  'fatcock',
  'fatflap',
  'fathersrightsne',
  'fatyachts',
  'fax',
  'fbi-agent',
  'fbi',
  'fdfdsfds',
  'fea',
  'federalcontractors',
  'feinripptraeger',
  'felicity',
  'felicitymail',
  'female',
  'femenino',
  'fepg',
  'fetchmail',
  'fettabernett',
  'feyenoorder',
  'ffanet',
  'fiberia',
  'fibertel',
  'ficken',
  'fificorp',
  'fightallspam',
  'filipinolinks',
  'filzmail',
  'financefan',
  'financemail',
  'financier',
  'findfo',
  'findhere',
  'findmail',
  'findmemail',
  'finebody',
  'fineemail',
  'finfin',
  'finklfan',
  'fire-brigade',
  'fireman',
  'fishburne',
  'fishfuse',
  'fivemail',
  'fixmail',
  'fizmail',
  'flashbox',
  'flashemail',
  'flashmail',
  'fleckens',
  'flipcode',
  'floridaemail',
  'flytecrew',
  'fmail',
  'fmailbox',
  'fmgirl',
  'fmguy',
  'fnbmail',
  'fnmail',
  'folkfan',
  'foodmail',
  'footard',
  'football',
  'footballmail',
  'foothills',
  'for-president',
  'force9',
  'forfree',
  'forgetmail',
  'fornow',
  'forpresident',
  'fortuncity',
  'fortunecity',
  'forum',
  'fossefans',
  'foxmail',
  'fr33mail',
  'francefans',
  'francemel',
  'frapmail',
  'free-email',
  'free-online',
  'free-org',
  'free',
  'freeaccess',
  'freeaccount',
  'freeandsingle',
  'freebox',
  'freedom',
  'freedomlover',
  'freefanmail',
  'freegates',
  'freeghana',
  'freelance-france',
  'freeler',
  'freemail',
  'freemails',
  'freemeil',
  'freenet',
  'freeola',
  'freeproblem',
  'freesbee',
  'freeserve',
  'freeservers',
  'freestamp',
  'freestart',
  'freesurf',
  'freeuk',
  'freeukisp',
  'freeweb',
  'freewebemail',
  'freeyellow',
  'freezone',
  'fresnomail',
  'freudenkinder',
  'freundin',
  'friction',
  'friendlydevices',
  'friendlymail',
  'friends-cafe',
  'friendsfan',
  'from-africa',
  'from-america',
  'from-argentina',
  'from-asia',
  'from-australia',
  'from-belgium',
  'from-brazil',
  'from-canada',
  'from-china',
  'from-england',
  'from-europe',
  'from-france',
  'from-germany',
  'from-holland',
  'from-israel',
  'from-italy',
  'from-japan',
  'from-korea',
  'from-mexico',
  'from-outerspace',
  'from-russia',
  'from-spain',
  'fromalabama',
  'fromalaska',
  'fromarizona',
  'fromarkansas',
  'fromcalifornia',
  'fromcolorado',
  'fromconnecticut',
  'fromdelaware',
  'fromflorida',
  'fromgeorgia',
  'fromhawaii',
  'fromidaho',
  'fromillinois',
  'fromindiana',
  'frominter',
  'fromiowa',
  'fromjupiter',
  'fromkansas',
  'fromkentucky',
  'fromlouisiana',
  'frommaine',
  'frommaryland',
  'frommassachusetts',
  'frommiami',
  'frommichigan',
  'fromminnesota',
  'frommississippi',
  'frommissouri',
  'frommontana',
  'fromnebraska',
  'fromnevada',
  'fromnewhampshire',
  'fromnewjersey',
  'fromnewmexico',
  'fromnewyork',
  'fromnorthcarolina',
  'fromnorthdakota',
  'fromohio',
  'fromoklahoma',
  'fromoregon',
  'frompennsylvania',
  'fromrhodeisland',
  'fromru',
  'fromsouthcarolina',
  'fromsouthdakota',
  'fromtennessee',
  'fromtexas',
  'fromthestates',
  'fromutah',
  'fromvermont',
  'fromvirginia',
  'fromwashington',
  'fromwashingtondc',
  'fromwestvirginia',
  'fromwisconsin',
  'fromwyoming',
  'front',
  'frontier',
  'frontiernet',
  'frostbyte',
  'fsmail',
  'ftc-i',
  'ftml',
  'fuckingduh',
  'fudgerub',
  'fullmail',
  'funiran',
  'funkfan',
  'funky4',
  'fuorissimo',
  'furnitureprovider',
  'fuse',
  'fusemail',
  'fut',
  'fux0ringduh',
  'fwnb',
  'fxsmails',
  'fyii',
  'galamb',
  'galaxy5',
  'galaxyhit',
  'gamebox',
  'gamegeek',
  'games',
  'gamespotmail',
  'gamil',
  'gamno',
  'garbage',
  'gardener',
  'garliclife',
  'gatwickemail',
  'gawab',
  'gay',
  'gaybrighton',
  'gaza',
  'gazeta',
  'gazibooks',
  'gci',
  'gdi',
  'gee-wiz',
  'geecities',
  'geek',
  'geeklife',
  'gehensiemirnichtaufdensack',
  'gelitik',
  'gencmail',
  'general-hospital',
  'gentlemansclub',
  'genxemail',
  'geocities',
  'geography',
  'geologist',
  'geopia',
  'germanymail',
  'get',
  'get1mail',
  'get2mail',
  'getairmail',
  'getmails',
  'getonemail',
  'gfxartist',
  'gh2000',
  'ghanamail',
  'ghostmail',
  'ghosttexter',
  'giantmail',
  'giantsfan',
  'giga4u',
  'gigileung',
  'girl4god',
  'girlsundertheinfluence',
  'gishpuppy',
  'givepeaceachance',
  'glay',
  'glendale',
  'globalfree',
  'globalpagan',
  'globalsite',
  'globetrotter',
  'globo',
  'globomail',
  'gmail',
  'gmial',
  'gmx',
  'gnwmail',
  'go',
  'go2',
  'go2net',
  'go4',
  'gobrainstorm',
  'gocollege',
  'gocubs',
  'godmail',
  'goemailgo',
  'gofree',
  'gol',
  'goldenmail',
  'goldmail',
  'goldtoolbox',
  'golfemail',
  'golfilla',
  'golfmail',
  'gonavy',
  'gonuts4free',
  'goodnewsmail',
  'goodstick',
  'google',
  'googlegroups',
  'googlemail',
  'goosemoose',
  'goplay',
  'gorillaswithdirtyarmpits',
  'gorontalo',
  'gospelfan',
  'gothere',
  'gotmail',
  'gotomy',
  'gotti',
  'govolsfan',
  'gportal',
  'grabmail',
  'graduate',
  'graffiti',
  'gramszu',
  'grandmamail',
  'grandmasmail',
  'graphic-designer',
  'grapplers',
  'gratisweb',
  'great-host',
  'greenmail',
  'greensloth',
  'groupmail',
  'grr',
  'grungecafe',
  'gsrv',
  'gtemail',
  'gtmc',
  'gua',
  'guerillamail',
  'guerrillamail',
  'guerrillamailblock',
  'guessmail',
  'guju',
  'gurlmail',
  'gustr',
  'guy',
  'guy2',
  'guyanafriends',
  'gwhsgeckos',
  'gyorsposta',
  'h-mail',
  'hab-verschlafen',
  'hablas',
  'habmalnefrage',
  'hacccc',
  'hackermail',
  'hailmail',
  'hairdresser',
  'haltospam',
  'hamptonroads',
  'handbag',
  'handleit',
  'hang-ten',
  'hangglidemail',
  'hanmail',
  'happemail',
  'happycounsel',
  'happypuppy',
  'harakirimail',
  'haramamba',
  'hardcorefreak',
  'hardyoungbabes',
  'hartbot',
  'hat-geld',
  'hatespam',
  'hawaii',
  'hawaiiantel',
  'headbone',
  'healthemail',
  'heartthrob',
  'heavynoize',
  'heerschap',
  'heesun',
  'hehe',
  'hello',
  'hellokitty',
  'helter-skelter',
  'hempseed',
  'herediano',
  'heremail',
  'herono1',
  'herp',
  'herr-der-mails',
  'hetnet',
  'hewgen',
  'hey',
  'hhdevel',
  'hideakifan',
  'hidemail',
  'hidzz',
  'highmilton',
  'highquality',
  'highveldmail',
  'hilarious',
  'hinduhome',
  'hingis',
  'hiphopfan',
  'hispavista',
  'hitmail',
  'hitmanrecords',
  'hitthe',
  'hkg',
  'hkstarphoto',
  'hmamail',
  'hochsitze',
  'hockeymail',
  'hollywoodkids',
  'home-email',
  'home',
  'homeart',
  'homelocator',
  'homemail',
  'homenetmail',
  'homeonthethrone',
  'homestead',
  'homeworkcentral',
  'honduras',
  'hongkong',
  'hookup',
  'hoopsmail',
  'hopemail',
  'horrormail',
  'host-it',
  'hot-mail',
  'hot-shop',
  'hot-shot',
  'hot',
  'hotbot',
  'hotbox',
  'hotbrev',
  'hotcoolmail',
  'hotepmail',
  'hotfire',
  'hotletter',
  'hotlinemail',
  'hotmail',
  'hotpop',
  'hotpop3',
  'hotvoice',
  'housefan',
  'housefancom',
  'housemail',
  'hsuchi',
  'html',
  'hu2',
  'hughes',
  'hulapla',
  'humanoid',
  'humanux',
  'humn',
  'humour',
  'hunsa',
  'hurting',
  'hush',
  'hushmail',
  'hypernautica',
  'i-connect',
  'i-france',
  'i-love-cats',
  'i-mail',
  'i-mailbox',
  'i-p',
  'i',
  'i12',
  'i2828',
  'i2pmail',
  'iam4msu',
  'iamawoman',
  'iamfinallyonline',
  'iamwaiting',
  'iamwasted',
  'iamyours',
  'icestorm',
  'ich-bin-verrueckt-nach-dir',
  'ich-will-net',
  'icloud',
  'icmsconsultants',
  'icq',
  'icqmail',
  'icrazy',
  'icu',
  'id-base',
  'id',
  'ididitmyway',
  'idigjesus',
  'idirect',
  'ieatspam',
  'ieh-mail',
  'iespana',
  'ifoward',
  'ig',
  'ignazio',
  'ignmail',
  'ihateclowns',
  'ihateyoualot',
  'iheartspam',
  'iinet',
  'ijustdontcare',
  'ikbenspamvrij',
  'ilkposta',
  'ilovechocolate',
  'ilovegiraffes',
  'ilovejesus',
  'ilovelionking',
  'ilovepokemonmail',
  'ilovethemovies',
  'ilovetocollect',
  'ilse',
  'imaginemail',
  'imail',
  'imailbox',
  'imails',
  'imap-mail',
  'imap',
  'imapmail',
  'imel',
  'imgof',
  'imgv',
  'immo-gerance',
  'imneverwrong',
  'imposter',
  'imstations',
  'imstressed',
  'imtoosexy',
  'in-box',
  'in2jesus',
  'iname',
  'inbax',
  'inbound',
  'inbox',
  'inboxalias',
  'inboxclean',
  'incamail',
  'includingarabia',
  'incredimail',
  'indeedemail',
  'index',
  'indexa',
  'india',
  'indiatimes',
  'indo-mail',
  'indocities',
  'indomail',
  'indosat',
  'indus',
  'indyracers',
  'inerted',
  'inet',
  'info-media',
  'info-radio',
  'info',
  'info66',
  'infoapex',
  'infocom',
  'infohq',
  'infomail',
  'infomart',
  'informaticos',
  'infospacemail',
  'infovia',
  'inicia',
  'inmail',
  'inmail24',
  'inmano',
  'inmynetwork',
  'innocent',
  'inonesearch',
  'inorbit',
  'inoutbox',
  'insidebaltimore',
  'insight',
  'inspectorjavert',
  'instant-mail',
  'instantemailaddress',
  'instantmail',
  'instruction',
  'instructor',
  'insurer',
  'interburp',
  'interfree',
  'interia',
  'interlap',
  'intermail',
  'internet-club',
  'internet-e-mail',
  'internet-mail',
  'internet-police',
  'internetbiz',
  'internetdrive',
  'internetegypt',
  'internetemails',
  'internetmailing',
  'internode',
  'invalid',
  'investormail',
  'inwind',
  'iobox',
  'iol',
  'iowaemail',
  'ip3',
  'ip4',
  'ip6',
  'ipdeer',
  'ipex',
  'ipoo',
  'iportalexpress',
  'iprimus',
  'iqemail',
  'irangate',
  'iraqmail',
  'ireland',
  'irelandmail',
  'irish2me',
  'irj',
  'iroid',
  'iscooler',
  'isellcars',
  'iservejesus',
  'islamonline',
  'islandemail',
  'isleuthmail',
  'ismart',
  'isonfire',
  'isp9',
  'israelmail',
  'ist-allein',
  'ist-einmalig',
  'ist-ganz-allein',
  'ist-willig',
  'italymail',
  'itelefonica',
  'itloox',
  'itmom',
  'ivebeenframed',
  'ivillage',
  'iwan-fals',
  'iwi',
  'iwmail',
  'iwon',
  'izadpanah',
  'jabble',
  'jahoopa',
  'jakuza',
  'japan',
  'jaydemail',
  'jazzandjava',
  'jazzfan',
  'jazzgame',
  'je-recycle',
  'jeanvaljean',
  'jerusalemmail',
  'jesusanswers',
  'jet-renovation',
  'jetable',
  'jetemail',
  'jewishmail',
  'jfkislanders',
  'jingjo',
  'jippii',
  'jmail',
  'jnxjn',
  'job4u',
  'jobbikszimpatizans',
  'joelonsoftware',
  'joinme',
  'jojomail',
  'jokes',
  'jordanmail',
  'journalist',
  'jourrapide',
  'jovem',
  'joymail',
  'jpopmail',
  'jsrsolutions',
  'jubiimail',
  'jump',
  'jumpy',
  'juniormail',
  'junk1e',
  'junkmail',
  'juno',
  'justemail',
  'justicemail',
  'justmail',
  'justmailz',
  'justmarriedmail',
  'jwspamspy',
  'k',
  'kaazoo',
  'kabissa',
  'kaduku',
  'kaffeeschluerfer',
  'kaixo',
  'kalpoint',
  'kansascity',
  'kapoorweb',
  'karachian',
  'karachioye',
  'karbasi',
  'kasmail',
  'kaspop',
  'katamail',
  'kayafmmail',
  'kbjrmail',
  'kcks',
  'kebi',
  'keftamail',
  'keg-party',
  'keinpardon',
  'keko',
  'kellychen',
  'keptprivate',
  'keromail',
  'kewpee',
  'keyemail',
  'kgb',
  'khosropour',
  'kichimail',
  'kickassmail',
  'killamail',
  'killergreenmail',
  'killermail',
  'killmail',
  'kimo',
  'kimsdisk',
  'kinglibrary',
  'kinki-kids',
  'kismail',
  'kissfans',
  'kitemail',
  'kittymail',
  'kitznet',
  'kiwibox',
  'kiwitown',
  'klassmaster',
  'klzlk',
  'km',
  'kmail',
  'knol-power',
  'koko',
  'kolumbus',
  'kommespaeter',
  'konkovo',
  'konsul',
  'konx',
  'korea',
  'koreamail',
  'kosino',
  'koszmail',
  'kozmail',
  'kpnmail',
  'kreditor',
  'krim',
  'krongthip',
  'krovatka',
  'krunis',
  'ksanmail',
  'ksee24mail',
  'kube93mail',
  'kukamail',
  'kulturbetrieb',
  'kumarweb',
  'kurzepost',
  'kuwait-mail',
  'kuzminki',
  'kyokodate',
  'kyokofukada',
  'l33r',
  'la',
  'labetteraverouge',
  'lackmail',
  'ladyfire',
  'ladymail',
  'lagerlouts',
  'lags',
  'lahoreoye',
  'lakmail',
  'lamer',
  'land',
  'langoo',
  'lankamail',
  'laoeq',
  'laposte',
  'lass-es-geschehen',
  'last-chance',
  'lastmail',
  'latemodels',
  'latinmail',
  'latino',
  'lavabit',
  'lavache',
  'law',
  'lawlita',
  'lawyer',
  'lazyinbox',
  'learn2compute',
  'lebanonatlas',
  'leeching',
  'leehom',
  'lefortovo',
  'legalactions',
  'legalrc',
  'legislator',
  'legistrator',
  'lenta',
  'leonlai',
  'letsgomets',
  'letterbox',
  'letterboxes',
  'letthemeatspam',
  'levele',
  'lex',
  'lexis-nexis-mail',
  'lhsdv',
  'lianozovo',
  'libero',
  'liberomail',
  'lick101',
  'liebt-dich',
  'lifebyfood',
  'link2mail',
  'linkmaster',
  'linktrader',
  'linuxfreemail',
  'linuxmail',
  'lionsfan',
  'liontrucks',
  'liquidinformation',
  'lissamail',
  'list',
  'listomail',
  'litedrop',
  'literaturelover',
  'littleapple',
  'littleblueroom',
  'live',
  'liveradio',
  'liverpoolfans',
  'ljiljan',
  'llandudno',
  'llangollen',
  'lmxmail',
  'lobbyist',
  'localbar',
  'localgenius',
  'locos',
  'login-email',
  'loh',
  'lol',
  'lolfreak',
  'lolito',
  'lolnetwork',
  'london',
  'loobie',
  'looksmart',
  'lookugly',
  'lopezclub',
  'lortemail',
  'louiskoo',
  'lov',
  'love',
  'loveable',
  'lovecat',
  'lovefall',
  'lovefootball',
  'loveforlostcats',
  'lovelygirl',
  'lovemail',
  'lover-boy',
  'lovergirl',
  'lovesea',
  'lovethebroncos',
  'lovethecowboys',
  'lovetocook',
  'lovetohike',
  'loveyouforever',
  'lovingjesus',
  'lowandslow',
  'lr7',
  'lr78',
  'lroid',
  'lubovnik',
  'lukop',
  'luso',
  'luukku',
  'luv2',
  'luvrhino',
  'lvie',
  'lvwebmail',
  'lycos',
  'lycosemail',
  'lycosmail',
  'm-a-i-l',
  'm-hmail',
  'm21',
  'm4',
  'm4ilweb',
  'mac',
  'macbox',
  'macfreak',
  'machinecandy',
  'macmail',
  'mad',
  'madcrazy',
  'madcreations',
  'madonnafan',
  'madrid',
  'maennerversteherin',
  'maffia',
  'magicmail',
  'mahmoodweb',
  'mail-awu',
  'mail-box',
  'mail-center',
  'mail-central',
  'mail-easy',
  'mail-filter',
  'mail-me',
  'mail-page',
  'mail-temporaire',
  'mail-tester',
  'mail',
  'mail114',
  'mail15',
  'mail1a',
  'mail1st',
  'mail2007',
  'mail21',
  'mail2aaron',
  'mail2abby',
  'mail2abc',
  'mail2actor',
  'mail2admiral',
  'mail2adorable',
  'mail2adoration',
  'mail2adore',
  'mail2adventure',
  'mail2aeolus',
  'mail2aether',
  'mail2affection',
  'mail2afghanistan',
  'mail2africa',
  'mail2agent',
  'mail2aha',
  'mail2ahoy',
  'mail2aim',
  'mail2air',
  'mail2airbag',
  'mail2airforce',
  'mail2airport',
  'mail2alabama',
  'mail2alan',
  'mail2alaska',
  'mail2albania',
  'mail2alcoholic',
  'mail2alec',
  'mail2alexa',
  'mail2algeria',
  'mail2alicia',
  'mail2alien',
  'mail2allan',
  'mail2allen',
  'mail2allison',
  'mail2alpha',
  'mail2alyssa',
  'mail2amanda',
  'mail2amazing',
  'mail2amber',
  'mail2america',
  'mail2american',
  'mail2andorra',
  'mail2andrea',
  'mail2andy',
  'mail2anesthesiologist',
  'mail2angela',
  'mail2angola',
  'mail2ann',
  'mail2anna',
  'mail2anne',
  'mail2anthony',
  'mail2anything',
  'mail2aphrodite',
  'mail2apollo',
  'mail2april',
  'mail2aquarius',
  'mail2arabia',
  'mail2arabic',
  'mail2architect',
  'mail2ares',
  'mail2argentina',
  'mail2aries',
  'mail2arizona',
  'mail2arkansas',
  'mail2armenia',
  'mail2army',
  'mail2arnold',
  'mail2art',
  'mail2artemus',
  'mail2arthur',
  'mail2artist',
  'mail2ashley',
  'mail2ask',
  'mail2astronomer',
  'mail2athena',
  'mail2athlete',
  'mail2atlas',
  'mail2atom',
  'mail2attitude',
  'mail2auction',
  'mail2aunt',
  'mail2australia',
  'mail2austria',
  'mail2azerbaijan',
  'mail2baby',
  'mail2bahamas',
  'mail2bahrain',
  'mail2ballerina',
  'mail2ballplayer',
  'mail2band',
  'mail2bangladesh',
  'mail2bank',
  'mail2banker',
  'mail2bankrupt',
  'mail2baptist',
  'mail2bar',
  'mail2barbados',
  'mail2barbara',
  'mail2barter',
  'mail2basketball',
  'mail2batter',
  'mail2beach',
  'mail2beast',
  'mail2beatles',
  'mail2beauty',
  'mail2becky',
  'mail2beijing',
  'mail2belgium',
  'mail2belize',
  'mail2ben',
  'mail2bernard',
  'mail2beth',
  'mail2betty',
  'mail2beverly',
  'mail2beyond',
  'mail2biker',
  'mail2bill',
  'mail2billionaire',
  'mail2billy',
  'mail2bio',
  'mail2biologist',
  'mail2black',
  'mail2blackbelt',
  'mail2blake',
  'mail2blind',
  'mail2blonde',
  'mail2blues',
  'mail2bob',
  'mail2bobby',
  'mail2bolivia',
  'mail2bombay',
  'mail2bonn',
  'mail2bookmark',
  'mail2boreas',
  'mail2bosnia',
  'mail2boston',
  'mail2botswana',
  'mail2bradley',
  'mail2brazil',
  'mail2breakfast',
  'mail2brian',
  'mail2bride',
  'mail2brittany',
  'mail2broker',
  'mail2brook',
  'mail2bruce',
  'mail2brunei',
  'mail2brunette',
  'mail2brussels',
  'mail2bryan',
  'mail2bug',
  'mail2bulgaria',
  'mail2business',
  'mail2buy',
  'mail2ca',
  'mail2california',
  'mail2calvin',
  'mail2cambodia',
  'mail2cameroon',
  'mail2canada',
  'mail2cancer',
  'mail2capeverde',
  'mail2capricorn',
  'mail2cardinal',
  'mail2cardiologist',
  'mail2care',
  'mail2caroline',
  'mail2carolyn',
  'mail2casey',
  'mail2cat',
  'mail2caterer',
  'mail2cathy',
  'mail2catlover',
  'mail2catwalk',
  'mail2cell',
  'mail2chad',
  'mail2champaign',
  'mail2charles',
  'mail2chef',
  'mail2chemist',
  'mail2cherry',
  'mail2chicago',
  'mail2chile',
  'mail2china',
  'mail2chinese',
  'mail2chocolate',
  'mail2christian',
  'mail2christie',
  'mail2christmas',
  'mail2christy',
  'mail2chuck',
  'mail2cindy',
  'mail2clark',
  'mail2classifieds',
  'mail2claude',
  'mail2cliff',
  'mail2clinic',
  'mail2clint',
  'mail2close',
  'mail2club',
  'mail2coach',
  'mail2coastguard',
  'mail2colin',
  'mail2college',
  'mail2colombia',
  'mail2color',
  'mail2colorado',
  'mail2columbia',
  'mail2comedian',
  'mail2composer',
  'mail2computer',
  'mail2computers',
  'mail2concert',
  'mail2congo',
  'mail2connect',
  'mail2connecticut',
  'mail2consultant',
  'mail2convict',
  'mail2cook',
  'mail2cool',
  'mail2cory',
  'mail2costarica',
  'mail2country',
  'mail2courtney',
  'mail2cowboy',
  'mail2cowgirl',
  'mail2craig',
  'mail2crave',
  'mail2crazy',
  'mail2create',
  'mail2croatia',
  'mail2cry',
  'mail2crystal',
  'mail2cuba',
  'mail2culture',
  'mail2curt',
  'mail2customs',
  'mail2cute',
  'mail2cutey',
  'mail2cynthia',
  'mail2cyprus',
  'mail2czechrepublic',
  'mail2dad',
  'mail2dale',
  'mail2dallas',
  'mail2dan',
  'mail2dana',
  'mail2dance',
  'mail2dancer',
  'mail2danielle',
  'mail2danny',
  'mail2darlene',
  'mail2darling',
  'mail2darren',
  'mail2daughter',
  'mail2dave',
  'mail2dawn',
  'mail2dc',
  'mail2dealer',
  'mail2deanna',
  'mail2dearest',
  'mail2debbie',
  'mail2debby',
  'mail2deer',
  'mail2delaware',
  'mail2delicious',
  'mail2demeter',
  'mail2democrat',
  'mail2denise',
  'mail2denmark',
  'mail2dennis',
  'mail2dentist',
  'mail2derek',
  'mail2desert',
  'mail2devoted',
  'mail2devotion',
  'mail2diamond',
  'mail2diana',
  'mail2diane',
  'mail2diehard',
  'mail2dilemma',
  'mail2dillon',
  'mail2dinner',
  'mail2dinosaur',
  'mail2dionysos',
  'mail2diplomat',
  'mail2director',
  'mail2dirk',
  'mail2disco',
  'mail2dive',
  'mail2diver',
  'mail2divorced',
  'mail2djibouti',
  'mail2doctor',
  'mail2doglover',
  'mail2dominic',
  'mail2dominica',
  'mail2dominicanrepublic',
  'mail2don',
  'mail2donald',
  'mail2donna',
  'mail2doris',
  'mail2dorothy',
  'mail2doug',
  'mail2dough',
  'mail2douglas',
  'mail2dow',
  'mail2downtown',
  'mail2dream',
  'mail2dreamer',
  'mail2dude',
  'mail2dustin',
  'mail2dyke',
  'mail2dylan',
  'mail2earl',
  'mail2earth',
  'mail2eastend',
  'mail2eat',
  'mail2economist',
  'mail2ecuador',
  'mail2eddie',
  'mail2edgar',
  'mail2edwin',
  'mail2egypt',
  'mail2electron',
  'mail2eli',
  'mail2elizabeth',
  'mail2ellen',
  'mail2elliot',
  'mail2elsalvador',
  'mail2elvis',
  'mail2emergency',
  'mail2emily',
  'mail2engineer',
  'mail2english',
  'mail2environmentalist',
  'mail2eos',
  'mail2eric',
  'mail2erica',
  'mail2erin',
  'mail2erinyes',
  'mail2eris',
  'mail2eritrea',
  'mail2ernie',
  'mail2eros',
  'mail2estonia',
  'mail2ethan',
  'mail2ethiopia',
  'mail2eu',
  'mail2europe',
  'mail2eurus',
  'mail2eva',
  'mail2evan',
  'mail2evelyn',
  'mail2everything',
  'mail2exciting',
  'mail2expert',
  'mail2fairy',
  'mail2faith',
  'mail2fanatic',
  'mail2fancy',
  'mail2fantasy',
  'mail2farm',
  'mail2farmer',
  'mail2fashion',
  'mail2fat',
  'mail2feeling',
  'mail2female',
  'mail2fever',
  'mail2fighter',
  'mail2fiji',
  'mail2filmfestival',
  'mail2films',
  'mail2finance',
  'mail2finland',
  'mail2fireman',
  'mail2firm',
  'mail2fisherman',
  'mail2flexible',
  'mail2florence',
  'mail2florida',
  'mail2floyd',
  'mail2fly',
  'mail2fond',
  'mail2fondness',
  'mail2football',
  'mail2footballfan',
  'mail2found',
  'mail2france',
  'mail2frank',
  'mail2frankfurt',
  'mail2franklin',
  'mail2fred',
  'mail2freddie',
  'mail2free',
  'mail2freedom',
  'mail2french',
  'mail2freudian',
  'mail2friendship',
  'mail2from',
  'mail2fun',
  'mail2gabon',
  'mail2gabriel',
  'mail2gail',
  'mail2galaxy',
  'mail2gambia',
  'mail2games',
  'mail2gary',
  'mail2gavin',
  'mail2gemini',
  'mail2gene',
  'mail2genes',
  'mail2geneva',
  'mail2george',
  'mail2georgia',
  'mail2gerald',
  'mail2german',
  'mail2germany',
  'mail2ghana',
  'mail2gilbert',
  'mail2gina',
  'mail2girl',
  'mail2glen',
  'mail2gloria',
  'mail2goddess',
  'mail2gold',
  'mail2golfclub',
  'mail2golfer',
  'mail2gordon',
  'mail2government',
  'mail2grab',
  'mail2grace',
  'mail2graham',
  'mail2grandma',
  'mail2grandpa',
  'mail2grant',
  'mail2greece',
  'mail2green',
  'mail2greg',
  'mail2grenada',
  'mail2gsm',
  'mail2guard',
  'mail2guatemala',
  'mail2guy',
  'mail2hades',
  'mail2haiti',
  'mail2hal',
  'mail2handhelds',
  'mail2hank',
  'mail2hannah',
  'mail2harold',
  'mail2harry',
  'mail2hawaii',
  'mail2headhunter',
  'mail2heal',
  'mail2heather',
  'mail2heaven',
  'mail2hebe',
  'mail2hecate',
  'mail2heidi',
  'mail2helen',
  'mail2hell',
  'mail2help',
  'mail2helpdesk',
  'mail2henry',
  'mail2hephaestus',
  'mail2hera',
  'mail2hercules',
  'mail2herman',
  'mail2hermes',
  'mail2hespera',
  'mail2hestia',
  'mail2highschool',
  'mail2hindu',
  'mail2hip',
  'mail2hiphop',
  'mail2holland',
  'mail2holly',
  'mail2hollywood',
  'mail2homer',
  'mail2honduras',
  'mail2honey',
  'mail2hongkong',
  'mail2hope',
  'mail2horse',
  'mail2hot',
  'mail2hotel',
  'mail2houston',
  'mail2howard',
  'mail2hugh',
  'mail2human',
  'mail2hungary',
  'mail2hungry',
  'mail2hygeia',
  'mail2hyperspace',
  'mail2hypnos',
  'mail2ian',
  'mail2ice-cream',
  'mail2iceland',
  'mail2idaho',
  'mail2idontknow',
  'mail2illinois',
  'mail2imam',
  'mail2in',
  'mail2india',
  'mail2indian',
  'mail2indiana',
  'mail2indonesia',
  'mail2infinity',
  'mail2intense',
  'mail2iowa',
  'mail2iran',
  'mail2iraq',
  'mail2ireland',
  'mail2irene',
  'mail2iris',
  'mail2irresistible',
  'mail2irving',
  'mail2irwin',
  'mail2isaac',
  'mail2israel',
  'mail2italian',
  'mail2italy',
  'mail2jackie',
  'mail2jacob',
  'mail2jail',
  'mail2jaime',
  'mail2jake',
  'mail2jamaica',
  'mail2james',
  'mail2jamie',
  'mail2jan',
  'mail2jane',
  'mail2janet',
  'mail2janice',
  'mail2japan',
  'mail2japanese',
  'mail2jasmine',
  'mail2jason',
  'mail2java',
  'mail2jay',
  'mail2jazz',
  'mail2jed',
  'mail2jeffrey',
  'mail2jennifer',
  'mail2jenny',
  'mail2jeremy',
  'mail2jerry',
  'mail2jessica',
  'mail2jessie',
  'mail2jesus',
  'mail2jew',
  'mail2jeweler',
  'mail2jim',
  'mail2jimmy',
  'mail2joan',
  'mail2joann',
  'mail2joanna',
  'mail2jody',
  'mail2joe',
  'mail2joel',
  'mail2joey',
  'mail2john',
  'mail2join',
  'mail2jon',
  'mail2jonathan',
  'mail2jones',
  'mail2jordan',
  'mail2joseph',
  'mail2josh',
  'mail2joy',
  'mail2juan',
  'mail2judge',
  'mail2judy',
  'mail2juggler',
  'mail2julian',
  'mail2julie',
  'mail2jumbo',
  'mail2junk',
  'mail2justin',
  'mail2justme',
  'mail2k',
  'mail2kansas',
  'mail2karate',
  'mail2karen',
  'mail2karl',
  'mail2karma',
  'mail2kathleen',
  'mail2kathy',
  'mail2katie',
  'mail2kay',
  'mail2kazakhstan',
  'mail2keen',
  'mail2keith',
  'mail2kelly',
  'mail2kelsey',
  'mail2ken',
  'mail2kendall',
  'mail2kennedy',
  'mail2kenneth',
  'mail2kenny',
  'mail2kentucky',
  'mail2kenya',
  'mail2kerry',
  'mail2kevin',
  'mail2kim',
  'mail2kimberly',
  'mail2king',
  'mail2kirk',
  'mail2kiss',
  'mail2kosher',
  'mail2kristin',
  'mail2kurt',
  'mail2kuwait',
  'mail2kyle',
  'mail2kyrgyzstan',
  'mail2la',
  'mail2lacrosse',
  'mail2lance',
  'mail2lao',
  'mail2larry',
  'mail2latvia',
  'mail2laugh',
  'mail2laura',
  'mail2lauren',
  'mail2laurie',
  'mail2lawrence',
  'mail2lawyer',
  'mail2lebanon',
  'mail2lee',
  'mail2leo',
  'mail2leon',
  'mail2leonard',
  'mail2leone',
  'mail2leslie',
  'mail2letter',
  'mail2liberia',
  'mail2libertarian',
  'mail2libra',
  'mail2libya',
  'mail2liechtenstein',
  'mail2life',
  'mail2linda',
  'mail2linux',
  'mail2lionel',
  'mail2lipstick',
  'mail2liquid',
  'mail2lisa',
  'mail2lithuania',
  'mail2litigator',
  'mail2liz',
  'mail2lloyd',
  'mail2lois',
  'mail2lola',
  'mail2london',
  'mail2looking',
  'mail2lori',
  'mail2lost',
  'mail2lou',
  'mail2louis',
  'mail2louisiana',
  'mail2lovable',
  'mail2love',
  'mail2lucky',
  'mail2lucy',
  'mail2lunch',
  'mail2lust',
  'mail2luxembourg',
  'mail2luxury',
  'mail2lyle',
  'mail2lynn',
  'mail2madagascar',
  'mail2madison',
  'mail2madrid',
  'mail2maggie',
  'mail2mail4',
  'mail2maine',
  'mail2malawi',
  'mail2malaysia',
  'mail2maldives',
  'mail2mali',
  'mail2malta',
  'mail2mambo',
  'mail2man',
  'mail2mandy',
  'mail2manhunter',
  'mail2mankind',
  'mail2many',
  'mail2marc',
  'mail2marcia',
  'mail2margaret',
  'mail2margie',
  'mail2marhaba',
  'mail2maria',
  'mail2marilyn',
  'mail2marines',
  'mail2mark',
  'mail2marriage',
  'mail2married',
  'mail2marries',
  'mail2mars',
  'mail2marsha',
  'mail2marshallislands',
  'mail2martha',
  'mail2martin',
  'mail2marty',
  'mail2marvin',
  'mail2mary',
  'mail2maryland',
  'mail2mason',
  'mail2massachusetts',
  'mail2matt',
  'mail2matthew',
  'mail2maurice',
  'mail2mauritania',
  'mail2mauritius',
  'mail2max',
  'mail2maxwell',
  'mail2maybe',
  'mail2mba',
  'mail2me4u',
  'mail2mechanic',
  'mail2medieval',
  'mail2megan',
  'mail2mel',
  'mail2melanie',
  'mail2melissa',
  'mail2melody',
  'mail2member',
  'mail2memphis',
  'mail2methodist',
  'mail2mexican',
  'mail2mexico',
  'mail2mgz',
  'mail2miami',
  'mail2michael',
  'mail2michelle',
  'mail2michigan',
  'mail2mike',
  'mail2milan',
  'mail2milano',
  'mail2mildred',
  'mail2milkyway',
  'mail2millennium',
  'mail2millionaire',
  'mail2milton',
  'mail2mime',
  'mail2mindreader',
  'mail2mini',
  'mail2minister',
  'mail2minneapolis',
  'mail2minnesota',
  'mail2miracle',
  'mail2missionary',
  'mail2mississippi',
  'mail2missouri',
  'mail2mitch',
  'mail2model',
  'mail2moldova',
  'mail2mom',
  'mail2monaco',
  'mail2money',
  'mail2mongolia',
  'mail2monica',
  'mail2montana',
  'mail2monty',
  'mail2moon',
  'mail2morocco',
  'mail2morpheus',
  'mail2mors',
  'mail2moscow',
  'mail2moslem',
  'mail2mouseketeer',
  'mail2movies',
  'mail2mozambique',
  'mail2mp3',
  'mail2mrright',
  'mail2msright',
  'mail2museum',
  'mail2music',
  'mail2musician',
  'mail2muslim',
  'mail2my',
  'mail2myboat',
  'mail2mycar',
  'mail2mycell',
  'mail2mygsm',
  'mail2mylaptop',
  'mail2mymac',
  'mail2mypager',
  'mail2mypalm',
  'mail2mypc',
  'mail2myphone',
  'mail2myplane',
  'mail2namibia',
  'mail2nancy',
  'mail2nasdaq',
  'mail2nathan',
  'mail2nauru',
  'mail2navy',
  'mail2neal',
  'mail2nebraska',
  'mail2ned',
  'mail2neil',
  'mail2nelson',
  'mail2nemesis',
  'mail2nepal',
  'mail2netherlands',
  'mail2network',
  'mail2nevada',
  'mail2newhampshire',
  'mail2newjersey',
  'mail2newmexico',
  'mail2newyork',
  'mail2newzealand',
  'mail2nicaragua',
  'mail2nick',
  'mail2nicole',
  'mail2niger',
  'mail2nigeria',
  'mail2nike',
  'mail2no',
  'mail2noah',
  'mail2noel',
  'mail2noelle',
  'mail2normal',
  'mail2norman',
  'mail2northamerica',
  'mail2northcarolina',
  'mail2northdakota',
  'mail2northpole',
  'mail2norway',
  'mail2notus',
  'mail2noway',
  'mail2nowhere',
  'mail2nuclear',
  'mail2nun',
  'mail2ny',
  'mail2oasis',
  'mail2oceanographer',
  'mail2ohio',
  'mail2ok',
  'mail2oklahoma',
  'mail2oliver',
  'mail2oman',
  'mail2one',
  'mail2onfire',
  'mail2online',
  'mail2oops',
  'mail2open',
  'mail2ophthalmologist',
  'mail2optometrist',
  'mail2oregon',
  'mail2oscars',
  'mail2oslo',
  'mail2painter',
  'mail2pakistan',
  'mail2palau',
  'mail2pan',
  'mail2panama',
  'mail2paraguay',
  'mail2paralegal',
  'mail2paris',
  'mail2park',
  'mail2parker',
  'mail2party',
  'mail2passion',
  'mail2pat',
  'mail2patricia',
  'mail2patrick',
  'mail2patty',
  'mail2paul',
  'mail2paula',
  'mail2pay',
  'mail2peace',
  'mail2pediatrician',
  'mail2peggy',
  'mail2pennsylvania',
  'mail2perry',
  'mail2persephone',
  'mail2persian',
  'mail2peru',
  'mail2pete',
  'mail2peter',
  'mail2pharmacist',
  'mail2phil',
  'mail2philippines',
  'mail2phoenix',
  'mail2phonecall',
  'mail2phyllis',
  'mail2pickup',
  'mail2pilot',
  'mail2pisces',
  'mail2planet',
  'mail2platinum',
  'mail2plato',
  'mail2pluto',
  'mail2pm',
  'mail2podiatrist',
  'mail2poet',
  'mail2poland',
  'mail2policeman',
  'mail2policewoman',
  'mail2politician',
  'mail2pop',
  'mail2pope',
  'mail2popular',
  'mail2portugal',
  'mail2poseidon',
  'mail2potatohead',
  'mail2power',
  'mail2presbyterian',
  'mail2president',
  'mail2priest',
  'mail2prince',
  'mail2princess',
  'mail2producer',
  'mail2professor',
  'mail2protect',
  'mail2psychiatrist',
  'mail2psycho',
  'mail2psychologist',
  'mail2qatar',
  'mail2queen',
  'mail2rabbi',
  'mail2race',
  'mail2racer',
  'mail2rachel',
  'mail2rage',
  'mail2rainmaker',
  'mail2ralph',
  'mail2randy',
  'mail2rap',
  'mail2rare',
  'mail2rave',
  'mail2ray',
  'mail2raymond',
  'mail2realtor',
  'mail2rebecca',
  'mail2recruiter',
  'mail2recycle',
  'mail2redhead',
  'mail2reed',
  'mail2reggie',
  'mail2register',
  'mail2rent',
  'mail2republican',
  'mail2resort',
  'mail2rex',
  'mail2rhodeisland',
  'mail2rich',
  'mail2richard',
  'mail2ricky',
  'mail2ride',
  'mail2riley',
  'mail2rita',
  'mail2rob',
  'mail2robert',
  'mail2roberta',
  'mail2robin',
  'mail2rock',
  'mail2rocker',
  'mail2rod',
  'mail2rodney',
  'mail2romania',
  'mail2rome',
  'mail2ron',
  'mail2ronald',
  'mail2ronnie',
  'mail2rose',
  'mail2rosie',
  'mail2roy',
  'mail2rss',
  'mail2rudy',
  'mail2rugby',
  'mail2runner',
  'mail2russell',
  'mail2russia',
  'mail2russian',
  'mail2rusty',
  'mail2ruth',
  'mail2rwanda',
  'mail2ryan',
  'mail2sa',
  'mail2sabrina',
  'mail2safe',
  'mail2sagittarius',
  'mail2sail',
  'mail2sailor',
  'mail2sal',
  'mail2salaam',
  'mail2sam',
  'mail2samantha',
  'mail2samoa',
  'mail2samurai',
  'mail2sandra',
  'mail2sandy',
  'mail2sanfrancisco',
  'mail2sanmarino',
  'mail2santa',
  'mail2sara',
  'mail2sarah',
  'mail2sat',
  'mail2saturn',
  'mail2saudi',
  'mail2saudiarabia',
  'mail2save',
  'mail2savings',
  'mail2school',
  'mail2scientist',
  'mail2scorpio',
  'mail2scott',
  'mail2sean',
  'mail2search',
  'mail2seattle',
  'mail2secretagent',
  'mail2senate',
  'mail2senegal',
  'mail2sensual',
  'mail2seth',
  'mail2sevenseas',
  'mail2sexy',
  'mail2seychelles',
  'mail2shane',
  'mail2sharon',
  'mail2shawn',
  'mail2ship',
  'mail2shirley',
  'mail2shoot',
  'mail2shuttle',
  'mail2sierraleone',
  'mail2simon',
  'mail2singapore',
  'mail2single',
  'mail2site',
  'mail2skater',
  'mail2skier',
  'mail2sky',
  'mail2sleek',
  'mail2slim',
  'mail2slovakia',
  'mail2slovenia',
  'mail2smile',
  'mail2smith',
  'mail2smooth',
  'mail2soccer',
  'mail2soccerfan',
  'mail2socialist',
  'mail2soldier',
  'mail2somalia',
  'mail2son',
  'mail2song',
  'mail2sos',
  'mail2sound',
  'mail2southafrica',
  'mail2southamerica',
  'mail2southcarolina',
  'mail2southdakota',
  'mail2southkorea',
  'mail2southpole',
  'mail2spain',
  'mail2spanish',
  'mail2spare',
  'mail2spectrum',
  'mail2splash',
  'mail2sponsor',
  'mail2sports',
  'mail2srilanka',
  'mail2stacy',
  'mail2stan',
  'mail2stanley',
  'mail2star',
  'mail2state',
  'mail2stephanie',
  'mail2steve',
  'mail2steven',
  'mail2stewart',
  'mail2stlouis',
  'mail2stock',
  'mail2stockholm',
  'mail2stockmarket',
  'mail2storage',
  'mail2store',
  'mail2strong',
  'mail2student',
  'mail2studio',
  'mail2studio54',
  'mail2stuntman',
  'mail2subscribe',
  'mail2sudan',
  'mail2superstar',
  'mail2surfer',
  'mail2suriname',
  'mail2susan',
  'mail2suzie',
  'mail2swaziland',
  'mail2sweden',
  'mail2sweetheart',
  'mail2swim',
  'mail2swimmer',
  'mail2swiss',
  'mail2switzerland',
  'mail2sydney',
  'mail2sylvia',
  'mail2syria',
  'mail2taboo',
  'mail2taiwan',
  'mail2tajikistan',
  'mail2tammy',
  'mail2tango',
  'mail2tanya',
  'mail2tanzania',
  'mail2tara',
  'mail2taurus',
  'mail2taxi',
  'mail2taxidermist',
  'mail2taylor',
  'mail2taz',
  'mail2teacher',
  'mail2technician',
  'mail2ted',
  'mail2telephone',
  'mail2teletubbie',
  'mail2tenderness',
  'mail2tennessee',
  'mail2tennis',
  'mail2tennisfan',
  'mail2terri',
  'mail2terry',
  'mail2test',
  'mail2texas',
  'mail2thailand',
  'mail2therapy',
  'mail2think',
  'mail2tickets',
  'mail2tiffany',
  'mail2tim',
  'mail2time',
  'mail2timothy',
  'mail2tina',
  'mail2titanic',
  'mail2toby',
  'mail2todd',
  'mail2togo',
  'mail2tom',
  'mail2tommy',
  'mail2tonga',
  'mail2tony',
  'mail2touch',
  'mail2tourist',
  'mail2tracey',
  'mail2tracy',
  'mail2tramp',
  'mail2travel',
  'mail2traveler',
  'mail2travis',
  'mail2trekkie',
  'mail2trex',
  'mail2triallawyer',
  'mail2trick',
  'mail2trillionaire',
  'mail2troy',
  'mail2truck',
  'mail2trump',
  'mail2try',
  'mail2tunisia',
  'mail2turbo',
  'mail2turkey',
  'mail2turkmenistan',
  'mail2tv',
  'mail2tycoon',
  'mail2tyler',
  'mail2u4me',
  'mail2uae',
  'mail2uganda',
  'mail2uk',
  'mail2ukraine',
  'mail2uncle',
  'mail2unsubscribe',
  'mail2uptown',
  'mail2uruguay',
  'mail2usa',
  'mail2utah',
  'mail2uzbekistan',
  'mail2v',
  'mail2vacation',
  'mail2valentines',
  'mail2valerie',
  'mail2valley',
  'mail2vamoose',
  'mail2vanessa',
  'mail2vanuatu',
  'mail2venezuela',
  'mail2venous',
  'mail2venus',
  'mail2vermont',
  'mail2vickie',
  'mail2victor',
  'mail2victoria',
  'mail2vienna',
  'mail2vietnam',
  'mail2vince',
  'mail2virginia',
  'mail2virgo',
  'mail2visionary',
  'mail2vodka',
  'mail2volleyball',
  'mail2waiter',
  'mail2wallstreet',
  'mail2wally',
  'mail2walter',
  'mail2warren',
  'mail2washington',
  'mail2wave',
  'mail2way',
  'mail2waycool',
  'mail2wayne',
  'mail2webmaster',
  'mail2webtop',
  'mail2webtv',
  'mail2weird',
  'mail2wendell',
  'mail2wendy',
  'mail2westend',
  'mail2westvirginia',
  'mail2whether',
  'mail2whip',
  'mail2white',
  'mail2whitehouse',
  'mail2whitney',
  'mail2why',
  'mail2wilbur',
  'mail2wild',
  'mail2willard',
  'mail2willie',
  'mail2wine',
  'mail2winner',
  'mail2wired',
  'mail2wisconsin',
  'mail2woman',
  'mail2wonder',
  'mail2world',
  'mail2worship',
  'mail2wow',
  'mail2www',
  'mail2wyoming',
  'mail2xfiles',
  'mail2xox',
  'mail2yachtclub',
  'mail2yahalla',
  'mail2yemen',
  'mail2yes',
  'mail2yugoslavia',
  'mail2zack',
  'mail2zambia',
  'mail2zenith',
  'mail2zephir',
  'mail2zeus',
  'mail2zipper',
  'mail2zoo',
  'mail2zoologist',
  'mail2zurich',
  'mail3000',
  'mail333',
  'mail4trash',
  'mail4u',
  'mail8',
  'mailandftp',
  'mailandnews',
  'mailas',
  'mailasia',
  'mailbidon',
  'mailbiz',
  'mailblocks',
  'mailbolt',
  'mailbomb',
  'mailboom',
  'mailbox',
  'mailbox72',
  'mailbox80',
  'mailbr',
  'mailbucket',
  'mailc',
  'mailcan',
  'mailcat',
  'mailcatch',
  'mailcc',
  'mailchoose',
  'mailcity',
  'mailclub',
  'mailde',
  'maildrop',
  'maildx',
  'mailed',
  'maileimer',
  'mailexcite',
  'mailexpire',
  'mailfa',
  'mailfly',
  'mailforce',
  'mailforspam',
  'mailfree',
  'mailfreeonline',
  'mailfreeway',
  'mailfs',
  'mailftp',
  'mailgate',
  'mailgenie',
  'mailguard',
  'mailhaven',
  'mailhood',
  'mailimate',
  'mailin8r',
  'mailinatar',
  'mailinater',
  'mailinator',
  'mailinator2',
  'mailinblack',
  'mailincubator',
  'mailingaddress',
  'mailingweb',
  'mailisent',
  'mailismagic',
  'mailite',
  'mailmate',
  'mailme',
  'mailme24',
  'mailmetrash',
  'mailmight',
  'mailmij',
  'mailmoat',
  'mailms',
  'mailnator',
  'mailnesia',
  'mailnew',
  'mailnull',
  'mailops',
  'mailorg',
  'mailoye',
  'mailpanda',
  'mailpick',
  'mailpokemon',
  'mailpost',
  'mailpride',
  'mailproxsy',
  'mailpuppy',
  'mailquack',
  'mailrock',
  'mailroom',
  'mailru',
  'mailsac',
  'mailscrap',
  'mailseal',
  'mailsent',
  'mailserver',
  'mailservice',
  'mailshell',
  'mailshuttle',
  'mailsiphon',
  'mailslapping',
  'mailsnare',
  'mailstart',
  'mailstartplus',
  'mailsurf',
  'mailtag',
  'mailtemp',
  'mailto',
  'mailtome',
  'mailtothis',
  'mailtrash',
  'mailtv',
  'mailueberfall',
  'mailup',
  'mailwire',
  'mailworks',
  'mailzi',
  'mailzilla',
  'makemetheking',
  'maktoob',
  'malayalamtelevision',
  'malayalapathram',
  'male',
  'maltesemail',
  'mamber',
  'manager',
  'mancity',
  'manlymail',
  'mantrafreenet',
  'mantramail',
  'mantraonline',
  'manutdfans',
  'manybrain',
  'marchmail',
  'marfino',
  'margarita',
  'mariah-carey',
  'mariahc',
  'marijuana',
  'marketing',
  'marketingfanatic',
  'marketweighton',
  'married-not',
  'marriedandlovingit',
  'marry',
  'marsattack',
  'martindalemail',
  'martinguerre',
  'mash4077',
  'masrawy',
  'matmail',
  'mauimail',
  'mauritius',
  'maximumedge',
  'maxleft',
  'maxmail',
  'mayaple',
  'mbox',
  'mbx',
  'mchsi',
  'mcrmail',
  'me-mail',
  'me',
  'meanpeoplesuck',
  'meatismurder',
  'medical',
  'medmail',
  'medscape',
  'meetingmall',
  'mega',
  'megago',
  'megamail',
  'megapoint',
  'mehrani',
  'mehtaweb',
  'meine-dateien',
  'meine-diashow',
  'meine-fotos',
  'meine-urlaubsfotos',
  'meinspamschutz',
  'mekhong',
  'melodymail',
  'meloo',
  'meltmail',
  'members',
  'menja',
  'merda',
  'merseymail',
  'mesra',
  'message',
  'messagebeamer',
  'messages',
  'messagez',
  'metacrawler',
  'metalfan',
  'metaping',
  'metta',
  'mexicomail',
  'mezimages',
  'mfsa',
  'miatadriver',
  'mierdamail',
  'miesto',
  'mighty',
  'migmail',
  'migumail',
  'miho-nakayama',
  'mikrotamanet',
  'millionaireintraining',
  'millionairemail',
  'milmail',
  'mindless',
  'mindspring',
  'minermail',
  'mini-mail',
  'minister',
  'ministry-of-silly-walks',
  'mintemail',
  'misery',
  'misterpinball',
  'mit',
  'mittalweb',
  'mixmail',
  'mjfrogmail',
  'ml1',
  'mlanime',
  'mlb',
  'mm',
  'mmail',
  'mns',
  'mo3gov',
  'moakt',
  'mobico',
  'mobilbatam',
  'mobileninja',
  'mochamail',
  'modemnet',
  'modernenglish',
  'modomail',
  'mohammed',
  'mohmal',
  'moldova',
  'moldovacc',
  'mom-mail',
  'momslife',
  'moncourrier',
  'monemail',
  'money',
  'mongol',
  'monmail',
  'monsieurcinema',
  'montevideo',
  'monumentmail',
  'moomia',
  'moonman',
  'moose-mail',
  'mor19',
  'mortaza',
  'mosaicfx',
  'moscowmail',
  'mosk',
  'most-wanted',
  'mostlysunny',
  'motorcyclefan',
  'motormania',
  'movemail',
  'movieemail',
  'movieluver',
  'mox',
  'mozartmail',
  'mozhno',
  'mp3haze',
  'mp4',
  'mr-potatohead',
  'mrpost',
  'mrspender',
  'mscold',
  'msgbox',
  'msn',
  'msx',
  'mt2009',
  'mt2014',
  'mt2015',
  'mt2016',
  'mttestdriver',
  'muehlacker',
  'multiplechoices',
  'mundomail',
  'munich',
  'music',
  'musician',
  'musicscene',
  'muskelshirt',
  'muslim',
  'muslimemail',
  'muslimsonline',
  'mutantweb',
  'mvrht',
  'my',
  'my10minutemail',
  'mybox',
  'mycabin',
  'mycampus',
  'mycard',
  'mycity',
  'mycleaninbox',
  'mycool',
  'mydomain',
  'mydotcomaddress',
  'myfairpoint',
  'myfamily',
  'myfastmail',
  'myfunnymail',
  'mygo',
  'myiris',
  'myjazzmail',
  'mymac',
  'mymacmail',
  'mymail-in',
  'mymail',
  'mynamedot',
  'mynet',
  'mynetaddress',
  'mynetstore',
  'myotw',
  'myownemail',
  'myownfriends',
  'mypacks',
  'mypad',
  'mypartyclip',
  'mypersonalemail',
  'myphantomemail',
  'myplace',
  'myrambler',
  'myrealbox',
  'myremarq',
  'mysamp',
  'myself',
  'myspaceinc',
  'myspamless',
  'mystupidjob',
  'mytemp',
  'mytempemail',
  'mytempmail',
  'mythirdage',
  'mytrashmail',
  'myway',
  'myworldmail',
  'n2',
  'n2baseball',
  'n2business',
  'n2mail',
  'n2soccer',
  'n2software',
  'nabc',
  'nabuma',
  'nafe',
  'nagarealm',
  'nagpal',
  'nakedgreens',
  'name',
  'nameplanet',
  'nanaseaikawa',
  'nandomail',
  'naplesnews',
  'naseej',
  'nate',
  'nativestar',
  'nativeweb',
  'naui',
  'naver',
  'navigator',
  'navy',
  'naz',
  'nc',
  'nchoicemail',
  'neeva',
  'nekto',
  'nemra1',
  'nenter',
  'neo',
  'neomailbox',
  'nepwk',
  'nervhq',
  'nervmich',
  'nervtmich',
  'net-c',
  'net-pager',
  'net-shopping',
  'net',
  'net4b',
  'net4you',
  'netaddres',
  'netaddress',
  'netbounce',
  'netbroadcaster',
  'netby',
  'netc',
  'netcenter-vn',
  'netcity',
  'netcmail',
  'netcourrier',
  'netexecutive',
  'netexpressway',
  'netfirms',
  'netgenie',
  'netian',
  'netizen',
  'netkushi',
  'netlane',
  'netlimit',
  'netmail',
  'netmails',
  'netman',
  'netmanor',
  'netmongol',
  'netnet',
  'netnoir',
  'netpiper',
  'netposta',
  'netradiomail',
  'netralink',
  'netscape',
  'netscapeonline',
  'netspace',
  'netspeedway',
  'netsquare',
  'netster',
  'nettaxi',
  'nettemail',
  'netterchef',
  'netti',
  'netvigator',
  'netzero',
  'netzidiot',
  'netzoola',
  'neue-dateien',
  'neuf',
  'neuro',
  'neustreet',
  'neverbox',
  'newap',
  'newarbat',
  'newmail',
  'newsboysmail',
  'newyork',
  'newyorkcity',
  'nextmail',
  'nexxmail',
  'nfmail',
  'ngs',
  'nhmail',
  'nice-4u',
  'nicebush',
  'nicegal',
  'nicholastse',
  'nicolastse',
  'niepodam',
  'nightimeuk',
  'nightmail',
  'nikopage',
  'nikulino',
  'nimail',
  'nincsmail',
  'ninfan',
  'nirvanafan',
  'nm',
  'nmail',
  'nnh',
  'nnov',
  'no-spam',
  'no4ma',
  'noavar',
  'noblepioneer',
  'nogmailspam',
  'nomail',
  'nomail2me',
  'nomorespamemails',
  'nonpartisan',
  'nonspam',
  'nonspammer',
  'nonstopcinema',
  'norika-fujiwara',
  'norikomail',
  'northgates',
  'nospam',
  'nospam4',
  'nospamfor',
  'nospammail',
  'nospamthanks',
  'notmailinator',
  'notsharingmy',
  'notyouagain',
  'novogireevo',
  'novokosino',
  'nowhere',
  'nowmymail',
  'ntelos',
  'ntlhelp',
  'ntlworld',
  'ntscan',
  null,
  'nullbox',
  'numep',
  'nur-fuer-spam',
  'nurfuerspam',
  'nus',
  'nuvse',
  'nwldx',
  'nxt',
  'ny',
  'nybce',
  'nybella',
  'nyc',
  'nycmail',
  'nz11',
  'nzoomail',
  'o-tay',
  'o2',
  'oaklandas-fan',
  'oath',
  'objectmail',
  'obobbo',
  'oceanfree',
  'ochakovo',
  'odaymail',
  'oddpost',
  'odmail',
  'odnorazovoe',
  'office-dateien',
  'office-email',
  'officedomain',
  'offroadwarrior',
  'oi',
  'oicexchange',
  'oikrach',
  'ok',
  'okbank',
  'okhuman',
  'okmad',
  'okmagic',
  'okname',
  'okuk',
  'oldbuthealthy',
  'oldies1041',
  'oldies104mail',
  'ole',
  'olemail',
  'oligarh',
  'olympist',
  'olypmall',
  'omaninfo',
  'omen',
  'ondikoi',
  'onebox',
  'onenet',
  'oneoffemail',
  'oneoffmail',
  'onet',
  'onewaymail',
  'oninet',
  'onlatedotcom',
  'online',
  'onlinecasinogamblings',
  'onlinewiz',
  'onmicrosoft',
  'onmilwaukee',
  'onobox',
  'onvillage',
  'oopi',
  'op',
  'opayq',
  'opendiary',
  'openmailbox',
  'operafan',
  'operamail',
  'opoczta',
  'optician',
  'optonline',
  'optusnet',
  'orange',
  'orbitel',
  'ordinaryamerican',
  'orgmail',
  'orthodontist',
  'osite',
  'oso',
  'otakumail',
  'otherinbox',
  'our-computer',
  'our-office',
  'our',
  'ourbrisbane',
  'ourklips',
  'ournet',
  'outel',
  'outgun',
  'outlawspam',
  'outlook',
  'outloook',
  'over-the-rainbow',
  'ovi',
  'ovpn',
  'owlpic',
  'ownmail',
  'ozbytes',
  'ozemail',
  'ozz',
  'pacbell',
  'pacific-ocean',
  'pacific-re',
  'pacificwest',
  'packersfan',
  'pagina',
  'pagons',
  'paidforsurf',
  'pakistanmail',
  'pakistanoye',
  'palestinemail',
  'pancakemail',
  'pandawa',
  'pandora',
  'paradiseemail',
  'paris',
  'parkjiyoon',
  'parrot',
  'parsmail',
  'partlycloudy',
  'partybombe',
  'partyheld',
  'partynight',
  'parvazi',
  'passwordmail',
  'pathfindermail',
  'patmail',
  'patra',
  'pconnections',
  'pcpostal',
  'pcsrock',
  'pcusers',
  'peachworld',
  'pechkin',
  'pediatrician',
  'pekklemail',
  'pemail',
  'penpen',
  'peoplepc',
  'peopleweb',
  'pepbot',
  'perfectmail',
  'perovo',
  'perso',
  'personal',
  'personales',
  'petlover',
  'petml',
  'petr',
  'pettypool',
  'pezeshkpour',
  'pfui',
  'phayze',
  'phone',
  'photo-impact',
  'photographer',
  'phpbb',
  'phreaker',
  'phus8kajuspa',
  'physicist',
  'pianomail',
  'pickupman',
  'picusnet',
  'piercedallover',
  'pigeonportal',
  'pigmail',
  'pigpig',
  'pilotemail',
  'pimagop',
  'pinoymail',
  'piracha',
  'pisem',
  'pjjkp',
  'planet-mail',
  'planet',
  'planetaccess',
  'planetall',
  'planetarymotion',
  'planetdirect',
  'planetearthinter',
  'planetmail',
  'planetout',
  'plasa',
  'playersodds',
  'playful',
  'playstation',
  'plexolan',
  'pluno',
  'plus',
  'plusmail',
  'pmail',
  'pobox',
  'pochta',
  'pochtamt',
  'poczta',
  'poetic',
  'pokemail',
  'pokemonpost',
  'pokepost',
  'polandmail',
  'polbox',
  'policeoffice',
  'politician',
  'politikerclub',
  'polizisten-duzer',
  'polyfaust',
  'poofy',
  'poohfan',
  'pookmail',
  'pool-sharks',
  'poond',
  'pop3',
  'popaccount',
  'popmail',
  'popsmail',
  'popstar',
  'populus',
  'portableoffice',
  'portugalmail',
  'portugalnet',
  'positive-thinking',
  'post',
  'posta',
  'postaccesslite',
  'postafiok',
  'postafree',
  'postaweb',
  'poste',
  'postfach',
  'postinbox',
  'postino',
  'postmark',
  'postmaster',
  'postpro',
  'pousa',
  'powerdivas',
  'powerfan',
  'pp',
  'praize',
  'pray247',
  'predprinimatel',
  'premium-mail',
  'premiumproducts',
  'premiumservice',
  'prepodavatel',
  'presidency',
  'presnya',
  'press',
  'prettierthanher',
  'priest',
  'primposta',
  'printesamargareta',
  'privacy',
  'privatdemail',
  'privy-mail',
  'privymail',
  'pro',
  'probemail',
  'prodigy',
  'professor',
  'progetplus',
  'programist',
  'programmer',
  'programozo',
  'proinbox',
  'project2k',
  'prokuratura',
  'prolaunch',
  'promessage',
  'prontomail',
  'protestant',
  'protonmail',
  'proxymail',
  'prtnx',
  'prydirect',
  'psv-supporter',
  'ptd',
  'public-files',
  'public',
  'publicist',
  'pulp-fiction',
  'punkass',
  'puppy',
  'purinmail',
  'purpleturtle',
  'put2',
  'putthisinyourspamdatabase',
  'pwrby',
  'q',
  'qatar',
  'qatarmail',
  'qdice',
  'qip',
  'qmail',
  'qprfans',
  'qq',
  'qrio',
  'quackquack',
  'quake',
  'quakemail',
  'qualityservice',
  'quantentunnel',
  'qudsmail',
  'quepasa',
  'quickhosts',
  'quickinbox',
  'quickmail',
  'quicknet',
  'quickwebmail',
  'quiklinks',
  'quikmail',
  'qv7',
  'qwest',
  'qwestoffice',
  'r-o-o-t',
  'r7',
  'raakim',
  'racedriver',
  'racefanz',
  'racingfan',
  'racingmail',
  'radicalz',
  'radiku',
  'radiologist',
  'ragingbull',
  'ralib',
  'rambler',
  'ranmamail',
  'rastogi',
  'ratt-n-roll',
  'rattle-snake',
  'raubtierbaendiger',
  'ravearena',
  'ravefan',
  'ravemail',
  'razormail',
  'rccgmail',
  'rcn',
  'rcpt',
  'realemail',
  'realestatemail',
  'reality-concept',
  'reallyfast',
  'reallymymail',
  'realradiomail',
  'realtyagent',
  'realtyalerts',
  'reborn',
  'recode',
  'reconmail',
  'recursor',
  'recycledmail',
  'recycler',
  'recyclermail',
  'rediff',
  'rediffmail',
  'rediffmailpro',
  'rednecks',
  'redseven',
  'redsfans',
  'redwhitearmy',
  'regbypass',
  'reggaefan',
  'reggafan',
  'regiononline',
  'registerednurses',
  'regspaces',
  'reincarnate',
  'relia',
  'reliable-mail',
  'religious',
  'remail',
  'renren',
  'repairman',
  'reply',
  'represantive',
  'representative',
  'rescueteam',
  'resgedvgfed',
  'resource',
  'resumemail',
  'retailfan',
  'rexian',
  'rezai',
  'rhyta',
  'richmondhill',
  'rickymail',
  'rin',
  'ring',
  'riopreto',
  'rklips',
  'rmqkr',
  'rn',
  'ro',
  'roadrunner',
  'roanokemail',
  'rock',
  'rocketmail',
  'rocketship',
  'rockfan',
  'rodrun',
  'rogers',
  'rojname',
  'rol',
  'rome',
  'romymichele',
  'roosh',
  'rootprompt',
  'rotfl',
  'roughnet',
  'royal',
  'rpharmacist',
  'rr',
  'rrohio',
  'rsub',
  'rt',
  'rtrtr',
  'ru',
  'rubyridge',
  'runbox',
  'rushpost',
  'ruttolibero',
  'rvshop',
  'rxdoc',
  's-mail',
  's0ny',
  'sabreshockey',
  'sacbeemail',
  'saeuferleber',
  'safarimail',
  'safe-mail',
  'safersignup',
  'safetymail',
  'safetypost',
  'safrica',
  'sagra',
  'sags-per-mail',
  'sailormoon',
  'saint-mike',
  'saintly',
  'saintmail',
  'sale-sale-sale',
  'salehi',
  'salesperson',
  'samerica',
  'samilan',
  'samiznaetekogo',
  'sammimail',
  'sanchezsharks',
  'sandelf',
  'sanfranmail',
  'sanook',
  'sanriotown',
  'santanmail',
  'sapo',
  'sativa',
  'saturnfans',
  'saturnperformance',
  'saudia',
  'savecougars',
  'savelife',
  'saveowls',
  'sayhi',
  'saynotospams',
  'sbcglbal',
  'sbcglobal',
  'scandalmail',
  'scanova',
  'scarlet',
  'scfn',
  'schafmail',
  'schizo',
  'schmusemail',
  'schoolemail',
  'schoolmail',
  'schoolsucks',
  'schreib-doch-mal-wieder',
  'schrott-email',
  'schweiz',
  'sci',
  'science',
  'scientist',
  'scifianime',
  'scotland',
  'scotlandmail',
  'scottishmail',
  'scottishtories',
  'scottsboro',
  'scrapbookscrapbook',
  'scubadiving',
  'seanet',
  'search',
  'search417',
  'searchwales',
  'sebil',
  'seckinmail',
  'secret-police',
  'secretarias',
  'secretary',
  'secretemail',
  'secretservices',
  'secure-mail',
  'seductive',
  'seekstoyboy',
  'seguros',
  'sekomaonline',
  'selfdestructingmail',
  'sellingspree',
  'send',
  'sendmail',
  'sendme',
  'sendspamhere',
  'senseless-entertainment',
  'sent',
  'sentrismail',
  'serga',
  'servemymail',
  'servermaps',
  'services391',
  'sesmail',
  'sexmagnet',
  'seznam',
  'sfr',
  'shahweb',
  'shaniastuff',
  'shared-files',
  'sharedmailbox',
  'sharewaredevelopers',
  'sharklasers',
  'sharmaweb',
  'shaw',
  'she',
  'shellov',
  'shieldedmail',
  'shieldemail',
  'shiftmail',
  'shinedyoureyes',
  'shitaway',
  'shitmail',
  'shitware',
  'shmeriously',
  'shockinmytown',
  'shootmail',
  'shortmail',
  'shotgun',
  'showfans',
  'showslow',
  'shqiptar',
  'shuf',
  'sialkotcity',
  'sialkotian',
  'sialkotoye',
  'sibmail',
  'sify',
  'sigaret',
  'silkroad',
  'simbamail',
  'sina',
  'sinamail',
  'singapore',
  'singles4jesus',
  'singmail',
  'singnet',
  'singpost',
  'sinnlos-mail',
  'sirindia',
  'siteposter',
  'skafan',
  'skeefmail',
  'skim',
  'skizo',
  'skrx',
  'skunkbox',
  'sky',
  'skynet',
  'slamdunkfan',
  'slapsfromlastnight',
  'slaskpost',
  'slave-auctions',
  'slickriffs',
  'slingshot',
  'slippery',
  'slipry',
  'slo',
  'slotter',
  'sm',
  'smap',
  'smapxsmap',
  'smashmail',
  'smellfear',
  'smellrear',
  'smileyface',
  'sminkymail',
  'smoothmail',
  'sms',
  'smtp',
  'snail-mail',
  'snakebite',
  'snakemail',
  'sndt',
  'sneakemail',
  'sneakmail',
  'snet',
  'sniper',
  'snkmail',
  'snoopymail',
  'snowboarding',
  'snowdonia',
  'so-simple',
  'socamail',
  'socceraccess',
  'socceramerica',
  'soccermail',
  'soccermomz',
  'social-mailer',
  'socialworker',
  'sociologist',
  'sofimail',
  'sofort-mail',
  'sofortmail',
  'softhome',
  'sogetthis',
  'sogou',
  'sohu',
  'sokolniki',
  'sol',
  'solar-impact',
  'solcon',
  'soldier',
  'solution4u',
  'solvemail',
  'songwriter',
  'sonnenkinder',
  'soodomail',
  'soodonims',
  'soon',
  'soulfoodcookbook',
  'soundofmusicfans',
  'southparkmail',
  'sovsem',
  'sp',
  'space-bank',
  'space-man',
  'space-ship',
  'space-travel',
  'space',
  'spaceart',
  'spacebank',
  'spacemart',
  'spacetowns',
  'spacewar',
  'spainmail',
  'spam',
  'spam4',
  'spamail',
  'spamarrest',
  'spamavert',
  'spambob',
  'spambog',
  'spambooger',
  'spambox',
  'spamcannon',
  'spamcero',
  'spamcon',
  'spamcorptastic',
  'spamcowboy',
  'spamday',
  'spamdecoy',
  'spameater',
  'spamex',
  'spamfree',
  'spamfree24',
  'spamgoes',
  'spamgourmet',
  'spamherelots',
  'spamhereplease',
  'spamhole',
  'spamify',
  'spaminator',
  'spamkill',
  'spaml',
  'spammotel',
  'spamobox',
  'spamoff',
  'spamslicer',
  'spamspot',
  'spamstack',
  'spamthis',
  'spamtroll',
  'spankthedonkey',
  'spartapiet',
  'spazmail',
  'speed',
  'speedemail',
  'speedpost',
  'speedrules',
  'speedrulz',
  'speedy',
  'speedymail',
  'sperke',
  'spils',
  'spinfinder',
  'spiritseekers',
  'spl',
  'spoko',
  'spoofmail',
  'sportemail',
  'sportmail',
  'sportsmail',
  'sporttruckdriver',
  'spray',
  'spybox',
  'spymac',
  'sraka',
  'srilankan',
  'ssl-mail',
  'st-davids',
  'stade',
  'stalag13',
  'standalone',
  'starbuzz',
  'stargateradio',
  'starmail',
  'starmedia',
  'starplace',
  'starspath',
  'start',
  'starting-point',
  'startkeys',
  'startrekmail',
  'starwars-fans',
  'stealthmail',
  'stillchronic',
  'stinkefinger',
  'stipte',
  'stockracer',
  'stockstorm',
  'stoned',
  'stones',
  'stop-my-spam',
  'stopdropandroll',
  'storksite',
  'streber24',
  'streetwisemail',
  'stribmail',
  'strompost',
  'strongguy',
  'student',
  'studentcenter',
  'stuffmail',
  'subnetwork',
  'subram',
  'sudanmail',
  'sudolife',
  'sudomail',
  'sudoverse',
  'sudoweb',
  'sudoworld',
  'sueddeutsche',
  'suhabi',
  'suisse',
  'sukhumvit',
  'sul',
  'sunmail1',
  'sunpoint',
  'sunrise-sunset',
  'sunsgame',
  'sunumail',
  'suomi24',
  'super-auswahl',
  'superdada',
  'supereva',
  'supergreatmail',
  'supermail',
  'supermailer',
  'superman',
  'superposta',
  'superrito',
  'superstachel',
  'surat',
  'suremail',
  'surf3',
  'surfree',
  'surfsupnet',
  'surfy',
  'surgical',
  'surimail',
  'survivormail',
  'susi',
  'sviblovo',
  'svk',
  'swbell',
  'sweb',
  'swedenmail',
  'sweetville',
  'sweetxxx',
  'swift-mail',
  'swiftdesk',
  'swingeasyhithard',
  'swingfan',
  'swipermail',
  'swirve',
  'swissinfo',
  'swissmail',
  'switchboardmail',
  'switzerland',
  'sx172',
  'sympatico',
  'syom',
  'syriamail',
  't-online',
  't',
  't2mail',
  'tafmail',
  'takoe',
  'takuyakimura',
  'talk21',
  'talkcity',
  'talkinator',
  'talktalk',
  'tamb',
  'tamil',
  'tampabay',
  'tangmonkey',
  'tankpolice',
  'taotaotano',
  'tatanova',
  'tattooedallover',
  'tattoofanatic',
  'tbwt',
  'tcc',
  'tds',
  'teacher',
  'teachermail',
  'teachers',
  'teamdiscovery',
  'teamtulsa',
  'tech-center',
  'tech4peace',
  'techemail',
  'techie',
  'technisamail',
  'technologist',
  'technologyandstocks',
  'techpointer',
  'techscout',
  'techseek',
  'techsniper',
  'techspot',
  'teenagedirtbag',
  'teewars',
  'tele2',
  'telebot',
  'telefonica',
  'teleline',
  'telenet',
  'telepac',
  'telerymd',
  'teleserve',
  'teletu',
  'teleworm',
  'telfort',
  'telfortglasvezel',
  'telinco',
  'telkom',
  'telpage',
  'telstra',
  'temp-mail',
  'temp',
  'tempail',
  'tempe-mail',
  'tempemail',
  'tempinbox',
  'tempmail',
  'tempmail2',
  'tempmaildemo',
  'tempmailer',
  'tempomail',
  'temporarioemail',
  'temporaryemail',
  'temporaryforwarding',
  'temporaryinbox',
  'temporarymailaddress',
  'tempthe',
  'tempymail',
  'temtulsa',
  'tenchiclub',
  'tenderkiss',
  'tennismail',
  'terminverpennt',
  'terra',
  'test',
  'tfanus',
  'tfbnw',
  'tfz',
  'tgasa',
  'tgma',
  'tgngu',
  'tgu',
  'thai',
  'thaimail',
  'thanksnospam',
  'thankyou2010',
  'thc',
  'the-african',
  'the-airforce',
  'the-aliens',
  'the-american',
  'the-animal',
  'the-army',
  'the-astronaut',
  'the-beauty',
  'the-big-apple',
  'the-biker',
  'the-boss',
  'the-brazilian',
  'the-canadian',
  'the-canuck',
  'the-captain',
  'the-chinese',
  'the-country',
  'the-cowboy',
  'the-davis-home',
  'the-dutchman',
  'the-eagles',
  'the-englishman',
  'the-fastest',
  'the-fool',
  'the-frenchman',
  'the-galaxy',
  'the-genius',
  'the-gentleman',
  'the-german',
  'the-gremlin',
  'the-hooligan',
  'the-italian',
  'the-japanese',
  'the-lair',
  'the-madman',
  'the-mailinglist',
  'the-marine',
  'the-master',
  'the-mexican',
  'the-ministry',
  'the-monkey',
  'the-newsletter',
  'the-pentagon',
  'the-police',
  'the-prayer',
  'the-professional',
  'the-quickest',
  'the-russian',
  'the-seasiders',
  'the-snake',
  'the-spaceman',
  'the-stock-market',
  'the-student',
  'the-whitehouse',
  'the-wild-west',
  'the18th',
  'thecoolguy',
  'thecriminals',
  'thedoghousemail',
  'thedorm',
  'theend',
  'theglobe',
  'thegolfcourse',
  'thegooner',
  'theheadoffice',
  'theinternetemail',
  'thelanddownunder',
  'thelimestones',
  'themail',
  'themillionare',
  'theoffice',
  'theplate',
  'thepokerface',
  'thepostmaster',
  'theraces',
  'theracetrack',
  'therapist',
  'thereisnogod',
  'thesimpsonsfans',
  'thestreetfighter',
  'theteebox',
  'thewatercooler',
  'thewebpros',
  'thewizzard',
  'thewizzkid',
  'thexyz',
  'thezhangs',
  'thirdage',
  'thisgirl',
  'thisisnotmyrealemail',
  'thismail',
  'thoic',
  'thraml',
  'thrott',
  'throwam',
  'throwawayemailaddress',
  'thundermail',
  'tibetemail',
  'tidni',
  'tilien',
  'timein',
  'timormail',
  'tin',
  'tipsandadvice',
  'tiran',
  'tiscali',
  'tittbit',
  'tizi',
  'tkcity',
  'tlcfan',
  'tmail',
  'tmailinator',
  'tmicha',
  'toast',
  'toke',
  'tokyo',
  'tom',
  'toolsource',
  'toomail',
  'toothfairy',
  'topchat',
  'topgamers',
  'topletter',
  'topmail-files',
  'topmail',
  'topranklist',
  'topsurf',
  'topteam',
  'toquedequeda',
  'torba',
  'torchmail',
  'torontomail',
  'tortenboxer',
  'totalmail',
  'totalmusic',
  'totalsurf',
  'toughguy',
  'townisp',
  'tpg',
  'tradermail',
  'trainspottingfan',
  'trash-amil',
  'trash-mail',
  'trash2009',
  'trash2010',
  'trash2011',
  'trashdevil',
  'trashemail',
  'trashmail',
  'trashmailer',
  'trashymail',
  'travel',
  'trayna',
  'trbvm',
  'trbvn',
  'trevas',
  'trialbytrivia',
  'trialmail',
  'trickmail',
  'trillianpro',
  'trimix',
  'tritium',
  'trjam',
  'trmailbox',
  'tropicalstorm',
  'truckeremail',
  'truckers',
  'truckerz',
  'truckracer',
  'truckracers',
  'trust-me',
  'truth247',
  'truthmail',
  'tsamail',
  'ttml',
  'tulipsmail',
  'tunisiamail',
  'turboprinz',
  'turboprinzessin',
  'turkey',
  'turual',
  'tushino',
  'tut',
  'tvcablenet',
  'tverskie',
  'tverskoe',
  'tvnet',
  'tvstar',
  'twc',
  'twcny',
  'twentylove',
  'twinmail',
  'twinstarsmail',
  'tx',
  'tycoonmail',
  'tyldd',
  'typemail',
  'tyt',
  'u14269',
  'u2club',
  'ua',
  'uae',
  'uaemail',
  'ubbi',
  'uboot',
  'uggsrock',
  'uk2',
  'uk2k',
  'uk2net',
  'uk7',
  'uk8',
  'ukbuilder',
  'ukcool',
  'ukdreamcast',
  'ukmail',
  'ukmax',
  'ukr',
  'ukrpost',
  'ukrtop',
  'uku',
  'ultapulta',
  'ultimatelimos',
  'ultrapostman',
  'umail',
  'ummah',
  'umpire',
  'unbounded',
  'underwriters',
  'unforgettable',
  'uni',
  'unican',
  'unihome',
  'universal',
  'uno',
  'unofree',
  'unomail',
  'unterderbruecke',
  'uogtritons',
  'uol',
  'uole',
  'uolmail',
  'uomail',
  'upc',
  'upcmail',
  'upf',
  'upliftnow',
  'uplipht',
  'uraniomail',
  'ureach',
  'urgentmail',
  'uroid',
  'us',
  'usa',
  'usaaccess',
  'usanetmail',
  'used-product',
  'userbeam',
  'usermail',
  'username',
  'userzap',
  'usma',
  'usmc',
  'uswestmail',
  'uymail',
  'uyuyuy',
  'uzhe',
  'v-sexi',
  'v8email',
  'vaasfc4',
  'vahoo',
  'valemail',
  'valudeal',
  'vampirehunter',
  'varbizmail',
  'vcmail',
  'velnet',
  'velocall',
  'veloxmail',
  'venompen',
  'verizon',
  'verizonmail',
  'verlass-mich-nicht',
  'versatel',
  'verticalheaven',
  'veryfast',
  'veryrealemail',
  'veryspeedy',
  'vfemail',
  'vickaentb',
  'videotron',
  'viditag',
  'viewcastmedia',
  'vinbazar',
  'violinmakers',
  'vip',
  'vipmail',
  'viralplays',
  'virgilio',
  'virgin',
  'virginbroadband',
  'virginmedia',
  'virtual-mail',
  'virtualactive',
  'virtualguam',
  'virtualmail',
  'visitmail',
  'visitweb',
  'visto',
  'visualcities',
  'vivavelocity',
  'vivianhsu',
  'viwanet',
  'vjmail',
  'vjtimail',
  'vkcode',
  'vlcity',
  'vlmail',
  'vnet',
  'vnn',
  'vnukovo',
  'vodafone',
  'vodafonethuis',
  'voila',
  'volcanomail',
  'vollbio',
  'volloeko',
  'vomoto',
  'voo',
  'vorsicht-bissig',
  'vorsicht-scharf',
  'vote-democrats',
  'vote-hillary',
  'vote-republicans',
  'vote4gop',
  'votenet',
  'vovan',
  'vp',
  'vpn',
  'vr9',
  'vsimcard',
  'vubby',
  'vyhino',
  'w3',
  'wahoye',
  'walala',
  'wales2000',
  'walkmail',
  'walla',
  'wam',
  'wanaboo',
  'wanadoo',
  'wapda',
  'war-im-urlaub',
  'warmmail',
  'warpmail',
  'warrior',
  'wasteland',
  'watchmail',
  'waumail',
  'wazabi',
  'wbdet',
  'wearab',
  'web-contact',
  'web-emailbox',
  'web-ideal',
  'web-mail',
  'web-police',
  'web',
  'webaddressbook',
  'webadicta',
  'webave',
  'webbworks',
  'webcammail',
  'webcity',
  'webcontact-france',
  'webdream',
  'webemail',
  'webemaillist',
  'webinbox',
  'webindia123',
  'webjump',
  'webm4il',
  'webmail',
  'webmails',
  'webmailv',
  'webname',
  'webprogramming',
  'webskulker',
  'webstation',
  'websurfer',
  'webtopmail',
  'webtribe',
  'webuser',
  'wee',
  'weedmail',
  'weekmail',
  'weekonline',
  'wefjo',
  'weg-werf-email',
  'wegas',
  'wegwerf-emails',
  'wegwerfadresse',
  'wegwerfemail',
  'wegwerfmail',
  'wegwerpmailadres',
  'wehshee',
  'weibsvolk',
  'weinenvorglueck',
  'welsh-lady',
  'wesleymail',
  'westnet',
  'wetrainbayarea',
  'wfgdfhj',
  'wh4f',
  'whale-mail',
  'whartontx',
  'whatiaas',
  'whatpaas',
  'wheelweb',
  'whipmail',
  'whoever',
  'wholefitness',
  'whoopymail',
  'whtjddn',
  'whyspam',
  'wickedmail',
  'wickmail',
  'wideopenwest',
  'wildmail',
  'wilemail',
  'will-hier-weg',
  'willhackforfood',
  'willselfdestruct',
  'windowslive',
  'windrivers',
  'windstream',
  'winemaven',
  'wingnutz',
  'winmail',
  'winning',
  'winrz',
  'wir-haben-nachwuchs',
  'wir-sind-cool',
  'wirsindcool',
  'witty',
  'wiz',
  'wkbwmail',
  'wmail',
  'wo',
  'woh',
  'wolf-web',
  'wolke7',
  'wollan',
  'wombles',
  'women-at-work',
  'women-only',
  'wonder-net',
  'wongfaye',
  'wooow',
  'work4teens',
  'worker',
  'workmail',
  'worldbreak',
  'worldemail',
  'worldmailer',
  'worldnet',
  'wormseo',
  'wosaddict',
  'wouldilie',
  'wovz',
  'wow',
  'wowgirl',
  'wowmail',
  'wowway',
  'wp',
  'wptamail',
  'wrestlingpages',
  'wrexham',
  'writeme',
  'writemeback',
  'writeremail',
  'wronghead',
  'wrongmail',
  'wtvhmail',
  'wwdg',
  'www',
  'www2000',
  'wwwnew',
  'wx88',
  'wxs',
  'wyrm',
  'x-mail',
  'x-networks',
  'x',
  'x5g',
  'xagloo',
  'xaker',
  'xd',
  'xemaps',
  'xents',
  'xing886',
  'xmail',
  'xmaily',
  'xmastime',
  'xmenfans',
  'xms',
  'xmsg',
  'xoom',
  'xoommail',
  'xoxox',
  'xoxy',
  'xpectmore',
  'xpressmail',
  'xs4all',
  'xsecurity',
  'xsmail',
  'xtra',
  'xtram',
  'xuno',
  'xww',
  'xy9ce',
  'xyz',
  'xyzfree',
  'xzapmail',
  'y7mail',
  'ya',
  'yada-yada',
  'yaho',
  'yahoo',
  'yahoofs',
  'yahoomail',
  'yalla',
  'yalook',
  'yam',
  'yandex',
  'yapost',
  'yapped',
  'yawmail',
  'yclub',
  'yeah',
  'yebox',
  'yeehaa',
  'yehaa',
  'yehey',
  'yemenmail',
  'yep',
  'yepmail',
  'yert',
  'yesbox',
  'yesey',
  'yeswebmaster',
  'ygm',
  'yifan',
  'ymail',
  'ynnmail',
  'yogamaven',
  'yogotemail',
  'yomail',
  'yopmail',
  'yopolis',
  'yopweb',
  'youareadork',
  'youmailr',
  'youpy',
  'your-house',
  'your-mail',
  'yourdomain',
  'yourinbox',
  'yourlifesucks',
  'yourlover',
  'yournightmare',
  'yours',
  'yourssincerely',
  'yourteacher',
  'yourwap',
  'youthfire',
  'youthpost',
  'youvegotmail',
  'yuuhuu',
  'yuurok',
  'yyhmail',
  'z1p',
  'z6',
  'z9mail',
  'za',
  'zahadum',
  'zaktouni',
  'zcities',
  'zdnetmail',
  'zdorovja',
  'zeeks',
  'zeepost',
  'zehnminuten',
  'zehnminutenmail',
  'zensearch',
  'zerocrime',
  'zetmail',
  'zhaowei',
  'zhouemail',
  'ziggo',
  'zing',
  'zionweb',
  'zip',
  'zipido',
  'ziplip',
  'zipmail',
  'zipmax',
  'zippymail',
  'zmail',
  'zoemail',
  'zoho',
  'zomg',
  'zonai',
  'zoneview',
  'zonnet',
  'zooglemail',
  'zoominternet',
  'zubee',
  'zuvio',
  'zuzzurello',
  'zvmail',
  'zwallet',
  'zweb',
  'zxcv',
  'zxcvbnm',
  'zybermail',
  'zydecofan',
  'zzn',
  'zzom',
  'zzz',
];

//Supported Browser names
export const supportedBrowsers = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  EDGE: 'Edge',
};

export {
  fieldMappingimg,
  enrichModeImg,
  taskSummaryImg,
  DfieldMappingimg,
  DenrichModeImg,
  DtaskSummaryImg,
  FileUpload,
};
