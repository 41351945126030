import React, { Component, useState, useEffect } from 'react';
import './dashboard.css';
import { Card, Row, Col, Button, Drawer } from 'antd';
import RecordStatsComp from './RecordStatsComp';
import CreditInfoComp from './CreditInfoComp';
import SubscriptionComp from './SubscriptionComp';
import { AuthContext } from '../../shared/auth/authContext';
import PageTransition from '../../shared/animation/PageTransition';
import TeamMemberListComp from './TeamMemberListComp';
import MonthCreditsUsageComp from './credits_usage_pattern/MonthCreditsUsageComp';
import APIKeyGenerationComp from './APIKeyGenerationComp';

const Dashboard = () => {
  const { loggedInUser, isAllowed, isUserInRole } = React.useContext(
    AuthContext
  );
  useEffect(() => {
    let isSuperAdmin = isUserInRole('Super Admin');
    if (isSuperAdmin) {
      window.location = 'manage-clients';
    }
  }, []);

  return (
    <PageTransition>
      <div className="dashboardContent">
        <Row gutter={16}>
          <Col span={9}>
            <RecordStatsComp />
          </Col>
          <Col span={9}>
            <MonthCreditsUsageComp />
          </Col>
          <Col span={6}>
            <CreditInfoComp clientId={loggedInUser.balance} />
          </Col>
        </Row>
        <Row gutter={16}>
          {isAllowed('VIEW_USER') && (
            <Col span={9}>
              <TeamMemberListComp />
            </Col>
          )}
          <Col span={9}>
            <APIKeyGenerationComp />
          </Col>
          <Col span={isAllowed('VIEW_USER') ? 6 : 9}>
            <SubscriptionComp />
          </Col>
        </Row>
      </div>
    </PageTransition>
  );
};

export default Dashboard;
