import React from 'react';
import Home from './Home';

function AuthenticatedApp() {
  document.body.style = 'background-image: none';

  return <Home />;
}

export { AuthenticatedApp };
