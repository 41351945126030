import React, { useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.less';
import { message, Spin } from 'antd';
import * as auth from './shared/auth/auth-provider';
import { AuthContext } from './shared/auth/authContext';
import { AuthenticatedApp } from './AuthenticatedApp';
import { UnAuthenticatedApp } from './UnAuthenticatedApp';
import setupAxiosInterceptors from './config/axios-interceptor';
import { userService } from './service/user.service';
import { useQuery, useQueryCache } from 'react-query';
import jwt from 'jwt-decode';
import { msgConfig, welcomeMessage } from './common/messageComp';

const App = () => {
  const queryCache = useQueryCache();
  const [loading, setLoading] = useState(false);
  const [isLoadingForSSO, setIsLoadingForSSO] = useState(false);
  const [invalidUserErr, setInvalidUserErr] = useState(false);
  const [invalidUserErrMsg, setInvalidUserErrMsg] = useState('');
  const [isLogoutDisable, setLogoutDisable] = useState(false);
  const [adminUserNameOld, setadminUserNameOld] = useState(null);
  const { isError, data: loggedInUser, isLoading } = useQuery(
    'loggedInUser',
    userService.fetchLoggedInUser
  );

  // disable right click
  React.useEffect(() => {
    console.log('Inside right-click useEffect ', auth.isLoggedFromIg);
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    if (
      window.localStorage.getItem(auth.isLoggedFromIg) &&
      window.localStorage.getItem(auth.isLoggedFromIg) === 'true'
    ) {
      setLogoutDisable(true);
    } else {
      setLogoutDisable(false);
    }
  });

  useEffect(() => {
    window.addEventListener('message', (message) => {
      //check if valid ip is accessing
      console.log('Inside listener');
      console.log(message);
      if (isJSON(message.data)) {
        console.log('EN listerner', { message });
        const data = JSON.parse(message.data);
        if (data) {
          console.log('data', data);
          const subData = JSON.parse(data);
          if (subData.logout) {
            console.log('logout by message', subData);
            auth.logout();
          } else {
            console.log('Storing in local', data, auth);
            window.localStorage.setItem(auth.localStorageKey, data);
            window.localStorage.setItem(auth.isLoggedFromIg, true);
            window.localStorage.setItem(
              '_ENRICH_AUTH_TOKEN',
              subData.referenceCode
            );
            window.localStorage.removeItem('loginMethod');
          }
        }
      }
      if (message.data.reload == true) {
        console.log('reloadset', message.data.reload);
        window.localStorage.setItem('reloadPage', true);
      }
    });

    window.addEventListener('storage', () => {
      let isRefresh = window.localStorage.getItem('reloadPage');
      console.log('reloadddd', loggedInUser, isRefresh, typeof isRefresh);
      if (isRefresh == 'true') {
        window.location.reload();
        window.localStorage.removeItem('reloadPage');
      }
    });
  }, []);

  function isJSON(str) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  const login = (form, isSSO) => {
    console.log('In Login');
    setInvalidUserErr(false);
    if (isSSO) {
      setIsLoadingForSSO(true);
      //setLogoutDisable(true);
      window.localStorage.setItem(auth.isLoggedFromIg, false);
    }

    auth
      .login(form)
      .then((userResponse) => {
        console.log(userResponse);
        if (form.loginMethod && form.loginMethod === 'SF') {
          window.localStorage.setItem('loginMethod', form.loginMethod);
          window.localStorage.removeItem(auth.isLoggedFromIg);
        }
        setLoading(false);
        if (isSSO) {
          setIsLoadingForSSO(false);
        }
        message.destroy();
        queryCache.invalidateQueries('loggedInUser');
        if (!isSSO) {
          setLogoutDisable(false);
          setTimeout(function () {
            message.success(
              msgConfig(
                welcomeMessage(
                  userResponse.firstName ? userResponse.firstName : ''
                ),
                3
              )
            );
          }, 2000);
        }
        if (isSSO) {
          window.location = '/dashboard';
        }
        setInvalidUserErr(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (isSSO) {
          setIsLoadingForSSO(false);
          window.location = '/login';
        }
        message.destroy();
        setInvalidUserErr(true);
        setInvalidUserErrMsg(err.message);
      });
  };

  function logout() {
    auth.logout();
  }

  function clearSession() {
    window.localStorage.removeItem(auth.localStorageKey);
    window.localStorage.removeItem('_ENRICH_AUTH_TOKEN');

    if (window.localStorage.getItem(auth.isLoggedFromIg)) {
      window.localStorage.removeItem(auth.isLoggedFromIg);
      console.log('hitting close from App');
      // window.close();
    } else {
      setTimeout(() => {
        window.location = '/login';
      }, 3000);
    }
  }

  setupAxiosInterceptors(clearSession);

  const isAllowed = (val) => {
    const decoded = jwt(loggedInUser.jwtToken);
    if (decoded) {
      const userInfo = JSON.parse(JSON.stringify(decoded));
      if (userInfo.authorities.includes('Super Admin')) {
        return true;
      }
      return userInfo.permissions.includes(val);
    }
    return false;
  };

  const userPermissions = () => {
    const decoded = jwt(loggedInUser.jwtToken);
    if (decoded) {
      const userInfo = JSON.parse(JSON.stringify(decoded));
      return userInfo.permissions;
    }
    return null;
  };

  const isUserInRole = (val) => {
    const decoded = jwt(loggedInUser.jwtToken);
    if (decoded) {
      const userInfo = JSON.parse(JSON.stringify(decoded));
      return userInfo.authorities.includes(val);
    }
    return false;
  };

  if (isError) {
    clearSession();
  }

  if (!isError) {
    const props = {
      loggedInUser,
      login,
      logout,
      isAllowed,
      setLoading,
      loading,
      isUserInRole,
      userPermissions,
      invalidUserErr,
      invalidUserErrMsg,
      setIsLoadingForSSO,
      setLoading,
      isLogoutDisable,
      clearSession,
      setadminUserNameOld,
      adminUserNameOld,
    };

    return (
      <>
        {isLoading || isLoadingForSSO ? (
          <Spin
            tip="Loading..."
            spinning={isLoading || isLoadingForSSO}
            size="large"
            className="spinLoader"
          />
        ) : (
          <>
            <AuthContext.Provider value={props}>
              {loggedInUser ? (
                <Router>
                  <AuthenticatedApp />
                </Router>
              ) : (
                <Router>
                  <UnAuthenticatedApp />
                </Router>
              )}
            </AuthContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </>
        )}
      </>
    );
  }
};

export default App;
