// sort function for chart data (match array for single element array)
export const sortFunction = (array, matchArr) => {
  if (array !== null && array.length > 0) {
    let arr = matchArr.map((matchArrItem, i) => {
      return array.find((x) => x.name == matchArrItem);
    });
    let data = arr.filter(function (element) {
      return element !== undefined;
    });
    return data;
  }
};

// sort function for unavailable and record status data (match array for JSON array)
export const sortFunByField = (array, matchArr) => {
  if (array !== null && array.length > 0) {
    let arr = matchArr.map((matchArrItem, i) => {
      return array.find(
        (x) => x.name.toLowerCase() == matchArrItem.name.toLowerCase()
      );
    });
    let data = arr.filter(function (element) {
      return element !== undefined;
    });
    return data;
  }
};

export const ItalicFont = (optionitem, substr) => {
  let str =
    optionitem.name !== null && optionitem.name.includes(substr)
      ? optionitem.name.replace(
          substr,
          '<span style="font-style:italic;">' + substr + '</span>'
        )
      : optionitem.name;
  return str;
};

export const numberWithCommas = (x) => {
  if (x > 0) {
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    return parseFloat(x)
      .toFixed(2)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
};

export const numFormatter = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
    : // : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? parseFloat(labelValue)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    : // labelValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      Math.abs(Number(labelValue)).toFixed(2);
};
