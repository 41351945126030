import React, { useState } from 'react';
import { Table, Collapse, Spin, Alert, Popover, Card, Row, Col } from 'antd';
import { AuthContext } from '../../../shared/auth/authContext';
import EnrichModeSummary from './EnrichModeSummary';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import CreditAvailbleComp from '../CreditAvailble';

const { Column } = Table;
const { Panel } = Collapse;

function TaskSummaryStep(props) {
  const { loggedInUser } = React.useContext(AuthContext);
  const [expand, setExpand] = useState('Field Mapping : Expand');
  const [isActive, setIsActive] = useState(undefined);

  const nameparts = [loggedInUser.firstName, loggedInUser.lastName];
  const userProfileName =
    nameparts[0].charAt(0).toUpperCase() +
    nameparts[0].slice(1) +
    ' ' +
    nameparts[1].charAt(0).toUpperCase() +
    nameparts[1].slice(1);

  if (props.step !== 3) {
    return null;
  }

  // //upload file details
  const uploadFileDetails = () => {
    //upload details
    const uploadDetails = [
      {
        key: 1,
        name: 'File',
        value: props.fileName,
      },
      {
        key: 2,
        name: 'Task Name',
        value: props.taskName,
      },
      {
        key: 3,
        name: 'Sheet Name',
        value:
          props.excelSheetName !== 'Select Sheet Name' &&
          props.excelSheetName !== 'Select Sheet is csv'
            ? props.excelSheetName
            : null,
      },
      {
        key: 4,
        name: 'Total Record Count',
        value: props.totalRecords
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
    ];
    //filter array whose value is null that column is not shown in table
    return uploadDetails.filter((item) => {
      return item.value !== null;
    });
  };

  //expand and collapse field mapping
  const toggle = (key) => {
    console.log('toggle', key);
    // if (key !== undefined) {
    setIsActive(key);
    // }
    // if (expand === 'Field Mapping : Collapse') {
    //   setExpand('Field Mapping : Expand');
    // } else {
    //   setExpand('Field Mapping : Collapse');
    // }
  };

  //mapping field name
  const matchFieldValue = Object.values(props.mapFieldName);

  //data Source for Field Mapping table
  const getFieldMap = () => {
    //default field mapping
    const fieldMapping = [
      {
        key: 1,
        name: 'Record ID',
        value: matchFieldValue[0] ? matchFieldValue[0] : '--',
      },
      {
        key: 2,
        name: 'First Name',
        value: matchFieldValue[1] ? matchFieldValue[1] : '--',
      },
      {
        key: 3,
        name: 'Middle Name',
        value: matchFieldValue[2] ? matchFieldValue[2] : '--',
      },
      {
        key: 4,
        name: 'Last Name',
        value: matchFieldValue[3] ? matchFieldValue[3] : '--',
      },
      {
        key: 5,
        name: 'Full Name',
        value: matchFieldValue[4] ? matchFieldValue[4] : '--',
      },
      {
        key: 6,
        name: 'Email ID ',
        value: matchFieldValue[5] ? matchFieldValue[5] : '--',
      },
      {
        key: 7,
        name: 'Job Title',
        value: matchFieldValue[6] ? matchFieldValue[6] : '--',
      },
      {
        key: 8,
        name: 'Company Name',
        value: matchFieldValue[7] ? matchFieldValue[7] : '--',
      },
      {
        key: 9,
        name: 'Country',
        value: matchFieldValue[8] ? matchFieldValue[8] : '--',
      },
      {
        key: 10,
        name: 'Website',
        value: matchFieldValue[9] ? matchFieldValue[9] : '--',
      },
      {
        key: 11,
        name: 'Contact LinkedIn URL',
        value: matchFieldValue[10] ? matchFieldValue[10] : '--',
      },
      {
        key: 12,
        name: 'Contact GUID',
        value: matchFieldValue[11] ? matchFieldValue[11] : '--',
      },
      {
        key: 13,
        name: 'Company LinkedIn URL',
        value: matchFieldValue[12] ? matchFieldValue[12] : '--',
      },
      {
        key: 14,
        name: 'Company GUID',
        value: matchFieldValue[13] ? matchFieldValue[13] : '--',
      },
    ];

    //to get keys of match field
    const matchFieldKey = Object.keys(props.mapFieldName);

    //to show mapped field in summary
    const summaryFieldMap = fieldMapping.filter((fieldMapItem) => {
      let name = fieldMapItem.name.replace(':', '');
      let fieldName = name.trim();
      return matchFieldKey.some(
        (items) => items.toLowerCase().trim() === fieldName.toLowerCase().trim()
      );
    });

    return summaryFieldMap;
  };

  //Task User Details
  const userDetails = [
    {
      key: 1,
      name: 'Created By',
      value: userProfileName,
    },
  ];

  if (props.step === 3) {
    // console.log('TaskSummaryStep', isActive);
    return (
      <Spin tip="Loading..." spinning={props.isLoading} size="large">
        {props.lowBalanceAlert ? (
          <>
            <div
              style={{
                paddingBottom: '10px',
              }}>
              <span>
                <Alert
                  style={{ padding: '5px' }}
                  className={
                    props.isCreditsAvailableZero ? 'errorAlert' : 'warningAlert'
                  }
                  message={props.alertLowBalanceMessage}
                  type={props.isCreditsAvailableZero ? 'error' : 'warning'}
                  showIcon="true"
                  closable
                />
              </span>
            </div>
          </>
        ) : null}

        <Row>
          <Col span={18} style={{ overflow: 'auto' }}>
            <div
              className="labelBoldText"
              style={{ color: '#454545', paddingLeft: '15px' }}>
              Upload Details
            </div>
            <div style={{ padding: '5px 20px' }}>
              <Table
                rowKey="id"
                dataSource={uploadFileDetails()}
                size="small"
                showHeader={false}
                pagination={false}>
                <Column
                  title="name"
                  dataIndex="name"
                  key="name"
                  ellipsis={true}
                  className="fieldLabel"
                />
                <Column
                  title="value"
                  width="75%"
                  dataIndex="value"
                  key="value"
                  ellipsis={true}
                />
              </Table>
            </div>
            <Collapse
              className="taskSummaryCollapse"
              onChange={toggle}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpOutlined style={{ fontSize: '16px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '16px' }} />
                )
              }
              activeKey={isActive}
              accordion
              ghost>
              <Panel
                header={<span className="labelBoldText">Field Mapping</span>}
                key="FIELD_MAPPING">
                <Table
                  rowKey="id"
                  dataSource={getFieldMap()}
                  size="small"
                  className="fieldMappingTaskStep"
                  pagination={false}>
                  <Column
                    title="SMARTe Fields"
                    dataIndex="name"
                    key="name"
                    className="fieldLabel"
                    ellipsis={true}
                  />
                  <Column
                    title="File Mapped Fields"
                    width="75%"
                    dataIndex="value"
                    key="value"
                    ellipsis={true}
                  />
                </Table>
              </Panel>
              {props.isEnrichModeEnable && (
                <Panel
                  header={<span className="labelBoldText">Enrich Mode</span>}
                  key="ENRICH_MODE">
                  <EnrichModeSummary enrichModeValue={props.enrichModeValue} />
                </Panel>
              )}
            </Collapse>

            <div style={{ padding: '5px' }}></div>
            <div
              className="labelBoldText"
              style={{ color: '#454545', paddingLeft: '15px' }}>
              Task User Details
            </div>
            <div style={{ padding: '5px 20px' }}>
              <Table
                rowKey="id"
                dataSource={userDetails}
                size="small"
                showHeader={false}
                pagination={false}>
                <Column
                  title="name"
                  dataIndex="name"
                  key="name"
                  ellipsis={true}
                />
                <Column
                  title="value"
                  width="75%"
                  dataIndex="value"
                  key="value"
                  ellipsis={true}
                />
              </Table>
            </div>
          </Col>
          <Col span={6}>
            <CreditAvailbleComp creditsAvailable={props.creditsAvailable} />
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default TaskSummaryStep;
