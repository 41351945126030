import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import logo from './assets/images/SMARTeLogo.svg';
import newlogo from './assets/images/smartelogo-new-enrich.svg';
import { useHistory, withRouter } from 'react-router';

const FooterLayout = (props) => {
  const year = useState(new Date().getFullYear());
  let pathname = props.location.pathname;
  const history = useHistory();
  useLayoutEffect(() => {
    let element = document.getElementsByClassName('footerLayout');
    const pagination = document.getElementsByClassName('customPagination');
    const wrappedElement = document.getElementById('header-layout');
    const copyRightsText = document.getElementById('copyRightsText');

    setTimeout(() => {
      if (wrappedElement.clientHeight < 788) {
        if (element[0]) {
          if (pagination && pagination[0] !== undefined) {
            element[0].style.height = '80px';
            pagination[0].style.height = '65px';
          } else {
            if (element[0].style == undefined) {
              element[0].setAttribute('style', 'height:55px');
            } else {
              element[0].style.height = '55px';
            }
            copyRightsText.classList.add('copyRightsText');
          }
        }
      } else {
        if (pagination && pagination[0] !== undefined) {
          element[0].style.height = '55px';
          pagination[0].style.height = '45px';
          copyRightsText.classList.remove('copyRightsText');
        }
      }
    }, 4000);
    window.addEventListener('scroll', function () {
      var top = this.scrollY,
        left = this.scrollX;
      let y = top > 10 ? top - 10 : top;
      if (top > y) {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          document.addEventListener(
            'scroll',
            trackScrolling(
              element[0],
              wrappedElement,
              pagination[0],
              copyRightsText
            )
          );
        }
      }
    });
  });
  const trackScrolling = (
    element,
    wrappedElement,
    pagination,
    copyRightsText
  ) => {
    if (isBottom(wrappedElement)) {
      if (pagination !== undefined) {
        element.style.height = '80px';
        pagination.style.height = '65px';
      }
      document.removeEventListener('scroll', trackScrolling);
    } else {
      if (pagination !== undefined) {
        element.style.height = '55px';
        pagination.style.height = '45px';
        copyRightsText.classList.remove('copyRightsText');
      }
    }
  };

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom.toFixed(0) <= window.innerHeight;
  };

  return (
    <div className="footerLayout" id={props.footerrecordenricher}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          width: '100%',
          gap: '6px',
        }}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (!isUserInRole('Super Admin')) {
              history.push('/dashboard');
            }
          }}>
          <img
            src={newlogo}
            alt="Enrich"
            style={{ width: '122px', marginTop: '9px', marginLeft: '9px' }}
          />
        </a>
      </div>
      <div
        id="footer-layout"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5px',
          marginBottom: '5px',
          paddingLeft: '5px',
          width: '100%',
        }}>
        <div>
          {pathname == '/task-list' && (
            <div className="">
              Files will be available to download for 180 days | Stats will be
              available for 365 days
            </div>
          )}
        </div>

        <div className={props.footertasklistnew} id="copyRightsText">
          © <span>{year}</span> All rights reserved.
        </div>
      </div>
    </div>
  );
};
FooterLayout.propTypes = {
  //   children: PropTypes.element,
  location: PropTypes.object,
  footerrecordenricher: PropTypes.string,
  footertasklistnew: PropTypes.string,
};

export default withRouter(FooterLayout);
