import React, { useEffect, useState } from 'react';

import {
  Spin,
  Button,
  Layout,
  Typography,
  notification,
  message,
  Empty,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './user.css';

import axios from 'axios';
import UserTable from './components/UserTable';
import UserCreateForm from './components/UserCreateForm';
import UserEditForm from './components/UserEditForm';
import config from '../../config/constants';
import UserDetails from './components/UserDetails';
import { AuthContext } from '../../shared/auth/authContext';
import { userService } from '../../service/user.service';
import { clientService } from '../../service/client.service';
import { useQueryCache, usePaginatedQuery } from 'react-query';
import emptyData from '../../assets/images/emptyData.png';
import { Error_500 } from '../errorHandler';
import { ShowOopsMsg } from '../../common/messageComp';
import { motion } from 'framer-motion';
import PageTransition from '../../shared/animation/PageTransition';

const { Title } = Typography;

const UserList = () => {
  const queryCache = useQueryCache();

  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState('created_on');
  const [sortValue, setSortValue] = useState('desc');
  const [isDataPresent, setIsDataPresent] = useState(false);

  const fetchUsers = (
    key,
    page = 0,
    columnName = 'created_on',
    sortValue = 'desc'
  ) => userService.getAll(key, page, columnName, sortValue);

  //fetch records for table
  const {
    isLoading,
    isError,
    isSuccess,
    error,
    resolvedData,
    latestData,
    isFetching,
  } = usePaginatedQuery(['users', page, columnName, sortValue], fetchUsers, {
    onSuccess: (resp) => {
      setIsDataPresent(true);
    },
  });

  //to handle pagination button
  const onPaginationClick = (page, pageSize) => {
    const pageNo = page - 1;
    setPage(pageNo);
  };

  function onChange(pagination, filters, sorter, extra) {
    if (sorter.order === 'ascend') {
      setSortValue('asc');
      if (sorter.columnKey === 'name') {
        setColumnName('first_name');
      } else if (sorter.columnKey === 'username') {
        setColumnName('user_name');
      } else if (sorter.columnKey === 'email') {
        setColumnName('email');
      } else if (sorter.columnKey === 'status') {
        setColumnName('user_status');
      }
      // else if (sorter.columnKey === 'role') {
      //   setColumnName('role');
      // }
      else {
        setColumnName('created_on');
      }
    } else if (sorter.order === 'descend') {
      setSortValue('desc');
      if (sorter.columnKey === 'name') {
        setColumnName('first_name');
      } else if (sorter.columnKey === 'username') {
        setColumnName('user_name');
      } else if (sorter.columnKey === 'email') {
        setColumnName('email');
      } else if (sorter.columnKey === 'status') {
        setColumnName('user_status');
      }
      // else if (sorter.columnKey === 'role') {
      //   setColumnName('role');
      // }
      else {
        setColumnName('created_on');
      }
    } else {
      setSortValue('desc');
      setColumnName('created_on');
    }
  }

  const { isAllowed, loggedInUser, isUserInRole } = React.useContext(
    AuthContext
  );
  const [loading, setLoading] = useState(false);
  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [displayEditForm, setDisplayEditForm] = useState(false);
  const [displayUserDetails, setDisplayUserDetails] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [disableRoleList, setDisableRoleList] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [superAdminLoggedIn, setSuperAdminLoggedIn] = useState(false);
  const userUrl = config.env.userServerUrl;

  useEffect(() => {
    let isSuperAdmin = isUserInRole('Super Admin');
    setSuperAdminLoggedIn(isSuperAdmin);
    window.location = 'manage-clients';
  });
  //to add new user
  const addUser = (values) => {
    console.log('Received values of form: ', values);
    setLoading(true);
    userService
      .addUser({
        email: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        selectedRoleId:
          values.selectedRoleId !== undefined
            ? values.selectedRoleId
            : undefined,
        selectedClientId:
          superAdminLoggedIn && values.selectedClientId !== undefined
            ? values.selectedClientId
            : loggedInUser.clientId,
      })
      .then((res) => {
        console.log('add user response', res);
        setDisplayCreateForm(false);
        setLoading(false);
        message.destroy();
        message.success(
          `User account created successfully. Email sent to user for account activation and to set password.`
        );
        queryCache.invalidateQueries(['users', page, columnName, sortValue]);
      })
      .catch((err) => {
        console.log('add user error', err);
        setLoading(false);
        if (err.response.status === 400) {
          message.destroy();
          message.error('An admin account already exists for this client.');
        } else {
          message.destroy();
          message.error(
            `Username '${values.userName}' already exists. Try another username.`
          );
        }
      });
  };

  //update user
  const updateUser = (values, userStatus) => {
    console.log('Received values of form: ', values);
    setLoading(true);
    let status;
    if (userStatus === 'Active') {
      status = 'ACTIVE';
    } else if (userStatus === 'Activation pending') {
      status = 'PENDING';
    } else {
      status = 'DEACTIVATED';
    }
    userService
      .updateUser(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          userStatus: status,
        },
        selectedUserId
      )
      .then((res) => {
        console.log('update user response', res);
        setDisplayEditForm(false);
        setLoading(false);
        message.destroy();
        message.success(`User updated`);
        queryCache.invalidateQueries(['users', page, columnName, sortValue]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.destroy();
        ShowOopsMsg();
      });
  };

  //get user id and show edit form
  const showEditForm = (id) => {
    setSelectedUserId(id);
    setDisplayEditForm(true);
  };

  //reset password (mail send to user for activation/setpassword)
  const sendMail = (userName) => {
    // console.log('send mail to user:', userName);
    axios
      .get(`${userUrl}/v1/accounts/reset-password-link/${userName}`)
      .then((resp) => {
        // console.log('send mail response', resp);
        message.destroy();
        message.success(`Email sent to the user.`);
      })
      .catch((err) => {
        console.log('In Summary fetch error', err);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  //get user id and show details
  const showUserdetails = (id) => {
    setSelectedUserId(id);
    setDisplayUserDetails(true);
  };

  const onModalCallFunction = () => {
    if (isUserInRole('Super Admin')) {
      getClientList();
    } else {
      setDisableRoleList(false);
      let id = loggedInUser.clientId;
      getRoleList(id);
    }
  };

  const onClientSelect = (clientId) => {
    setDisableRoleList(false);
    getRoleList(clientId);
  };

  const getClientList = () => {
    clientService
      .getClientListOnCallSuperAdmin()
      .then((res) => {
        setClientList(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
      })
      .finally(() => {
        // always executed
      });
  };

  const getRoleList = (id) => {
    clientService
      .getRoleIdbyClientId(id)
      .then((res) => {
        setRoleList(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
      })
      .finally(() => {
        // always executed
      });
  };

  //show when error is occurred
  if (isError) {
    return (
      <>
        <div style={{ padding: '10px' }}>
          <div>
            <Title className="sectionHeading" level={4}>
              <span>Manage Users</span>
            </Title>
          </div>
        </div>
        <div>
          <Error_500 />
        </div>
      </>
    );
  }

  //to show when list API successfully but no data is present
  if (isSuccess && resolvedData.data.content.length === 0) {
    return (
      <>
        <div
          style={{
            padding: '10px',
          }}>
          <div>
            <Title className="sectionHeading" level={4}>
              <span>Manage Users</span>
            </Title>
          </div>
        </div>
        <div style={{ paddingTop: '100px' }}>
          <Empty
            image={emptyData}
            imageStyle={{
              height: 100,
            }}
            description={<span>No Data</span>}>
            {isAllowed('ADD_USER') && (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    setDisplayCreateForm(true);
                    onModalCallFunction();
                  }}
                  icon={<PlusOutlined />}>
                  Create User
                </Button>
              </div>
            )}
          </Empty>
        </div>
        {displayCreateForm && (
          <UserCreateForm
            visible={displayCreateForm}
            onCreate={addUser}
            onCancel={() => {
              setDisplayCreateForm(false);
            }}
            isLoading={loading}
            roleList={roleList}
            clientList={clientList}
            onClientSelect={onClientSelect}
            disableRoleList={disableRoleList}
            isSuperAdmin={superAdminLoggedIn}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {/* <div>
          <Title className="sectionHeading" level={4}>
            <span>Manage Users</span>
          </Title>
        </div> */}
        {isAllowed('ADD_USER') && isDataPresent && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              right: '5px',
              right: '26px',
            }}>
            <Button
              type="primary"
              onClick={() => {
                setDisplayCreateForm(true);
                onModalCallFunction();
              }}
              icon={<PlusOutlined />}>
              Create User
            </Button>
          </div>
        )}
      </div>
      <Layout.Content className="layout" style={{ padding: '1px 1px' }}>
        <Spin
          tip="Loading..."
          spinning={isLoading}
          size="large"
          style={{ marginTop: '200px' }}>
          {displayCreateForm && (
            <UserCreateForm
              visible={displayCreateForm}
              onCreate={addUser}
              onCancel={() => {
                setDisplayCreateForm(false);
                setDisableRoleList(true);
              }}
              isLoading={loading}
              roleList={roleList}
              clientList={clientList}
              onClientSelect={onClientSelect}
              disableRoleList={disableRoleList}
              isSuperAdmin={superAdminLoggedIn}
            />
          )}
          {selectedUserId && displayEditForm && (
            <UserEditForm
              visible={displayEditForm}
              userId={selectedUserId}
              onUpdate={updateUser}
              onCancel={() => {
                setDisplayEditForm(false);
              }}
              isLoading={loading}
              isSuperAdmin={superAdminLoggedIn}
            />
          )}

          {selectedUserId && displayUserDetails && (
            <UserDetails
              visible={displayUserDetails}
              userId={selectedUserId}
              onCancel={() => {
                setDisplayUserDetails(false);
              }}
            />
          )}

          {resolvedData && (
            <PageTransition>
              <UserTable
                users={resolvedData}
                onChange={onChange}
                onPaginationClick={onPaginationClick}
                page={page}
                showEditForm={showEditForm}
                sendMail={sendMail}
                showUserdetails={showUserdetails}
                update={true}
              />
            </PageTransition>
          )}
        </Spin>
      </Layout.Content>
    </>
  );
};

export default UserList;
