import React from 'react';
import { Result } from 'antd';

function Error_500() {
  return (
    <Result
      status="500"
      title="Oops!!"
      subTitle="Something went wrong. Try reloading the page."
    />
  );
}

export default Error_500;
