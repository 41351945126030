import React, { useState, useContext, useLayoutEffect } from 'react';
import { Form, Input, Button, Modal, Spin, message } from 'antd';
import {
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  UnlockFilled,
} from '@ant-design/icons';
import MarketoContext from '../../../../context/MarketoContext';
import {
  msgConfig,
  errorMessage,
  ShowOopsMsg,
} from '../../../../common/messageComp';

const ConnectionDetails = () => {
  const {
    connectConnection,
    testConnection,
    disconnectConnection,
    getConnectionDetails,
  } = useContext(MarketoContext);
  const [form] = Form.useForm();
  const [loadingFormDetails, setLoadingFormDetails] = useState(false);
  const [loadingConnection, setLoadingConnection] = useState(false);
  const [loadingDisconnection, setLoadingDisconnection] = useState(false);
  const [loadingTestConnection, setLoadingTestConnection] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
  };
  const buttonItemLayout = {
    wrapperCol: {
      offset: 3,
      span: 14,
    },
  };

  useLayoutEffect(() => {
    getDetails();
  }, []);

  //test connection
  const onTestConnection = () => {
    setLoadingTestConnection(true);
    testConnection((response) => {
      if (response && response.status === 200) {
        setLoadingTestConnection(false);
        setIsConnected(true);
        message.destroy();
        message.success(`Connected Successfully`);
      } else {
        setIsConnected(false);
        setLoadingTestConnection(false);
        message.destroy();
        message.error(
          msgConfig(
            errorMessage(
              `Connection Unsuccessful, Please input valid credentials`
            )
          )
        );
      }
    });
  };

  //disconnecting connection
  const onDisconnection = () => {
    Modal.confirm({
      title: 'Are you sure you want to disconnect?',
      icon: <ExclamationCircleFilled style={{ fontSize: '25px' }} />,
      centered: true,
      okText: 'No',
      okType: 'primary',
      cancelText: 'Yes',
      cancelType: 'ghost',
      onOk() {},
      onCancel() {
        setLoadingDisconnection(true);

        disconnectConnection((response) => {
          if (response && response.status === 200) {
            form.resetFields();
            setLoadingDisconnection(false);
            setIsConnected(false);
            message.destroy();
            message.success(`Marketo Disconnected Successfully `);
          } else {
            setLoadingDisconnection(false);
            setIsConnected(true);
            ShowOopsMsg();
          }
        });
      },
    });
  };

  //connecting connection
  const onConnection = (values) => {
    let fromValues = {
      url: values.url.replace(/\s+/g, ' ').trim(),
      clientId: values.clientId.replace(/\s+/g, ' ').trim(),
      clientSecret: values.clientSecret,
      clientSecret: values.clientSecret,
    };
    // console.log('values', values, fromValues);
    setLoadingConnection(true);
    connectConnection(fromValues, (response) => {
      if (response && response.status === 200) {
        setLoadingConnection(false);
        setIsConnected(true);
        message.destroy();
        message.success(`Connected Successfully`);
      } else {
        setLoadingConnection(false);
        setIsConnected(false);

        message.destroy();
        message.error(
          msgConfig(
            errorMessage(
              `Connection Unsuccessful, Please input valid credentials`
            )
          )
        );
      }
    });
  };

  //get form details
  const getDetails = () => {
    setLoadingFormDetails(true);
    getConnectionDetails((response) => {
      if (response && response.status === 200) {
        setLoadingFormDetails(false);
        if (response.data.active) {
          form.setFieldsValue({
            ...response.data,
          });
        }
        if (response.data.active) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      } else {
        setIsConnected(false);
        setLoadingFormDetails(false);
      }
    });
  };

  return (
    <Spin tip="Loading..." spinning={loadingFormDetails} size="large">
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="marketo-connection-details"
        validateTrigger="onBlur"
        className="connectionForm"
        colon={false}
        onFinish={onConnection}>
        <Form.Item
          label="Instance URL"
          name="url"
          rules={[
            { required: true, message: 'Please provide a Instance URL!' },
          ]}>
          <Input.Password
            placeholder="Enter Instance URL"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            readOnly={isConnected}
          />
        </Form.Item>
        <Form.Item
          label="Client ID"
          name="clientId"
          rules={[{ required: true, message: 'Please provide a Client Id!' }]}>
          <Input.Password
            placeholder="Enter Client Id"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            readOnly={isConnected}
          />
        </Form.Item>
        <Form.Item
          label="Client Secret"
          name="clientSecret"
          rules={[
            { required: true, message: 'Please provide a Client Secret!' },
          ]}>
          <Input.Password
            placeholder="Enter Client Secret"
            readOnly={isConnected}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          {!isConnected ? (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '130px' }}
              loading={loadingConnection}>
              {loadingConnection ? 'Connecting' : 'Connect'}
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                danger
                onClick={() => {
                  onDisconnection();
                }}
                style={{ width: '130px' }}
                loading={loadingDisconnection}>
                {loadingDisconnection ? 'Disconnecting' : 'Disconnect'}
              </Button>
              <span style={{ paddingLeft: '20px' }}>
                <Button
                  disabled={!isConnected || loadingDisconnection}
                  onClick={() => {
                    onTestConnection();
                  }}
                  loading={loadingTestConnection}>
                  {loadingTestConnection
                    ? 'Testing Connection'
                    : 'Test Connection'}
                </Button>
              </span>
            </>
          )}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ConnectionDetails;
