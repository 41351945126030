import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function DropdownReadMappingFields(props) {
  //to show select value
  function getSelectedOption() {
    const selectedOption = props.mappedFields.find(
      (item) => item.name == props.fieldName
    );

    return selectedOption !== undefined ? selectedOption.mappedBy : undefined;
  }

  const handleSelect = (selectedItem) => {
    if (selectedItem) {
      let index = selectedItem.split('...');
      props.handleChange(index);
    } else {
      props.handleChange(selectedItem);
    }
  };

  return (
    <div>
      <Select
        className="listViewSelect"
        style={{
          width: '70%',
          border:
            getSelectedOption() == undefined
              ? '1px solid #d9d9d9'
              : ' var(--blue)',
          color: getSelectedOption() !== undefined ? 'var(--blue)' : '#d9d9d9',
        }}
        showSearch
        placeholder="Type to Search"
        listHeight={140}
        value={getSelectedOption()}
        onChange={handleSelect}
        virtual={true}>
        <Select.Option id="0-select">Select</Select.Option>
        {props.headerFeildsOptions.map((item, index) => {
          return (
            <Select.Option
              key={index}
              value={`${item.value.displayName}...${item.value.name}`}
              selected={true}>
              {item.value.displayName}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

DropdownReadMappingFields.propTypes = {
  handleChange: PropTypes.func,
  headerFeildsOptions: PropTypes.array,
  mappedFields: PropTypes.array,
  fieldName: PropTypes.string,
};

export default DropdownReadMappingFields;
