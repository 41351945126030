import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Spin,
} from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../shared/auth/authContext';
import { Error_500 } from '../../errorHandler';
import { userService } from '../../../service/user.service';

const UserEditForm = ({
  visible,
  userId,
  onUpdate,
  onCancel,
  isLoading,
  isSuperAdmin,
}) => {
  const { isAllowed } = React.useContext(AuthContext);

  const [form] = Form.useForm();
  const [userStatusDisable, setUserStatusDisable] = useState(false);
  const [checked, setchecked] = useState();
  const [userStatus, setUserStatus] = useState('');
  const [userTime, setUserTime] = useState();
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadUser = (id) => {
    userService
      .getById(id)
      .then((res) => {
        setLoading(false);
        let roleId =
          res.data !== null &&
          res.data.clientRoles !== undefined &&
          res.data.clientRoles.length > 0 &&
          res.data.clientRoles[0].name
            ? res.data.clientRoles[0].name
            : '';
        form.setFieldsValue(res.data);
        form.setFieldsValue({
          selectedRoleId: roleId,
          selectedClientId:
            res.data !== null && res.data.clientName ? res.data.clientName : '',
        });
        if (res.data.userStatus === 'ACTIVE') {
          setchecked(true);
          setUserStatus('Active');
        } else {
          setchecked(false);
          setUserStatus('Deactivated');
        }
        if (res.data.userStatus === 'PENDING') {
          setUserStatusDisable(true);
          setUserStatus('Activation pending');
        } else {
          setUserStatusDisable(false);
        }
        // setUserStatus(
        //   res.data.userStatus.charAt(0).toUpperCase() +
        //     res.data.userStatus.slice(1).toLowerCase()
        // );
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsError(false);

    if (userId !== null) {
      form.resetFields();
      setLoading(true);
      loadUser(userId);
    }
  }, [userId]);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const onChangeStatus = (status) => {
    console.log('change status', status);
    if (status) {
      setchecked(status);
      setUserStatus('Active');
    } else {
      setchecked(status);
      setUserStatus('Deactivated');
    }
  };

  return (
    <Modal
      visible={visible}
      title="Edit User"
      destroyOnClose={true}
      width={'65%'}
      centered
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                onUpdate(values, userStatus);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
          loading={isLoading}>
          Save
        </Button>,
      ]}
      bodyStyle={{
        maxHeight: 'calc(100vh - 140px)',
        overflow: 'auto',
        padding: '20px',
      }}>
      {isError ? (
        <Error_500 />
      ) : (
        <Spin tip="Loading..." spinning={loading} size="small">
          <Form
            {...formItemLayout}
            form={form}
            layout="horizontal"
            name="form_in_modal"
            className="userForm"
            colon={false}
            validateTrigger="onBlur">
            <Row gutter={16}>
              {isSuperAdmin && (
                <Col span={12}>
                  <Form.Item name="selectedClientId" label="Client">
                    <Input readOnly disabled placeholder="Select client" />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item name="selectedRoleId" label="Role">
                  <Input readOnly disabled placeholder="Select role" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Enter your first name',
                    },
                    {
                      min: 2,
                      message: 'First name atleast 2 characters',
                    },
                    // {
                    //   max: 50,
                    //   message: 'First Name maximum 50 characters',
                    // },
                    {
                      pattern: new RegExp(/^[a-zA-Z]+$/),
                      message: 'First name must be alphabetic',
                    },
                  ]}>
                  <Input
                    placeholder="First Name"
                    name="firstName"
                    maxlength="50"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: 'Enter your last name',
                    },
                    {
                      min: 2,
                      message: 'Last name atleast 2 characters',
                    },
                    // {
                    //   max: 50,
                    //   message: 'Last Name maximum 50 characters',
                    // },
                    {
                      pattern: new RegExp(/^[a-zA-Z]+$/),
                      message: 'Last name must be alphabetic',
                    },
                  ]}>
                  <Input
                    placeholder="Last Name"
                    name="lastName"
                    maxlength="50"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="userName" label="Username">
                  <Input
                    readOnly
                    disabled
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Email ID">
                  <Input
                    readOnly
                    disabled
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email ID"
                  />
                </Form.Item>
              </Col>
            </Row>

            {isAllowed('USER_ACTIVE_DEACTIVE') && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Status"
                    name="userStatus"
                    className="collection-create-form_last-form-item">
                    {userStatusDisable === false ? (
                      <>
                        <Switch checked={checked} onChange={onChangeStatus} />
                        &nbsp;{userStatus}
                      </>
                    ) : (
                      <>{userStatus}</>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </Spin>
      )}
    </Modal>
  );
};

UserEditForm.propTypes = {
  visible: PropTypes.bool,
  userId: PropTypes.number,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
};

export default UserEditForm;
