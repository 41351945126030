import React, { useEffect, useState } from 'react';
import { Card, Divider, Spin } from 'antd';
import PieChartStats from './pieChart';
import { record_status } from '../../config/constants';
import { TableOutlined } from '@ant-design/icons';
import TabularViewStats from './TabularView';
import { dashboardService } from '../../service/dashboard.service';
import { sortFunByField } from '../../config/constantMethod';
import EmptyData from '../../assets/images/DashboardEmptyData.png';

const RecordStatsComp = (props) => {
  const [tBView, setTBView] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllRecordStatsTasks();
  }, []);

  const handleTabulerView = () => {
    setTBView((prev) => !prev);
  };
  const onCancel = () => {
    setTBView(false);
  };

  const getAllRecordStatsTasks = () => {
    setIsLoading(true);
    dashboardService
      .getRecordStatsForAllTasks(-1)
      .then((res) => {
        convertResponseIntoArray(res.data);
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
      });
  };

  const convertResponseIntoArray = (responceObj) => {
    let chartData = [];
    responceObj.stats.map((items, index) => {
      if (Object.keys(items).length !== 0 && items.constructor === Object) {
        let objPieChart = {};

        //piechart JSON array
        let name = items.name;
        objPieChart.key = index;
        objPieChart.name = items.name;
        objPieChart.y = items.count;
        objPieChart.count = items.count;
        objPieChart.percentage = Number(items.percentage);
        objPieChart.lastUpdatedDate = items.lastUpdatedDate;

        record_status.map((obj, i) => {
          if (obj.name.toLocaleLowerCase() == items.name.toLocaleLowerCase()) {
            objPieChart.color = obj.color;
          }
        });

        chartData.push(objPieChart);
        let data = sortFunByField(chartData, record_status);
        setPieData(data);
      }
    });
    setIsLoading(false);
  };

  return (
    <div>
      <Card bodyStyle={{ padding: 12 }} className="dashbordCards">
        <div className="cardTitle">Record Status of All Tasks</div>
        <div className="toggleTableIcon">
          <TableOutlined className="table-icon" onClick={handleTabulerView} />
        </div>
        <Spin tip="Loading..." spinning={isLoading} size="large">
          {pieData.length > 0 ? (
            <PieChartStats pieData={pieData} />
          ) : (
            <div className="NDTD">
              {!isLoading ? (
                <span>
                  <img src={EmptyData} />
                  <br />
                  <br />
                  <div>No Data Available</div>
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </Spin>
      </Card>
      <TabularViewStats
        visible={tBView}
        statsTableData={pieData}
        onCancel={onCancel}
      />
    </div>
  );
};

export default RecordStatsComp;
