import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function DropdownFields(props) {
  //to show select value
  function getSelectedOption() {
    const selectedOption = props.mappedFields.find(
      (item) => item.position == props.index
    );
    return selectedOption !== undefined ? selectedOption.name : undefined;
  }

  const handleSelect = (selectedItem) => {
    props.handleChange(selectedItem, props.id);
  };

  return (
    <div id={props.id}>
      <Select
        showSearch
        style={{
          width: '100%',
          borderTop: getSelectedOption() == undefined && '1px solid #d9d9d9',
        }}
        placeholder="Type to Search"
        value={getSelectedOption()}
        onChange={handleSelect}
        virtual={true}
        listHeight={140}
        bordered={getSelectedOption() !== undefined ? true : false}>
        <Select.Option id="0-select">Select</Select.Option>
        {props.filteredOptions.map((item, index) => (
          <Select.Option
            key={index}
            value={item.name}
            id={`${index + 1}-select`}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

DropdownFields.propTypes = {
  handleChange: PropTypes.func,
  filteredOptions: PropTypes.array,
  mappedFields: PropTypes.array,
  index: PropTypes.number,
  id: PropTypes.string,
};

export default DropdownFields;
