import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './enrichModeScreen.css';
import { Radio, Popover, Row, Col, Card } from 'antd';
import { AuthContext } from '../../../shared/auth/authContext';
import { ItalicFont } from '../../../config/constantMethod';
// import { InfoCircleOutlined } from '@ant-design/icons';
import infoIcon from '../../../assets/images/info-enrich-icon.png';
function EnrichModeStep({ step, enrichModeValue }) {
  const { isAllowed } = useContext(AuthContext);
  //to set value in enrich mode array
  const onChange = (e) => {
    const selectedValue = e.target.value;
    enrichModeValue.forEach((enrichModeItem) => {
      if (enrichModeItem.name === e.target.name) {
        enrichModeItem.option.forEach((optionitem) => {
          if (optionitem.value === selectedValue) {
            enrichModeItem.value = selectedValue;
          }
        });
      }
    });
  };

  const EMAIL_VERIFIER_RUN_DESC = () => {
    return (
      <div className="content-popover">
        {' '}
        <span className="boldFont">Yes :</span> External Email Verifier will be
        run on all DB match and non DB match records.
        <br />
        <span className="boldFont">Yes-Limited :</span> External Email Verifier
        will only run on DB match records.
        <br />
        <span className="boldFont">No :</span> Email Verifier will not run,
        however using existing email DB will provide the best email if
        available. The output is not 100% reliable.
      </div>
    );
  };

  const COMP_MATCHBACK_PROCESS_DESC = () => {
    return (
      <div className="content-popover">
        {' '}
        <span className="boldFont">Exact :</span> Exact Company matchback based
        on input company name and cleaned company name.
        <br />
        <span className="boldFont">Exact + Fuzzy :</span> Both Exact &amp; Fuzzy
        Company matchback based on input company name and cleaned company name.
        <br />
        Fuzzy match increases match rate at the cost of the performance &amp;
        accuracy.
      </div>
    );
  };

  const COMP_INDEX_REACH_DESC = () => {
    return (
      <div className="content-popover">
        <span className="boldFont">Full :</span> System will first match with
        production pool companies and then No Match records will be matched with
        non production pool of companies.
        <br />
        <span className="boldFont">Prod :</span> System will match on production
        pool of companies only.
      </div>
    );
  };

  const EXPORT_FIELDS_DESC = () => {
    return (
      <div className="content-popover">
        {' '}
        <span className="boldFont">Limited :</span> Limited fields is delivered
        to external clients.
        <br />
        <span className="boldFont">All :</span> All fields includes additional
        fields used for internal purpose.
      </div>
    );
  };

  const DIRECT_DIAL_DESC = () => {
    return (
      <div className="content-popover">
        {' '}
        <span className="boldFont">Lite :</span> Enriches Contact & Company
        information. Costs half a credit for either of the enrichment.
        <br />
        <span className="boldFont">Premium :</span> Lite Enrichment plus Mobile
        Numbers and current employment information for No Longer records. Costs
        one credit for any part of the enrichment delivered.
      </div>
    );
  };

  if (step !== 2) {
    return null;
  }
  if (step === 2) {
    return (
      <div>
        <Row gutter={24} className="rowWidth">
          {/* Email Verifier Run */}
          {isAllowed('EMAIL_VERIFIER_RUN') && (
            <Col span={12}>
              <Card className="enrichmodeCard" bordered={false}>
                <div className="field-text">
                  <span>&nbsp;Email Verifier Run</span>
                  <Popover
                    placement="bottom"
                    content={EMAIL_VERIFIER_RUN_DESC}
                    overlayClassName="content-popover-modal">
                    <img src={infoIcon} className="info-icon" />
                    {/* <InfoCircleOutlined className="info-icon" /> */}
                  </Popover>
                </div>
                <div className="enrichmodeCard-radio">
                  {enrichModeValue.map((enrichModeItem) => {
                    if (enrichModeItem.name === 'Email Verifier Run') {
                      return (
                        <Radio.Group
                          onChange={onChange}
                          name={enrichModeItem.name}
                          defaultValue={enrichModeItem.value}>
                          {enrichModeItem.option.map((optionitem) => {
                            var substr = 'Default';
                            let name = ItalicFont(optionitem, substr);
                            return (
                              <>
                                <Radio
                                  className="radioStyle"
                                  value={optionitem.value}>
                                  <span
                                    style={{ fontSize: '1vw' }}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  />
                                </Radio>
                              </>
                            );
                          })}
                        </Radio.Group>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
          )}
          {/* Company Match Process */}
          {isAllowed('COMP_MATCHBACK_PROCESS') && (
            <Col span={12}>
              <Card className="enrichmodeCard" bordered={false}>
                <div className="field-text">
                  <span>&nbsp;Company Match Process</span>
                  <Popover
                    placement="bottom"
                    content={COMP_MATCHBACK_PROCESS_DESC}
                    overlayClassName="content-popover-modal">
                    <img src={infoIcon} className="info-icon" />
                    {/* <InfoCircleOutlined className="info-icon" /> */}
                  </Popover>
                </div>
                <div className="enrichmodeCard-radio">
                  {enrichModeValue.map((enrichModeItem) => {
                    if (enrichModeItem.name === 'Company Match Process') {
                      return (
                        <Radio.Group
                          onChange={onChange}
                          name={enrichModeItem.name}
                          defaultValue={enrichModeItem.value}>
                          {enrichModeItem.option.map((optionitem) => {
                            var substr = 'Default';
                            let name = ItalicFont(optionitem, substr);
                            return (
                              <>
                                <Radio
                                  className="radioStyle"
                                  value={optionitem.value}>
                                  <span
                                    style={{ fontSize: '1vw' }}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  />
                                </Radio>
                              </>
                            );
                          })}
                        </Radio.Group>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
          )}
          {/* Company Index Reach */}
          {isAllowed('COMP_INDEX_REACH') && (
            <Col span={12}>
              <Card className="enrichmodeCard" bordered={false}>
                <div className="field-text">
                  &nbsp;Company Index Reach
                  <Popover
                    placement="bottom"
                    content={COMP_INDEX_REACH_DESC}
                    overlayClassName="content-popover-modal">
                    <img src={infoIcon} className="info-icon" />
                  </Popover>
                </div>
                <div className="enrichmodeCard-radio">
                  {enrichModeValue.map((enrichModeItem) => {
                    if (enrichModeItem.name === 'Company Index Reach') {
                      return (
                        <Radio.Group
                          onChange={onChange}
                          name={enrichModeItem.name}
                          defaultValue={enrichModeItem.value}>
                          {enrichModeItem.option.map((optionitem) => {
                            var substr = 'Default';
                            let name = ItalicFont(optionitem, substr);
                            return (
                              <>
                                <Radio
                                  className="radioStyle"
                                  value={optionitem.value}>
                                  <span
                                    style={{ fontSize: '1vw' }}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  />
                                </Radio>
                              </>
                            );
                          })}
                        </Radio.Group>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
          )}
          {/* Export Fields */}
          {isAllowed('EXPORT_FIELDS') && (
            <Col span={12}>
              <Card className="enrichmodeCard" bordered={false}>
                <div className="field-text">
                  &nbsp;Export Fields
                  <Popover
                    placement="bottom"
                    content={EXPORT_FIELDS_DESC}
                    overlayClassName="content-popover-modal">
                    <img src={infoIcon} className="info-icon" />
                  </Popover>
                </div>
                <div className="enrichmodeCard-radio">
                  {enrichModeValue.map((enrichModeItem) => {
                    if (enrichModeItem.name === 'Export Fields') {
                      return (
                        <Radio.Group
                          onChange={onChange}
                          name={enrichModeItem.name}
                          defaultValue={enrichModeItem.value}>
                          {enrichModeItem.option.map((optionitem) => {
                            var substr = 'Default';
                            let name = ItalicFont(optionitem, substr);
                            return (
                              <>
                                <Radio
                                  className="radioStyle"
                                  value={optionitem.value}>
                                  <span
                                    style={{ fontSize: '1vw' }}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  />
                                </Radio>
                              </>
                            );
                          })}
                        </Radio.Group>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
          )}
          {/* Direct Dial Run' */}
          {/* {isAllowed('DIRECT_DIAL') && (
            <Col span={12}>
              <Card className="enrichmodeCard" bordered={false}>
                <div className="field-text">
                  &nbsp;Enrich Type
                  <Popover
                    placement="bottomLeft"
                    content={DIRECT_DIAL_DESC}
                    overlayClassName="content-popover-modal-dd">
                    <img src={infoIcon} className="info-icon" />
                  </Popover>
                </div>
                <div className="enrichmodeCard-radio">
                  {enrichModeValue.map((enrichModeItem) => {
                    if (enrichModeItem.name === 'Enrich Type') {
                      return (
                        <Radio.Group
                          onChange={onChange}
                          name={enrichModeItem.name}
                          defaultValue={enrichModeItem.value}>
                          {enrichModeItem.option.map((optionitem) => {
                            var substr = 'Default';
                            let name = ItalicFont(optionitem, substr);
                            return (
                              <>
                                <Radio
                                  className="radioStyle"
                                  value={optionitem.value}>
                                  <span
                                    style={{ fontSize: '1vw' }}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  />
                                </Radio>
                              </>
                            );
                          })}
                        </Radio.Group>
                      );
                    }
                  })}
                </div>
              </Card>
            </Col>
          )} */}
        </Row>
      </div>
    );
  }
}

EnrichModeStep.propTypes = {
  step: PropTypes.number,
  enrichModeValue: PropTypes.array,
};

export default EnrichModeStep;

// return false;
