import axios from 'axios';
import config from '../config/constants';

const userUrl = config.env.userServerUrl;

export const clientService = {
  getLicensesType,
  getAll,
  getById,
  addClient,
  updateClient,
  getRoleIdbyClientId,
  getClientListOnCallSuperAdmin,
  getClientPasswordPolicy,
};

function getAll(key) {
  return axios.get(`${userUrl}/v1/clients`);
}

function getById(id) {
  return axios.get(`${userUrl}/v1/clients/${id}`);
}

function addClient(client) {
  return axios.post(`${userUrl}/v1/clients`, client);
}

function updateClient(client, selectedClientId) {
  return axios.put(`${userUrl}/v1/clients/${selectedClientId}`, client);
}

function getRoleIdbyClientId(id) {
  return axios.get(`${userUrl}/v1/clients/roles/${id}`);
}
function getClientListOnCallSuperAdmin() {
  return axios.get(`${userUrl}/v1/clients/names`);
}

function getClientPasswordPolicy(token) {
  return axios.get(`${userUrl}/v1/clients/policies/password/${token}`);
}

function getLicensesType() {
  return axios.get(`${userUrl}/v1/clients/licences-types`);
}
