import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Radio, Pagination, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const PaginationComp = ({
  defaultPageSize,
  totalTaskCount,
  onChange,
  current,
  totalElementsOnPage,
}) => {
  useLayoutEffect(() => {
    let replaceCont = document.getElementsByClassName('ant-pagination-slash');
    if (replaceCont && replaceCont[0] !== undefined) {
      replaceCont[0].innerHTML = 'of';
    }
  });

  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return (
        <Button style={{ padding: '4px 10px' }}>
          <LeftOutlined />
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button className="paginationBtn">
          <RightOutlined />
        </Button>
      );
    }

    return originalElement;
  }

  return (
    <div className="customPagination" style={{ display: 'inline-flex' }}>
      <span style={{ padding: '3px' }}>
        ({totalElementsOnPage ? totalElementsOnPage : 0} results) Page &nbsp;
      </span>
      <Pagination
        className="paginationContent"
        simple
        current={current}
        defaultPageSize={20}
        total={totalTaskCount}
        itemRender={itemRender}
        onChange={onChange}
      />
    </div>
  );
};

PaginationComp.propTypes = {
  defaultPageSize: PropTypes.number,
  totalTaskCount: PropTypes.string,
  onChange: PropTypes.func,
  current: PropTypes.number,
  totalElementsOnPage: PropTypes.number,
};
export default PaginationComp;
