import React, { useEffect, useState } from 'react';
import './enrich.css';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, message, Switch, Card } from 'antd';
import ShowResultComp from './ShowResultComp';
import InputFormComp from './InputFormComp';
import EnrichContext from '../../context/EnrichContext';
import { apiService } from '../../service/enrich.service';
import { ShowOopsMsg, errorMessage, msgConfig } from '../../common/messageComp';
import ThreeDotsWave from '../../shared/animation/ThreeDotsWave';
import { motion } from 'framer-motion';
import PageTransition from '../../shared/animation/PageTransition';

const EnrichFields = [
  { name: 'contactFirstName', data: [] },
  { name: 'contactLastName', data: [] },
  { name: 'contactEmail', data: [] },
  { name: 'contactJobTitle', data: [] },
  { name: 'companyName', data: [] },
  { name: 'contactCountry', data: [] },
  { name: 'companyWebAddress', data: [] },
  { name: 'contactUrl', data: [] },
  { name: 'contactGuid', data: [] },
  { name: 'companyLnUrl', data: [] },
  { name: 'companyGuid', data: [] },
];

var EnrichFieldsStore = JSON.parse(localStorage.getItem('EnrichFields'));
if (!EnrichFieldsStore) {
  EnrichFieldsStore = EnrichFields;
} else {
  //Change EnrichFieldsStore.length whenever there is addition of new fields
  // if (EnrichFieldsStore.length == 9) {
  //   let additionalObj = [
  //     { name: 'companyLnUrl', data: [] },
  //     { name: 'companyGuid', data: [] },
  //   ];
  // let storageData = [...EnrichFieldsStore, ...additionalObj];
  // localStorage.setItem('EnrichFields', JSON.stringify(storageData));
  // }
  let newEnrichFieldsStore = EnrichFieldsStore;
  EnrichFields.forEach((fields) =>
    addEnrichField(newEnrichFieldsStore, fields, EnrichFieldsStore)
  );
  localStorage.setItem('EnrichFields', JSON.stringify(EnrichFieldsStore));
}

function addEnrichField(existingFields, field, store) {
  const isObjectPresent = existingFields.find(
    (element) => element.name === field.name
  );
  if (!isObjectPresent) {
    store.push(field);
  }
}

const EnrichScreenComp = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [mobileNoEnrichment, setMobileNoEnrichment] = useState({});
  const [enrichType, setEnrichType] = useState('lite');
  // const [showInfo, setShowInfo] = useState(false);
  // const [ddLoading, setDdLoading] = useState(false);
  // const [showErrorMsg, setShowErrorMsg] = useState(null);
  const [enrichLocalData, setEnrichLocalData] = useState(EnrichFieldsStore);
  const [jsonView, setJsonView] = useState(false);
  const [toggleDisable, setToggleDisable] = useState(true);

  const localStorageArray = (formData) => {
    let data = [...EnrichFieldsStore];
    Object.keys(formData).length > 0
      ? Object.keys(formData).map((formLable, i) => {
          let index = data.findIndex((x) => x.name == formLable);
          let val = formData[formLable];
          if (val !== null || val !== '') {
            if (val !== undefined && val.length > 0) {
              if (index !== -1) {
                if (!data[index].data.includes(val)) {
                  data[index].data.push(val);
                }
              }
            }
          }
        })
      : [];
    localStorage.setItem('EnrichFields', JSON.stringify(data));
    let arr = JSON.parse(localStorage.getItem('EnrichFields'));
    return arr;
  };

  const onSubmit = (formData) => {
    setContactInfo(null);
    setIsLoading(true);

    // let enrichType = 'lite';

    if (formData) {
      var enrichLocalArray = localStorageArray(formData);
      setEnrichLocalData(enrichLocalArray);
      // setEnrichType(formData.enrichType);
      // enrichType = formData.enrichType;
    }

    apiService
      .getContactInfo(formData)
      .then((res) => {
        setContactInfo(res.data);
        setIsLoading(false);
        // setShowInfo(false);
        setToggleDisable(false);
        // if (enrichType !== 'lite') {
        //   if (
        //     res.data.directDial1 !== undefined ||
        //     res.data.directDial2 !== undefined
        //   ) {
        //     setMobileNoEnrichment({
        //       direct_dial_1:
        //         res.data.directDial1 !== undefined
        //           ? res.data.directDial1
        //           : null,
        //       direct_dial_2:
        //         res.data.directDial2 !== undefined
        //           ? res.data.directDial2
        //           : null,
        //     });
        //   } else {
        //     setMobileNoEnrichment(null);
        //   }
        // } else {
        //   setMobileNoEnrichment(null);
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        // setMobileNoEnrichment(null);
        // setShowInfo(false);
        message.destroy();
        if (
          err.response &&
          err.response.status !== undefined &&
          err.response.status === 412
        ) {
          message.error(
            msgConfig(
              errorMessage(
                'No credits available. Add credits to enrich record.'
              )
            )
          );
        } else {
          ShowOopsMsg();
        }
        setToggleDisable(true);
      })
      .finally(() => {
        // always executed
      });
  };

  const clearResponse = () => {
    setContactInfo(null);
    // setMobileNoEnrichment(null);
    setToggleDisable(true);
    setJsonView(false);
    // setShowInfo(false);
    // setDdLoading(false);
  };

  // const showDirectDial = (conGUID, lnurl) => {
  //   setDdLoading(true);
  //   apiService
  //     .getMobileNumber(conGUID, lnurl)
  //     .then((res) => {
  //       var msg = res.data.data.direct_dial_flag;
  //       var errMsg =
  //         msg !== null && (msg == 'Vendor No Match' || msg == 'Unavailable')
  //           ? 'Not Available'
  //           : msg == 'Internal error' || msg == 'External error - Bad request'
  //           ? 'Oops! something went wrong...'
  //           : '';
  //
  //       setMobileNoEnrichment(res.data.data);
  //       setShowErrorMsg(errMsg);
  //       setShowInfo(true);
  //       setDdLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       message.destroy();
  //       ShowOopsMsg();
  //       setDdLoading(false);
  //
  //       setMobileNoEnrichment(null);
  //     })
  //     .finally(() => {
  //       // always executed
  //     });
  // };

  const onChangeToggle = (val) => {
    setJsonView(val);
  };

  return (
    <EnrichContext.Provider
      value={{
        onSubmit,
        clearResponse,
        contactInfo,
        jsonView,
        // mobileNoEnrichment,
        isLoading,
        setIsLoading,
        // showInfo,
        // ddLoading,
        // showErrorMsg,
        // showDirectDial,
      }}>
      {/* {isLoading && (
          <div className="spinLoader-mask">
            <Spin className="spinLoader" size="large" />
          </div>
        )} */}
      <Row gutter={16}>
        <Col span={12}>
          <PageTransition>
            <InputFormComp enrichLocalData={enrichLocalData} />
          </PageTransition>
        </Col>
        <Col span={12}>
          <Card className="white-block-card">
            {isLoading && (
              <div className="loading-center">
                <ThreeDotsWave />
              </div>
            )}

            {!isLoading && <ShowResultComp />}
            {/* enrichType={enrichType} */}
          </Card>
        </Col>
        <Col span={18}></Col>
        <Col span={6}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '5px',
            }}>
            <div>Normal View</div>
            <Switch
              onChange={onChangeToggle}
              checked={jsonView}
              disabled={toggleDisable}
            />
            <div>JSON View</div>
          </div>
        </Col>
      </Row>
    </EnrichContext.Provider>
  );
};

EnrichScreenComp.propTypes = {};

export default EnrichScreenComp;
