import { React, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

const variants = {
  initial: {
    opacity: 0,
    y: 8,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.61, 1, 0.88, 1],
    },
  },
};

const PageTransition = (props) => (
  <AnimatePresence>
    {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}> */}
    <motion.div initial="initial" animate="enter" variants={variants}>
      {props.children}
    </motion.div>
  </AnimatePresence>
);

PageTransition.propTypes = {
  children: PropTypes.element,
};

export default PageTransition;
