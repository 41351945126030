import React, { useState, useLayoutEffect } from 'react';
import PropTypes, { element } from 'prop-types';
// Import Highcharts
import Highcharts from 'highcharts/highstock';
import ReactHighcharts from 'highcharts-react-official';

const StackedChartStats = ({ stackData }) => {
  const [stackChartData, setStackChartData] = useState([]);
  const [categories, setCategories] = useState([]);

  useLayoutEffect(() => {
    let categories = stackData
      .filter((element) => element.name === 'PERSON')
      // .filter(
      //   (element) => element.name === 'PERSON' || element.name === 'NUMBER'
      // )
      .map((element) => {
        return element.months;
      });
    setCategories(categories);

    // let pieChartdata = stackData.map((element) => {
    //   if (element.name === 'PERSON') {
    //     element.color = '#ed7d31';
    //     element.name = 'Person Enrichment';
    //     return element;
    //   }
    //   if (element.name === 'NUMBER') {
    //     element.color = '#4472c4';
    //     element.name = 'Mobile Numbers';
    //     return element;
    //   }
    //   return element;
    // });
    let pieChartdata = stackData
      .filter((element) => element.name === 'PERSON')
      .map((element) => {
        if (element.name === 'PERSON') {
          element.color = '#ed7d31';
          element.name = 'Enrichment';
          return element;
        }
        return element;
      });
    setStackChartData(pieChartdata);
  }, [stackData]);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const options = {
    chart: {
      type: 'column',
      reflow: true,
      height: 240,
      stroke: 'transperant',
      style: {
        fontFamily: 'inherit',
      },
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },

    // to display chart
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      //   to set fix column width
      //   series: {
      //     pointWidth: 25,
      //   },
    },

    xAxis: {
      categories: categories[0],
      labels: {
        skew3d: false,
        style: {
          fontSize: '11px',
          fontFamily: 'Roboto',
        },
      },
    },

    yAxis: {
      min: 0,
      title: {
        text: 'Credits Consumed',
      },
      opposite: false,
      labels: {
        skew3d: false,
        style: {
          color: '#666666',
          fontSize: '11px',
          fontFamily: 'Roboto',
        },
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
        },
      },
    },

    legend: {
      enabled: true,
      itemStyle: {
        fontWeight: 'normal',
        fontSize: '11px',
        color: '#666666',
        fontFamily: 'Roboto',
      },
      labelFormatter: function () {
        // return this.name + ' (click to hide)';
        return this.name;
      },
    },

    tooltip: {
      fontSize: '11px',
      fontFamily: 'Roboto',
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: `Enrich Count: {point.y}<br/>`,
      // pointFormat: '{series.name}: {point.y}<br/>',
      // footerFormat: `<b>Total Consumption: {point.total}</b><br/>`,
      shared: true,
    },

    series: stackChartData,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
        },
      ],
    },
  };

  return (
    <div style={{ margin: '10px' }}>
      <ReactHighcharts highcharts={Highcharts} options={options} />
    </div>
  );
};
StackedChartStats.propTypes = {
  stackData: PropTypes.array,
};
export default StackedChartStats;
