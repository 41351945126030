import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthContext } from './shared/auth/authContext';
import LoginForm from './screens/login/LoginForm';
import ForgotPassword from './screens/forgotPassword/ForgotPassword';
import SetPassword from './screens/resetPassword/SetPassword';
import AccountActivation from './screens/resetPassword/AccountActivation';
import LandingLayout from './screens/login/LandingLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { unAuthURL, authURL } from '../src/config/constants';
import useQueryParam from './hooks/useQueryParam';

function UnAuthenticatedApp() {
  const { login, loggedInUser } = useContext(AuthContext);
  const {
    setLoading,
    invalidUserErr,
    invalidUserErrMsg,
    setIsLoadingForSSO,
  } = useContext(AuthContext);
  const { loading } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParam();

  useEffect(() => {
    const code = query.get('code');
    const userCode = query.get('userCode');
    const isDisable = query.get('isDisable');
    if (
      code != null &&
      code != undefined &&
      userCode != null &&
      userCode != undefined
    ) {
      login(
        {
          referenceCode: code,
          userVerifyCode: userCode,
          loginMethod: isDisable && isDisable === 'true' ? 'WEB' : 'SF',
        },
        true
      );
    } else {
      let authTrigger = authURL.some((x) => x === location.pathname);
      let unAuthTrigger = unAuthURL.some((x) => x === location.pathname);
      if (authTrigger) {
        if (!loggedInUser) {
          history.push('/login');
        }
      } else if (unAuthTrigger) {
      } else {
        history.push('/login');
      }
    }
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route
          exact
          path="/login"
          render={() => (
            <LandingLayout>
              <LoginForm
                onSubmit={login}
                setLoading={setLoading}
                loading={loading}
                invalidUserErr={invalidUserErr}
                invalidUserErrMsg={invalidUserErrMsg}
              />
            </LandingLayout>
          )}
        />
        <Route
          path="/forgot-password"
          render={() => (
            <LandingLayout>
              <ForgotPassword />
            </LandingLayout>
          )}
        />
        <Route path="/reset-password" render={() => <SetPassword />} />
        <Route
          path="/account-activation"
          render={() => <AccountActivation />}
        />
        {/* <Route path="*" component={Error_404} /> */}
      </Switch>
    </div>
  );
}

export { UnAuthenticatedApp };
