import React, { useContext, useState } from 'react';
import { Card, Col, Row, Button, Switch, Layout, Typography } from 'antd';
import ConnectorContext from '../../context/ConnectorContext';
import './connector.css';
import PropTypes from 'prop-types';
import marketo from '../../assets/images/marketo-logo.png';
import Salesforce from '../../assets/images/salesforceGrey.png';
import HubSpot from '../../assets/images/hubspotsGrey.png';
import Elaquo from '../../assets/images/oracle-Grey.png';

const ConnectorScreen = ({ openScreenName, connectionStatus }) => {
  const { requestToOpenScreen } = useContext(ConnectorContext);
  const [active, setActive] = useState(true);

  const onChangeStatus = (status) => {
    setActive(status);
  };

  if (openScreenName !== 'ConnectorScreen') {
    return null;
  }

  return (
    <>
      <div
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div>
          <Typography.Title level={4}>
            <span>Integrations</span>
          </Typography.Title>
        </div>
      </div>
      <Layout.Content style={{ padding: '1px 1px' }}>
        <Row gutter={16} style={{ marginLeft: '20px' }}>
          <Col span={6}>
            <Card
              className="connectorCards"
              hoverable={true}
              bodyStyle={{ height: '230px', padding: '15px' }}>
              <img
                src={marketo}
                alt="Marketo"
                height="auto"
                width="70%"
                style={{ margin: '10px 15%', textAlign: 'center' }}
              />
              <div className="cardBottomContent">
                <div>
                  <h5>
                    Connection Status &nbsp;
                    {connectionStatus !== undefined ? (
                      <span
                        className="current-status"
                        style={{
                          border: `1px solid ${
                            connectionStatus ? '#548235' : '#BF9000'
                          }`,
                          backgroundColor: connectionStatus
                            ? '#A9D18E'
                            : '#FFD966',
                          color: connectionStatus ? '#548235' : '#BF9000',
                        }}>
                        {connectionStatus ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      ''
                    )}
                  </h5>
                </div>
                <div>
                  <Button
                    type="primary"
                    disabled={!active}
                    onClick={() => {
                      requestToOpenScreen('MarketoScreen');
                    }}>
                    Manage
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

ConnectorScreen.propTypes = {
  openScreenName: PropTypes.string,
  connectionStatus: PropTypes.bool,
};

export default ConnectorScreen;
