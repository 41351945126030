import { LockOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import OopsErrMsg from '../../assets/images/OopErrMsg.png';
import ForwordPassword from '../../assets/images/reset-pwd-copy.png';

function TokenExpire() {
  return (
    <>
      <div className="token-expire-blocks">
        <div className="token-expire-centerBox">
          <div className="token-expire-message" style={{ textAlign: 'center' }}>
            <img src={OopsErrMsg} width="80px" style={{ marginTop: '5px' }} />
            <Alert
              message={`Your set password link has expired.`}
              description="Not to worry, click on forgot password to resend the link."
              type="error"
              showIcon={false}
              className="token-expire-alert"
            />
          </div>
          <div className="token-expire-footer">
            {/* <span className="light">Click here &nbsp;&gt;&gt;&nbsp;</span> */}
            <Button type="primary" className="layout-btn">
              <Link to="/forgot-password">
                <img src={ForwordPassword} width="20px" /> &nbsp;Forgot Password
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TokenExpire;
