import React, { useEffect, useState } from 'react';
import { Card, Col, Popover, Row } from 'antd';
import { dashboardService } from '../../service/dashboard.service';
import moment from 'moment';
import { APP_SHORT_DATE_FORMAT } from '../../config/constants';
import { AuthContext } from '../../shared/auth/authContext';
import infoicon from '../../assets/images/info-enrich-icon.png';
const SubscriptionComp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subScriptionDetails, setSubScriptionDetails] = useState(null);
  const { loggedInUser, isLogoutDisable } = React.useContext(AuthContext);

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = () => {
    setIsLoading(true);
    dashboardService
      .getSubscriptionInfo()
      .then((res) => {
        setSubScriptionDetails(res.data);
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Card
        bodyStyle={{ padding: 12 }}
        className="dashbordCards"
        style={{ height: '200px', marginTop: '10px' }}>
        <div className="cardTitle">Subscription</div>
        <Row style={{ marginTop: '10px' }}>
          <React.Fragment>
            <Col span={8}>
              <span className="dashboardSubTitle">Org Name</span>
            </Col>
            <Col span={16}>
              {' '}
              <span className="subText">
                {loggedInUser !== null && loggedInUser.clientName != null
                  ? loggedInUser.clientName
                  : '---'}
              </span>
            </Col>
          </React.Fragment>
          {/* <Col span={8}>
            <span className="dashboardSubTitle">Org Name</span>
          </Col>
          <Col span={16}>
            {' '}
            <span className="subText">{clientName}</span>
          </Col> */}
          {/* <Col span={8}>
            <span className="dashboardSubTitle">Plan</span>
          </Col>
          <Col span={16}>
            {' '}
            <span className="subText">
              {subScriptionDetails !== null &&
              subScriptionDetails.subscriptionPlan !== null
                ? subScriptionDetails.subscriptionPlan
                : '---'}
            </span>
          </Col> */}
          <Col span={8}>
            <span className="dashboardSubTitle">Subscription Type</span>
          </Col>
          <Col span={16}>
            {' '}
            <span className="subText">
              {subScriptionDetails !== null &&
              subScriptionDetails.subscriptionType !== null
                ? subScriptionDetails.subscriptionType
                : '---'}
              <Popover
                placement="bottomLeft"
                content={
                  subScriptionDetails !== null &&
                  subScriptionDetails.subscriptionType == 'Enterprise Plus'
                    ? 'With mobile number'
                    : subScriptionDetails !== null &&
                      subScriptionDetails.subscriptionType ==
                        'Enterprise Standard'
                    ? 'Without mobile number'
                    : subScriptionDetails !== null &&
                      subScriptionDetails.subscriptionType == 'Pro'
                    ? 'Monthly recurring plan - With mobile number'
                    : null
                }
                overlayClassName="content-popover-modal-dd subs-cont-popup-dd">
                <img src={infoicon} className="info-icon" />
              </Popover>
            </span>
          </Col>
          <Col span={8}>
            <span className="dashboardSubTitle">Start Date</span>
          </Col>
          <Col span={16}>
            {' '}
            <span className="subText">
              {subScriptionDetails !== null &&
              subScriptionDetails.subscriptionStartDate
                ? moment
                    .utc(subScriptionDetails.subscriptionStartDate)
                    .local()
                    .format(APP_SHORT_DATE_FORMAT)
                : '---'}
            </span>
          </Col>
          {/* {!isLogoutDisable && (
            <React.Fragment>
              <Col span={8}>
                <span className="dashboardSubTitle">Renewal Date</span>
              </Col>
              <Col span={16}>
                {' '}
                <span className="subText">
                  {subScriptionDetails !== null &&
                  subScriptionDetails.subscriptionRenewalDate
                    ? moment
                        .utc(subScriptionDetails.subscriptionRenewalDate)
                        .local()
                        .format(APP_SHORT_DATE_FORMAT)
                    : '---'}
                </span>
              </Col>
            </React.Fragment>
          )} */}
        </Row>
      </Card>
    </div>
  );
};

export default SubscriptionComp;
