import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Modal,
  message,
  Typography,
} from 'antd';
import './resetpassword.css';
import axios from 'axios';
import config from '../../config/constants';
const { Text } = Typography;

const userUrl = config.env.userServerUrl;

function ResetPassword({
  token,
  showActiveMessage,
  showRestMessage,
  passwordPolicies,
}) {
  const [form] = Form.useForm();
  const [passwordRegExp, setPasswordRegExp] = useState();
  const [policyDescription, setPolicyDescription] = useState([]);

  useEffect(() => {
    if (passwordPolicies !== null && passwordPolicies !== undefined) {
      setPasswordRegExp(passwordPolicies.rule);
      setPolicyDescription(passwordPolicies.description);
    } else {
      setPasswordRegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
      );
      setPolicyDescription([]);
    }
  }, []);

  //password and token is submit
  const submitPasswordWithToken = (password) => {
    axios
      .post(`${userUrl}/v1/accounts/activate`, {
        token: token,
        newPassword: password,
      })
      .then((res) => {
        console.log('password set ', res);
        showRestMessage && resetPasswordSuccess();
        showActiveMessage && accountActivationSuccess();
      })
      .catch((err) => {
        console.log('password not set');
        console.log(err);
        message.destroy();
        message.error('Something went wrong, please try again');
      })
      .finally(() => {
        // always executed
      });
  };

  //Form is submitted
  const onFinish = (values) => {
    const { password } = values;
    submitPasswordWithToken(password);
  };

  //success message for reset password
  const resetPasswordSuccess = () => {
    Modal.success({
      title: 'Password reset successful',
      content: 'Awesome, you’ve successfully updated your password.',
      centered: true,
      width: '30%',
      onOk() {
        window.location = '/login';
      },
    });
  };

  //success message for account activation
  const accountActivationSuccess = () => {
    Modal.success({
      title: 'Your account is now active',
      content: 'Awesome, you’ve successfully updated your password.',
      centered: true,
      width: '30%',
      onOk() {
        window.location = '/login';
      },
    });
  };

  return (
    <>
      <div className="white-blocks">
        <Row justify="center" align="middle">
          <Col>
            <div className="resetContent">
              <div className="reset-header">
                {showActiveMessage ? (
                  <span className="titleText">Activate Your Account</span>
                ) : (
                  <span className="titleText">Set Password</span>
                )}
                <br />
                <span>
                  Be sure to remember your password or keep it in a safe place.
                </span>
              </div>
              <div className="loginBox">
                <Form
                  name="login"
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}>
                  <div className="reset-form">
                    <Form.Item
                      autocomplete="off"
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                        {
                          pattern: new RegExp(passwordRegExp),
                          message:
                            'Password does not match the below criteria.',
                        },
                      ]}
                      hasFeedback>
                      <Input.Password disabled={!passwordPolicies} />
                    </Form.Item>
                    <Form.Item
                      autocomplete="off"
                      label="Confirm Password"
                      name="confirmPassword"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject('Password does not match!');
                          },
                        }),
                      ]}
                      hasFeedback>
                      <Input.Password disabled={!passwordPolicies} />
                    </Form.Item>
                    <div className="reset-criteria">
                      <div className="reset-criteria-header">
                        <span className="reset-font">
                          Your password must contain:
                        </span>
                        <br />
                        <span>
                          {passwordPolicies ? (
                            policyDescription !== undefined &&
                            policyDescription.length !== 0 ? (
                              <ul>
                                {policyDescription.map((content) => {
                                  return (
                                    <>
                                      <li>{content}</li>
                                    </>
                                  );
                                })}
                              </ul>
                            ) : (
                              <>
                                <ul>
                                  <li>8-20 characters long.</li>
                                  <li>At least 1 numb3r.</li>
                                  <li>At least 1 UPPERCASE.</li>
                                  <li>At least 1 lowercase.</li>
                                  <li>
                                    At least one special character (!@#$%^&*()).
                                  </li>
                                </ul>
                              </>
                            )
                          ) : (
                            <span style={{ marginLeft: '50px' }}>
                              <Text type="danger">
                                Oops! An error occurred.
                              </Text>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <Form.Item>
                      <Button
                        className="layout-btn"
                        type="primary"
                        disabled={!passwordPolicies}
                        htmlType="submit"
                        style={{ width: '40%', margin: ' 0px 30%' }}>
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

ResetPassword.propTypes = {
  token: PropTypes.string,
  showActiveMessage: PropTypes.bool,
  showRestMessage: PropTypes.bool,
  passwordPolicies: PropTypes.object,
};

export default ResetPassword;
