import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Modal, Button, Spin } from 'antd';
import { userService } from '../../../service/user.service';
import { APP_STANDARD_DATE_FORAMT } from '../../../config/constants';
import { Error_500 } from '../../errorHandler';
function UserDetails({ visible, userId, onCancel }) {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isError, setIsError] = useState(false);
  const [role, setRole] = useState('');

  const loadUser = (id) => {
    setLoading(true);
    userService
      .getById(id)
      .then((res) => {
        // console.log(res.data);
        setUserDetails(res.data);
        setRole(res.data.clientRoleName);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsError(false);
    if (userId !== null) {
      loadUser(userId);
    }
  }, [userId]);

  return (
    <Modal
      visible={visible}
      title="User Details"
      width={'50%'}
      centered
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onCancel}>
          Ok
        </Button>
      }
      bodyStyle={{
        maxHeight: 'calc(100vh - 140px)',
        overflow: 'auto',
        padding: '20px',
      }}>
      <Spin tip="Loading..." spinning={loading} size="small">
        {isError ? (
          <Error_500 />
        ) : (
          <div>
            <div style={{ paddingTop: '10px' }}>
              <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                <Col span={10} offset={2}>
                  <div>
                    <p className="textLabel">First Name</p>
                    <p className="textInfo">{userDetails.firstName}</p>
                  </div>
                </Col>
                <Col className="gutter-row" span={10}>
                  <div>
                    <p className="textLabel">Last Name</p>
                    <p className="textInfo">{userDetails.lastName}</p>
                  </div>
                </Col>

                <Col className="gutter-row" span={10} offset={2}>
                  <div>
                    <p className="textLabel">Username</p>
                    <p className="textInfo">{userDetails.userName}</p>
                  </div>
                </Col>
                <Col className="gutter-row" span={10}>
                  <div>
                    <p className="textLabel">Created On</p>
                    <p className="textInfo">
                      {userDetails.createdOn
                        ? moment
                            .utc(userDetails.createdOn)
                            .local()
                            .format(APP_STANDARD_DATE_FORAMT)
                        : null}
                    </p>
                  </div>
                </Col>

                <Col className="gutter-row" span={10} offset={2}>
                  <div>
                    <p className="textLabel">Email ID</p>
                    <p className="textInfo">{userDetails.email}</p>
                  </div>
                </Col>
                <Col className="gutter-row" span={10}>
                  <div>
                    <p className="textLabel">Status</p>
                    {userDetails.userStatus ? (
                      userDetails.userStatus === 'ACTIVE' ? (
                        <p className="textInfo">Active</p>
                      ) : userDetails.userStatus === 'PENDING' ? (
                        <p className="textInfo">Activation pending</p>
                      ) : (
                        <p className="textInfo">Deactivated</p>
                      )
                    ) : null}
                  </div>
                </Col>

                <Col className="gutter-row" span={10} offset={2}>
                  <div>
                    <p className="textLabel">Org Name</p>
                    <p className="textInfo">{userDetails.clientName}</p>
                  </div>
                </Col>
                <Col className="gutter-row" span={10}>
                  <div>
                    <p className="textLabel">Role</p>
                    <p className="textInfo">{role}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
}

UserDetails.propTypes = {
  visible: PropTypes.bool,
  userId: PropTypes.number,
  onCancel: PropTypes.func,
};

export default UserDetails;
