import React from 'react';
import { defineLordIconElement } from 'lord-icon-element';
import { loadAnimation, loadIcon } from 'lottie-web';
import DashbordIcon from '../assets/images/1388-page-view-quilt-outline.png';
import DeDashboard from '../assets/images/deactiveGifFrames/dashboard.png';
import FileListIcon from '../assets/images/47-to-do-list-outline - Blue 1.png';
import DeFileList from '../assets/images/deactiveGifFrames/file-list.png';
import UsersIcon from '../assets/images/276-female-and-male-outline.png';
import DeUsers from '../assets/images/deactiveGifFrames/users.png';
import RegenerateIcon from '../assets/images/227-arrow-16 1.png';
import DeRegenerateIcon from '../assets/images/deactiveGifFrames/227-arrow-16.png';
import downloadIcon from '../assets/images/199-download-2-outline 1.png';
import DeDownload from '../assets/images/deactiveGifFrames/download.png';
import PauseIcon from '../assets/images/22-play-pause-morph-outline.png';
import DePause from '../assets/images/deactiveGifFrames/pause.png';
import DeStats from '../assets/images/deactiveGifFrames/stats.png';
import EnrichIcon from '../assets/images/220-arrow-9.png';
import DeEnrich from '../assets/images/deactiveGifFrames/enrich.png';
import statsIcon from '../assets/images/163-graph-line-chart-outline 1.png';
import ResumeIcon from '../assets/images/resume.png';
import EditIcon from '../assets/images/edit-gif.png';
import ResetIcon from '../assets/images/reset.png';

// GiF

import DashboardGif from '../assets/GIF/106-map-outline-edited.json';
import DownloadGif from '../assets/GIF/download.json';
import RegenerateGif from '../assets/GIF/regenerate.gif';
import PauseGif from '../assets/GIF/pause.gif';
import UsersGif from '../assets/GIF/users.gif';
import StatsGif from '../assets/GIF/stats.gif';
import FileGif from '../assets/GIF/file.gif';
import EnrichGif from '../assets/GIF/enrich.gif';
import EditGif from '../assets/GIF/edit-gif.gif';
export {
  statsIcon,
  ResumeIcon,
  EnrichIcon,
  UsersIcon,
  DashbordIcon,
  ResetIcon,
  RegenerateIcon,
  DeRegenerateIcon,
  PauseIcon,
  EditIcon,
  FileListIcon,
  downloadIcon,
  DeDashboard,
  DeFileList,
  DeUsers,
  DeDownload,
  DePause,
  DeStats,
  DeEnrich,
  DashboardGif,
  DownloadGif,
  RegenerateGif,
  PauseGif,
  UsersGif,
  StatsGif,
  FileGif,
  EnrichGif,
  EditGif,
  StopAnimateIcon,
  changeColor,
  AnimationOnce,
};

defineLordIconElement(loadAnimation);

const changeColor = (type, id, path) => {
  let element = document.getElementById(id);
  if (type == 'in' || location.pathname === path) {
    element.setAttribute('colors', 'primary:#1a4fb7,secondary:#1a4fb7');
  } else if (type === 'out') {
    element.setAttribute('colors', 'primary:#7a7a7a,secondary:#7a7a7a');
  }
};

const StopAnimateIcon = (url, uniqueId, path) => (
  <lord-icon
    id={uniqueId}
    src={url}
    trigger="hover"
    className="onhover-class"
    colors={
      location.pathname === path
        ? 'primary:#1a4fb7,secondary:#1a4fb7'
        : 'primary:#7a7a7a,secondary:#7a7a7a'
    }
    target="li"
    stroke="68"></lord-icon>
);

const AnimationOnce = (url, target, colorCode) => (
  <lord-icon
    src={url}
    trigger="hover"
    target={target}
    className="onhover-class"
    colors={
      colorCode
        ? `primary:${colorCode},secondary:${colorCode}`
        : 'primary:#1a4fb7,secondary:#1a4fb7'
    }
    stroke="68"></lord-icon>
);
