import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Divider, Avatar, Spin, Button } from 'antd';
import UserDetailsComp from './UserDetailsComp';
import EnrichContext from '../../context/EnrichContext';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import loaderDots from '../../assets/images/loading-dots.gif';
import JsonResultComp from './JsonResultComp';
import { enrichResponseOrder } from './constantData';

/* To show result in right side */
const ShowResultComp = (props) => {
  const [contact, setContact] = useState({});
  const context = useContext(EnrichContext);
  // const [directdialErr, setdirectdialErr] = useState(false);
  const [displayKeysInOrder, setDisplayKeysInOrder] = useState([]);
  const [remainingKeys, setRemainingKeys] = useState([]);
  const [currentTenureResult, setCurrentTenureResult] = useState([]);
  const [isCurrentTenureResult, setIsCurrentTenureResult] = useState(false);

  useEffect(() => {
    setContact(context.contactInfo);

    // if (
    //   context.mobileNoEnrichment !== null &&
    //   context.mobileNoEnrichment.direct_dial_1 == null &&
    //   context.mobileNoEnrichment.direct_dial_2 == null
    // ) {
    //   setdirectdialErr(true);
    // } else {
    //   setdirectdialErr(false);
    // }
  }, [context]);

  useEffect(() => {
    /* To create array accordingly enrichResponseOrder (constantData.jsx) */
    let ordered_array = mapOrder();

    if (ordered_array) {
      setDisplayKeysInOrder(ordered_array);
    }

    let results = [];
    results =
      contact !== null && Object.keys(contact).length > 0
        ? Object.keys(contact).filter(
            (element1) =>
              !enrichResponseOrder.some(
                ({ name: element2 }) =>
                  element2.toLowerCase() === element1.toLowerCase()
              )
          )
        : [];

    let remainingKey =
      results !== undefined &&
      results.length > 0 &&
      results.map((a, i) => {
        if (a === 'currentTenure') {
          mapCurrentTenureResult(contact[a]);
          return {
            responsekey: a,
            name: a,
            value: '',
          };
        } else {
          return {
            responsekey: a,
            name: a,
            value: contact[a],
          };
        }
      });

    if (remainingKey) {
      setRemainingKeys(remainingKey);
    }
  }, [contact]);

  // convert current tenure data into display formate
  const mapCurrentTenureResult = (currentTenureData) => {
    let result = [];
    setIsCurrentTenureResult(true);
    currentTenureData !== null && Object.keys(currentTenureData).length > 0
      ? Object.keys(currentTenureData).filter(function (item) {
          result.push({
            responsekey: item,
            name: item,
            value: currentTenureData[item],
          });
          return true;
        })
      : [];
    setCurrentTenureResult(result);
  };

  const mapOrder = () => {
    let result = [];
    enrichResponseOrder.forEach(function (key) {
      var found = false;
      contact !== null && Object.keys(contact).length > 0
        ? Object.keys(contact).filter(function (item) {
            if (!found && item == key.name) {
              result.push({
                responsekey: key.name,
                name: key.title,
                value: contact[item],
              });
              found = true;
              return false;
            } else return true;
          })
        : [];
    });
    return result;
  };

  return (
    <div>
      {contact !== undefined &&
      contact !== null &&
      Object.keys(contact).length > 0 ? (
        <>
          {!context.jsonView ? (
            <>
              <Row style={{ height: '90px' }}>
                <Col span={4}>
                  <div className="contact-image">
                    <Avatar size={70}>
                      <span
                        style={{
                          fontSize: '35px',
                          lineHeight: '65px',
                        }}>
                        {contact.conFirstName && contact.conLastName ? (
                          contact.conFirstName.charAt(0).toUpperCase() +
                          contact.conLastName.charAt(0).toUpperCase()
                        ) : (
                          <UserOutlined size={50} />
                        )}
                      </span>
                    </Avatar>
                  </div>
                </Col>
                <Col span={18}>
                  <div className="contact-info">
                    <div className="mTop2 repeater">
                      <span className="contact-name contact-tab-name ellipsis">
                        {contact.conFullName}
                      </span>
                    </div>
                    <div className="repeater gray font-size-small contact-title box">
                      <span className="repeater ellipsis">
                        {contact.conJobTitleEn}
                      </span>
                    </div>
                    <div className="repeater gray font-size-small contact-email box">
                      <span className="repeater ellipsis">
                        {contact.conEmail ? (
                          <span>
                            <MailOutlined className="iconPadding" />{' '}
                            <span className="mail-link">
                              <a
                                href={`mailto:${contact.conEmail}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                {contact.conEmail}
                              </a>
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    {/* <div className="repeater gray font-size-small contact-phone box">
                      {props.enrichType !== 'lite' ? (
                        <>
                          <span style={{ fontSize: '12px' }}>
                            {context.mobileNoEnrichment !== null ? (
                              <span>
                                <span>
                                  {context.mobileNoEnrichment.direct_dial_1 !==
                                  null ? (
                                    <span>
                                      <PhoneOutlined className="iconPadding rotate" />
                                      {context.mobileNoEnrichment.direct_dial_1}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                                {context.mobileNoEnrichment.direct_dial_2 !==
                                null ? (
                                  <span>
                                    {context.mobileNoEnrichment
                                      .direct_dial_1 !== null && (
                                      <span>,&nbsp;</span>
                                    )}
                                    <span>
                                      {/* <PhoneOutlined className="iconPadding rotate" /> */}
                    {/* {context.mobileNoEnrichment.direct_dial_2}
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            ) : (
                              <span>
                                <PhoneOutlined className="iconPadding rotate" />{' '}
                                ---
                              </span>
                            )}
                          </span>
                        </>
                      ) : null}
                    </div>  */}
                  </div>
                </Col>
              </Row>
              <Divider className="dividerMargin" />
              <Row>
                <UserDetailsComp
                  contact={displayKeysInOrder}
                  dynamicData={remainingKeys}
                />
              </Row>
            </>
          ) : (
            <div>
              <JsonResultComp
                contact={displayKeysInOrder}
                dynamicData={remainingKeys}
                type={'response'}
                isCurrentTenureResult={isCurrentTenureResult}
                currentTenureResult={currentTenureResult}
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

ShowResultComp.propTypes = {
  // enrichType: PropTypes.string,
};

export default ShowResultComp;
