import React, { useEffect, useState } from 'react';
import ResetPassword from './ResetPassword';
import { Spinner } from './Spinner';
import useQuery from './useQuery';
import TokenExpire from './TokenExpire';
import axios from 'axios';
import config from '../../config/constants';
import LandingLayout from '../../screens/login/LandingLayout';
import { clientService } from '../../service/client.service';
const userUrl = config.env.userServerUrl;

function AccountActivation() {
  const [isLoading, setIsLoading] = useState(true);
  const [tokenExpire, setTokenExpire] = useState(false);
  const [passwordPolicies, setPasswordPolicies] = useState({});

  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    verifyToken();
  }, []);

  //verify token
  const verifyToken = () => {
    setIsLoading(true);
    axios({
      method: 'GET',
      url: `${userUrl}/v1/accounts/verify-token/${token}`,
    })
      .then((res) => {
        console.log('token is verify', res);
        if (res.status === 200) {
          getPasswordPolicy(token);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setTokenExpire(true);
      })
      .finally(() => {
        // always executed
      });
  };

  //get password policy
  const getPasswordPolicy = (token) => {
    clientService
      .getClientPasswordPolicy(token)
      .then((response) => {
        setPasswordPolicies(response.data);
        setIsLoading(false);
        setTokenExpire(false);
      })
      .catch((err) => {
        setPasswordPolicies();
        setIsLoading(false);
        setTokenExpire(false);
      });
  };

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  } else if (!isLoading && tokenExpire) {
    return (
      <LandingLayout>
        <TokenExpire />
      </LandingLayout>
    );
  } else if (!isLoading && !tokenExpire) {
    return (
      <>
        {
          <LandingLayout>
            <ResetPassword
              token={token}
              showActiveMessage={true}
              passwordPolicies={passwordPolicies}
            />
          </LandingLayout>
        }
      </>
    );
  }
}

export default AccountActivation;
