import React, { useEffect, useState } from 'react';
import { Modal, Popover, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { APP_STANDARD_DATE_FORAMT } from '../../../config/constants';

function TaskStats(props) {
  const [statsValue, setStatsValue] = useState([]);

  const statsDescription = [
    {
      recordStatus: 'Active',
      description: 'Person actively working with the provided input company.',
    },
    {
      recordStatus: 'No Longer',
      description: 'Person no longer working with the provided input company.',
    },
    {
      recordStatus: 'Company Match',
      description: 'Records where only company enrichment is provided.',
    },
    {
      recordStatus: 'Junk or Ineligible',
      description:
        "Records that don't have sufficient inputs to qualify for enrichment or provided values are all junk values. These records should be deleted from system.",
    },
    {
      recordStatus: 'Possible Junk',
      description:
        'Records qualify for enrichment, but have at least one junk or generic value input.',
    },
    {
      recordStatus: 'No Match',
      description: 'Records that could NOT be enriched.',
    },
    {
      recordStatus: 'Out of scope',
      description:
        'Records that belong to less than 50 employee company or low priority countries like Ecuador, Nigeria, Morocco, etc.',
    },
  ];

  useEffect(() => {
    const statsResult = props.specificStatsDetails
      .filter((item) => item.count !== 0)
      .map((items) => {
        return items;
      });

    setStatsValue(statsResult);
  }, [props.specificStatsDetails]);

  const columns = [
    //1st col Name
    {
      title: 'MB Status',
      key: 'name',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return <span>{text.name}</span>;
      },
    },
    //2nd count
    {
      title: 'Count',
      key: 'count',
      align: 'right',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return <span>{text.count}</span>;
      },
    },
    //3rd percentage
    {
      title: 'Percentage',
      // dataIndex: 'percentage',
      key: 'percentage',
      align: 'right',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return <span>{text.percentage}%</span>;
      },
    },
  ];

  const modalTitle = (
    <div className="stats-title">
      Task Stats <span className="stats-taskname"> &nbsp;{props.taskName}</span>
    </div>
  );

  const statsDescriptionColumns = [
    //1st col Name
    {
      title: 'Record Status',
      dataIndex: 'recordStatus',
      key: 'recordStatus',
      width: '80px',
      // ellipsis: true,
    },
    //2nd col Name
    {
      title: 'Description',
      key: 'description',
      // ellipsis: true,
      render: (text) => {
        if (text.recordStatus === 'No Match') {
          return (
            <>
              {text.description}
              <br />
              {/* <b style={{ fontSize: '0.68vw' }}>
                Note – Company info could be delivered for these records, even
                if Person info could not be matched - task view
              </b> */}
            </>
          );
        } else {
          return <>{text.description}</>;
        }
      },
    },
  ];
  const content = (
    <div>
      <Table
        rowKey="id"
        // bordered
        dataSource={statsDescription}
        size="small"
        columns={statsDescriptionColumns}
        showHeader={true}
        pagination={false}
        className="statsDescriptionTable"
      />
    </div>
  );

  return (
    <>
      <div>
        <Modal
          title={modalTitle}
          visible={props.showStats}
          onCancel={props.closeStats}
          maskClosable={false}
          footer={null}
          width={'40%'}
          centered={true}
          destroyOnClose={true}
          bodyStyle={{
            maxHeight: 'calc(100vh - 140px)',
            overflow: 'auto',
          }}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <div className="titleInBody">Record Status Overview</div>
              <div className="statsIconDiv">
                <Popover
                  placement="right"
                  content={content}
                  trigger="click"
                  overlayClassName="popoverContent">
                  <InfoCircleOutlined className="statsIcon" />
                </Popover>
              </div>
            </div>
            <div style={{ padding: '15px' }}>
              <Table
                rowKey="id"
                dataSource={statsValue}
                size="small"
                columns={columns}
                showHeader={true}
                pagination={false}
                className="taskStatsTable"
              />
            </div>
            <div
              style={{
                float: 'right',
                paddingRight: '15px',
                color: '#454545',
              }}>
              Last updated on :{' '}
              {moment
                .utc(props.lastUpdatedOn)
                .local()
                .format(APP_STANDARD_DATE_FORAMT)}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

TaskStats.propTypes = {
  specificStatsDetails: PropTypes.array,
  showStats: PropTypes.bool,
  closeStats: PropTypes.func,
  taskName: PropTypes.string,
  lastUpdatedOn: PropTypes.string,
};

export default TaskStats;
