import React, { useEffect } from 'react';
import './BrowserWarning.css';
import ClassList from '../../utilities/ClassList';
import PropTypes from 'prop-types';

const BrowserWarning = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="browser-warning-div">
      <div className="browser-warning-image-div">
        <div className="browser-warning-support-image">
          <p className="browser-warning-image">!</p>
        </div>
      </div>
      <div className="browser-warning-message">
        <span>Unsupported Browser:</span> This browser may not support all the
        features. To ensure best user experience, we recommend using Chrome,
        Edge or Firefox. We apologize for the inconvenience and appreciate your
        understanding in this matter.
      </div>
    </div>
  );
};
export default BrowserWarning;
