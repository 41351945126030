import React, { useState, useEffect, useLayoutEffect } from 'react';
import ConnectorScreen from './ConnectorScreen';
import ConnectorContext from '../../context/ConnectorContext';
import MarketoLayer from './marketo/MarketoLayer';
import { connectorService } from '../../service/connector.service';

const ConnectorLayer = () => {
  const [byDefaultScreen, setByDefaultScreen] = useState(true);
  const [openScreen, setOpenScreen] = useState('');
  const [connectionStatus, setConnectionStatus] = useState(false);
  useEffect(() => {
    setByDefaultScreen(true);
    setOpenScreen('ConnectorScreen');
    getDetails();
  }, []);

  useLayoutEffect(() => {
    getDetails();
  }, [openScreen === 'ConnectorScreen']);

  const requestToOpenScreen = (nameOfScreen) => {
    setOpenScreen(nameOfScreen);
    setByDefaultScreen(false);
    if (nameOfScreen === 'ConnectorScreen') {
      setByDefaultScreen(true);
    }
  };

  const getConnectionStatus = (status) => {
    setConnectionStatus(status);
  };

  const getDetails = () => {
    connectorService.getDetails().then((response) => {
      if (response.status === 200) {
        if (response.data.active) {
          setConnectionStatus(true);
        } else {
          setConnectionStatus(false);
        }
      } else {
        setConnectionStatus(false);
      }
    });
  };

  console.log(byDefaultScreen, openScreen);

  return (
    <>
      <ConnectorContext.Provider
        value={{ requestToOpenScreen, getConnectionStatus }}>
        {byDefaultScreen && openScreen === 'ConnectorScreen' ? (
          <ConnectorScreen
            openScreenName={openScreen}
            connectionStatus={connectionStatus}
          />
        ) : openScreen === 'MarketoScreen' ? (
          <MarketoLayer />
        ) : null}
      </ConnectorContext.Provider>
    </>
  );
};

export default ConnectorLayer;
