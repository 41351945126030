import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import EmptyData from '../../../assets/images/DashboardEmptyData.png';
import StackedChartStats from './StackedChartStats';
import { taskService } from '../../../service/task.service';

const MonthCreditsUsageComp = () => {
  const [stackData, setStackData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStackValues();
  }, []);

  const getStackValues = () => {
    setIsLoading(true);
    taskService
      .getCreditsUsage(5)
      .then((resp) => {
        console.log(resp.data);
        setIsLoading(false);
        setStackData(resp.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Card
        bodyStyle={{ padding: 12 }}
        className="dashbordCards"
        // style={{ marginTop: '10px', height: '360px' }}
      >
        <div className="cardTitle">Enrich Credit Usage Pattern</div>
        <Spin tip="Loading..." spinning={isLoading} size="large">
          {stackData.length > 0 ? (
            <span className="creditUsage-stackChart">
              <StackedChartStats stackData={stackData} />
            </span>
          ) : (
            <div className="NDTD">
              {!isLoading ? (
                <span>
                  <img src={EmptyData} />
                  <br />
                  <br />
                  <div>No Data Available</div>
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </Spin>
      </Card>
    </div>
  );
};

export default MonthCreditsUsageComp;
