import React, { useEffect, useState } from 'react';
import { Button, Card, Col, message, Popover, Row } from 'antd';
import coinStack from '../../assets/images/coin-stack.png';
import { numFormatter, numberWithCommas } from '../../config/constantMethod';
import PropTypes from 'prop-types';
import CreditsRequestComp from './CreditsRequestComp';
import { dashboardService } from '../../service/dashboard.service';
import { ShowOopsMsg } from '../../common/messageComp';

const CreditInfoComp = ({ clientId }) => {
  const [visible, setVisible] = useState(false);
  const [creditsAvailable, setCreditsAvailable] = useState(clientId);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   getCreditsAvailable();
  // }, []);
  const showDrawer = () => {
    setVisible((prev) => !prev);
  };

  // const getCreditsAvailable = () => {
  //   creditService
  //     .getClientCreditBalance(clientId)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setCreditsAvailable(response.data.creditsAvailable);
  //       } else {
  //         message.destroy();
  //         setCreditsAvailable(null);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('error in getCreditsBalance', err);
  //     })
  //     .finally(() => {});
  // };

  const onSubmit = (values) => {
    setIsLoading(true);
    dashboardService
      .sendCreditsRequest(values.credit)
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          message.success(
            "We've received your request for credits and our sales rep will soon be in touch with you.",
            10
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.destroy();
        ShowOopsMsg();
        setIsLoading(false);
      });

    setVisible(false);
    console.log('submit');
  };

  const onCancel = () => {
    setVisible(false);
  };
  const onHoverContent = (val) => (
    <div style={{ width: '115px' }}>
      <div>Credits Available</div>
      <span style={{ fontWeight: 600, textAlign: 'right' }}>
        {numberWithCommas(val)}
      </span>
    </div>
  );

  return (
    <div>
      <Card bodyStyle={{ padding: 15 }} className="dashbordCards">
        <Row>
          <Col span={10}>
            <div className="cardTitle">Credits Info</div>
          </Col>
          <Col span={14}>
            <div>
              <Card bodyStyle={{ padding: 7 }} className="creditInfoCard">
                <div
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <img
                    src={coinStack}
                    style={{ width: '40px', height: '40px' }}
                  />
                  <Popover
                    style={{ width: '150px' }}
                    content={onHoverContent(clientId)}
                    trigger="hover"
                    placement="top">
                    <div>
                      <div className="creditCount">
                        {numFormatter(clientId)}
                      </div>
                      <div>Credits Available</div>
                    </div>
                  </Popover>
                </div>
              </Card>
            </div>
          </Col>
          <Col></Col>
          {/* <Col span={10} style={{ marginTop: '10px' }}>
            <span className="dashboardSubTitle">Available Credits</span>
          </Col>
          <Col
            span={14}
            style={{ marginTop: '10px' }}
            className="textAlignRight">
            <span className="subText">
              {numberWithCommas(creditsAvailable)}
            </span>
          </Col> */}
          {/* <Col span={10}>
            <span className="dashboardSubTitle">Credits Used</span>
          </Col>
          <Col span={14} className="textAlignRight">
            : <span className="subText">67,777</span>
          </Col> */}
        </Row>
        <Row className="needMoreCreditBtn">
          <Button type="primary" onClick={showDrawer}>
            Need more credits ?
          </Button>
        </Row>
      </Card>

      <CreditsRequestComp
        visible={visible}
        onCancel={onCancel}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </div>
    // </div>
  );
};

CreditInfoComp.propTypes = {
  clientId: PropTypes.string,
};
export default CreditInfoComp;
