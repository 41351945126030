import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'antd';
import moment from 'moment';
import './stats.css';
import StatsTask from './StatsTask';
import {
  APP_STANDARD_DATE_FORAMT,
  statsNameList,
} from '../../../config/constants';
import BackArrow from '../../../assets/images/backArrow.png';

const StatsScreen = ({
  taskName,
  taskId,
  statsLastUpdatedOn,
  closeStats,
  isShowRecordStats,
}) => {
  if (!isShowRecordStats) {
    statsNameList.splice(10, 1);
  }
  return (
    <div>
      <div className="mainBox">
        <div
          style={{
            padding: '10px',
            display: 'flex',
            margin: '0px 10px',
            alignItems: 'center',
          }}>
          <div>
            <Button onClick={closeStats} style={{ padding: '2px 10px' }}>
              <img src={BackArrow} />
            </Button>
          </div>
          <div className="statsTaskNameBody">
            &nbsp;Task Name : <span className="statsTaskName">{taskName}</span>
          </div>
        </div>
        <div>
          <Row gutter={4}>
            {statsNameList.map((item, i) => {
              if (
                (!isShowRecordStats &&
                  item.statsName != 'Email Deliverability Score') ||
                isShowRecordStats
              ) {
                return (
                  <Col span={12} key={i}>
                    <Card
                      bodyStyle={{ padding: 0 }}
                      className="middleBox"
                      key={`card-${i}`}>
                      <StatsTask
                        key={item.statsName}
                        taskId={taskId}
                        reportId={item.reportId}
                        statsName={item.statsName}
                        tableName={item.tableName}
                        taskName={taskName}
                      />
                    </Card>
                  </Col>
                );
              }
            })}
          </Row>
        </div>

        {statsLastUpdatedOn !== undefined && (
          <div className="lastBox">
            <span className="lastUpdateText">
              Last Refreshed On{' '}
              {moment
                .utc(statsLastUpdatedOn)
                .local()
                .format(APP_STANDARD_DATE_FORAMT)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

StatsScreen.propTypes = {
  taskName: PropTypes.string,
  taskId: PropTypes.number,
  statsLastUpdatedOn: PropTypes.string,
  closeStats: PropTypes.func,
  isShowRecordStats: PropTypes.bool,
};

export default StatsScreen;
