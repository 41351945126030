import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Tooltip,
  Button,
  Menu,
  Typography,
  Modal,
  message,
  Dropdown,
} from 'antd';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  DownOutlined,
} from '@ant-design/icons';
import ListTask from './list/ListTask';
import AddTaskWizard from './AddTask/AddTaskWizard';
import './task.css';
import axios from 'axios';
import config from '../../config/constants';
import { useQueryCache } from 'react-query';
import { AuthContext } from '../../shared/auth/authContext';
import cloudImg from '../../assets/images/cloudImg.png';
import {
  msgConfig,
  taskSubmitMessage,
  ShowOopsMsg,
} from '../../common/messageComp';
import { motion } from 'framer-motion';
import PageTransition from '../../shared/animation/PageTransition';
import TaskSelectionComp from './TaskSelection';
import { AnimationOnce } from '../../common/animatedIcon';
import marketoIcon from '../../assets/images/Marketo-Logos.png';
import { connectorService } from '../../service/connector.service';

const { Title } = Typography;
const { confirm } = Modal;

const taskUrl = config.env.taskServerUrl;

function TaskList() {
  const { isAllowed, loggedInUser } = React.useContext(AuthContext);

  const queryCache = useQueryCache();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState('created_on');
  const [sortValue, setSortValue] = useState('desc');
  const [totalTaskCount, setTotalTaskCount] = useState('-');
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [openStats, setOpenStats] = useState(false);
  const [checkDD, setCheckDD] = useState(false);
  const [dragFile, setDragFile] = useState(null);
  const [fileUploadType, setFileUploadType] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  function readfiles(files) {
    for (var i = 0; i < files.length; i++) {
      let fileName = files[i].name;
      files[0].uid = '-1';
      setDragFile([files[0]]);
      setCheckDD(true);
    }
  }

  useLayoutEffect(() => {
    let dropArea = document.getElementById('drop-area');
    let dragMask = document.getElementById('maskdiv');
    let dragText = document.getElementById('maskText');
    ['dragenter', 'dragover', 'dragleave', 'drop', 'undefiend'].forEach(
      (eventName) => {
        dropArea.addEventListener(eventName, handleFileSelect, false);
      }
    );

    dropArea.ondrop = function (e) {
      dragMask.style.display = 'none';
      dragText.style.display = 'none';
      this.className = '';
      e.preventDefault();
      readfiles(e.dataTransfer.files);
    };

    dropArea.addEventListener('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
      const data = e.dataTransfer.getData('text/plain');
      e.target.textContent = data;
    });
  }, []);

  const handleFileSelect = (event) => {
    let dragMask = document.getElementById('maskdiv');
    let dragText = document.getElementById('maskText');
    if (event.type) {
      dragMask.style.display = 'flex';
      dragText.style.display = 'flex';
    }
    if (event.type == 'dragleave') {
      setTimeout(() => {
        dragMask.style.display = 'none';
        dragText.style.display = 'none';
      }, 2000);
    }
  };

  useEffect(() => {
    if (dragFile !== null) {
      setUploadPopup(true);
    }
  }, [dragFile]);

  useEffect(() => {
    getConnectionDetails();
  }, []);

  // to check marketo connection status
  const getConnectionDetails = (callback) => {
    connectorService
      .getDetails()
      .then((response) => {
        console.log('resp', response);
        if (response.status === 200) {
          if (response.data.active) {
            setIsConnected(true);
          } else {
            setIsConnected(false);
          }
        } else {
          setIsConnected(false);
        }
      })
      .catch((err) => {
        console.log('in marketo layer getConnection', err);
      });
  };

  //to get previous record count
  const getPreviousRecordCount = () => {
    const previousTaskData = queryCache.getQueryData([
      'tasks',
      page,
      columnName,
      sortValue,
    ]);

    if (previousTaskData) {
      setTotalTaskCount(previousTaskData.data.totalElements);
    }
  };
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          getPreviousRecordCount();
        }
      });
      getPreviousRecordCount();
    }
  }, []);

  //dynamic steps pass to wizard.
  const wizardSteps = () => {
    let applicableSteps = '0,1,2,3'; // default is all steps
    //TASK_STEPS should be ADD_TASK_ENRICH_STEP
    if (loggedInUser.clientType == 'Regular') {
      applicableSteps = '0,1,3';
    }
    if (loggedInUser.clientType == 'Premium') {
      applicableSteps = '0,1,2,3';
    }
    if (!isAllowed('ADD_TASK_ENRICH_STEP')) {
      applicableSteps = '0,1,3';
    }

    return applicableSteps.split(',').map(Number); // return Array of Number
  };

  //stats window open or close
  const getActionForStats = () => {
    setOpenStats((preState) => !preState);
  };

  //get the value from child component
  const getTotalTaskCount = (count) => {
    setTotalTaskCount(count);
  };
  const isCreateTaskEnable = (value) => {
    setIsDataPresent(value);
  };
  const getCurrentPageNo = (pageNo) => {
    setPage(pageNo);
  };

  //To open popup Add task
  const uploadTask = () => {
    setUploadPopup((preState) => !preState);
  };

  const setUploadTask = (type) => {
    setFileUploadType(type);
    setUploadPopup((preState) => !preState);
  };

  //submit csv file to server
  const onSubmitCSVFile = (
    csvFile,
    taskName,
    fieldMapping,
    excelSheetName,
    enrichModeRuleMapping
  ) => {
    console.log('csv file upload');
    let formData = new FormData();
    formData.append('file', csvFile);
    formData.append('taskName', taskName);
    formData.append('priority', 'HIGH');
    formData.append('fieldMapping', JSON.stringify(fieldMapping));
    if (enrichModeRuleMapping && enrichModeRuleMapping.length) {
      formData.append('ruleMapping', JSON.stringify(enrichModeRuleMapping));
    }

    // if (
    //   excelSheetName !== 'Select Sheet Name' &&
    //   excelSheetName !== 'Select Sheet is csv'
    // ) {
    //   formData.append('sheetName', excelSheetName);
    // }
    axios({
      method: 'POST',
      url: `${taskUrl}/api/v1/upload`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      timeout: 1000 * 60 * 5,
    })
      .then((res) => {
        let responseData = res;
        console.log(
          'onSubmitUploadFile responseData status',
          responseData.status
        );
        if (responseData.status === 201) {
          message.destroy();
          message.success(msgConfig(taskSubmitMessage()));
          // this.props.onClose();
          refetchTaskList();
        }
        setUploadPopup((preState) => !preState);
        setLoading(false);
        setCheckDD(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let errorMessage = err.message;
        if (errorMessage.includes('timeout')) {
          message.destroy();
          message.error('Your connection timeout.');
        } else {
          message.destroy();
          ShowOopsMsg();
        }
      })
      .finally(() => {
        // always executed
      });
  };

  //submit excel file to server
  const onSubmitExcelFile = (
    taskName,
    fieldMapping,
    excelSheetName,
    newFileName,
    fileName,
    enrichModeRuleMapping
  ) => {
    console.log('onSubmitExcelFile');
    let formData = new FormData();
    formData.append('uuidFileName', newFileName);
    formData.append('actualFileName', fileName);
    formData.append('taskName', taskName);
    formData.append('priority', 'HIGH');
    formData.append('fieldMapping', JSON.stringify(fieldMapping));
    if (enrichModeRuleMapping && enrichModeRuleMapping.length) {
      formData.append('ruleMapping', JSON.stringify(enrichModeRuleMapping));
    }
    formData.append('sheetName', excelSheetName);
    axios({
      method: 'POST',
      url: `${taskUrl}/api/v1/xlUpload`,
      data: formData,
      timeout: 1000 * 60 * 5,
    })
      .then((res) => {
        let responseData = res;
        console.log(
          'onSubmitUploadFile responseData status',
          responseData.status
        );
        if (responseData.status === 201) {
          message.destroy();
          message.success(msgConfig(taskSubmitMessage()));
          refetchTaskList();
        }
        setUploadPopup((preState) => !preState);
        setLoading(false);
        setCheckDD(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let errorMessage = err.message;
        if (errorMessage.includes('timeout')) {
          message.destroy();
          message.error('Your connection timeout.');
        } else {
          message.destroy();
          ShowOopsMsg();
        }
      })
      .finally(() => {
        // always executed
      });
  };

  const refetchTaskList = () => {
    queryCache.refetchQueries(['tasks', page, columnName, sortValue]);
  };
  //submit task file popup
  const submitUploadFile = (
    csvFile,
    taskName,
    fieldMapping,
    excelSheetName,
    newFileName,
    fileName,
    enrichModeRuleMapping
  ) => {
    // console.log(enrichModeRuleMapping);
    confirm({
      title: 'Are you sure you want to submit the task?',
      icon: <ExclamationCircleFilled style={{ fontSize: '25px' }} />,
      centered: true,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        console.log('submitUploadFile popup');
        // setUploadPopup((preState) => !preState);
        // console.log('newFileName', newFileName);
        if (newFileName === '') {
          onSubmitCSVFile(
            csvFile,
            taskName,
            fieldMapping,
            excelSheetName,
            enrichModeRuleMapping
          );
        } else {
          onSubmitExcelFile(
            taskName,
            fieldMapping,
            excelSheetName,
            newFileName,
            fileName,
            enrichModeRuleMapping
          );
        }
        console.log('submit');
        setLoading(true);
      },
      onCancel() {
        console.log('cancel');
        setLoading(false);
      },
    });
  };

  //closed add task popup
  const closeUploadTask = () => {
    confirm({
      title: 'Are you sure you want to cancel?',
      icon: <ExclamationCircleFilled style={{ fontSize: '25px' }} />,
      centered: true,
      okText: 'No',
      okType: 'primary',
      cancelText: 'Yes',
      cancelType: 'ghost',
      onOk() {
        console.log('cancel');
      },
      onCancel() {
        console.log('closeUploadTask popup');
        onCancelUploadTask();
      },
    });
  };

  const onCancelUploadTask = () => {
    setUploadPopup((preState) => !preState);
    setCheckDD(false);
  };

  const FileUploadType = (
    <Menu
      className="menuUploadType"
      onClick={(e) => setUploadTask(e.key)}
      style={{
        background: 'var(--primary)',
      }}>
      <Menu.Item
        key="fileUpload"
        icon={AnimationOnce(
          'icons/47-to-do-list-outline-edited.json',
          'li',
          '#FFFFFF'
        )}>
        From File
      </Menu.Item>
      <Menu.Item
        key="MarketoUpload"
        icon={
          <img src={marketoIcon} width="18px" style={{ marginLeft: '8px' }} />
        }>
        &nbsp;From Marketo
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginTop: '10px' }}>
      <div id="drop-area">
        <div className="dropFileText labelBoldText" id="maskText">
          Please drop your file here!
        </div>
        <div className="dragfileMask" id="maskdiv"></div>
        {openStats ? null : (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              right: '5px',
              right: '26px',
            }}>
            {isAllowed('ADD_TASK') && isDataPresent && (
              <div>
                {isConnected && isAllowed('ADD_MARKETO_TASK') ? (
                  <Dropdown.Button
                    type="primary"
                    placement="bottomLeft"
                    trigger="click"
                    overlay={FileUploadType}
                    onClick={() => {
                      setUploadTask(null);
                    }}
                    icon={<DownOutlined />}>
                    {' '}
                    <PlusOutlined /> Create Task
                  </Dropdown.Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      setUploadTask('fileUpload');
                    }}
                    className="btn-width">
                    <PlusOutlined /> Create Task
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
        <PageTransition>
          <ListTask
            getTotalTaskCount={getTotalTaskCount}
            isCreateTaskEnable={isCreateTaskEnable}
            getActionForStats={getActionForStats}
            getCurrentPageNo={getCurrentPageNo}
            uploadTask={setUploadTask}
            isConnected={isConnected}
            fileUploadType={fileUploadType}
            totalTaskCount={totalTaskCount}
          />
        </PageTransition>
        {/* 
      <div className="footnote">
        Files will be available to download for 180 days | Stats will be
        available for 365 days
      </div> */}

        {/* open Add task popup */}
        {uploadPopup ? (
          <>
            <TaskSelectionComp
              open={uploadPopup}
              closeUploadTask={closeUploadTask}
              steps={wizardSteps()}
              dragFile={checkDD ? dragFile : null}
              submitUploadFile={submitUploadFile}
              isLoading={loading}
              fileUploadType={fileUploadType}
              onCancelUploadTask={onCancelUploadTask}
              refetchTaskList={refetchTaskList}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TaskList;
