import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Table,
  Tag,
  message,
  Input,
  Popconfirm,
  Button,
  Spin,
} from 'antd';
import { clientService } from '../../service/client.service';

import keyImg from '../../assets/images/423-key-outline-disabled.png';
import documentImg from '../../assets/images/documentation-text.svg';
import { AnimationOnce } from '../../common/animatedIcon';
import EyeIconClose from '../../assets/images/eye-icon-close.png';
import { AuthContext } from '../../shared/auth/authContext';
import { Tooltip } from 'antd';

function ApiKeyGenerationComp(props) {
  const { loggedInUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const [apikeyDetails, setApikeyDetails] = useState([]);

  useEffect(() => {
    getApikeyValues();
  }, []);

  const getApikeyValues = () => {
    let id = loggedInUser.clientId;
    setIsLoading(true);
    clientService
      .getById(id)
      .then((res) => {
        let dataSource = [
          {
            key: '1',
            name: 'Enrich API ',
            apiKey:
              res.data &&
              res.data.enrichApiKey !== null &&
              res.data.enrichApiKey !== undefined
                ? res.data.enrichApiKey
                : '-',
          },
          // {
          //   key: '2',
          //   name: 'Mobile Enrich API ',
          //   apiKey:
          //     res.data &&
          //     res.data.ddApiKey !== null &&
          //     res.data.ddApiKey !== undefined
          //       ? res.data.ddApiKey
          //       : '-',
          // },
        ];

        setApikeyDetails(dataSource);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
      });
  };

  function confirm(e) {
    console.log(e);
    message.success('Click on Yes');
  }

  function cancel(e) {
    console.log(e);
    message.error('Click on Cancel');
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '26%',
    },
    {
      title: 'API key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      width: '45%',
      align: 'center',
      render: (text) => {
        if (text !== '-') {
          return (
            <Input.Password
              className="apiKeyInput"
              value={text}
              bordered={false}
              iconRender={(visible) =>
                visible ? (
                  AnimationOnce('icons/69-eye-outline-edited.json', 'li')
                ) : (
                  <img src={EyeIconClose} style={{ width: '30px' }} />
                )
              }
              readOnly></Input.Password>
          );
        } else {
          return <span>-</span>;
        }
      },
    },

    {
      title: 'Generate New Key',
      key: 'action',
      width: '29%',
      align: 'center',
      disabled: true,
      render: (text) => {
        if (text === 'ACTIVE') {
          return text;
        } else {
          return (
            <span>
              {/* <Popconfirm
                title="Are you sure you want to generate new api key to replace 1a2b?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="Cancel"> */}
              <Button type="text" disabled={true} style={{ padding: 0 }}>
                <img src={keyImg} width="30px" />
              </Button>
              {/* </Popconfirm> */}
            </span>
          );
        }
      },
    },
  ];

  return (
    <div>
      <div>
        <Card
          bodyStyle={{ padding: 12 }}
          className="dashbordCards"
          style={{ height: '200px', marginTop: '10px' }}>
          <div className="cardBody">
            <div className="cardTitle">API Key</div>
            <Tooltip
              color={'white'}
              placement="top"
              title={<span className="gray">Documentation</span>}>
              <a
                onClick={() => {
                  window.open(process.env.REACT_APP_DOC_URL, '_blank');
                }}>
                <img src={documentImg} />
              </a>
            </Tooltip>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Spin tip="Loading..." spinning={isLoading} size="large">
              <Table
                className="apiKeyGenerationTable"
                columns={columns}
                dataSource={apikeyDetails}
                pagination={false}
              />
            </Spin>
          </div>
        </Card>
      </div>
    </div>
  );
}

ApiKeyGenerationComp.propTypes = {};

export default ApiKeyGenerationComp;
