import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import LinkedIn from '../../assets/images/LinkedIn.png';

const ShowHideTextComp = ({ data }) => {
  const [showText, setShowText] = useState(false);
  const [showPlusIcon, setShowPlusIcon] = useState(false);
  const [showLinkedInIcon, setShowLinkedInIcon] = useState(false);
  const [isCompanyWebsite, setIsCompanyWebsite] = useState(false);
  const [isPercentagevalue, setIsPercentagevalue] = useState(false);
  const [isMail, setIsMail] = useState(false);

  useEffect(() => {
    let showPlusIcon = data.data && data.data.length > 70 ? true : false;
    setShowPlusIcon(showPlusIcon);
    let showLinkedInIcon =
      data.name === 'Company Linkedin Url' ||
      data.name === 'Contact Linkedin Url'
        ? true
        : false;
    setShowLinkedInIcon(showLinkedInIcon);
    let isCompanyWebsite = data.name === 'Company Website' ? true : false;
    setIsCompanyWebsite(isCompanyWebsite);
    let isMail =
      data.name === 'Contact Email' || data.name === 'Client Given Email'
        ? true
        : false;
    setIsMail(isMail);
    let isPercentagevalue =
      data.responsekey == 'conEmailDeliverabilityScore%' ||
      data.responsekey == 'cgEmailDeliverabilityScore%' ||
      data.responsekey == 'recordConfidenceScore%' ||
      data.responsekey == 'emailDeliverabilityScore%'
        ? true
        : false;
    setIsPercentagevalue(isPercentagevalue);
  });

  const showHideText = () => {
    setShowText(!showText);
    let div = document.getElementById(data.name);
    if (div !== undefined && div !== null) {
      if (!showText) {
        div.style.height = 'auto';
      } else {
        div.style.height = '1.6rem';
      }
    }
  };
  return (
    <div>
      <div
        className="repeater padding5 gray field-div"
        id={data.name}
        style={{ height: '1.6rem' }}>
        {showPlusIcon && (
          <span className="showHideIcons">
            {!showText ? (
              <PlusOutlined onClick={showHideText} className="plusMinusIcon" />
            ) : (
              <MinusOutlined onClick={showHideText} className="plusMinusIcon" />
            )}
          </span>
        )}
        <Tooltip
          title={<span className="gray">{data.name}</span>}
          color={'white'}
          placement="bottom">
          <div className="oneThird"> {data.name}</div>
        </Tooltip>
        <div className="twoThird">
          {showLinkedInIcon ? (
            <>
              <span className="link">
                <a
                  href={`${data.data}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                &nbsp;
                <a
                  href={`${data.data}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {data.data}
                </a>
              </span>
            </>
          ) : isCompanyWebsite ? (
            <>
              <span className="link">
                <a
                  href={`${data.data}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {data.data}
                </a>
              </span>
            </>
          ) : isMail ? (
            <>
              <span className="link">
                <a
                  href={`mailto:${data.data}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {data.data}
                </a>
              </span>
            </>
          ) : isPercentagevalue ? (
            <>{data.data}%</>
          ) : (
            <span>{data.data}</span>
          )}
        </div>
      </div>
    </div>
  );
};

ShowHideTextComp.propTypes = {
  data: PropTypes.object,
};
export default ShowHideTextComp;
