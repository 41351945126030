import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './enrichModeScreen.css';
import { AuthContext } from '../../../shared/auth/authContext';

function EnrichModeSummary({ enrichModeValue }) {
  const { isAllowed } = useContext(AuthContext);
  var substr = '(Default)';

  const removeText = (optionitem, substr) => {
    let name = optionitem.name.replace(substr, '');
    return name;
  };
  //to set value in enrich mode array

  return (
    <div>
      <table className="table" style={{ fontSize: '14px' }}>
        <tbody>
          {/* Email Verifier Run */}
          {isAllowed('EMAIL_VERIFIER_RUN') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Email Verifier Run</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  if (enrichModeItem.name === 'Email Verifier Run') {
                    let optionitem = enrichModeItem.option.find(
                      (x) => x.value == enrichModeItem.value
                    );
                    let name = removeText(optionitem, substr);
                    return name;
                  }
                })}
              </td>
            </tr>
          )}
          {/* Company Match Process */}
          {isAllowed('COMP_MATCHBACK_PROCESS') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;
                <span className="fieldLabel">Company Match Process</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  if (enrichModeItem.name === 'Company Match Process') {
                    let optionitem = enrichModeItem.option.find(
                      (x) => x.value == enrichModeItem.value
                    );
                    let name = removeText(optionitem, substr);
                    return name;
                  }
                })}
              </td>
            </tr>
          )}
          {/* Company Index Reach */}
          {isAllowed('COMP_INDEX_REACH') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Company Index Reach</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  if (enrichModeItem.name === 'Company Index Reach') {
                    let optionitem = enrichModeItem.option.find(
                      (x) => x.value == enrichModeItem.value
                    );
                    let name = removeText(optionitem, substr);
                    return name;
                  }
                })}
              </td>
            </tr>
          )}
          {/* Export Fields */}
          {isAllowed('EXPORT_FIELDS') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Export Fields</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  if (enrichModeItem.name === 'Export Fields') {
                    let optionitem = enrichModeItem.option.find(
                      (x) => x.value == enrichModeItem.value
                    );
                    let name = removeText(optionitem, substr);
                    return name;
                  }
                })}
              </td>
            </tr>
          )}
          {/* Direct Dial Run */}
          {/* {isAllowed('DIRECT_DIAL') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;
                <span className="fieldLabel">Enrich Type</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  if (enrichModeItem.name === 'Enrich Type') {
                    let optionitem = enrichModeItem.option.find(
                      (x) => x.value == enrichModeItem.value
                    );
                    let name = removeText(optionitem, substr);
                    return name;
                  }
                })}
              </td>
            </tr> 
          )} */}
        </tbody>
      </table>
    </div>
  );
}

EnrichModeSummary.propTypes = {
  enrichModeValue: PropTypes.array,
};

export default EnrichModeSummary;
