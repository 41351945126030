import React, { useState } from 'react';
import { Col, Row } from 'antd';
import LandingImg from '../../assets/images/landingImg.png';
import BannerBgImg from '../../assets/images/Banner background 1.png';
import PropTypes from 'prop-types';
import logo from '../../assets/images/SMARTeLogoNew.svg';
import ResolutionInfoComp from './ResolutionInfoComp';
import BrowserWarning from '../../common/warning/BrowserWarning.jsx';
import '../../common/warning/BrowserWarning.css';
import { isUnSupportedBrowser } from '../../utilities/commonUtils.js';
import ClassList from '../../utilities/ClassList.js';

const LandingLayout = (props) => {
  const year = useState(new Date().getFullYear());
  return (
    <div>
      {isUnSupportedBrowser() && (
        <div>
          <BrowserWarning />
          <div className="browser-warning-space"></div>
        </div>
      )}
      <Row gutter={16} style={{ marginLeft: 0, marginRight: 0 }}>
        <img
          src={BannerBgImg}
          className={ClassList({
            bgLayout: true,
            'top-65': isUnSupportedBrowser() ? true : false,
          })}
        />
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          className="loginBlock">
          <section className="logoRightSide">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}>
              <img src={logo} alt="Enrich" width="187px" />
            </a>
          </section>
          <div>{props.children}</div>
          <div
            style={{
              position: 'absolute',
              bottom: '0px',
              left: '-7px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <div className="footer-text">
              © <span>{year}</span> smarteinc.com All Right Reserved
            </div>
          </div>
        </Col>
        <Col
          className="landingImg"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 16 }}
          lg={{ span: 15 }}
          xl={{ span: 16 }}>
          <img src={LandingImg} />
        </Col>
      </Row>
      <ResolutionInfoComp />
    </div>
  );
};
LandingLayout.propTypes = {
  children: PropTypes.element,
};

export default LandingLayout;
