import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const JsonResultComp = ({
  contact,
  type,
  dynamicData,
  isCurrentTenureResult,
  currentTenureResult,
}) => {
  const [showData, setShowData] = useState(true);
  const showHideData = () => {
    setShowData(!showData);
  };

  return (
    <div>
      <div>
        <div>
          {type == 'response' && (
            <>
              <div style={{ marginBottom: '5px' }}>
                <b>Response Headers:</b>
              </div>
              <table className="headerTable" border="1">
                <tbody>
                  <tr>
                    <th>Key</th>
                    <th>&nbsp;Value</th>
                  </tr>
                  <tr>
                    <td>Content-Type</td>
                    <td>&nbsp;application/json</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {type == 'request' && (
            <>
              <div style={{ marginBottom: '5px' }}>
                <b>Request Headers:</b>
              </div>
              <table className="headerTable" border="1">
                <tbody>
                  <tr>
                    <th>Key</th>
                    <th>&nbsp;Value</th>
                  </tr>
                  <tr>
                    <td>Content-Type</td>
                    <td> &nbsp;application/json</td>
                  </tr>
                  <tr>
                    <td>apikey</td>
                    <td> &nbsp;b1j0t1d5-ad3b-4b12-a4cd-29f2ab8e2fxx</td>
                  </tr>
                  <tr>
                    <td>enrich-type</td>
                    <td> &nbsp;{contact.enrichType}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <br />
      &#123;
      {type == 'request' && (
        <>
          {contact !== undefined &&
          contact !== null &&
          Object.keys(contact).length > 0
            ? Object.keys(contact)
                .filter((item) => item !== 'enrichType')
                .map((item, i) => {
                  return (
                    <div key={i} style={{ marginLeft: '15px' }}>
                      <span>
                        <b>&#34;{item}&#34;</b>
                      </span>{' '}
                      : <span>&#34;{contact[item]}&#34;</span>
                      {Object.keys(contact).length - 2 == i ? '' : ','}
                    </div>
                  );
                })
            : ''}
          &#125;
        </>
      )}
      {type == 'response' && (
        <>
          {contact !== undefined && contact !== null && contact.length > 0
            ? contact.map((item, i) => {
                return (
                  <div key={i} style={{ marginLeft: '15px' }}>
                    <span>
                      <b>&#34;{item.responsekey}&#34;</b>
                    </span>{' '}
                    : <span>&#34;{item.value}&#34;</span>
                    {dynamicData !== undefined &&
                    dynamicData.length == 0 &&
                    contact.length - 1 == i
                      ? ''
                      : ','}
                  </div>
                );
              })
            : ''}
          {isCurrentTenureResult ? (
            <div style={{ marginLeft: '15px' }}>
              <span className="showHideIcons">
                {!showData ? (
                  <PlusOutlined
                    onClick={showHideData}
                    className="plusMinusIcon"
                  />
                ) : (
                  <MinusOutlined
                    onClick={showHideData}
                    className="plusMinusIcon"
                  />
                )}
              </span>
              <span>
                <b>&#34;currentTenure&#34;</b>
              </span>{' '}
              :{' '}
              {!showData ? (
                <span>
                  &#123; <ArrowLeftOutlined className="plusMinusIcon" />{' '}
                  {currentTenureResult.length}{' '}
                  <ArrowRightOutlined className="plusMinusIcon" /> &#125;
                </span>
              ) : (
                <>
                  &#123;{' '}
                  {currentTenureResult !== undefined &&
                    currentTenureResult.length > 0 &&
                    currentTenureResult.map((item, i) => {
                      return (
                        <div key={i} style={{ marginLeft: '15px' }}>
                          <span>
                            <b>
                              &#34;
                              {item.responsekey}
                              &#34;
                            </b>
                          </span>{' '}
                          : <span>&#34;{item.value}&#34;</span>
                          {currentTenureResult.length - 1 == i ? '' : ','}
                        </div>
                      );
                    })}{' '}
                  &#125;
                </>
              )}
              {dynamicData !== undefined && dynamicData.length == 1 ? '' : ','}
            </div>
          ) : (
            ''
          )}
          {dynamicData !== undefined &&
            dynamicData.length > 0 &&
            dynamicData.map((item, i) => {
              if (item.name !== 'currentTenure')
                return (
                  <div key={i} style={{ marginLeft: '15px' }}>
                    <span>
                      <b>
                        &#34;
                        {item.responsekey}
                        &#34;
                      </b>
                    </span>{' '}
                    : <span>&#34;{item.value}&#34;</span>
                    {dynamicData.length - 1 == i ? '' : ','}
                  </div>
                );
            })}
          &#125;
        </>
      )}
    </div>
  );
};
JsonResultComp.propTypes = {
  contact: PropTypes.array,
  type: PropTypes.string,
  dynamicData: PropTypes.array,
  isCurrentTenureResult: PropTypes.bool,
  currentTenureResult: PropTypes.array,
};
export default JsonResultComp;
