import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { dashboardService } from '../../service/dashboard.service';
import { color_code } from '../../config/constants';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const TeamMemberListComp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    getUsersOnDashboard();
  }, []);

  const getUsersOnDashboard = () => {
    setIsLoading(true);
    // userService
    //   .getAll()
    dashboardService
      .getUsersList()
      .then((res) => {
        let data = res.data;
        data &&
          data.length > 0 &&
          data.map((item, index) => {
            color_code.map((obj, i) => {
              if (index == i) {
                item.color = obj;
              }
            });
          });
        setTeamList(data);
      })
      .catch((err) => {
        console.log('err', err);
        setIsLoading(false);
        // return false;
      });
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };

  const initialNameRender = (fName, lName) => {
    return fName.charAt(0).toUpperCase() + lName.charAt(0).toUpperCase();
  };

  return (
    <div>
      <Card
        bodyStyle={{ padding: 15 }}
        className="dashbordCards"
        style={{ height: '200px', marginTop: '10px' }}>
        <div className="cardTitle">Team</div>
        <Carousel
          className="teamMemberCarousel"
          responsive={responsive}
          centerMode={true}
          centerSlidePercentage={
            teamList.length > 3 ? 20 : teamList.length == 1 ? 100 : 33.33
          }
          dots={false}
          infiniteLoop={
            teamList.length == 1 || teamList.length == 2 ? false : true
          }>
          {/* {teamList.length > 0 && teamList.length == 2 && (
            <div>
              <div
                style={{
                  background: 'transparent',
                }}></div>
              <div className="teamList-user-name wrap-text"></div>
              <div className="user-email-id wrap-text"></div>
            </div>
          )} */}
          {teamList.length > 0 &&
            teamList.map((item, i) => {
              return (
                <div key={i + 1}>
                  <div
                    className="slick-slide-div"
                    style={{ background: item.color ? item.color : '#4d9aab' }}>
                    <p>{initialNameRender(item.firstName, item.lastName)}</p>
                  </div>
                  <div
                    className="center-active"
                    style={{
                      margin:
                        teamList.length > 3
                          ? '10px -35px auto'
                          : teamList.length == 1
                          ? '10px auto'
                          : '10px -12px auto',
                    }}>
                    <Tooltip
                      title={
                        <span className="gray">{`${item.firstName} ${item.lastName}`}</span>
                      }
                      color={'white'}>
                      <div className="teamList-user-name wrap-text">
                        {item.firstName} {item.lastName}
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={<span className="gray">{item.email}</span>}
                      color={'white'}>
                      <div className="user-email-id wrap-text">
                        {item.email}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </Carousel>
        <div className="total-team-member">
          <div className="total-user">{teamList.length}</div>
          <div className="team-label">Team member</div>
        </div>
      </Card>
    </div>
  );
};
TeamMemberListComp.propTypes = {};

export default TeamMemberListComp;
