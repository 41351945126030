import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ReadMappingContext from './ReadMappingContext';
import ReadFieldMappingComp from './ReadFieldMappingComp';
import CustomData from './customData.json';
import { fieldMappingArray } from '../../../../../../config/constants';
import { AuthContext } from '../../../../../../shared/auth/authContext';

const ReadMappingLayer = () => {
  const { userPermissions } = useContext(AuthContext);

  // API call to get header fields from marketo
  const [headerDataArray, setHeaderDataArray] = useState([]);
  // field that give to user for mapping
  const [fieldMappingList, setFieldMappingList] = useState([]);

  // for showing status of whole block
  const [matchBackBlock, setMatchBackBlock] = useState([
    { name: 'companyMatchback', status: false },
    { name: 'contactMatchback', status: false },
  ]);

  /* for checking companyMatchback block is valied or not
     e.g all false menas block is not valid atleast one elements should be true
  */
  const [companyMatchback, setCompanyMatchback] = useState([
    { name: 'Email', fieldMap: 'Email ID', value: false },
    { name: 'Company Name', fieldMap: 'Company Name', value: false },
    { name: 'Website', fieldMap: 'Website', value: false },
  ]);

  /* for checking contactMatchback block is valied or not
      e.g all false menas block is not valid atleast one elements should be true
  */
  const [contactMatchback, setContactMatchback] = useState([
    {
      name: 'Email',
      fieldMap: 'Email ID',
      value: false,
    },
    {
      name: 'Contact Full Name',
      fieldMap: 'Full Name',
      value: false,
    },
    {
      name: 'Contact First Name and Last Name',
      fieldMap: [
        { fieldMapName: 'First Name', value: false },
        { fieldMapName: 'Last Name', value: false },
      ],
      value: false,
    },
  ]);

  // default Icon of CompanyMatchback state
  const [
    defaultIconCompanyMatchback,
    setDefaultIconCompanyMatchback,
  ] = useState(true);

  // default Icon of ContactMatchback state
  const [
    defaultIconContactMatchback,
    setDefaultIconContactMatchback,
  ] = useState(true);

  // is button disable or not
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useLayoutEffect(() => {
    // API call for static file  header List
    const result = CustomData.map((item, i) => {
      let obj = {
        key: i,
        value: { displayName: item.displayName, name: item.name },
      };
      return obj;
    });
    setHeaderDataArray(result);
  }, []);

  useEffect(() => {
    setPermissionBaseValue();
  }, []);

  //when component render according to permission we get fieldmapping array
  const setPermissionBaseValue = () => {
    //to get mapping fields array
    const MappingFields = fieldMappingArray.filter((fieldMappingItem) => {
      return userPermissions().some(
        (permissionItem) =>
          permissionItem.toLowerCase().trim() ===
          fieldMappingItem.name.toLowerCase().trim()
      );
    });

    //to reset mapping field value
    MappingFields.forEach((item) => {
      if (item.position !== null) {
        item.position = null;
        item.mappedBy = null;
      }
    });

    //set fieldmap array
    /*
      To call DGOD for read mapping and if we get read mapping then we set in MappingFields
      O.W leave as it is. 
    */
    setFieldMappingList(getReadMapping(MappingFields));

    feildValidation(getReadMapping(MappingFields));
  };

  // set read mapping : Its temp for demo ( call DGOD for read mapping and if we get data then mapped)
  const getReadMapping = (MappingFields) => {
    /**
     *  for marketo 
      {
        name: 'Record ID',
        position: null, : name
        mappedBy: null,	: displayName
        apiFieldName: 'recordId',
      }
    */
    return MappingFields.map((item) => {
      if (item.name == 'Record ID') {
        item.position = 'firstName';
        item.mappedBy = 'Record ID';
      }
      if (item.name == 'First Name') {
        item.position = 'firstName';
        item.mappedBy = 'First Name';
      }
      if (item.name == 'Middle Name') {
        item.position = 'firstName';
        item.mappedBy = 'Middle Name';
      }
      if (item.name == 'Last Name') {
        item.position = 'firstName';
        item.mappedBy = 'Last Name';
      }
      if (item.name == 'Full Name') {
        item.position = 'firstName';
        item.mappedBy = 'Full Name';
      }
      if (item.name == 'Email ID') {
        item.position = 'firstName';
        item.mappedBy = 'Email ID';
      }
      if (item.name == 'Job Title') {
        item.position = 'email';
        item.mappedBy = 'Job Title';
      }
      if (item.name == 'Company Name') {
        item.position = 'marketoCompCountry';
        item.mappedBy = 'Company Name';
      }
      if (item.name == 'Country') {
        item.position = 'facebookId';
        item.mappedBy = 'Country';
      }
      if (item.name == 'Website') {
        item.position = 'facebookId';
        item.mappedBy = 'Website';
      }

      return item;
    });
  };

  //field validation of companyMatchback and contactMatchback
  const feildValidation = (fieldMappingList) => {
    fieldMappingList.forEach((fieldValueItems) => {
      //set companyMatchback value
      let CompanyMB = companyMatchback.map((companyMatchbackItem) => {
        if (
          fieldValueItems.name.toLowerCase().trim() ===
          companyMatchbackItem.fieldMap.toLowerCase().trim()
        ) {
          if (fieldValueItems.position === null) {
            companyMatchbackItem.value = false;
          } else {
            companyMatchbackItem.value = true;
            setDefaultIconCompanyMatchback(true);
          }
        }
        return companyMatchbackItem;
      });
      if (CompanyMB !== undefined) {
        setCompanyMatchback(CompanyMB);
      }

      //set contactMatchback value
      let ContactMB = contactMatchback.map((contactMatchbackItem) => {
        if (Array.isArray(contactMatchbackItem.fieldMap)) {
          contactMatchbackItem.fieldMap.forEach((fieldMap) => {
            if (
              fieldValueItems.name.toLowerCase().trim() ===
              fieldMap.fieldMapName.toLowerCase().trim()
            ) {
              if (fieldValueItems.position === null) {
                fieldMap.value = false;
              } else {
                fieldMap.value = true;
              }
            }
          });
        } else {
          if (
            fieldValueItems.name.toLowerCase().trim() ===
            contactMatchbackItem.fieldMap.toLowerCase().trim()
          ) {
            if (fieldValueItems.position === null) {
              contactMatchbackItem.value = false;
            } else {
              contactMatchbackItem.value = true;
              setDefaultIconContactMatchback(true);
            }
          }
        }
        return contactMatchbackItem;
      });
      if (ContactMB !== undefined) {
        setContactMatchback(ContactMB);
      }
    });
    // If first name and last name is set
    if (
      contactMatchback.filter((contactMatchbackItem) => {
        if (Array.isArray(contactMatchbackItem.fieldMap)) {
          return contactMatchbackItem.fieldMap.every((fieldMap) => {
            return fieldMap.value === true;
          });
        }
      }).length !== 0
    ) {
      contactMatchback.forEach((contactMatchbackItem) => {
        if (contactMatchbackItem.name === 'Contact First Name and Last Name') {
          contactMatchbackItem.value = true;
          setDefaultIconContactMatchback(true);
        }
      });
    } else {
      contactMatchback.forEach((contactMatchbackItem) => {
        if (contactMatchbackItem.name === 'Contact First Name and Last Name') {
          contactMatchbackItem.value = false;
        }
      });
    }

    checkFieldBlockMap();
  };

  // to check hole block is true/false
  const checkFieldBlockMap = () => {
    let companyMB = [];
    let contactMB = [];
    companyMB = companyMatchback.filter((x) => x.value == true);
    contactMB = contactMatchback.filter((x) => x.value == true);

    if (companyMB.length > 0) {
      matchBackBlock[0].status = true;
      setMatchBackBlock(matchBackBlock);
    } else {
      matchBackBlock[0].status = null;
      setMatchBackBlock(matchBackBlock);
    }
    if (contactMB.length > 0) {
      matchBackBlock[1].status = true;
      setMatchBackBlock(matchBackBlock);
    } else {
      matchBackBlock[1].status = null;
      setMatchBackBlock(matchBackBlock);
    }
    checkDefaultValue();
  };

  // from this we decied the button is enable or not
  const checkDefaultValue = () => {
    let comMB = matchBackBlock[0].status;
    let conMB = matchBackBlock[1].status;

    if (comMB && conMB) {
      // console.log('button enable');
      setIsButtonDisable(false);
    } else {
      // console.log('button disable');
      setIsButtonDisable(true);
    }
  };

  // to set filed mapping array when some one select
  const handleSelect = (selectedItem, fieldName) => {
    fieldMappingList.forEach((item) => {
      if (item.name.toLowerCase().trim() === fieldName.toLowerCase().trim()) {
        item.position = selectedItem ? selectedItem[1] : null;
        item.mappedBy = selectedItem ? selectedItem[0] : null;
        console.log('set', item);
      }
    });
    feildValidation(fieldMappingList);
  };

  /*
    filter header data for given particular elements.
    i.e in header data contains all header value which is not mapped, mapped with current elements
     but remove value which is mapped with other fields. 
  */
  const getFilterHeaderDataValue = (fieldName) => {
    let mappingData = headerDataArray.filter((item) =>
      checkNotMapped(item, fieldName)
    );

    return mappingData;
  };

  /*
    Check current header value is mapped with for given particular elements.
    check other header value is mapped or not 
    if mapped with other fields then return false 
    if mapped with given particular elements then return true
    if not mapped then return true
  */
  function checkNotMapped(item, fieldName) {
    let fieldBelongsToDropDown = false;
    let fieldIsNotMapped = false;
    let isMappedthenDisgradFlag = false;

    fieldMappingList.length > 0 &&
      fieldMappingList.forEach((element) => {
        let fieldBelongsToCurrentDropDown =
          element.mappedBy === item.value.displayName &&
          element.name === fieldName;
        if (fieldBelongsToCurrentDropDown) {
          fieldBelongsToDropDown = true;
        }

        let isMappedthenDisgrad = element.mappedBy === item.value.displayName;
        if (isMappedthenDisgrad) {
          isMappedthenDisgradFlag = true;
        }
        let notmappedFeilds = element.mappedBy !== item.value.displayName;
        if (notmappedFeilds) {
          fieldIsNotMapped = true;
        }
      });
    if (fieldBelongsToDropDown) {
      return true;
    } else if (isMappedthenDisgradFlag) {
      return false;
    } else if (fieldIsNotMapped) {
      return true;
    }
    return false;
  }

  // filter fieldMappingList array which is mapped
  const mappedFields = fieldMappingList.filter((item) => {
    return item.position !== null;
  });

  // for indivisual field check it is mapped or not
  const showSelectedFieldsStatus = (fieldName) => {
    const selectedOption = mappedFields.find((item) => item.name == fieldName);
    return selectedOption !== undefined ? (
      <span
        className="feildsLableStatus"
        style={{ border: '1.5px solid #1ADE80' }}
      />
    ) : (
      <span className="feildsLableStatus" style={{ background: '#bbb' }} />
    );
  };

  const props = {
    headerDataArray,
    defaultIconCompanyMatchback,
    defaultIconContactMatchback,
    showSelectedFieldsStatus,
    matchBackBlock,
    mappedFields,
    getFilterHeaderDataValue,
    handleSelect,
    isButtonDisable,
  };

  return (
    <>
      <ReadMappingContext.Provider value={props}>
        <ReadFieldMappingComp />
      </ReadMappingContext.Provider>
    </>
  );
};

ReadMappingLayer.propTypes = {};

export default ReadMappingLayer;
