import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  message,
  InputNumber,
  Popconfirm,
  Spin,
} from 'antd';
import { SyncOutlined, LoadingOutlined, UndoOutlined } from '@ant-design/icons';
import { creditService } from '../../../service/credit.service';
import { numberWithCommas } from '../../../config/constantMethod';
import { ShowOopsMsg } from '../../../common/messageComp';

const AddCreditTab = ({ creditScore, onCancel, clientId, loadClient }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [creditsAvailableScore, setCreditsAvailableScore] = useState(0);

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    setCreditsAvailableScore(creditScore);
  }, []);

  const onAddedCredit = (values) => {
    form.resetFields();
    creditService
      .addedCredit(clientId, values.credit)
      .then((response) => {
        if (response.status === 200) {
          setCreditsAvailableScore(response.data.creditsAvailable);
          loadClient(clientId);
          setIsSpinLoading(false);
          message.destroy();
          message.success('Credits Added Successfully');
        } else {
          message.destroy();
          ShowOopsMsg();

          setCreditsAvailableScore(null);
        }
        setCreditsAvailable(0);
      })
      .catch((err) => {
        setIsSpinLoading(false);
        message.destroy();
        console.log('error in getCreditsBalance', err.response.status);
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  const onConfirm = () => {
    setIsSpinLoading(true);
    form
      .validateFields()
      .then((values) => {
        onAddedCredit(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  function cancel(e) {
    console.log(e);
  }
  const onRefreshCreditAvailable = () => {
    setIsLoading(true);
    creditService
      .getClientCreditBalance(clientId)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setCreditsAvailableScore(response.data.creditsAvailable);
          message.destroy();
          message.success('Credits updated');
        } else {
          message.destroy();
          ShowOopsMsg();

          setCreditsAvailableScore(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  return (
    <div className="addCreditTab">
      {isSpinLoading ? (
        <Spin tip="Loading..." className="spinLoader" />
      ) : (
        <>
          <div style={{ float: 'right', display: 'flex' }}>
            <div>
              <div className="creditAvailablesTitle">Credits Available</div>
              <div className="creditAvailables" style={{ marginRight: '25px' }}>
                {' '}
                {creditsAvailableScore !== null
                  ? numberWithCommas(creditsAvailableScore)
                  : '---'}
                <Tooltip title="Refresh Credits">
                  <span
                    className="creditAvailablesTitle refreshIcon"
                    onClick={onRefreshCreditAvailable}>
                    <SyncOutlined spin={isLoading ? true : false} />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div style={{ height: '150px', padding: '10px' }}>
            <Form
              form={form}
              layout="horizontal"
              name="form_in_modal"
              validateTrigger="onBlur">
              <Row gutter={16}>
                <Col span={15}>
                  <Form.Item
                    name="credit"
                    label="Credit"
                    rules={[
                      {
                        required: false,
                        message: 'Enter credits',
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (value) {
                            if (!value.match(/^(\d*)(\d\.\d{1,2})?$/)) {
                              cb(
                                'Enter a positive numeric value with two decimal'
                              );
                            }
                          }
                          cb();
                        },
                      },
                    ]}>
                    <Input
                      // className="creditInput"
                      placeholder="Enter Credit"
                      style={{ width: '100%' }}
                      onChange={(val) => setCreditsAvailable(val.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <div className="editClientFooter">
                  <Button type="default" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Popconfirm
                    title="Are you sure to add this credits?"
                    onConfirm={onConfirm}
                    onCancel={cancel}
                    okText="Yes"
                    disabled={creditsAvailable > 0 ? false : true}
                    cancelText="No">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: '5px' }}
                      disabled={creditsAvailable > 0 ? false : true}>
                      Add
                    </Button>
                  </Popconfirm>
                </div>
              </Row>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

AddCreditTab.propTypes = {
  creditScore: PropTypes.string,
  onCancel: PropTypes.func,
  loadClient: PropTypes.func,
  clientId: PropTypes.string,
};

export default AddCreditTab;
