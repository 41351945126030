import React from 'react';
import { Table, Button, Popover, Dropdown, Typography, Menu } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../shared/auth/authContext';
import resetPassDisable from '../../../assets/images/resetPassDisable.png';
import PagenationComp from '../../../shared/PaginationComp';
import { AnimationOnce } from '../../../common/animatedIcon';
const { Link, Text } = Typography;

const UserTable = (props) => {
  const { isAllowed, isUserInRole } = React.useContext(AuthContext);

  //user table
  const columns = [
    {
      title: '',
      key: 'status',
      dataIndex: 'userStatus',
      // eslint-disable-next-line react/display-name
      render: (text) => (
        <span
          style={{
            fontSize: '20px',
            margin: '-10px',
            borderLeft: `6px solid
           ${
             text === 'ACTIVE'
               ? 'var(--success)'
               : text === 'DEACTIVATED'
               ? 'var(--danger)'
               : 'var(--warning)'
           }
            `,
            borderRadius: '5px',
          }}></span>
      ),
    },
    //Name of user
    {
      key: 'name',
      title: 'Name',
      width: '20%',
      ellipsis: true,
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const nameparts = [record.firstName, record.lastName];
        const profileName =
          nameparts[0].charAt(0).toUpperCase() +
          nameparts[1].charAt(0).toUpperCase();
        return (
          <>
            &nbsp;
            <Avatar className="userProfileImage">{profileName}</Avatar>
            &nbsp;&nbsp;
            <Link
              className="user-name"
              onClick={(e) => openUserDetails(record.id)}>
              {record.firstName} {record.lastName}
            </Link>
          </>
        );
      },
    },
    //clientname
    {
      key: 'clientName',
      title: 'Org Name',
      dataIndex: 'clientName',
      ellipsis: true,
      width: isUserInRole('Super Admin') ? '15%' : null,
    },
    //Username
    {
      key: 'username',
      title: 'Username',
      dataIndex: 'userName',
      ellipsis: true,
      sorter: true,
      width: '20%',
    },
    // Email ID
    {
      key: 'email',
      title: 'Email ID',
      dataIndex: 'email',
      ellipsis: true,
      sorter: true,
      width: '20%',
    },
    // Status
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'userStatus',
      width: '13%',
      ellipsis: true,
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        // return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        if (text === 'ACTIVE') {
          return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        } else if (text === 'DEACTIVATED') {
          return <span>Deactivated</span>;
        } else {
          return <span>Activation pending</span>;
        }
      },
    },
    // Role
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'clientRoleName',
      width: '22%',
      ellipsis: true,
      // sorter: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        if (text === undefined) {
          return 'No Role';
        } else {
          return text;
        }
      },
    },
    // Actions
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      ellipsis: true,
      width: '5%',
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const content = (
          <Menu className="user-list-action-menu">
            {/* eslint-disable react/prop-types */}
            {isAllowed('EDIT_USER') && props.update ? (
              <Menu.Item
                onClick={(e) => openEditForm(record.id)}
                className="userEditIcon">
                <span className="linkIcon" style={{ paddingRight: '5px' }}>
                  {AnimationOnce(
                    'icons/245-edit-document-outline-edited.json',
                    'li'
                  )}
                </span>
                Edit
              </Menu.Item>
            ) : null}
            {isAllowed('RESET_PASSWORD') &&
            record.userStatus !== 'DEACTIVATED' ? (
              <Menu.Item
                onClick={(e) => sendMailToUser(record.userName)}
                className="userResetIcon">
                <span className="linkIcon" style={{ paddingRight: '5px' }}>
                  {AnimationOnce('icons/213-arrow-2-rounded-edited.json', 'li')}
                </span>
                Reset Password
              </Menu.Item>
            ) : (
              <Menu.Item>
                <span
                  className="linkIconDisabled"
                  style={{ paddingRight: '5px' }}>
                  <img src={resetPassDisable} width="15px" />
                </span>
                <Text disabled>Reset Password</Text>
              </Menu.Item>
            )}
          </Menu>
        );
        return (
          <Dropdown
            overlay={content}
            // placement="bottomCenter"
            overlayClassName="userListDropDownMenu">
            <span className="threedotContainer">
              <div className="threeDotsUser"></div>
              <div className="threeDotsUser"></div>
              <div className="threeDotsUser"></div>
            </span>
          </Dropdown>
        );
      },
    },
  ];

  //open edit form with id
  const openEditForm = (id) => {
    props.showEditForm(id);
  };

  //get user username to send mail
  const sendMailToUser = (userName) => {
    props.sendMail(userName);
  };

  //open user details with id
  const openUserDetails = (id) => {
    props.showUserdetails(id);
  };

  //pagination Use text link for prev and next button.
  const itemRenders = (current, type, originalElement) => {
    // console.log('itemRenders current', current);
    // console.log('itemRenders type', type);
    // console.log('itemRenders originalElement', originalElement);
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  return (
    <>
      <Table
        className="userListTable"
        rowKey="id"
        dataSource={props.users.data.content}
        columns={columns}
        onChange={props.onChange}
        // bordered
        size="medium"
        showHeader={true}
        pagination={false}
        // pagination={{
        //   position: ['bottomCenter'],
        //   total: props.users.data.totalElements,
        //   current: props.page + 1,
        //   showSizeChanger: false,
        //   defaultPageSize: 20,
        //   itemRender: itemRenders,
        //   onChange: props.onPaginationClick,
        // }}
      />
      <PagenationComp
        onChange={props.onPaginationClick}
        defaultPageSize={20}
        current={props.page + 1}
        totalTaskCount={props.users.data.totalElements}
        totalElementsOnPage={props.users.data.numberOfElements}
      />
    </>
  );
};

UserTable.propTypes = {
  showUserdetails: PropTypes.func,
  sendMail: PropTypes.func,
  showEditForm: PropTypes.func,
  update: PropTypes.bool,
  users: PropTypes.object,
  page: PropTypes.number,
  onPaginationClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default UserTable;
