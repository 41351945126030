import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Popover, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const TabularViewStats = ({ visible, onCancel, statsTableData, tableName }) => {
  //stats table data
  const [statsValue, setStatsValue] = useState([]);
  //stats drilldown data

  //stats description
  const statsDescription = [
    {
      recordStatus: 'Active',
      description: 'Person actively working with the provided input company.',
    },
    {
      recordStatus: 'No Longer',
      description: 'Person no longer working with the provided input company.',
    },
    {
      recordStatus: 'Company Match',
      description: 'Records where only company enrichment is provided.',
    },
    {
      recordStatus: 'No Match',
      description: 'Records that could NOT be enriched.',
    },
    {
      recordStatus: 'Possible Junk',
      description:
        'Records qualify for enrichment, but have at least one junk or generic value input.',
    },
    {
      recordStatus: 'Junk or Ineligible',

      description:
        "Records that don't have sufficient inputs to qualify for enrichment or provided values are all junk values. These records should be deleted from system.",
    },
    {
      recordStatus: 'Error',
      description:
        'Some internal error occurred. Need to re-submit the record for enrichment.',
    },
  ];
  //stats description column
  const statsDescriptionColumns = [
    //1st col Name
    {
      title: 'Record Status',
      dataIndex: 'recordStatus',
      key: 'recordStatus',
      width: '80px',
      // ellipsis: true,
    },
    //2nd col Name
    {
      title: 'Description',
      key: 'description',
      // ellipsis: true,
      render: (text) => {
        if (text.recordStatus === 'No Match') {
          return (
            <>
              {text.description}
              <br />
              {/* <b style={{ fontSize: '0.68vw' }}>
                Note – Company info could be delivered for these records, even
                if Person info could not be matched- tabular view
              </b> */}
            </>
          );
        } else {
          return <>{text.description}</>;
        }
      },
    },
  ];
  //stats description table
  const content = (
    <div>
      <Table
        rowKey="id"
        // bordered
        dataSource={statsDescription}
        size="small"
        columns={statsDescriptionColumns}
        showHeader={true}
        pagination={false}
        className="statsDescriptionTable"
      />
    </div>
  );

  //stats table column
  const columns = [
    //1st col Name
    {
      title: 'Record Status',
      key: 'name',
      width: '20vw',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return <span>{text.name}</span>;
      },
    },
    //2nd count
    {
      title: 'Count',
      key: 'count',
      align: 'right',
      width: '8vw',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        let count = text.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return <span>{count}</span>;
      },
    },
    //3rd percentage
    {
      title: 'Percentage',
      key: 'percentage',
      align: 'right',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <span>
            {Number.isInteger(text.percentage)
              ? `${text.percentage}.0`
              : text.percentage}
            %
          </span>
        );
      },
    },
  ];

  //stats table data filter
  useEffect(() => {
    const statsResult = statsTableData
      .filter((item) => item.count !== 0)
      .map((items) => {
        return items;
      });

    setStatsValue(statsResult);
  }, [statsTableData]);

  return (
    <Modal
      className="modalStatsTable"
      title={'Record Status of All Tasks'}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      width={'50%'}
      centered={true}
      bodyStyle={{
        maxHeight: 'calc(110vh - 150px)',
        overflow: 'auto',
        padding: '10px 20px',
      }}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
          }}>
          <div className="statsIconDiv">
            <Popover
              placement="right"
              content={content}
              trigger="click"
              overlayClassName="popoverContent">
              <InfoCircleOutlined className="statsIcon" />
            </Popover>
          </div>
        </div>
        <div style={{ padding: '5px 5px 15px 5px' }}>
          <Table
            dataSource={statsValue}
            bordered
            columns={columns}
            showHeader={true}
            pagination={false}
            className="recordStatsTable"
          />
        </div>
      </div>
    </Modal>
  );
};

TabularViewStats.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  statsLastUpdatedOn: PropTypes.string,
  statsTableData: PropTypes.array,
  tableName: PropTypes.string,
  taskName: PropTypes.string,
};

export default TabularViewStats;
