import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  DatePicker,
  Divider,
  Select,
  Button,
  Radio,
  Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  APP_SHORT_DATE_FORMAT,
  COMPETITOR_DOMAINS_LIST,
  GENERIC_DOMAINS_LIST,
} from '../../../config/constants';
import {
  CustomLabel,
  clientTypeInfoMsg,
  rateLimitInfoMsg,
  sfCreationInfoMsg,
  salesforceOrgIdInfoMsg,
  subscriptiontypeInfoMsg,
} from '../../../common/messageComp';

const { Option } = Select;

const ClientCreateForm = ({
  visible,
  onCreate,
  onCancel,
  isLoading,
  licenseList,
}) => {
  const [form] = Form.useForm();
  // const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  // const [showRateLimit, setShowRateLimit] = useState(false);
  // const [errorThrow, setErrorThrow] = useState(false);
  const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 14 },
  };
  const [minusValidation, setMinusValidation] = useState(1);
  const [licenseDetails, setLicenseDetails] = useState([
    {
      licenseCount: '',
      licenseTypeName: '',
    },
  ]);
  const [selectedLicense, setSelectedLicense] = useState([]);
  const [licenseTypeOpt, setLicenseTypeOpt] = useState([]);
  const [clientOpt, setClientOpt] = useState('No');
  const [subScriptionAvailable, setSubscriptionAvailable] = useState(
    'Enterprise Standard'
  );

  const countHandlerAdd = () => {
    var resultMinus = minusValidation + 1;
    setMinusValidation(resultMinus);
  };
  const countHandlerRemove = () => {
    var resultMinus = minusValidation - 1;
    setMinusValidation(resultMinus);
  };
  // const getSelectedOption = (item) => {
  //   // console.log(licenseDetails[item].licenseCount);
  //   const listr = [...licenseDetails];
  //   console.log(listr);
  //   const value = e.target.value;
  //   console.log(value);
  //   const name = e.target.name;
  //   console.log(name);
  //   listr[item][e.target.name] = e.target.value;
  //   return listr[item].licenseCount;
  // };
  // const handleServiceRemove = (index) => {
  //   const list = [...licenseDetails];
  //   list.splice(index, 1);
  //   setLicenseDetails(list);
  // };
  // const licenseCountHandler = (e, index) => {
  //   setValueCount(e.target.value);
  //   console.log(e);
  //   const listr = [...licenseDetails];
  //   console.log(listr);
  //   const value = e.target.value;
  //   console.log(value);
  //   const name = e.target.name;
  //   console.log(name);
  //   listr[index][e.target.name] = e.target.value;
  //   setLicenseDetails(listr);
  // };

  // const handlerlicensed = (value) => {
  //   console.log(value);
  //   selectedLicense.push(value);
  //   console.log(...new set(selectedLicense));
  //   const duplicateHandler = licenseTypeOpt.map((it) => it.licenseTypeName);
  //   let intersection = selectedLicense.filter((x) =>
  //     duplicateHandler.includes(x)
  //   );
  //   console.log(intersection);
  // };
  // console.log(licenseTypeOpt.map((it) => it.licenseTypeName));

  // const licenseTypeHandler = (e, index) => {
  //   console.log(e);
  //   console.log('index', index);
  //   const listr = [...licenseDetails];
  //   listr[index].licenseTypeName = e;
  //   setLicenseDetails(listr);
  // };

  const sfclientHandler = (value) => {
    setClientOpt(value);
  };

  const subscriptionTypeHandler = (e, value) => {
    setSubscriptionAvailable(e);
    console.log(e);
    console.log(value);
  };
  //when open form then clear all fields
  useEffect(() => {
    // console.log('first time render stage');
    form.resetFields();
    setLicenseTypeOpt(licenseList);

    return () => {
      // console.log('unmounting...stage');
      console.log(licenseList);
      form.resetFields();
      setLicenseTypeOpt(licenseList);
    };
  }, []);

  // Can not select days before today
  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf('day');

    // // You cannot choose today or later, you can only choose [Today ~ 90 days forward]. Today is optional.
    // let result = false;
    // if (current) {
    //   if (
    //     // current < moment().subtract(90, 'days') ||
    //     // current > moment().endOf('day')

    //     current > moment().add(90, 'days') ||
    //     current < moment().startOf('day')
    //   ) {
    //     result = true;
    //   } else {
    //     result = false;
    //   }
    // }
    // return result;
  };

  // const onSelectProduct = (type) => {
  //   console.log(type);
  //   if (type.length == 0) {
  //     setErrorThrow(true);
  //   } else {
  //     setErrorThrow(false);
  //   }

  //   let index = type.length > 0 ? type.findIndex((x) => x === 2) : -1;
  //   setShowRateLimit(index === -1 ? false : true);
  // };

  return (
    <Modal
      visible={visible}
      title="Create ORG"
      destroyOnClose={true}
      width={'70%'}
      className="client_create_container"
      centered
      maskClosable={false}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                values.selectedProduct = [1, 2];
                onCreate(values);
                // if (
                //   values.selectedProduct !== undefined &&
                //   values.selectedProduct.length > 0
                // ) {
                //   setErrorThrow(false);
                //   onCreate(values);
                // } else {
                //   setErrorThrow(true);
                // }
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
          loading={isLoading}>
          Save
        </Button>,
      ]}
      bodyStyle={{
        maxHeight: 'calc(100vh - 140px)',
        overflow: 'auto',
        padding: '20px',
      }}>
      <Form
        {...formItemLayout}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        validateTrigger="onBlur"
        colon={false}
        initialValues={{
          users: licenseDetails,
          directDialAccess:
            subScriptionAvailable == 'Enterprise Standard' ? 'No' : 'Yes',
          emailRefresherAccess: 'Yes',
          sfCreation: 'No',
          SubscriptionType: 'Enterprise Standard',
        }}
        className="clientForm">
        <Col style={{ padding: '0px 15px 0px 15px' }}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="clientName"
                label="Org Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Org Name',
                  },
                  {
                    min: 2,
                    message: 'Org Name atleast 2 characters',
                  },
                  // {
                  //   pattern: new RegExp(/^[A-Za-z ]*[A-Za-z][A-Za-z ]*$/),
                  //   message: 'Client Name must be alphabetic',
                  // },
                ]}>
                <Input placeholder="Org Name" maxLength="50" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={CustomLabel(
                  'Subscription Type',
                  subscriptiontypeInfoMsg
                )}
                name="SubscriptionType"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select placeholder="Select" onChange={subscriptionTypeHandler}>
                  <Select.Option value="Enterprise Standard">
                    Enterprise Standard
                  </Select.Option>
                  <Select.Option value="Enterprise Plus">
                    Enterprise Plus
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              name="billingEmail"
              label="Billing Email ID"
              rules={[
                {
                  type: 'email',
                  message: 'Invalid Email!',
                },
                // {
                //   required: true,
                //   message: 'Please input your username',
                // },
              ]}>
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email ID"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="billingAddress" label="Billing Address">
              <Input.TextArea
                placeholder="Billing Address"
                maxLength="45"
                rows="2"
              />
            </Form.Item>
          </Col>
        </Row> */}
          <Row>
            <Col span={12}>
              <Form.Item
                name="subscriptionStartDate"
                label="Subscription Start Date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value) {
                        if (getFieldValue('subscriptionEndDate')) {
                          let subscriptionEndDate = getFieldValue(
                            'subscriptionEndDate'
                          );
                          if (
                            moment(value.format('YYYY-MM-DD')).isAfter(
                              subscriptionEndDate.format('YYYY-MM-DD')
                            )
                          ) {
                            return Promise.reject(
                              'Start date should be smaller than End date'
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <DatePicker
                  format={APP_SHORT_DATE_FORMAT}
                  disabledDate={disabledDate}
                  style={{ width: '100%' }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="subscriptionEndDate"
                label="Subscription End Date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value) {
                        if (getFieldValue('subscriptionStartDate')) {
                          let subscriptionStartDate = getFieldValue(
                            'subscriptionStartDate'
                          );
                          if (
                            moment(value.format('YYYY-MM-DD')).isBefore(
                              subscriptionStartDate.format('YYYY-MM-DD')
                            )
                          ) {
                            return Promise.reject(
                              'End date should be greater than Start date'
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <DatePicker
                  format={APP_SHORT_DATE_FORMAT}
                  disabledDate={disabledDate}
                  style={{ width: '100%' }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="SmarteCRMID"
                label="SMARTe CRM ID"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input placeholder="SMARTe CRM ID" maxLength="50" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Divider orientation="left" className="divider_enrich">
          Enrich Org Detail
        </Divider>
        {/* <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Notification CC To">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <div>
                  <Form.Item
                    name="ccEmail"
                    noStyle
                    // label="Notification CC To"
                    rules={[
                      {
                        pattern: new RegExp(
                          /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*)+$/
                        ),
                        message: 'Enter valid email address',
                      },
                      // {
                      //   max: 255,
                      //   message: 'Maximum 255 characters allowed.',
                      // },
                      {
                        validator: (_, value) => {
                          if (value !== undefined) {
                            let isDuplicateFound = false;
                            let emailsArray = value.split(',');
                            let sorted_arr = emailsArray.slice().sort();
                            //check array contains duplicate email
                            for (let i = 0; i < sorted_arr.length - 1; i++) {
                              if (sorted_arr[i + 1] == sorted_arr[i]) {
                                isDuplicateFound = true;
                              }
                            }
                            if (isDuplicateFound)
                              return Promise.reject(
                                'Duplicate email is not allowed'
                              );
                            else return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}>
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email CC"
                      maxLength="255"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
                <div style={{ paddingLeft: '20px' }}>
                  <Tooltip
                    placement="bottom"
                    title={`All tasks related email notification will be default CC marked on this email address.`}>
                    <InfoCircleOutlined className="ccEmailIcon" />
                  </Tooltip>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="BCC Notification">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}>
                <div>
                  <Form.Item
                    name="bccEmail"
                    noStyle
                    rules={[
                      {
                        pattern: new RegExp(
                          /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*)+$/
                        ),
                        message: 'Enter valid email address',
                      },
                      // {
                      //   max: 255,
                      //   message: 'Maximum 255 characters allowed.',
                      // },
                      {
                        validator: (_, value) => {
                          if (value !== undefined) {
                            let isDuplicateFound = false;
                            let emailsArray = value.split(',');
                            let sorted_arr = emailsArray.slice().sort();
                            //check array contains duplicate email
                            for (let i = 0; i < sorted_arr.length - 1; i++) {
                              if (sorted_arr[i + 1] == sorted_arr[i]) {
                                isDuplicateFound = true;
                              }
                            }
                            if (isDuplicateFound)
                              return Promise.reject(
                                'Duplicate email is not allowed'
                              );
                            else return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}>
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email BCC"
                      maxLength="255"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
                <div style={{ paddingLeft: '20px' }}>
                  <Tooltip
                    placement="bottom"
                    title={`All tasks related email notification will be default BCC marked on this email address.`}>
                    <InfoCircleOutlined className="ccEmailIcon" />
                  </Tooltip>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row> */}
        <Col style={{ padding: '0px 15px 0px 15px' }}>
          <Row>
            {/* <Col span={12}>
            <span className="astrickSign">*</span>

            <Form.Item label="Products" name="selectedProduct">
              <Checkbox.Group
                options={productTypes}
                onChange={onSelectProduct}
              />
            </Form.Item>
            {errorThrow && (
              <span className="errorThrow" style={{ marginLeft: '160px' }}>
                Please Select Product
              </span>
            )}
          </Col> */}
            <Col span={12}>
              <Form.Item
                label={CustomLabel('Client Type', clientTypeInfoMsg)}
                name="clientType"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Client',
                  },
                ]}>
                <Radio.Group name="radiogroup">
                  <Radio value={'Premium'}>Internal</Radio>
                  <Radio value={'Regular'}>External</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={CustomLabel('Rate Limit', rateLimitInfoMsg)}
                name="rateLimit"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Rate Limit',
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value) {
                        if (
                          !value.match(
                            /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
                          )
                        ) {
                          cb('Enter a numeric value greater than zero');
                        } else if (value % 1 !== 0 || value.includes('.')) {
                          cb('Decimal value is not allowed');
                        } else if (value > 600) {
                          cb(' Rate limit cannot exceed 600 request per min.');
                        }
                      }
                      cb();
                    },
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Divider orientation="left" className="divider_enrich">
          Discover Org Detail
        </Divider>
        <Col style={{ padding: '0px 15px 0px 15px' }}>
          <Row>
            <Col span={12} className="dd-disabled-create">
              <Form.Item
                label="Direct Dial Access"
                name="directDialAccess"
                rules={[
                  {
                    required: false,
                    message: 'Please select option',
                  },
                ]}>
                <span>
                  {subScriptionAvailable == 'Enterprise Standard'
                    ? 'No'
                    : 'Yes'}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email Refresher Access"
                name="emailRefresherAccess"
                rules={[
                  {
                    required: false,
                    message: 'Please select option',
                  },
                ]}>
                <Select>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={CustomLabel('SF Creation', sfCreationInfoMsg)}
                name="sfCreation"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Select placeholder="Select" onChange={sfclientHandler}>
                  <Select.Option value="Yes">Yes</Select.Option>
                  <Select.Option value="No">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={CustomLabel('SalesForce Org ID', salesforceOrgIdInfoMsg)}
                name="salesforceOrgId"
                rules={[
                  {
                    //Based on the above sf creation dropdown value , salesforce org ID will be mandatory or not.
                    required: clientOpt == 'Yes' ? true : false,
                    message: 'Please Enter Org-ID',
                  },
                ]}>
                <Input placeholder="Org ID" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col style={{ padding: '0px 15px 0px 15px' }}>
          <Form.List name="users">
            {(licenseDetails, { add, remove }) => (
              <React.Fragment>
                {licenseDetails.map(({ key, name, index, ...restField }) => (
                  <Row
                    key={key}
                    style={{
                      marginBottom: 5,
                    }}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label="License Type"
                        name={[name, 'licenseTypeName']}
                        className="license_type_container"
                        placeholder="Select"
                        rules={[
                          {
                            required: true,
                            message: 'Missing license type',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              // 1. This validator works to check that the same licensetype dropdown have no duplicate value
                              //selected in when add new button clicked.
                              // 2. value contains the current selected value & licenseTypediff contains both current
                              // selected and earlier one .
                              // 3.getOccurence function check the current selected value is there in the licenseTypeddiff.
                              var licenseTypediff = getFieldValue(
                                'users'
                              ).map((it) =>
                                it != undefined ? it.licenseTypeName : ''
                              );
                              function getOccurrence(array, value) {
                                return array.filter((v) => v === value).length;
                              }
                              console.log(value);
                              console.log(licenseTypediff);
                              if (
                                licenseTypediff.includes(value) &&
                                value != '' &&
                                getOccurrence(licenseTypediff, value) > 1
                              ) {
                                return Promise.reject(
                                  'You have selected duplicate license type'
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <Select placeholder="Select">
                          {licenseTypeOpt.map((item, i) => {
                            return (
                              <Select.Option
                                key={item.id}
                                value={item.licenseTypeName}>
                                {item.licenseTypeName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label="License Count"
                        name={[name, 'licenseCount']}
                        className="license_count_container"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter license count',
                          },
                          {
                            validator: (rule, value, cb) => {
                              if (value) {
                                if (
                                  !value.match(
                                    /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
                                  )
                                ) {
                                  cb('Enter a numeric value');
                                } else if (
                                  value % 1 !== 0 ||
                                  value.includes('.')
                                ) {
                                  cb('Decimal value is not allowed');
                                }
                              }
                              cb();
                            },
                          },
                        ]}>
                        <Input
                          placeholder="License Count"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      {minusValidation >= 2 ? (
                        <MinusCircleOutlined
                          className="create-minus"
                          onClick={() => {
                            remove(name);
                            countHandlerRemove();
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <a
                    onClick={(e) => {
                      add();
                      countHandlerAdd();
                    }}
                    block
                    icon={<PlusOutlined />}>
                    + Add License
                  </a>
                </Form.Item>
              </React.Fragment>
            )}
          </Form.List>
        </Col>

        <Divider orientation="left" className="divider_enrich">
          CSM User Detail
        </Divider>
        <Col style={{ padding: '0px 15px 0px 15px' }}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="adminFirstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter First Name',
                  },
                  {
                    min: 2,
                    message: 'First Name atleast 2 characters',
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: 'First Name must be alphabetic',
                  },
                ]}>
                <Input placeholder="First Name" maxLength="50" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="adminLastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Last Name',
                  },
                  {
                    min: 2,
                    message: 'Last Name atleast 2 characters',
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: 'Last Name must be alphabetic',
                  },
                ]}>
                <Input placeholder="Last Name" maxLength="50" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="adminEmail"
                label="Email ID"
                rules={[
                  {
                    required: true,
                    message: 'Enter Email ID',
                  },
                  {
                    type: 'email',
                    message: 'Invalid Email!',
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value) {
                        console.log({ value });
                        if (
                          value.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          )
                        ) {
                          let splitingemail = value.split('@');
                          if (COMPETITOR_DOMAINS_LIST.includes(splitingemail)) {
                            cb(
                              'Non-compliant email found. Please provide a valid work email'
                            );
                          } else {
                            let splitDomain = splitingemail[1].split('.');
                            if (GENERIC_DOMAINS_LIST.includes(splitDomain[0])) {
                              cb('Please provide a valid work email');
                            }
                          }
                        } else {
                          cb('Please provide valid input');
                        }
                        cb();
                      } else {
                        cb();
                      }
                    },
                  },
                ]}>
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email ID"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="adminUsername"
                label="User Name"
                rules={[
                  {
                    required: true,
                    message: 'Enter User Name',
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value) {
                        console.log({ value });
                        if (
                          value.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          )
                        ) {
                          let splitingemail = value.split('@');
                          if (COMPETITOR_DOMAINS_LIST.includes(splitingemail)) {
                            cb(
                              'Non-compliant email found. Please provide a valid work email'
                            );
                          } else {
                            let splitDomain = splitingemail[1].split('.');
                            if (GENERIC_DOMAINS_LIST.includes(splitDomain[0])) {
                              cb('Please provide a valid work email');
                            }
                          }
                        } else {
                          cb('Please provide valid user name');
                        }
                        cb();
                      } else {
                        cb();
                      }
                    },
                  },
                ]}>
                <Input placeholder="User Name" maxLength="50" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </Modal>
  );
};

ClientCreateForm.propTypes = {
  visible: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  licenseList: PropTypes.array,
};

export default ClientCreateForm;
