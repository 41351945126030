import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import WelcomeMsg from '../assets/images/welcomeMsg.png';
import TaskSubmitMsg from '../assets/images/taskSubmitMsg.png';
import SWRErrMsg from '../assets/images/swrErrMsg.png';
import OopsErrMsg from '../assets/images/OopErrMsg.png';
import './common.css';
import { message, Popover } from 'antd';
import infoicon from '../assets/images/info-enrich-icon.png';

const onClose = () => {
  message.destroy();
};
export const msgConfig = (comp, duration) => ({
  icon: (
    <CloseOutlined
      onClick={onClose}
      style={{ float: 'right', color: '#7a7a7a' }}
    />
  ),
  className: 'successMsg',
  content: comp,
  onClose: onClose,
  duration: duration ? duration : 5,
});

export const ShowOopsMsg = () => message.error(msgConfig(oopsErrorMessage()));

export const welcomeMessage = (name) => (
  <div style={{ width: '356px', height: '135px', padding: '10px' }}>
    <div>
      <img src={WelcomeMsg} style={{ width: '70px', marginBottom: '10px' }} />
    </div>
    <div className="msgText">Welcome back, {name}!</div>
  </div>
);

export const taskSubmitMessage = () => (
  <div style={{ width: '356px', height: '130px', padding: '10px' }}>
    <div>
      <img
        src={TaskSubmitMsg}
        style={{ width: '80px', marginBottom: '10px' }}
      />
    </div>
    <div className="msgText">Sit back and relax while we enrich your Data.</div>
  </div>
);

export const swrErrorMessage = () => (
  <div style={{ width: '356px', height: '130px', padding: '10px' }}>
    <div>
      <img src={SWRErrMsg} style={{ width: '80px', marginBottom: '10px' }} />
    </div>
    <div className="msgText">Some warning message here !</div>
  </div>
);

export const oopsErrorMessage = () => (
  <div style={{ width: '356px', height: '130px', padding: '10px' }}>
    <div>
      <img src={OopsErrMsg} style={{ width: '80px', marginBottom: '10px' }} />
    </div>
    <div className="msgText">Oops! An error occurred.</div>
  </div>
);

export const errorMessage = (msg) => (
  <div style={{ width: '356px', height: '130px', padding: '10px' }}>
    <div>
      <img src={OopsErrMsg} style={{ width: '80px', marginBottom: '10px' }} />
    </div>
    <div className="msgText">{msg}</div>
  </div>
);

export const clientTypeInfoMsg = () => {
  return (
    <div className="content-popover">
      {' '}
      <span className="boldFont">Internal :</span> client will have additional
      features such as enrich modes where user can select multiple options while
      submitting the task. We used this type of client for Internal purpose due
      to multiple use cases such as marketing download, testing and analysis
      purposes.
      <br />
      <span className="boldFont">External :</span> client will not have this
      additional feature and used for creating External Client.
    </div>
  );
};

export const rateLimitInfoMsg = () => {
  return (
    <div className="content-popover">
      {' '}
      Rate limiting is implemented to prevent abuse and ensure the optimal
      performance of the service. For each client, an ideal rate limit of 50
      requests per minute is recommended
    </div>
  );
};

export const sfCreationInfoMsg = () => {
  return (
    <div className="content-popover">
      {' '}
      Yes to create base org and No to create nobase org
    </div>
  );
};

export const salesforceOrgIdInfoMsg = () => {
  return (
    <div className="content-popover">
      {' '}
      When creating base org salesforce org id is mandatory field which is
      present in salesforce app
    </div>
  );
};

export const CustomLabel = (labelData, popoverData) => (
  <div>
    <label>
      {labelData}
      <Popover
        placement="bottomLeft"
        content={popoverData}
        overlayClassName="content-popover-modal-dd">
        <img src={infoicon} className="info-icon" />
      </Popover>
    </label>
  </div>
);

export const labelWithColen = (labelData) => (
  <div>
    <label>{labelData} :</label>
  </div>
);

export const subscriptiontypeInfoMsg = () => {
  return (
    <div className="content-popover">
      <div>
        <span style={{ color: 'black', fontWeight: 'bold' }}>
          Enterprise Standard &nbsp;
        </span>
        : Provides access to our services but without mobile numbers in the
        output.
      </div>
      <div>
        <span style={{ color: 'black', fontWeight: 'bold' }}>
          Enterprise Plus &nbsp;
        </span>
        : Provides access to our services with mobile numbers in the output.
      </div>
    </div>
  );
};

// export const subscriptiontypedashboardmsg = () => {
//   return (
//     <div className="content-popover">
//       {' '}
//       <span className="boldFont">Enterprise Standard - </span>
//       “Without mobile number”
//       <br />
//       <span className="boldFont">Enterprise Plus - </span> “With mobile number”
//       <br />
//       <span className="boldFont">Pro - </span>
//       “Monthly recurring plan - With mobile number“
//     </div>
//   );
// };
