import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Popover, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { record_status } from '../../../config/constants';
import { sortFunByField } from '../../../config/constantMethod';
import { APP_STANDARD_DATE_FORAMT } from '../../../config/constants';
import InternalRecordStatusComp from './InternalRecordStatusComp';

const StatsTable = ({
  visible,
  onCancel,
  statsName,
  statsLastUpdatedOn,
  statsTableData,
  statsDrillDownData,
  tableName,
  taskName,
  data,
}) => {
  //stats table data
  const [statsValue, setStatsValue] = useState([]);
  //stats drilldown data
  const [statsDillDownValue, setStatsDillDownValue] = useState([]);

  //stats description
  const statsDescription = [
    {
      recordStatus: 'Active',
      description: 'Person actively working with the provided input company.',
    },
    {
      recordStatus: 'No Longer',
      description: 'Person no longer working with the provided input company.',
    },
    {
      recordStatus: 'Company Match',
      description: 'Records where only company enrichment is provided.',
    },
    {
      recordStatus: 'No Match',
      description: 'Records that could NOT be enriched.',
    },
    {
      recordStatus: 'Possible Junk',
      description:
        'Records qualify for enrichment, but have at least one junk or generic value input.',
    },
    {
      recordStatus: 'Junk or Ineligible',

      description:
        "Records that don't have sufficient inputs to qualify for enrichment or provided values are all junk values. These records should be deleted from system.",
    },
    {
      recordStatus: 'Error',
      description:
        'Some internal error occurred. Need to re-submit the record for enrichment.',
    },
  ];
  //stats description column
  const statsDescriptionColumns = [
    //1st col Name
    {
      title: 'Record Status',
      dataIndex: 'recordStatus',
      key: 'recordStatus',
      width: '80px',
      // ellipsis: true,
    },
    //2nd col Name
    {
      title: 'Description',
      key: 'description',
      // ellipsis: true,
      render: (text) => {
        if (text.recordStatus === 'No Match') {
          return (
            <>
              {text.description}
              <br />
              {/* <b style={{ fontSize: '0.68vw' }}>
                Note – Company info could be delivered for these records, even
                if Person info could not be matched.
              </b> */}
            </>
          );
        } else {
          return <>{text.description}</>;
        }
      },
    },
  ];
  //stats description table
  const content = (
    <div>
      <Table
        rowKey="id"
        // bordered
        dataSource={statsDescription}
        size="small"
        columns={statsDescriptionColumns}
        showHeader={true}
        pagination={false}
        className="statsDescriptionTable"
      />
    </div>
  );

  //stats table column
  const columns = [
    //1st col Name
    {
      title: tableName,
      key: 'name',
      width: '20vw',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return <span>{text.name}</span>;
      },
    },
    //2nd count
    {
      title: 'Count',
      key: 'count',
      align: 'right',
      width: '8vw',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        let count = text.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return <span>{count}</span>;
      },
    },
    //3rd percentage
    {
      title: 'Percentage',
      // dataIndex: 'percentage',
      key: 'percentage',
      align: 'right',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <span>
            {Number.isInteger(text.percentage)
              ? `${text.percentage}.0`
              : text.percentage}
            %
          </span>
        );
      },
    },
  ];

  //stats table data filter
  useEffect(() => {
    // console.log(statsTableData);
    const statsResult = statsTableData
      .filter((item) => item.count !== 0)
      .map((items) => {
        return items;
      });

    const statsDillResult = statsDrillDownData.filter((item) => item.name);
    // console.log(statsDillResult);
    setStatsValue(statsResult);
    setStatsDillDownValue(statsDillResult);
  }, [statsTableData, statsDrillDownData]);

  const modalTitle = (
    <div className="statsTitle">
      <div className="titleInBody">{statsName}</div>
      {/* Task Name <span className="stats-reportId"> &nbsp;{taskName}</span> */}
    </div>
  );

  //table expand data is filter
  const expandedRowRender = (row) => {
    // console.log(statsValue);
    // console.log(row);
    const tableData = statsDillDownValue
      .filter((item) => item.name === row.name)
      .map((item) => {
        if (
          item.name !== 'Unavailable' &&
          item.name !== 'Enriched' &&
          item.name !== 'Non-Enriched'
        ) {
          let data =
            item.data !== null &&
            item.data.length > 0 &&
            item.data.sort((a, b) => {
              return b.y - a.y;
            });
          return data;
        } else {
          let data = sortFunByField(item.data, record_status);
          return data;
        }
      });

    //stats table drill down column
    const drillDownColumns = [
      //1st col Name
      {
        key: 'name',
        width: '20vw',
        ellipsis: true,
        // eslint-disable-next-line react/display-name
        render: (text) => {
          return <span>{text.name}</span>;
        },
      },
      //2nd count
      {
        key: 'count',
        align: 'right',
        width: '8vw',
        ellipsis: true,
        // eslint-disable-next-line react/display-name
        render: (text) => {
          let count = text.count
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return <span>{count}</span>;
        },
      },
      //3rd percentage
      {
        key: 'percentage',
        align: 'right',
        ellipsis: true,
        // eslint-disable-next-line react/display-name
        render: (text) => {
          return (
            <span>
              {Number.isInteger(text.percentage)
                ? `${text.percentage}.0`
                : text.percentage}
              %
            </span>
          );
        },
      },
    ];

    return (
      <Table
        className="statsDrillDownTable"
        columns={drillDownColumns}
        // bordered
        dataSource={tableData[0]}
        pagination={false}
        showHeader={false}
        bordered
      />
    );
  };
  return (
    <Modal
      className="modalStatsTable"
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
      width={statsName == 'Internal Record Status' ? '70%' : '50%'}
      centered={true}
      bodyStyle={{
        maxHeight: 'calc(110vh - 150px)',
        overflow: 'auto',
        padding: '10px 20px',
      }}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
          }}>
          {/* <div className="titleInBody">{statsName} Stats</div> */}
          {/* <div className="titleInBody">{statsName}</div> */}
          {statsName == 'Record Status' ? (
            <div className="statsIconDiv">
              <Popover
                placement="right"
                content={content}
                trigger="click"
                overlayClassName="popoverContent">
                <InfoCircleOutlined className="statsIcon" />
              </Popover>
            </div>
          ) : statsName == 'Mobile Number Enrichment' ? (
            <div className="statsIconDiv">
              <Popover
                placement="top"
                content={
                  'Only active and no longer records are considered for mobile number enrichment'
                }
                trigger="click">
                <InfoCircleOutlined className="statsIcon" />
              </Popover>
            </div>
          ) : null}
        </div>
        <div style={{ padding: '5px 5px 15px 5px' }}>
          {statsName == 'Internal Record Status' ? (
            <InternalRecordStatusComp dataSource={data} columns={columns} />
          ) : (
            <Table
              rowKey="key"
              dataSource={statsValue}
              size="small"
              bordered
              columns={columns}
              // bordered
              expandable={{
                expandedRowRender,
                rowExpandable: (record) =>
                  record.name === record.tableDrilldown,
              }}
              showHeader={true}
              pagination={false}
              className="taskStatsTable"
            />
          )}
        </div>
        <div
          style={{
            float: 'right',
            paddingRight: '15px',
            color: '#828181',
            paddingBottom: statsName == 'Internal Record Status' ? '10px' : 0,
          }}>
          Last Refreshed On{' '}
          {moment
            .utc(statsLastUpdatedOn)
            .local()
            .format(APP_STANDARD_DATE_FORAMT)}
        </div>
      </div>
    </Modal>
  );
};

StatsTable.propTypes = {
  statsName: PropTypes.string,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  statsLastUpdatedOn: PropTypes.string,
  statsTableData: PropTypes.array,
  statsDrillDownData: PropTypes.array,
  tableName: PropTypes.string,
  taskName: PropTypes.string,
  data: PropTypes.array,
};

export default StatsTable;
