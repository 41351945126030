import React, { useState, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  DownloadOutlined,
  FileTextOutlined,
  PauseOutlined,
  CaretRightOutlined,
  LineChartOutlined,
  SyncOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Table,
  Tooltip,
  Badge,
  Popover,
  Spin,
  Typography,
  message,
  Progress,
  Modal,
  Dropdown,
  Menu,
  Empty,
  Button,
} from 'antd';
import TaskSummary from '../Summary/TaskSummary';
import config from '../../../config/constants';
import TaskStats from '../Stats/TaskStats';
import { AuthContext } from '../../../shared/auth/authContext';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { taskService } from '../../../service/task.service';
import StatsScreen from '../Stats/StatsScreen';
import { APP_STANDARD_DATE_FORAMT } from '../../../config/constants';
import emptyData from '../../../assets/images/emptyTaskLIst.png';
import { Error_500 } from '../../errorHandler';
import { useHotkeys } from 'react-hotkeys-hook';
import useToggle from '../../../hooks/useToggle';
import { ShowOopsMsg, msgConfig } from '../../../common/messageComp';
import PagenationComp from '../../../shared/PaginationComp';
import {
  DeDownload,
  PauseIcon,
  DeStats,
  AnimationOnce,
  DeRegenerateIcon,
} from '../../../common/animatedIcon';

const { Text, Link } = Typography;
const { confirm } = Modal;

const taskUrl = config.env.taskServerUrl;

function ListTask(props) {
  const { isAllowed, isUserInRole, loggedInUser } = useContext(AuthContext);
  const queryCache = useQueryCache();

  const [page, setPage] = useState(0);
  const [isDetailsOn, detailsIsOn] = useToggle();
  const [columnName, setColumnName] = useState('created_on');
  const [sortValue, setSortValue] = useState('desc');
  const [loading, setLoading] = useState(true);

  useHotkeys('ctrl+b', () => detailsIsOn());

  const fetchTasks = (
    key,
    page = 0,
    columnName = 'created_on',
    sortValue = 'desc'
  ) => taskService.getAll(key, page, columnName, sortValue);

  //fetch records for table
  const {
    isLoading,
    isError,
    isSuccess,
    error,
    resolvedData,
    latestData,
    isFetching,
  } = usePaginatedQuery(['tasks', page, columnName, sortValue], fetchTasks, {
    onSuccess: (resp) => {
      setLoading(false);
      const totalRecords = resp.data.totalElements;
      props.getTotalTaskCount(totalRecords);
      if (totalRecords > 0) {
        props.isCreateTaskEnable(true);
      } else {
        props.isCreateTaskEnable(false);
      }
    },
  });

  //varibale for taskSummary
  const [showSummary, setShowSummary] = useState(false);
  const [specificTaskDetails, setSpecificTaskDetails] = useState({});
  //variable for stats
  const [showStats, setShowStats] = useState(false);
  const [specificStatsDetails, setSpecificStatsDetails] = useState([]);
  const [statsLastUpdatedOn, setStatsLastUpdatedOn] = useState('');
  const [taskName, setTaskName] = useState('');
  const [taskId, setTaskId] = useState();
  const [statsGeneratedOn, setStatsGeneratedOn] = useState();

  //pagination Use text link for prev and next button.
  const itemRenders = (current, type, originalElement) => {
    // console.log('itemRenders', current);
    // console.log('itemRenders', type);
    // console.log('itemRenders', originalElement);
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  //to handle pagination button
  const onPaginationClick = (pageNumber, pageSize) => {
    const pageNo = pageNumber - 1;
    setPage(pageNo);
    if (pageNo > page) {
      setLoading(true);
    }
    document.documentElement.scrollTop = 0;
    props.getCurrentPageNo(pageNo);
  };

  //pause task
  const pauseTask = (id) => {
    axios
      .put(`${taskUrl}/api/v1/task/${id}?status=Pause`)
      .then((resp) => {
        queryCache.invalidateQueries(['tasks', page, columnName, sortValue]);
      })
      .catch((error) => {
        console.log('pause error', error);
        message.destroy();
        message.error('Only queued or in-progress task can be pause');
      });
  };

  //resume task
  const resumeTask = (id) => {
    axios
      .put(`${taskUrl}/api/v1/task/${id}?status=Resume`)
      .then((resp) => {
        queryCache.invalidateQueries(['tasks', page, columnName, sortValue]);
      })
      .catch((error) => {
        console.log('Resume error', error);
        message.destroy();
        message.error('Only a paused task can be resumed.');
      });
  };

  //download file
  // const downloadFile = (id, fileName, fileType) => {
  //   const url = `${taskUrl}/api/v1/download/${id}`;
  //   const filename = fileName.replace(/\.[^.]+$/, fileType);
  //   console.log(fileName.lastIndexOf('.'));
  //   console.log(fileName.substr(0, fileName.lastIndexOf('.')) + fileType);
  //   axios
  //     .get(url, {
  //       responseType: 'blob',
  //     })
  //     .then((response) => {
  //       const blob = new Blob([response.data], { type: response.data.type });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;

  //       link.setAttribute('download', filename);

  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((err) => {
  //       console.log('Download fetch error', err);
  //       message.destroy();
  //       message.error('Download is unavailable');
  //     });
  // };

  //fetch summary of specific task
  const summary = (id) => {
    taskService
      .getById(id)
      .then((resp) => {
        const response = resp.data;
        setSpecificTaskDetails(response);
        setShowSummary((preState) => !preState);
      })
      .catch((err) => {
        console.log('In Summary fetch error', err);
        setShowSummary(false);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  //fetch stats of specific task (older code to show matchback stats only in popup)
  const stats = (id, name) => {
    console.log('inside fetchTaskStatsApi Call');
    axios
      .get(`${taskUrl}/api/v1/tasks/${id}/stats`)
      .then((resp) => {
        const response = resp.data;
        // console.log(response);
        if (response !== '') {
          setTaskName(name);
          setSpecificStatsDetails(response[0].stats);
          setStatsLastUpdatedOn(response[1].lastUpdatedOn);
          setShowStats((preState) => !preState);
        } else {
          console.log('Stats is not ready');
          message.destroy();
          message.error('Stats are not generated ');
        }
      })
      .catch((err) => {
        console.log('In Stats fetch error');
        message.destroy();
        message.error('stats are not generated ');
      })
      .finally(() => {});
  };

  //fetch stats of specific task (new stats UI screen)
  const statsUI = (task_id, task_name, stats_generated_on) => {
    setShowStats((preState) => !preState);
    setTaskName(task_name);
    setTaskId(task_id);
    setStatsGeneratedOn(stats_generated_on);
    props.getActionForStats();
  };

  //re-Generate Stats stats of specific task
  const reGenerateStats = (id, name) => {
    // console.log(id, name);
    axios
      .post(`${taskUrl}/api/v1/tasks/${id}/stats`)
      .then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          // queryCache.invalidateQueries(['tasks', page, columnName, sortValue]);
          message.destroy();
          message.success('Your request for stat generation has been accepted');
        } else {
          message.destroy();
          message.error(msgConfig(errorMessage(`Stats cannot be generated`)));
        }
      })
      .catch((err) => {
        // console.log(err);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  //download Input file of specific task
  // const downloadInputFile = (id, fileName, fileType) => {
  //   const url = `${taskUrl}/api/v1/downloadInput/${id}`;
  //   let fileNameWithOutExtension = fileName.replace(/\.[^/.]+$/, '');
  //   let originalFileNameWithOutExtension = fileNameWithOutExtension.slice(
  //     0,
  //     fileNameWithOutExtension.length - 14
  //   );
  //   const inputFilename = originalFileNameWithOutExtension.concat(fileType);

  //   axios
  //     .get(url, {
  //       responseType: 'blob',
  //     })
  //     .then((response) => {
  //       const blob = new Blob([response.data], { type: response.data.type });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;

  //       link.setAttribute('download', inputFilename);

  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((err) => {
  //       console.log('Download fetch error', err);
  //       message.destroy();
  //       message.error('Download is unavailable');
  //     });
  // };

  //delete specific task
  const deleteSpecificTask = (id, name) => {
    console.log(id, name);
    confirm({
      title: `Are you sure you want to delete this task?`,
      icon: <ExclamationCircleOutlined style={{ fontSize: '25px' }} />,
      content: `All your processing data for '${name}' will be lost`,
      centered: true,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        console.log('delete the task');
        //calling delete api
        axios
          .delete(`${taskUrl}/api/v1/task/delete/${id}`)
          .then((resp) => {
            message.destroy();
            message.success('Task delete successfully');
            queryCache.invalidateQueries([
              'tasks',
              page,
              columnName,
              sortValue,
            ]);
            // fetchData(initialPages);
          })
          .catch((error) => {
            console.log('pause error', error);
            message.destroy();
            ShowOopsMsg();
          });
      },
      onCancel() {
        console.log('cancel delete');
      },
    });
  };
  //  console.log('totalRecords',totalRecords)
  //table columns
  const columns = [
    {
      title: '',
      key: 'status',
      // eslint-disable-next-line react/display-name
      render: (text) => (
        <span
          style={{
            fontSize: '20px',
            margin: '-3px',
            borderLeft: `6px solid ${
              text.status == 'COMPLETE'
                ? 'var(--success)'
                : text.status === 'IN_PROGRESS' || text.status === 'QUEUED'
                ? 'var(--primary)'
                : text.status === 'PAUSED'
                ? 'var(--warning)'
                : text.status === 'FAILED'
                ? '#ED7325'
                : ''
            }`,
            borderRadius: '5px',
          }}></span>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      width: isDetailsOn ? '5%' : null,
      // eslint-disable-next-line react/display-name
      render: (text) => isDetailsOn && text.id,
    },
    {
      title: 'Remark',
      key: 'remark',
      width: isDetailsOn ? '10%' : null,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        if (isDetailsOn) {
          return (
            <>
              <div style={{ color: '#000' }}>
                <small>{text.internalId}</small>{' '}
              </div>
              <div>{text.remark}</div>
            </>
          );
        }
      },
    },
    // file name
    {
      title: (
        <span>
          Task Name &nbsp;
          <span className="totalTaskCount">({props.totalTaskCount} Tasks)</span>
        </span>
      ),
      // dataIndex: 'name',
      key: 'name',
      width: '25%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        let isDisable =
          text.taskType == 'MARKETO' &&
          text.status == 'IN_PROGRESS' &&
          (text.integrationStatus == 'QUEUED' ||
            text.integrationStatus == 'READ_IN_PROGRESS');
        return (
          <>
            {!isDisable ? (
              <Link className="font-big" onClick={() => summary(text.id)}>
                {text.name}
              </Link>
            ) : (
              <div className="font-big-disabled">{text.name}</div>
            )}
          </>
        );
      },
    },

    //records
    {
      title: 'Record Count',
      key: 'records',
      align: 'right',
      ellipsis: true,
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <span className="font-small">
            {text.records != undefined ? (
              <>
                {text.records.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                records
              </>
            ) : (
              <>- -</>
            )}
          </span>
        );
      },
    },
    //createdOn date
    {
      title: 'Submitted On',
      key: 'createdOn',
      align: 'center',
      ellipsis: true,
      width: '15%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        const localServerTime = moment.utc(text.createdOn).local().format();
        const localTime = moment();
        const days = localTime.to(localServerTime);
        const createdOnDate = moment
          .utc(text.createdOn)
          .local()
          .format(APP_STANDARD_DATE_FORAMT);
        return (
          // <Tooltip placement="bottom" title={createdOnDate} color={''}>
          <span className="font-small">{createdOnDate}</span>
          // </Tooltip>
        );
      },
    },
    //status
    {
      title: 'Status',
      key: 'status',
      ellipsis: true,
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        const taskProcessPerc = () => {
          let percentage =
            text.records !== undefined
              ? ((text.processedRecords / text.records) * 100).toFixed(1)
              : 0.0;
          return percentage;
        };

        let marketoWritePro =
          text.taskType === 'MARKETO' &&
          text.integrationStatus === 'WRITE_IN_PROGRESS'
            ? true
            : false;

        const recordsProcess = (
          <div
            style={{ maxHeight: '500px', overflow: 'auto' }}
            className="recordProcessbarContent">
            <div style={{ textAlign: 'center' }}>
              <lable className="recordsProcessTitle">
                Task Progress Status
              </lable>
              <div className="recordsProcessBar">
                <Progress
                  percent={taskProcessPerc()}
                  type="dashboard"
                  width={80}
                  strokeWidth="10"
                />
              </div>
              {text.records != undefined ? (
                <>
                  <lable className="processRecordCount">
                    {`${text.processedRecords
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                  processed out of 
                  ${text.records
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                  </lable>
                </>
              ) : null}
              {marketoWritePro && (
                <div style={{ fontSize: '12px', fontFamily: 'Roboto-medium' }}>
                  Sync In-Progress
                </div>
              )}
            </div>
          </div>
        );

        return (
          <>
            {text.status === 'IN_PROGRESS' || text.status === 'PAUSED' ? (
              <Popover
                arrowPointAtCenter="true"
                placement="right"
                content={recordsProcess}>
                {text.status === 'IN_PROGRESS' ? (
                  <span className="font-small">
                    <Badge
                      status="processing"
                      text="In-Progress"
                      color={'#1e5ad0'}
                    />
                  </span>
                ) : text.status === 'PAUSED' ? (
                  <span className="font-small">
                    <Badge status="warning" text="Paused" />
                  </span>
                ) : null}
              </Popover>
            ) : (
              <>
                {text.status === 'COMPLETE' ? (
                  <span className="font-small">
                    <Badge status="success" text="Complete" />
                  </span>
                ) : text.status === 'FAILED' ? (
                  <span className="font-small">
                    <Badge status="error" text="Failed" />
                  </span>
                ) : text.status === 'QUEUED' ? (
                  <span className="font-small">
                    <Badge
                      status="processing"
                      text="Queued"
                      color={'#1e5ad0'}
                    />
                  </span>
                ) : null}
              </>
            )}
          </>
        );
      },
    },
    //submitted user name
    {
      key: 'name',
      title: 'Submitted By',
      ellipsis: true,
      width: isDetailsOn ? '10%' : isUserInRole('Super Admin') ? '15%' : '10%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <Popover
            placement="bottomLeft"
            content={
              <span>
                <div className="userDetailsLabel">
                  Username: <br />
                  <span className="userDetailsText">
                    {text.createdBy.userName}
                  </span>
                </div>
                <div className="userDetailsLabel">
                  User: <br />
                  <span className="userDetailsText">
                    {text.createdBy.firstName} {text.createdBy.lastName}
                  </span>
                </div>
              </span>
            }>
            <span className="font-small">
              {text.createdBy.firstName} {text.createdBy.lastName}
            </span>
          </Popover>
        );
      },
    },
    // Task Type
    {
      key: 'taskType',
      title: 'Type',
      width: '10%',
      render: (text) => {
        if (text.taskType == 'MARKETO') {
          return <span className="font-small">Marketo</span>;
        } else if (text.taskType == 'FILE') {
          return (
            <span className="font-small">
              {text.fileType == '.xlsx'
                ? 'Excel file'
                : text.fileType == '.csv'
                ? 'CSV file'
                : ''}
            </span>
          );
        }
      },
    },
    // client name
    {
      key: 'clientName',
      title: 'Org Name',
      ellipsis: true,
      width: isUserInRole('Super Admin') ? '15%' : isDetailsOn ? '10%' : null,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <Popover
            placement="bottomLeft"
            content={
              <span>
                <div className="userDetailsLabel">
                  Org Name: <br />
                  <span className="userDetailsText">{text.clientName}</span>
                </div>
              </span>
            }>
            <span className="font-small">{text.clientName}</span>
          </Popover>
        );
      },
    },
    // download
    {
      title: '',
      key: 'id',
      align: 'right',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text) => {
        return (
          <>
            {text.downloadAvailable && isAllowed('DOWNLOAD_OUT_FILE') ? (
              <span className="taskDownloadIcon">
                <Link
                  href={`${taskUrl}/api/v1/download?id=${text.id}&authorization=${loggedInUser.jwtToken}`}
                  target="_blank">
                  <span className="linkIcon downloadIcon">
                    {AnimationOnce(
                      'icons/199-download-2-outline-edited.json',
                      'td'
                    )}
                    &nbsp;
                  </span>
                </Link>
                <Link
                  className="downloadBtn"
                  // onClick={() =>
                  //   downloadFile(text.id, text.fileName, text.fileType)
                  // }
                  href={`${taskUrl}/api/v1/download?id=${text.id}&authorization=${loggedInUser.jwtToken}`}
                  target="_blank">
                  <>
                    <span className="downloadBtn">Download</span>
                  </>
                </Link>
              </span>
            ) : (
              <>
                <img
                  src={DeDownload}
                  className="linkIconDisabled tasklistimg"
                />
                &nbsp;
                <Text disabled>Download</Text>
              </>
            )}
          </>
        );
      },
    },
    // stats
    {
      title: '',
      key: 'id',
      align: 'right',
      ellipsis: true,
      width: '7%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        // console.log(text);
        return (
          <>
            {text.statsGenerated !== undefined &&
            text.statsGenerated &&
            isAllowed('VIEW_STATS') ? (
              <span className="taskStatsIcon">
                <Link
                  // onClick={() => stats(text.id, text.name)}
                  onClick={() =>
                    statsUI(text.id, text.name, text.statsGeneratedOn)
                  }>
                  <span className="linkIcon">
                    {AnimationOnce(
                      'icons/163-graph-line-chart-outline-edited.json',
                      'td'
                    )}
                    &nbsp;
                  </span>
                </Link>
                <Link
                  className="statsBtn"
                  // onClick={() => stats(text.id, text.name)}
                  onClick={() =>
                    statsUI(text.id, text.name, text.statsGeneratedOn)
                  }>
                  <span className="linkIcon">Stats</span>
                </Link>
              </span>
            ) : (
              <>
                <img src={DeStats} className=" tasklistimg" />{' '}
                <Text disabled>Stats</Text>
              </>
            )}
          </>
        );
      },
    },
    // action: view summary and Pause and Resume, regenerate, delete
    {
      title: '',
      key: 'id',
      align: 'center',
      ellipsis: true,
      width: '3%',
      // eslint-disable-next-line react/display-name
      render: (text) => {
        let isDisable =
          text.taskType == 'MARKETO' &&
          text.status == 'IN_PROGRESS' &&
          (text.integrationStatus == 'QUEUED' ||
            text.integrationStatus == 'READ_IN_PROGRESS');
        const content = (
          <Menu className="task-list-action-menu">
            <Menu.Item
              onClick={() => summary(text.id)}
              disabled={isDisable}
              className="taskFileListIcon">
              <span className="linkIcon">
                {AnimationOnce('icons/47-to-do-list-outline-edited.json', 'li')}
              </span>
              Task Details
            </Menu.Item>
            {isAllowed('TASK_PAUSE_RESUME') && text.taskType === 'FILE' && (
              <>
                {text.status === 'IN_PROGRESS' || text.status === 'QUEUED' ? (
                  <Menu.Item
                    onClick={() => pauseTask(text.id)}
                    className="taskPauseIcon">
                    <span className="linkIcon">
                      {AnimationOnce(
                        'icons/22-play-pause-morph-outline-edited.json',
                        'li'
                      )}
                    </span>
                    &nbsp;Pause
                  </Menu.Item>
                ) : null}
                {text.status == 'PAUSED' ? (
                  <Menu.Item
                    onClick={() => resumeTask(text.id)}
                    className="taskPauseIcon">
                    <span className="linkIcon">
                      <img src={PauseIcon} className="tasklistimg" />
                      &nbsp;
                    </span>
                    Resume
                  </Menu.Item>
                ) : null}
              </>
            )}
            {isAllowed('DOWNLOAD_IN_FILE') && (
              <Menu.Item className="taskDownloadIcon">
                <Link
                  href={`${taskUrl}/api/v1/downloadInput?id=${text.id}&authorization=${loggedInUser.jwtToken}`}
                  target="_blank">
                  <span className="linkIcon">
                    {AnimationOnce(
                      'icons/199-download-2-outline-edited.json',
                      'li'
                    )}
                  </span>
                  &nbsp;
                  <span>Input File Download</span>
                </Link>
              </Menu.Item>
            )}
            {isAllowed('REGENERATE_STATS') && (
              <>
                {text.status !== 'QUEUED' ? (
                  <>
                    <Menu.Item
                      className="RegenerateIcon"
                      onClick={() => reGenerateStats(text.id, text.name)}>
                      <span className="linkIcon">
                        {AnimationOnce('icons/227-arrow-16-edited.json', 'li')}
                      </span>
                      &nbsp;Re-Generate Stats
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item>
                    <img src={DeRegenerateIcon} className="tasklistimg" />
                    <Text disabled>Re-Generate Stats</Text>
                  </Menu.Item>
                )}
              </>
            )}
            {isAllowed('TASK_DELETE') && (
              <>
                {text.status !== 'QUEUED' && text.status !== 'IN_PROGRESS' ? (
                  <>
                    <Menu.Item
                      className="actionBtnAlert"
                      type="danger"
                      onClick={() => deleteSpecificTask(text.id, text.name)}>
                      <span className="linkIconAlert">
                        <DeleteOutlined />
                      </span>
                      Delete
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item>
                    <DeleteOutlined className="linkIconDisabled" />
                    <Text disabled>Delete</Text>
                  </Menu.Item>
                )}
              </>
            )}
          </Menu>
        );
        return (
          <Dropdown
            overlay={content}
            placement="bottomCenter"
            overlayClassName="taskListDropDownMenu">
            <span className="threedotContainer">
              <div className="threeDots"></div>
              <div className="threeDots"></div>
              <div className="threeDots"></div>
            </span>
          </Dropdown>
        );
      },
    },
  ];

  if (isError) {
    props.isCreateTaskEnable(false);
    props.getTotalTaskCount('-');
    return <Error_500 />;
  }

  if (isSuccess && resolvedData.data.content.length === 0) {
    return (
      <div style={{ paddingTop: '100px' }}>
        <Empty
          image={emptyData}
          imageStyle={{
            height: 250,
          }}
          description={
            <span>
              No enrichment tasks yet, let’s get started
              {isAllowed('ADD_TASK') && (
                <div style={{ marginTop: '20px' }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      props.uploadTask(
                        props.isConnected && isAllowed('ADD_MARKETO_TASK')
                          ? null
                          : 'fileUpload'
                      )
                    }>
                    <PlusOutlined /> Create Task
                  </Button>
                </div>
              )}
            </span>
          }></Empty>
      </div>
    );
  }

  const tableLoading = {
    spinning: loading,
    indicator: (
      <Spin
        tip={
          <div style={{ fontSize: '15px', marginLeft: '-10px' }}>
            <div>&nbsp;</div>
            <div>Loading...</div>
          </div>
        }
        spinning={loading}
        size="large"
      />
    ),
  };
  return (
    <Spin
      tip="Loading..."
      spinning={isLoading}
      size="large"
      style={{ marginTop: '200px' }}>
      {showStats ? (
        <StatsScreen
          taskName={taskName}
          taskId={taskId}
          statsLastUpdatedOn={statsGeneratedOn}
          isShowRecordStats={isAllowed('INTERNAL_STATS')}
          closeStats={() => {
            setShowStats((preState) => !preState);
            props.getActionForStats();
          }}
        />
      ) : (
        resolvedData && (
          <>
            <Table
              className="taskListTable"
              id="taskListTable"
              rowKey="id"
              dataSource={resolvedData.data.content}
              columns={columns}
              // bordered
              size="medium"
              showHeader={true}
              pagination={false}
              scrollToFirstRowOnChange={true}
              loading={tableLoading}
            />
            <PagenationComp
              onChange={onPaginationClick}
              defaultPageSize={20}
              current={page + 1}
              totalTaskCount={resolvedData.data.totalElements}
              totalElementsOnPage={resolvedData.data.numberOfElements}
            />
          </>
        )
      )}
      {specificTaskDetails && (
        <TaskSummary
          showSummary={showSummary}
          closeSummary={() => setShowSummary((preState) => !preState)}
          specificTaskDetails={specificTaskDetails}
        />
      )}
      {/* <TaskStats
        showStats={showStats}
        closeStats={() => setShowStats((preState) => !preState)}
        specificStatsDetails={specificStatsDetails}
        taskName={taskName}
        lastUpdatedOn={statsLastUpdatedOn}
      /> */}
    </Spin>
  );
}

ListTask.propTypes = {
  getTotalTaskCount: PropTypes.func,
  getActionForStats: PropTypes.func,
  getCurrentPageNo: PropTypes.func,
  uploadTask: PropTypes.func,
  isCreateTaskEnable: PropTypes.func,
  steps: PropTypes.array,
  totalTaskCount: PropTypes.string,
  isConnected: PropTypes.bool,
};

export default ListTask;
