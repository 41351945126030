import axios from 'axios';
import config from '../config/constants';

const userUrl = config.env.userServerUrl;
const taskUrl = config.env.taskServerUrl;

export const connectorService = {
  connectConnection,
  getDetails,
  testConnection,
  disconnectConnection,
  fetchStaticList,
  submitConnectorTask,
};

function connectConnection(connectionDetails) {
  return axios.post(`${userUrl}/v1/connectors`, connectionDetails);
}

function getDetails() {
  return axios.get(`${userUrl}/v1/connectors`);
}

function testConnection() {
  return axios.get(`${userUrl}/v1/connectors/test`);
}

function disconnectConnection() {
  return axios.get(`${userUrl}/v1/connectors/disconnect`);
}

function fetchStaticList(assetType) {
  return axios.get(
    `${taskUrl}/api/v1/connectors/marketo/${assetType}/assetsList`
  );
}

function submitConnectorTask(taskDetails) {
  return axios.post(`${taskUrl}/api/v1/connectors/marketo/add`, taskDetails);
}
