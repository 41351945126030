import React from 'react';
import PropTypes from 'prop-types';
import '../AddTask/enrichModeScreen.css';

function EnrichModeDetails({ apiHeaderValues, enrichModeValue }) {
  var substr = '(Default)';

  const removeText = (optionitem, substr) => {
    let name = optionitem.name.replace(substr, '');
    return name;
  };

  const isEnrichValue = (val) => {
    return apiHeaderValues.some((item) => item.headerName === val);
  };

  return (
    <div>
      <table className="table" style={{ fontSize: '14px' }}>
        <tbody>
          {/* Email Verifier Run */}
          {isEnrichValue('ev-value') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Email Verifier Run</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  let name;
                  apiHeaderValues.map((apiValues) => {
                    if (
                      enrichModeItem.name === 'Email Verifier Run' &&
                      apiValues.headerName === 'ev-value'
                    ) {
                      let optionitem = enrichModeItem.option.find(
                        (x) => x.value == apiValues.headerValue
                      );
                      name = removeText(optionitem, substr);
                    }
                  });
                  return name;
                })}
              </td>
            </tr>
          )}
          {/* Company Match Process */}
          {isEnrichValue('compmb-value') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;
                <span className="fieldLabel">Company Match Process</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  let name;
                  apiHeaderValues.map((apiValues) => {
                    if (
                      enrichModeItem.name === 'Company Match Process' &&
                      apiValues.headerName === 'compmb-value'
                    ) {
                      let optionitem = enrichModeItem.option.find(
                        (x) => x.value == apiValues.headerValue
                      );
                      name = removeText(optionitem, substr);
                    }
                  });
                  return name;
                })}
              </td>
            </tr>
          )}
          {/* Company Index Reach */}
          {isEnrichValue('index-value') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Company Index Reach</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  let name;
                  apiHeaderValues.map((apiValues) => {
                    if (
                      enrichModeItem.name === 'Company Index Reach' &&
                      apiValues.headerName === 'index-value'
                    ) {
                      let optionitem = enrichModeItem.option.find(
                        (x) => x.value == apiValues.headerValue
                      );
                      name = removeText(optionitem, substr);
                    }
                  });
                  return name;
                })}
              </td>
            </tr>
          )}
          {/* Export Fields */}
          {isEnrichValue('output-value') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;<span className="fieldLabel">Export Fields</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  let name;
                  apiHeaderValues.map((apiValues) => {
                    if (
                      enrichModeItem.name === 'Export Fields' &&
                      apiValues.headerName === 'output-value'
                    ) {
                      let optionitem = enrichModeItem.option.find(
                        (x) => x.value == apiValues.headerValue
                      );
                      name = removeText(optionitem, substr);
                    }
                  });
                  return name;
                })}
              </td>
            </tr>
          )}
          {/* Direct Dial Run */}
          {/* {isEnrichValue('direct-dial-run') && (
            <tr className="rowBorder">
              <td className="columnOne">
                &nbsp;
                <span className="fieldLabel">Enrich Type</span>
              </td>
              <td className="columnTwo cursorPointer">
                {enrichModeValue.map((enrichModeItem) => {
                  let name;
                  apiHeaderValues.map((apiValues) => {
                    if (
                      enrichModeItem.name === 'Enrich Type' &&
                      apiValues.headerName === 'direct-dial-run'
                    ) {
                      let optionitem = enrichModeItem.option.find(
                        (x) => x.value == apiValues.headerValue
                      );
                      name = removeText(optionitem, substr);
                    }
                  });
                  return name;
                })}
              </td>
            </tr>
          )} */}
        </tbody>
      </table>
    </div>
  );
}

EnrichModeDetails.propTypes = {
  apiHeaderValues: PropTypes.array,
  enrichModeValue: PropTypes.array,
};

export default EnrichModeDetails;
