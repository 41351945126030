import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Highcharts
import Highcharts from 'highcharts/highstock';
import drilldow from 'highcharts/modules/drilldown';
import PieChart from 'highcharts-react-official';
drilldow(Highcharts);

const PieChartStats = ({ pieData, drillDownData, reportId, statsName }) => {
  const [pieChartData, setPieChartData] = useState([]);
  const [drillDownChartData, setDrillDownChartData] = useState([]);
  //use to show message on wizard
  const [drillDownCount, setDrillDownCount] = useState(0);

  useEffect(() => {
    let pieChartdata = pieData.filter((item) => item && item.name !== 'Total');
    let count = 0;
    pieChartdata.forEach((a, i) => {
      count = count + (a.drilldown ? 1 : 0);
    });
    let drillDownChartdata = drillDownData.filter((item) => item.name);
    setPieChartData(pieChartdata);
    setDrillDownChartData(drillDownChartdata);
    setDrillDownCount(count);
  }, [pieData, drillDownData]);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const options = {
    chart: {
      type: 'pie',
      reflow: true,
      height: 300,
      style: {
        fontFamily: 'inherit',
      },
    },

    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },
    colorAxis: [{}],

    plotOptions: {
      pie: {
        slicedOffset: 0,
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        shadow: false,
        dataLabels: {
          connectorWidth: 1,
          enabled: true,
          padding: 0,
          style: {
            textOutline: false,
            fontWeight: 'normal',
            textDecoration: 'none',
          },
          formatter: function () {
            var max = 20;
            var val = this.point.name;
            return val.length > max ? val.slice(0, max) + '...' : val;
          },
          fontWeight: 'normal',
          color: '#666666',
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: true,
          },
        },
        //show name and color of records
        showInLegend: true,
        borderWidth: 0,
      },
    },

    legend: {
      //show name and color of records
      enabled: false,
      itemStyle: {
        fontWeight: 'normal',
      },
    },

    tooltip: {
      headerFormat:
        '{point.point.name}: {point.percentage:.1f}% | {point.y:,.0f}',
      pointFormat: '',
    },

    series: [
      {
        // name: reportId.charAt(0).toUpperCase() + reportId.slice(1).toLowerCase(),
        name: 'previous',
        colorByPoint: true,
        data: pieChartData,
        dataLabels: {
          enabled: true,
          padding: 0,
          distance: 10,
          fontWeight: 'normal',
        },
        size: '80%',
        cursor: 'pointer',
      },
    ],

    drilldown: {
      allowPointDrilldown: true,
      activeDataLabelStyle: {
        textDecoration: 'none',
        fontWeight: 'normal',
        color: '#666666',
      },

      series: drillDownChartData,

      dataLabels: {
        enabled: true,
        padding: 0,
        fontWeight: 'normal',
        distance: 10,
        color: '#666666',
      },
      id: 'versions',
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [
              {
                id: 'versions',
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },

    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      <PieChart highcharts={Highcharts} options={options} />
      {/*  To show massege when most of record has drilldown*/}
      <div style={{ height: '1.066rem' }}>
        <div
          id={`click-${statsName}`}
          style={{
            float: 'right',
            fontSize: '1rem',
            fontWeight: '600',
            color: ' #80808073',
            display: drillDownCount > 0 ? 'block' : 'none',
          }}>
          Click to Drill-Down
        </div>
      </div>
    </div>
  );
};

PieChartStats.propTypes = {
  pieData: PropTypes.array,
  drillDownData: PropTypes.array,
  reportId: PropTypes.string,
  statsName: PropTypes.string,
};

export default PieChartStats;
