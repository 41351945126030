import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Radio,
  Switch,
  DatePicker,
  Divider,
  Select,
  Tabs,
  Button,
  Spin,
  Space,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import moment from 'moment';
import { clientService } from '../../../service/client.service';
import { Error_500 } from '../../errorHandler';
import AddCreditTab from '../creditManagement/AddCreditForm';
import { numberWithCommas } from '../../../config/constantMethod';
import {
  CustomLabel,
  clientTypeInfoMsg,
  sfCreationInfoMsg,
  labelWithColen,
  subscriptiontypeInfoMsg,
} from '../../../common/messageComp';
import { AuthContext } from '../../../shared/auth/authContext';
import { useContext } from 'react';
import {
  APP_SHORT_DATE_FORMAT,
  COMPETITOR_DOMAINS_LIST,
  GENERIC_DOMAINS_LIST,
} from '../../../config/constants';

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const { Option } = Select;

const ClientEditForm = ({
  visible,
  clientId,
  onUpdate,
  onCancel,
  isLoading,
  licenseList,
}) => {
  const [form] = Form.useForm();
  const [clientStatusDisable, setClientStatusDisable] = useState(false);
  const [checked, setchecked] = useState();
  const [clientStatus, setClientStatus] = useState('');
  const [isError, setIsError] = useState(false);
  const [creditScore, setCreditScore] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [showRateLimit, setShowRateLimit] = useState(false);
  // const [selectedProductType, setSelectedProductType] = useState([]);
  // const [errorThrow, setErrorThrow] = useState(false);
  const [rateLimit, setRateLimit] = useState();
  const [licenseTypeOpt, setLicenseTypeOpt] = useState([]);
  const { setadminUserNameOld } = useContext(AuthContext);
  const [licenseDetails, setLicenseDetails] = useState([
    {
      licenseCount: '',
      licenseTypeName: '',
    },
  ]);

  const [clientOpt, setClientOpt] = useState('No');
  const sfclientHandler = (value) => {
    setClientOpt(value);
  };
  const [subScriptionAvailable, setSubscriptionAvailable] = useState(
    'Enterprise Standard'
  );

  const subscriptionTypeHandler = (e, value) => {
    setSubscriptionAvailable(e);
    console.log(e);
    console.log(value);
  };
  // get client details from id
  const loadClient = (id) => {
    clientService
      .getById(id)
      .then((res) => {
        setLoading(false);
        console.log('res >>>', res);
        let subscriptionEndDate = moment(res.data.expiryDate);
        let subscriptionStartDate = moment(res.data.startDate);
        let roleName = res.data.clientRole.name;
        let emailRefresherAccess = res.data.emailRefresherAccess;
        let directDialAccess = res.data.directDialAccess;
        let creditsAvailable = res.data.creditsAvailable;
        setLicenseDetails(res.data.licenses);
        form.setFieldsValue({
          ...res.data,
          sfCreation:
            res.data.salesforceOrgId &&
            (res.data.salesforceOrgId != null || res.data.salesforceOrgId != '')
              ? 'Yes'
              : 'No',
          users: res.data.licenses,
          emailRefresherAccess:
            emailRefresherAccess && emailRefresherAccess == true ? 'Yes' : 'No',
          directDialAccess:
            directDialAccess && directDialAccess == true ? 'Yes' : 'No',
          licenseCount: res.data.licenses
            ? res.data.licenses.licenseCount
            : null,
          creditsAvailable: numberWithCommas(creditsAvailable),
          subscriptionEndDate: subscriptionEndDate,
          subscriptionStartDate: subscriptionStartDate,
          SubscriptionType: res.data.subscriptionType,
          SmarteCRMID: res.data.smarteCrmId && res.data.smarteCrmId,
          roleAssigned: roleName,
          // selectedProduct: productSelected,
          clientType: res.data.clientType,
          rateLimit:
            res.data.rateLimit !== undefined
              ? res.data.rateLimit.toString()
              : undefined,
        });
        setadminUserNameOld(res.data.adminUsername);
        setClientOpt(
          res.data.salesforceOrgId &&
            (res.data.salesforceOrgId != null || res.data.salesforceOrgId != '')
            ? 'Yes'
            : 'No'
        );
        setRateLimit(
          res.data.rateLimit !== undefined
            ? res.data.rateLimit.toString()
            : undefined
        );
        // setSelectedProductType(productSelected);
        // checkShowRateLimit(productSelected);

        if (res.data != null && res.data.creditsAvailable !== undefined) {
          setCreditScore(res.data.creditsAvailable);
        } else {
          setCreditScore(0);
        }

        if (res.data.clientStatus === 'ACTIVE') {
          setchecked(true);
          setClientStatus('Active');
        } else {
          setchecked(false);
          setClientStatus('Deactivated');
        }
        if (res.data.clientStatus === 'PENDING') {
          setClientStatusDisable(true);
          setClientStatus('Activation pending');
        } else {
          setClientStatusDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setLoading(false);
      });
  };

  //when open form then clear all fields and set data
  useEffect(() => {
    setIsError(false);

    if (clientId !== null) {
      form.resetFields();
      setLoading(true);
      loadClient(clientId);
    }
  }, [clientId]);
  useEffect(() => {
    // console.log('first time render stage');
    setLicenseTypeOpt(licenseList);

    return () => {
      // console.log('unmounting...stage');
      setLicenseTypeOpt(licenseList);
    };
  }, []);

  const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 14 },
  };

  const onChangeStatus = (status) => {
    console.log('change status', status);
    if (status) {
      setchecked(status);
      setClientStatus('Active');
    } else {
      setchecked(status);
      setClientStatus('Deactivated');
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf('day');
  };

  // const onSelectProduct = (list) => {
  //   checkShowRateLimit(list);
  // };

  // const checkShowRateLimit = (list) => {
  //   if (list.length == 0) {
  //     setErrorThrow(true);
  //   } else {
  //     setErrorThrow(false);
  //   }
  //   let index = list.length > 0 ? list.findIndex((x) => x === 2) : -1;
  //   setShowRateLimit(index === -1 ? false : true);
  // };

  return (
    <Modal
      visible={visible}
      okText="Save "
      cancelText="Cancel"
      width={'70%'}
      centered
      maskClosable={false}
      destroyOnClose={true}
      footer={false}
      onCancel={onCancel}
      className="clientModal client_edit_container"
      bodyStyle={{
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'auto',
        padding: '20px 20px 40px 20px',
      }}>
      <Tabs className="clientTabHeader">
        <TabPane tab={<span className="tabsName">Edit ORG</span>} key="1">
          {isError ? (
            <Error_500 />
          ) : (
            <Form
              {...formItemLayout}
              form={form}
              layout="horizontal"
              name="form_in_modal"
              initialValues={{ users: licenseDetails }}
              className="clientForm"
              validateTrigger="onBlur">
              <Spin tip="Loading..." spinning={loading} size="small">
                <Col style={{ padding: '0px 15px 0px 15px' }}>
                  <Row>
                    {/* <Col span={12}>
                      <Form.Item name="internalId" label="Client ID">
                        <Input readOnly disabled placeholder="Client ID" />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        name="clientName"
                        label={labelWithColen('Org Name')}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Org Name',
                          },
                          {
                            min: 2,
                            message: 'Org Name atleast 2 characters',
                          },
                          // {
                          //   pattern: new RegExp(/^[A-Za-z ]*[A-Za-z][A-Za-z ]*$/),
                          //   message: 'Client Name must be alphabetic',
                          // },
                        ]}>
                        <Input placeholder="Org Name" maxLength="50" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={CustomLabel(
                          'Subscription Type',
                          subscriptiontypeInfoMsg
                        )}
                        name="SubscriptionType"
                        rules={[
                          {
                            required: true,
                          },
                        ]}>
                        <Select
                          placeholder="Select"
                          onChange={subscriptionTypeHandler}>
                          <Select.Option value="Enterprise Standard">
                            Enterprise Standard
                          </Select.Option>
                          <Select.Option value="Enterprise Plus">
                            Enterprise Plus
                          </Select.Option>
                          <Select.Option value="Pro">Pro</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="billerName" label="Billing Company Name">
                      <Input
                        placeholder="Billing Company Name"
                        maxLength="50"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="billingEmail"
                      label="Billing Email ID"
                      rules={[
                        {
                          type: 'email',
                          message: 'Invalid Email!',
                        },
                      ]}>
                      <Input
                        prefix={
                          <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email ID"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="billingAddress" label="Billing Address">
                      <Input.TextArea
                        placeholder="Billing Address"
                        maxLength="45"
                        rows="2"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                    {/* <Row> */}
                    <Col span={12}>
                      <Form.Item
                        name="subscriptionStartDate"
                        label={labelWithColen('Subscription Start Date')}
                        rules={[
                          {
                            type: 'object',
                            required: true,
                            message: 'Please select date!',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (value) {
                                if (getFieldValue('subscriptionEndDate')) {
                                  let subscriptionEndDate = getFieldValue(
                                    'subscriptionEndDate'
                                  );
                                  if (
                                    moment(value.format('YYYY-MM-DD')).isAfter(
                                      subscriptionEndDate.format('YYYY-MM-DD')
                                    )
                                  ) {
                                    return Promise.reject(
                                      'Start date should be smaller than End date'
                                    );
                                  }
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <DatePicker
                          format={APP_SHORT_DATE_FORMAT}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="subscriptionEndDate"
                        label={labelWithColen('Subscription End Date')}
                        rules={[
                          {
                            type: 'object',
                            required: true,
                            message: 'Please select date!',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (value) {
                                if (getFieldValue('subscriptionStartDate')) {
                                  let subscriptionStartDate = getFieldValue(
                                    'subscriptionStartDate'
                                  );
                                  if (
                                    moment(value.format('YYYY-MM-DD')).isBefore(
                                      subscriptionStartDate.format('YYYY-MM-DD')
                                    )
                                  ) {
                                    return Promise.reject(
                                      'End date should be greater than Start date'
                                    );
                                  }
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <DatePicker
                          format={APP_SHORT_DATE_FORMAT}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row> */}
                    {/* <Row gutter={16}> */}
                    <Col span={12}>
                      <Form.Item
                        label={labelWithColen('Credits Available')}
                        name="creditsAvailable">
                        <Input
                          readOnly
                          disabled
                          placeholder="Credits Available"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={labelWithColen('Org Status')}
                        name="clientstatus">
                        {clientStatusDisable === false ? (
                          <React.Fragment>
                            <Switch
                              // disabled={clientStatusDisable}
                              checked={checked}
                              onChange={onChangeStatus}
                            />
                            &nbsp;{clientStatus}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>{clientStatus}</React.Fragment>
                        )}
                      </Form.Item>
                    </Col>
                    {/* </Row> */}
                    {/* <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Notification CC To">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <div>
                          <Form.Item
                            name="ccEmail"
                            noStyle
                            // label="Notification CC To"
                            rules={[
                              {
                                pattern: new RegExp(
                                  /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*)+$/
                                ),
                                message: 'Enter valid email address',
                              },
                              // {
                              //   max: 255,
                              //   message: 'Maximum 255 characters allowed.',
                              // },
                              {
                                validator: (_, value) => {
                                  if (value !== undefined) {
                                    let isDuplicateFound = false;
                                    let emailsArray = value.split(',');
                                    let sorted_arr = emailsArray.slice().sort();
                                    //check array contains duplicate email
                                    for (
                                      let i = 0;
                                      i < sorted_arr.length - 1;
                                      i++
                                    ) {
                                      if (sorted_arr[i + 1] == sorted_arr[i]) {
                                        isDuplicateFound = true;
                                      }
                                    }
                                    if (isDuplicateFound)
                                      return Promise.reject(
                                        'Duplicate email is not allowed'
                                      );
                                    else return Promise.resolve();
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}>
                            <Input
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder="Email CC"
                              maxLength="255"
                            />
                          </Form.Item>
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                          <Tooltip
                            placement="bottom"
                            title={`All tasks related email notification will be default CC marked on this email address.`}>
                            <InfoCircleOutlined className="ccEmailIcon" />
                          </Tooltip>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="BCC Notification">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <div>
                          <Form.Item
                            name="bccEmail"
                            noStyle
                            rules={[
                              {
                                pattern: new RegExp(
                                  /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,*)+$/
                                ),
                                message: 'Enter valid email address',
                              },
                              // {
                              //   max: 255,
                              //   message: 'Maximum 255 characters allowed.',
                              // },
                              {
                                validator: (_, value) => {
                                  if (value !== undefined) {
                                    let isDuplicateFound = false;
                                    let emailsArray = value.split(',');
                                    let sorted_arr = emailsArray.slice().sort();
                                    //check array contains duplicate email
                                    for (
                                      let i = 0;
                                      i < sorted_arr.length - 1;
                                      i++
                                    ) {
                                      if (sorted_arr[i + 1] == sorted_arr[i]) {
                                        isDuplicateFound = true;
                                      }
                                    }
                                    if (isDuplicateFound)
                                      return Promise.reject(
                                        'Duplicate email is not allowed'
                                      );
                                    else return Promise.resolve();
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}>
                            <Input
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder="Email BCC"
                              maxLength="255"
                            />
                          </Form.Item>
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                          <Tooltip
                            placement="bottom"
                            title={`All tasks related email notification will be default BCC marked on this email address.`}>
                            <InfoCircleOutlined className="ccEmailIcon" />
                          </Tooltip>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row> */}
                    {/* <Row gutter={16}> */}
                    {/* <Col span={12}>
                    <span className="astrickSign">*</span>
                    <Form.Item label="Products" name="selectedProduct">
                      <Checkbox.Group
                        options={productTypes}
                        onChange={onSelectProduct}
                      />
                    </Form.Item>
                    {errorThrow && (
                      <span
                        className="errorThrow"
                        style={{ marginLeft: '180px' }}>
                        Please Select Product
                      </span>
                    )}
                  </Col> */}
                    <Col span={12}>
                      <Form.Item
                        label={labelWithColen('CMS Subscription ID')}
                        name="subscriptionId">
                        <Input
                          readOnly
                          disabled
                          placeholder="CMS Subscription ID"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="SmarteCRMID"
                        label="SMARTe CRM ID"
                        rules={[
                          {
                            required: false,
                          },
                        ]}>
                        <Input placeholder="SMARTe CRM ID" maxLength="50" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Divider orientation="left">Enrich Org Details</Divider>
                <Col style={{ padding: '0px 15px 0px 15px' }}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="internalId"
                        label={labelWithColen('Client ID')}>
                        <Input readOnly disabled placeholder="Client ID" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={CustomLabel('Client Type :', clientTypeInfoMsg)}
                        name="clientType"
                        rules={[
                          {
                            required: true,
                            message: 'Please Select Client',
                          },
                        ]}>
                        <Radio.Group name="radiogroup" disabled>
                          <Radio value={'Premium'}>Internal</Radio>
                          <Radio value={'Regular'}>External</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {/* <Row>
                  {showRateLimit && (
                    <Col span={12}>
                      <Form.Item
                        label="Rate Limit"
                        name="rateLimit"
                        rules={[
                          {
                            required: true,
                            message: 'Please Enter Rate Limit',
                          },
                          {
                            validator: (rule, value, cb) => {
                              if (value) {
                                if (
                                  !value.match(
                                    /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
                                  )
                                ) {
                                  cb('Enter a numeric value greater than zero');
                                } else if (
                                  value % 1 !== 0 ||
                                  value.includes('.')
                                ) {
                                  cb('Decimal value is not allowed');
                                } else if (value > 600) {
                                  cb(
                                    ' Rate limit cannot exceed 600 request per min.'
                                  );
                                }
                              }
                              cb();
                            },
                          },
                        ]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                </Row> */}
                <Divider orientation="left" className="divider_enrich">
                  Discover Org Detail
                </Divider>
                <Col style={{ padding: '0px 15px 0px 15px' }}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={labelWithColen('Email Refresher Access')}
                        name="emailRefresherAccess"
                        rules={[
                          {
                            required: false,
                          },
                        ]}>
                        <Select placeholder="Select Email Refresher">
                          <Select.Option value="Yes">Yes</Select.Option>
                          <Select.Option value="No">No</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={labelWithColen('Direct Dial Access')}
                        name="directDialAccess"
                        rules={[
                          {
                            required: false,
                            message: 'Please select option',
                          },
                        ]}>
                        <Select placeholder="Select ">
                          <Select.Option value="Yes">Yes</Select.Option>
                          <Select.Option value="No">No</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={CustomLabel('SF Creation :', sfCreationInfoMsg)}
                        name="sfCreation"
                        rules={[
                          {
                            required: false,
                          },
                        ]}>
                        <Select
                          placeholder="Select SF"
                          readOnly
                          disabled
                          onChange={sfclientHandler}>
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={CustomLabel(
                          'SalesForce Org ID :',
                          sfCreationInfoMsg
                        )}
                        name="salesforceOrgId"
                        className="salesforce_org_css"
                        rules={[
                          {
                            required: clientOpt == 'Yes' ? true : false,
                            message: 'Please Enter Org-ID',
                          },
                        ]}>
                        <Input placeholder="ORG ID" readOnly disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ padding: '0px 15px 0px 15px' }}>
                  <Form.List name="users">
                    {(licenseDetails, { add, remove }) => (
                      <React.Fragment>
                        {licenseDetails.map(
                          ({ key, name, index, ...restField }) => (
                            <Row
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 8,
                              }}
                              align="baseline">
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={labelWithColen('License Type')}
                                  name={[name, 'licenseTypeName']}
                                  className="license_type_container"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing license type',
                                    },
                                  ]}>
                                  <Select
                                    placeholder="Select License Type"
                                    readOnly
                                    disabled>
                                    {licenseTypeOpt.map((item, i) => {
                                      return (
                                        <Select.Option
                                          key={item.id}
                                          value={item.licenseTypeName}>
                                          {item.licenseTypeName}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={labelWithColen('License Count')}
                                  name={[name, 'licenseCount']}
                                  className="license_count_container license_edit"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter license count',
                                    },
                                  ]}>
                                  <Input
                                    value={name.licenseCount}
                                    placeholder="License Count"
                                    readOnly
                                    disabled
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )
                        )}
                        {/* <Form.Item>
                        <a onClick={(e) => add()} block icon={<PlusOutlined />}>
                          + Add field
                        </a>
                      </Form.Item> */}
                      </React.Fragment>
                    )}
                  </Form.List>
                </Col>
                <Divider orientation="left">CSM Admin details</Divider>
                <Col style={{ padding: '0px 15px 0px 15px' }}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="adminFirstName"
                        label={labelWithColen('First Name')}
                        rules={[
                          {
                            required: true,
                            message: 'Enter First Name',
                          },
                          {
                            min: 2,
                            message: 'First Name atleast 2 characters',
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z]+$/),
                            message: 'First Name must be alphabetic',
                          },
                        ]}>
                        <Input placeholder="First Name" maxLength="50" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="adminLastName"
                        label={labelWithColen('Last Name')}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Last Name',
                          },
                          {
                            min: 2,
                            message: 'Last Name atleast 2 characters',
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z]+$/),
                            message: 'Last Name must be alphabetic',
                          },
                        ]}>
                        <Input placeholder="Last Name" maxLength="50" />
                      </Form.Item>
                    </Col>
                    {/* </Row>
                <Row gutter={16}> */}
                    <Col span={12}>
                      <Form.Item
                        name="adminEmail"
                        label={labelWithColen('Email ID')}
                        rules={[
                          {
                            required: true,
                            message: 'Enter Email ID',
                          },
                        ]}>
                        <Input
                          readOnly
                          disabled
                          prefix={
                            <MailOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email ID"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="adminUsername"
                        label={labelWithColen('User Name')}
                        rules={[
                          {
                            required: true,
                            message: 'Enter User Name',
                          },
                          {
                            validator: (rule, value, cb) => {
                              if (value) {
                                console.log({ value });
                                if (
                                  value.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                  )
                                ) {
                                  let splitingemail = value.split('@');
                                  if (
                                    COMPETITOR_DOMAINS_LIST.includes(
                                      splitingemail
                                    )
                                  ) {
                                    cb(
                                      'Non-compliant email found. Please provide a valid work email'
                                    );
                                  } else {
                                    let splitDomain = splitingemail[1].split(
                                      '.'
                                    );
                                    if (
                                      GENERIC_DOMAINS_LIST.includes(
                                        splitDomain[0]
                                      )
                                    ) {
                                      cb('Please provide a valid work email');
                                    }
                                  }
                                } else {
                                  cb('Please provide valid user name');
                                }
                                cb();
                              } else {
                                cb();
                              }
                            },
                          },
                        ]}>
                        <Input placeholder="User Name" maxLength="50" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Spin>
              <Row>
                {!loading && (
                  <div className="editClientFooter">
                    <Button type="default" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '5px' }}
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            values.selectedProduct = [1, 2];
                            values.rateLimit = rateLimit;
                            onUpdate(values, clientStatus);
                            // if (
                            //   values.selectedProduct !== undefined &&
                            //   values.selectedProduct.length > 0
                            // ) {
                            //   onUpdate(values, clientStatus);
                            //   setErrorThrow(false);
                            // } else {
                            //   setErrorThrow(true);
                            // }
                          })
                          .catch((info) => {
                            console.log('Validate Failed:', info);
                          });
                      }}
                      loading={isLoading}>
                      Save
                    </Button>
                  </div>
                )}
              </Row>
            </Form>
          )}
        </TabPane>
        <TabPane tab={<span className="tabsName">Add Credits</span>} key="2">
          <AddCreditTab
            creditScore={creditScore}
            onCancel={onCancel}
            clientId={clientId}
            loadClient={loadClient}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

ClientEditForm.propTypes = {
  visible: PropTypes.bool,
  clientId: PropTypes.number,
  onUpdate: PropTypes.func,
  licenseList: PropTypes.array,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ClientEditForm;
