import axios from 'axios';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
// axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (req) => {
    const user = JSON.parse(localStorage.getItem('__dg_token'));
    const enrichDiscoverToken = localStorage.getItem('_ENRICH_AUTH_TOKEN');
    const loginMethod = localStorage.getItem('loginMethod');
    if (user && user.jwtToken) {
      req.headers.Authorization = `Bearer ${user.jwtToken}`;
      req.headers.enrichDiscoverToken = `${enrichDiscoverToken}`;
      req.headers.loginMethod = `${loginMethod}`;
    }
    return req;
  };
  const onResponseSuccess = (response) => response;
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
