import axios from 'axios';
import config from '../config/constants';
import * as auth from '../shared/auth/auth-provider';

const userUrl = config.env.userServerUrl;

export const userService = {
  getAll,
  getById,
  addUser,
  updateUser,
  fetchLoggedInUser,
};

async function fetchLoggedInUser() {
  const token = await auth.getToken();
  let user = null;
  if (token) {
    const loggedInUser = JSON.parse(token);
    const { data } = await axios.get(`${userUrl}/v1/users/refresh-token`);
    user = data;
    console.log({ data });

    let updatedToken = data.jwtToken;
    let updatedBalance = data.balance;
    updatelocalStorageToken(loggedInUser, updatedToken, updatedBalance);
  }
  return user;
}

//update local storage token
function updatelocalStorageToken(loggedInUser, updatedToken, updatedBalance) {
  let localStorageKey = '__dg_token';
  loggedInUser.jwtToken = updatedToken;
  loggedInUser.balance = updatedBalance;
  window.localStorage.setItem(localStorageKey, JSON.stringify(loggedInUser));
}

function getAll(key, page = 0, columnName, sortValue) {
  return axios.get(
    `${userUrl}/v1/users?page=${page}&size=20&sort=${columnName},${sortValue}`
  );
}

function getById(id) {
  return axios.get(`${userUrl}/v1/users/${id}`);
}

function addUser(user) {
  return axios.post(`${userUrl}/v1/users`, user);
}

function updateUser(user, selectedUserId) {
  return axios.put(`${userUrl}/v1/users/${selectedUserId}`, user);
}
