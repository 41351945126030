import React, { useEffect, useLayoutEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router';
import {
  Layout,
  Menu,
  Button,
  Dropdown,
  Divider,
  Tooltip,
  message,
} from 'antd';
import {
  PieChartOutlined,
  TeamOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  DownOutlined,
  SyncOutlined,
  SolutionOutlined,
  SwapOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import UserList from './screens/user/UserList';
import TaskList from './screens/task/TaskList';
import { AuthContext } from './shared/auth/authContext';
import { Error_404 } from './screens/errorHandler';
import PrivateRoute from './shared/auth/PrivateRoute';
import Avatar from 'antd/lib/avatar/avatar';
import ClientList from './screens/client/ClientList';
// import PEAPIComp from './screens/peApi';
import EnrichScreen from './screens/enrich/EnrichScreen';
import { APP_STANDARD_DATE_FORAMT } from '../src/config/constants';
import PropTypes from 'prop-types';
import { creditService } from './service/credit.service';
import { numberWithCommas } from './config/constantMethod';
import Dashboard from './screens/dashboard/Dashboard';
import { ShowOopsMsg } from './common/messageComp';
import FooterLayout from './FooterLayout';
import { StopAnimateIcon, changeColor } from './common/animatedIcon';
import withTracker from './shared/withTracker';
import ConnectorLayer from './screens/connector/ConnectorLayer';
import useQueryParam from './hooks/useQueryParam';
import BrowserWarning from './common/warning/BrowserWarning';
import { isUnSupportedBrowser } from './utilities/commonUtils';

const { Header, Content, Footer } = Layout;

const Home = (props) => {
  const {
    loggedInUser,
    isUserInRole,
    logout,
    isAllowed,
    userPermissions,
    login,
    isLogoutDisable,
    clearSession,
  } = React.useContext(AuthContext);
  const location = useLocation();
  const year = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [superAdminLoggedIn, setSuperAdminLoggedIn] = useState(false);

  useEffect(() => {
    let isSuperAdmin = isUserInRole('Super Admin');
    setSuperAdminLoggedIn(isSuperAdmin);
    if (loggedInUser && !isSuperAdmin) {
      onRefreshCreditAvail();
    }
  }, []);

  const onRefreshCreditAvail = (key) => {
    if (key !== undefined) {
      setIsLoading(true);
    }
    creditService
      .getClientCreditBalance(loggedInUser.clientId)
      .then((response) => {
        if (response.status === 200) {
          setCreditsAvailable(response.data.creditsAvailable);
          if (key !== undefined) {
            message.destroy();
            message.success('Credits updated');
            setIsLoading(false);
          } else {
            message.destroy();
            setIsLoading(false);
          }
        } else {
          message.destroy();
          setIsLoading(false);
          setCreditsAvailable(null);
        }
      })
      .catch((err) => {
        if (key !== undefined) {
          message.destroy();
          setIsLoading(false);
          ShowOopsMsg();
        }
        console.log('error in getCreditsBalance', err);
      })
      .finally(() => {});
  };

  const onClickCreditsFetch = () => {
    if (!superAdminLoggedIn) {
      onRefreshCreditAvail();
    }
  };

  // lastlogin is change when user is loging our system So can't take latest
  const localStorage = window.localStorage.getItem('__dg_token');
  const userInfo = JSON.parse(localStorage);
  if (userInfo == null) {
    console.log('clear Session Called');
    clearSession();
    // window.close();
    return;
  }
  const lastLogin = userInfo.lastLogin;
  const clientName = userInfo.clientName;

  const menu = (
    <Menu className="menuBg">
      <Menu.Item>
        <span className="fontSize11">Org Name</span>
        <div className="label-text">{clientName}</div>
      </Menu.Item>
      <Divider style={{ margin: '2px' }} />
      <Menu.Item>
        <span className="fontSize11">Last Login</span>
        <div className="label-text">
          {moment.utc(lastLogin).local().format(APP_STANDARD_DATE_FORAMT)}
        </div>
      </Menu.Item>
      <Divider style={{ margin: '2px' }} />
      {!superAdminLoggedIn && (
        <>
          <Menu.Item>
            <span className="fontSize11">Credits Available</span>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="label-text">
                {' '}
                {creditsAvailable !== null
                  ? numberWithCommas(creditsAvailable)
                  : '---'}
              </div>
              <Tooltip
                title={<span className="gray">Refresh Credits</span>}
                placement="topRight"
                color={'white'}>
                <span
                  className="fontSize11"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}>
                  <SyncOutlined
                    className="creditsLoader"
                    style={{ color: isLoading ? '#4293D2' : '#454545' }}
                    spin={isLoading ? true : false}
                    onClick={() => onRefreshCreditAvail('onRefresh')}
                  />
                </span>
              </Tooltip>
            </div>
          </Menu.Item>
          {!isLogoutDisable && <Divider style={{ margin: '2px' }} />}
        </>
      )}
      {/* <Menu.Item>
        <Button type="link" size="middle" icon={<UserOutlined />}>
          Profile
        </Button>
      </Menu.Item> */}
      {!isLogoutDisable && (
        <Menu.Item>
          <Link onClick={logout} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              key="logout"
              icon={<PoweroffOutlined />}
              style={{ padding: 0 }}>
              Logout
            </Button>
          </Link>
        </Menu.Item>
      )}
      {/* <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.tmall.com/">
          3rd menu item
        </a>
      </Menu.Item> */}
    </Menu>
  );

  const nameparts = [loggedInUser.firstName, loggedInUser.lastName];
  const profileName =
    nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();
  const userProfileName =
    'Hi, ' + nameparts[0].charAt(0).toUpperCase() + nameparts[0].slice(1);
  const userName = nameparts[0].charAt(0).toUpperCase() + nameparts[0].slice(1);
  // let pathname = props.location.pathname;

  return (
    <Layout id="header-layout">
      <Header
        className="dfm-header"
        style={{
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          boxShadow: '0px 4px 8px 1px rgba(0, 0, 0, 0.1)',
          lineHeight: '65px',
        }}>
        <Menu
          mode="horizontal"
          selectedKeys={[location.pathname]}
          className="menu-style">
          {!isUserInRole('Super Admin') && (
            <Menu.Item
              key="/dashboard"
              className="homeDashboardIcon"
              onMouseOver={() =>
                changeColor('in', 'dashboardIcon', '/dashboard')
              }
              onMouseOut={() =>
                changeColor('out', 'dashboardIcon', '/dashboard')
              }>
              <Link to="/dashboard" className="homeimg">
                <span>
                  {StopAnimateIcon(
                    'icons/1388-page-view-quilt-outline-edited.json',
                    'dashboardIcon',
                    '/dashboard'
                  )}
                </span>{' '}
                <span>Overview</span>
              </Link>
            </Menu.Item>
          )}
          {isAllowed('VIEW_TASK') && (
            <Menu.Item
              key="/task-list"
              className="homeTaskListIcon"
              onMouseOver={() =>
                changeColor('in', 'tasklistIcon', '/task-list')
              }
              onMouseOut={() =>
                changeColor('out', 'tasklistIcon', '/task-list')
              }>
              <Link to="/task-list">
                <span>
                  {StopAnimateIcon(
                    'icons/47-to-do-list-outline-edited.json',
                    'tasklistIcon',
                    '/task-list'
                  )}
                </span>{' '}
                <span>Bulk Enrich List</span>
              </Link>
            </Menu.Item>
          )}
          {/* {isAllowed('VIEW_USER') && (
            <Menu.Item
              key="/manage-users"
              className="homeUsersIcon"
              onMouseOver={() =>
                changeColor('in', 'manageUsersIcon', '/manage-users')
              }
              onMouseOut={() =>
                changeColor('out', 'manageUsersIcon', '/manage-users')
              }>
              <Link to="/manage-users">
                <span>
                  {StopAnimateIcon(
                    'icons/276-female-and-male-outline-edited.json',
                    'manageUsersIcon',
                    '/manage-users'
                  )}
                </span>{' '}
                <span>Manage Users</span>
              </Link>
            </Menu.Item>
          )} */}
          {isAllowed('VIEW_CLIENT_ACCOUNT') && (
            <Menu.Item
              key="/manage-clients"
              onMouseOver={() =>
                changeColor('in', '/manageClientsIcon', '/manage-clients')
              }
              onMouseOut={() =>
                changeColor('out', '/manageClientsIcon', '/manage-clients')
              }>
              <Link to="/manage-clients">
                <span>
                  {StopAnimateIcon(
                    'icons/411-news-newspaper-outline-edited.json',
                    '/manageClientsIcon',
                    '/manage-clients'
                  )}
                </span>{' '}
                <span>Manage Org</span>
              </Link>
            </Menu.Item>
          )}
          {isAllowed('VIEW_ENRICH') && (
            <Menu.Item
              key="/enrich"
              className="homeEnrichIcon"
              onMouseOver={() => changeColor('in', 'enrichIcon', '/enrich')}
              onMouseOut={() => changeColor('out', 'enrichIcon', '/enrich')}>
              <Link to="/enrich">
                <span>
                  {StopAnimateIcon(
                    'icons/220-arrow-9-edited.json',
                    'enrichIcon',
                    '/enrich'
                  )}
                </span>{' '}
                <span>Record Enricher</span>
              </Link>
            </Menu.Item>
          )}
          {isAllowed('INTEGRATION_LIST') && (
            <Menu.Item
              key="/integrations"
              className="homeEnrichIcon"
              onMouseOver={() =>
                changeColor('in', 'integrationsIcon', '/integrations')
              }
              onMouseOut={() =>
                changeColor('out', 'integrationsIcon', '/integrations')
              }>
              <Link to="/integrations">
                <span>
                  {StopAnimateIcon(
                    'icons/963-multichannel-outline-edited.json',
                    'integrationsIcon',
                    '/integrations'
                  )}
                </span>{' '}
                <span>Integrations</span>
              </Link>
            </Menu.Item>
          )}
          <span className="global-header-right">
            {/* <Button
              type="link"
              key="logout"
              icon={<PoweroffOutlined />}
              onClick={logout}>
              Logout
            </Button> */}

            <Dropdown
              overlay={menu}
              placement="bottomRight"
              overlayClassName="userDropDownMenu"
              trigger="click"
              onClick={() => onClickCreditsFetch()}>
              <Button className="userProfile_btn">
                <DownOutlined className="downProfileIcon" />{' '}
                <span>
                  <Tooltip
                    color={'white'}
                    placement="bottom"
                    title={<span className="gray">{`${userName}`}</span>}
                    // color="#4293D2"
                  >
                    <span className="userProfileName_text">
                      &nbsp;{userProfileName}
                    </span>
                  </Tooltip>
                </span>
                <Avatar className="userProfileIcon">{profileName}</Avatar>
              </Button>
            </Dropdown>
          </span>
        </Menu>
      </Header>
      {isUnSupportedBrowser() && (
        <div style={{ marginTop: '68px' }}>
          <BrowserWarning />
          <div className="browser-warning-space"></div>
        </div>
      )}
      <Content
        className="site-layout"
        style={{ padding: '10px', marginTop: 64 }}>
        <div className="site-layout-background" style={{ padding: 0 }}>
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/logout" render={() => logout()} />
              <Route exact path="/">
                {!isUserInRole('Super Admin') ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Redirect to="/task-list" />
                )}
              </Route>
              <Route exact path="/login">
                {!isUserInRole('Super Admin') ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Redirect to="/task-list" />
                )}
              </Route>
              <PrivateRoute
                exact
                path="/dashboard"
                userPermissions="VIEW_TASK"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/task-list"
                userPermissions="VIEW_TASK"
                component={TaskList}
              />
              <PrivateRoute
                exact
                path="/manage-users"
                userPermissions="VIEW_USER"
                component={UserList}
              />
              <PrivateRoute
                exact
                path="/manage-clients"
                userPermissions="VIEW_CLIENT_ACCOUNT"
                component={ClientList}
              />
              <PrivateRoute
                exact
                path="/enrich"
                userPermissions="VIEW_ENRICH"
                component={EnrichScreen}
              />
              <PrivateRoute
                exact
                path="/integrations"
                userPermissions="INTEGRATION_LIST"
                component={ConnectorLayer}
              />
              {/* <Route path="*" component={NotFound} /> */}
              <Route path="*" component={Error_404} />
            </Switch>
          </AnimatePresence>
        </div>
      </Content>
      <Footer>
        <FooterLayout
          footerrecordenricher={
            location.pathname == '/enrich' ? 'footer-record-enricher' : null
          }
          footertasklistnew={
            location.pathname == '/task-list' ? 'footer-task-list-new' : null
          }
        />
        {/* <div
          className="logo-footer"
          style={{
            zIndex: 1000,
            position: 'fixed',
            bottom: 0,
          }}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload(false);
            }}>
            <img src={logo} alt="Enrich" style={{ width: '140px' }} />
          </a>
        </div> */}
        {/* <div
          id="footer-layout"
          className="site-layout-footer"
          style={{
            display: 'none',
            justifyContent: 'space-between',
          }}>
          <div>
            {pathname == '/task-list' && (
              <div className="copy-text">
                Files will be available to download for 180 days | Stats will be
                available for 365 days
              </div>
            )}
          </div>

          <div className="copy-text ">
            © <span>{year}</span> All rights reserved | Enrich OnDemand
            empowered by SMARTe Inc.
          </div>
        </div> */}
      </Footer>
    </Layout>
  );
};

Home.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Home);
