import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShowHideTextComp from './ShowHideTextComp';

const UserDetailsComp = ({ contact, dynamicData }) => {
  return (
    <div className="repeater">
      {contact !== undefined && contact !== null && contact.length > 0 ? (
        <div className="repeater mTop10">
          {contact.map((item, i) => {
            return (
              <DataShowDiv
                data={item.value}
                name={item.name}
                key={i}
                responsekey={item.responsekey}
              />
            );
          })}
        </div>
      ) : (
        '---'
      )}
      {dynamicData !== undefined &&
        dynamicData.length > 0 &&
        dynamicData.map((item, i) => {
          if (item.name !== 'currentTenure')
            return (
              <DataShowDiv
                data={item.value}
                name={item.name}
                key={i}
                responsekey={item.responsekey}
              />
            );
        })}
    </div>
  );
};

UserDetailsComp.propTypes = {
  contact: PropTypes.object,
  dynamicData: PropTypes.array,
};

export default UserDetailsComp;

const DataShowDiv = (data, name) => {
  return (
    <>
      <ShowHideTextComp data={data} />
    </>
  );
};
