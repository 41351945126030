import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Layout, Menu, Tabs } from 'antd';
import ConnectorContext from '../../../context/ConnectorContext';
import ConnectionDetails from '../marketo/components/ConnectionDetails';
import marketo from '../../../assets/images/marketo-logo.png';
import './marketo.css';
import ReadMappingLayer from './components/FieldMapping/ReadMapping/ReadMappingLayer';

const { Sider, Content } = Layout;
const { TabPane } = Tabs;
const MarketoScreen = ({ isConnected }) => {
  const { requestToOpenScreen } = useContext(ConnectorContext);

  const [menuKey, setMenuKey] = useState('ConnectionDetails');

  const oneLineStyle = {
    position: 'absolute',
    right: '20px',
    top: '90px',
  };
  const marginRightOneLineStyle = {
    width: '200px',
    textAlign: 'right',
    fontSize: '33px',
    color: '#808080ad',
  };

  const onOpenForm = (values) => {
    setMenuKey(values.key);
  };

  return (
    <>
      <div>
        <div style={{ display: 'flex' }}>
          <h2 style={marginRightOneLineStyle}>
            <b>Connect with</b>
          </h2>
          <div style={{ marginLeft: '20px' }}>
            <img src={marketo} alt="Marketo" height="auto" width="150px" />
          </div>
          <div style={oneLineStyle}>
            <Button
              type="primary"
              onClick={() => {
                requestToOpenScreen('ConnectorScreen');
              }}>
              Back
            </Button>
          </div>
        </div>
        <div>
          <Tabs tabPosition={'left'} className="marketoTabs">
            <TabPane tab="Connection Details" key="1">
              <ConnectionDetails />
            </TabPane>
            <TabPane
              tab="Read Field Mapping"
              key="2"
              disabled={isConnected ? false : true}>
              <ReadMappingLayer />
            </TabPane>
            <TabPane tab="Write Field Mapping" key="3" disabled={true}>
              Content of Tab 3
            </TabPane>
          </Tabs>
          {/* <Layout>
            <Sider>
              <Menu
                className="connectorMenu"
                mode="inline"
                dashed={true}
                defaultSelectedKeys={['ConnectionDetails']}
                onClick={onOpenForm}>
                <Menu.Item key="ConnectionDetails">
                  Connection Details
                </Menu.Item>
                <Menu.Item key="2">Read Field Mapping</Menu.Item>
                <Menu.Item key="3">Write Field Mapping</Menu.Item>
              </Menu>
            </Sider>
            <Content
              style={{
                padding: '0px 30px',
                // border: '2px solid',
              }}>
              {menuKey === 'ConnectionDetails' ? <ConnectionDetails /> : null}
            </Content>
          </Layout> */}
        </div>
      </div>
    </>
  );
};

MarketoScreen.propTypes = {
  isConnected: PropTypes.bool,
};

export default MarketoScreen;
