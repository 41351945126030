import axios from 'axios';
import config from '../config/constants';
const taskUrl = config.env.taskServerUrl;

const userUrl = config.env.userServerUrl;

export const dashboardService = {
  getRecordStatsForAllTasks,
  getSubscriptionInfo,
  sendCreditsRequest,
  getUsersList,
};

function getRecordStatsForAllTasks(count) {
  return axios.get(
    `${taskUrl}/api/v1/tasks/stats/MATCHBACK?taskCount=${count}`
  );
}

function getSubscriptionInfo() {
  return axios.get(`${userUrl}/v1/clients/subscription`);
}

function sendCreditsRequest(credits) {
  return axios.post(
    `${userUrl}/v1/clients/add-credit-request?credits=${credits}`
  );
}

function getUsersList() {
  return axios.get(`${userUrl}/v1/users/minimal`);
}
