import { UAParser } from 'ua-parser-js';
import { supportedBrowsers } from '../config/constants';

export const isUnSupportedBrowser = () => {
  const uaParser = new UAParser();
  if (
    uaParser.getBrowser().name !== supportedBrowsers.CHROME &&
    uaParser.getBrowser().name !== supportedBrowsers.FIREFOX &&
    uaParser.getBrowser().name !== supportedBrowsers.EDGE
  ) {
    return true;
  }
  return false;
};
