import React, { useState } from 'react';
import { Table, Tooltip, Alert, Switch } from 'antd';
import DropdownFields from './DropdownFields';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';

const duplicateText = 'Duplicate column';
function GridViewMappingStep(props) {
  const [dataindex, setDataIndex] = useState();
  // filter field pass to dropdown list

  //filter field pass to show selected value in dropdown

  if (props.step !== 1) {
    return null;
  }

  const getSpecificNumberOfRows = (fileData) => {
    let lowerLimit = 0;
    let upperLimit = 11;

    return fileData.slice(lowerLimit, upperLimit);
  };

  if (props.step === 1) {
    return (
      <div>
        <div
          style={{
            width: '100%',
            boxSizing: 'border-box',
            maxHeight: 'calc(100vh - 140px)',
            paddingTop: 20,
          }}>
          {props.isDuplicate ? (
            <div
              style={{
                fontSize: '1vw',
                color: '#fd00007a',
              }}>
              <span>
                <Alert
                  style={{ padding: '5px', width: '96%' }}
                  className="warningAlert"
                  message="Duplicate column names observed in input file. Please be careful
                while mapping the fields."
                  type="warning"
                  showIcon
                  closable
                />
              </span>
            </div>
          ) : null}
          <div
            style={{
              fontSize: '14px',
              lineHeight: '16px',
              color: '#545454',
            }}>
            Map more fields, for better match rate and enrichment
          </div>
          {/* <div
            style={{
              fontSize: '0.82vw',
              textAlign: 'center',
              padding: '5px 0',
              color: '#868889',
            }}>
            All original information of mapped and non-mapped fields will be
            delivered along with our match fields, so no worries stitching input
            and output data
          </div> */}

          <div
            style={{
              padding: '5px 0',
              color: '#868889',
              display: 'flex' /* establish flex container */,
              justifyContent:
                'space-around' /* switched from default (flex-start, see below) */,
              fontSize: '0.8vw',
            }}>
            <div
              style={{
                float: 'left',
              }}>
              <p
                style={{
                  margin: '1px',
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#545454',
                }}>
                {props.defaultIconCompanyMatchback ? (
                  props.matchBackBlock[0].status ? (
                    <CheckCircleFilled style={{ color: '#1ADE80' }} />
                  ) : (
                    <CheckCircleFilled style={{ color: '#bbb' }} />
                  )
                ) : (
                  <CloseCircleFilled style={{ color: '#E64E31' }} />
                )}
                &nbsp;One of below is mandatory input for{' '}
                <b>Company Matchback</b>
              </p>
              <p
                style={{
                  paddingLeft: '20px',
                  margin: '1px',
                  fontSize: '14px',
                  lineHeight: '16px',
                }}>
                {props.companyMatchback.map((item) => (
                  <>
                    {props.defaultIconCompanyMatchback ? (
                      item.value ? (
                        <CheckCircleTwoTone twoToneColor="#1ADE80" />
                      ) : (
                        <span className="fieldValidationDefaultIcon" />
                      )
                    ) : (
                      <CloseCircleTwoTone twoToneColor="#E64E31" />
                    )}
                    &nbsp;&nbsp;
                    <label
                      style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        marginLeft: '10px',
                      }}>
                      {item.name}
                    </label>
                    <br />
                  </>
                ))}
              </p>
            </div>
            <div
              style={{
                float: 'right',
              }}>
              <p
                style={{
                  margin: '1px',
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#545454',
                }}>
                {props.defaultIconContactMatchback ? (
                  props.matchBackBlock[1].status ? (
                    <CheckCircleFilled style={{ color: '#1ADE80' }} />
                  ) : (
                    <CheckCircleFilled style={{ color: '#ddd' }} />
                  )
                ) : (
                  <CloseCircleFilled style={{ color: '#E64E31' }} />
                )}
                &nbsp;One of below is mandatory input for{' '}
                <b>Contact Matchback</b>
              </p>
              <p
                style={{
                  paddingLeft: '20px',
                  margin: '1px',
                  lineHeight: '16px',
                  fontSize: '14px',
                }}>
                {props.contactMatchback.map((item) => (
                  <>
                    {props.defaultIconContactMatchback ? (
                      item.value ? (
                        <CheckCircleTwoTone twoToneColor="#1ADE80" />
                      ) : (
                        <span className="fieldValidationDefaultIcon" />
                      )
                    ) : (
                      <CloseCircleTwoTone twoToneColor="#E64E31" />
                    )}
                    &nbsp;&nbsp;
                    <label
                      style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        marginLeft: '10px',
                      }}>
                      {item.name}
                    </label>
                    <br />
                  </>
                ))}
              </p>
            </div>
          </div>

          <div>
            <Table
              rowKey="id"
              dataSource={getSpecificNumberOfRows(props.fileData)}
              columns={props.getHeaders()}
              bordered
              size="small"
              className="fieldMapTable"
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GridViewMappingStep;
