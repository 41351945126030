import React, { useContext, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Steps, Divider, Card } from 'antd';
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  UploadOutlined,
  PartitionOutlined,
  FileDoneOutlined,
  RightOutlined,
  PicRightOutlined,
} from '@ant-design/icons';
import FieldMappingStep from './FieldMappingStep';
import TaskSummaryStep from './TaskSummaryStep';
import FileUploadStep from './FileUploadStep';
import EnrichModeStep from './EnrichModeStep';
import PropTypes from 'prop-types';
import { autoFieldValue } from './AutoFieldValue';
import { AuthContext } from '../../../shared/auth/authContext';
import {
  enrichModeArray,
  fieldMappingArray,
  fieldMappingimg,
  enrichModeImg,
  taskSummaryImg,
  DenrichModeImg,
  DfieldMappingimg,
  DtaskSummaryImg,
  FileUpload,
} from '../../../config/constants';
import { taskService } from '../../../service/task.service';
import { ShowOopsMsg } from '../../../common/messageComp';
// import ListViewStep from './ListViewStep';

const { Step } = Steps;

function AddTaskWizard(props) {
  const { isAllowed, userPermissions } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [stepIncomplete, setStepIncomplete] = useState(true);
  //to get credit balance in task summary
  const [isCallingCreditBalance, setIsCallingCreditBalance] = useState(false);
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
  const [alertLowBalanceMessage, setAlertLowBalanceMessage] = useState('');
  const [isCreditsAvailableZero, setIsCreditsAvailableZero] = useState(false);

  const [fileName, setFileName] = useState('');
  const [taskName, setTaskName] = useState('');
  const [csvFile, setCSVFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [excelSheetName, setExcelSheetName] = useState('');
  const [newFileName, setNewFileName] = useState('');
  const [matchBackBlock, setMatchBackBlock] = useState([
    { name: 'companyMatchback', status: null },
    { name: 'contactMatchback', status: null },
  ]);

  const [companyMatchback, setCompanyMatchback] = useState([
    // { name: 'Email', fieldMap: 'Email ID', value: false },
    { name: 'Company Name', fieldMap: 'Company Name', value: false },
    { name: 'Website', fieldMap: 'Website', value: false },
  ]);

  const [
    companyMatchbackAdditionalFields,
    setCompanyMatchbackAdditionalFields,
  ] = useState([
    { name: 'Country', fieldMap: 'Country', value: false },
    { name: 'Job Title', fieldMap: 'Job Title', value: false },
    { name: 'Company GUID', fieldMap: 'Company GUID', value: false },
    { name: 'Contact GUID', fieldMap: 'Contact GUID', value: false },
    {
      name: 'Contact LinkedIn URL',
      fieldMap: 'Contact LinkedIn URL',
      value: false,
    },
    {
      name: 'Company LinkedIn URL',
      fieldMap: 'Company LinkedIn URL',
      value: false,
    },
    {
      name: 'First Name',
      fieldMap: 'First Name',
      value: false,
    },
    {
      name: 'Last Name',
      fieldMap: 'Last Name',
      value: false,
    },
  ]);

  const [contactMatchback, setContactMatchback] = useState([
    {
      name: 'Email',
      fieldMap: 'Email ID',
      value: false,
    },
    {
      name: 'Contact Full Name',
      fieldMap: 'Full Name',
      value: false,
    },
    {
      name: 'Contact First Name and Last Name',
      fieldMap: [
        { fieldMapName: 'First Name', value: false },
        { fieldMapName: 'Last Name', value: false },
      ],
      value: false,
    },
  ]);
  const [
    defaultIconCompanyMatchback,
    setDefaultIconCompanyMatchback,
  ] = useState(true);
  const [
    defaultIconContactMatchback,
    setDefaultIconContactMatchback,
  ] = useState(true);
  //check Duplicate fields
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateElementArray, setDuplicateElementArray] = useState([]);

  // field that give to user for mapping
  const [fieldMapping, setFieldMapping] = useState([]);

  //enrich mode value give to user for enrich mode screen
  const [enrichModeValue, setEnrichModeValue] = useState([]);
  const [isPrevBtnCall, setIsPrevBtnCall] = useState(false);

  useEffect(() => {
    setPermissionBaseValue();
  }, []);

  //when component render according to permission we get fieldmapping array
  const setPermissionBaseValue = () => {
    //to get mapping fields array
    const MappingFields = fieldMappingArray.filter((fieldMappingItem) => {
      return userPermissions().some(
        (permissionItem) =>
          permissionItem.toLowerCase().trim() ===
          fieldMappingItem.name.toLowerCase().trim()
      );
    });

    //set fieldmap array
    setFieldMapping(MappingFields);
    //set enrichMode array
    if (props.steps.includes(2)) {
      setEnrichModeValue(enrichModeArray);
    }
    checkFieldBlockMap();
  };

  // to send map field name to tasksummary
  const mapFieldName = fieldMapping.reduce((acc, cur) => {
    return { ...acc, [cur.name]: cur.mappedBy };
  }, {});

  /* commented this code cause In task details/summary 
          we want to show all mapped or unmapped field
  //filter fieldMapping array to pass to server only mapped value
  const fieldMap = fieldMapping.filter((item) => {
    return item.mappedBy !== null;
  });
  */

  //filter enrichModeValue array to pass to server
  const enrichModeRuleMapping = enrichModeValue
    .map((enrichModeItem) => {
      if (
        isAllowed('EMAIL_VERIFIER_RUN') &&
        enrichModeItem.name === 'Email Verifier Run'
      ) {
        return { headerKey: 'ev-value', headerValue: enrichModeItem.value };
      }
      if (
        isAllowed('COMP_MATCHBACK_PROCESS') &&
        enrichModeItem.name === 'Company Match Process'
      ) {
        return {
          headerKey: 'compmb-value',
          headerValue: enrichModeItem.value,
        };
      }
      if (
        isAllowed('COMP_INDEX_REACH') &&
        enrichModeItem.name === 'Company Index Reach'
      ) {
        return {
          headerKey: 'index-value',
          headerValue: enrichModeItem.value,
        };
      }
      if (
        isAllowed('EXPORT_FIELDS') &&
        enrichModeItem.name === 'Export Fields'
      ) {
        return {
          headerKey: 'output-value',
          headerValue: enrichModeItem.value,
        };
      }
      // if (isAllowed('DIRECT_DIAL') && enrichModeItem.name === 'Enrich Type') {
      //   return {
      //     headerKey: 'direct-dial-run',
      //     headerValue: enrichModeItem.value,
      //   };
      //}
    })
    .filter((item) => item !== undefined);

  //auto field mapping based on our redefine values
  const autoFieldMapping = (userArray) => {
    setIsDuplicate(false);
    // fieldMapping is reset for drag& drop Approch
    if (fieldMapping.length !== 0) {
      fieldMapping.forEach((item) => {
        if (item.position !== null) {
          item.position = null;
          item.mappedBy = null;
        }
      });
    }

    let sorted_arr = userArray.slice().sort();
    let duplicate_sorted_arr = [];
    //check input file contains duplicate field
    for (let i = 0; i < sorted_arr.length; i++) {
      let duplicateCount = 0;
      for (let j = 0; j < sorted_arr.length; j++) {
        let itemOfFirstLoop = sorted_arr[i].toLowerCase().trim();
        let itemOfSecondLoop = sorted_arr[j].toLowerCase().trim();
        if (itemOfFirstLoop == itemOfSecondLoop) {
          duplicateCount++;
        }
      }
      if (duplicateCount > 1) {
        setIsDuplicate(true);
        duplicate_sorted_arr.push(sorted_arr[i]);
      }
    }
    duplicate_sorted_arr.map((item) => {
      setDuplicateElementArray((oldArray) => [...oldArray, item]);
    });

    //auto map and check duplicate field mapping present in predefine array
    autoFieldValue.forEach((autoFieldValueItems) => {
      fieldMapping.forEach((fieldMappingItem) => {
        if (
          fieldMappingItem.name.toLowerCase().trim() ===
          autoFieldValueItems.name.toLowerCase().trim()
        ) {
          let singleElement = 0;
          let position = '';
          let mappedBy = '';
          let duplicateElement = [];

          // for loop is apply for break the iteration
          for (let i = 0; i < userArray.length; i++) {
            let userArrayValue = userArray[i].toLowerCase().trim();
            if (autoFieldValueItems.values.includes(userArrayValue)) {
              duplicateElement.push(userArray[i]);
              singleElement++;
              position = `${i}`;
              mappedBy = userArray[i];
            }
          }
          if (singleElement === 1) {
            fieldMappingItem.position = position;
            fieldMappingItem.mappedBy = mappedBy;
          }
          if (singleElement > 1) {
            setIsDuplicate(true);
            duplicateElement.map((item) => {
              setDuplicateElementArray((oldArray) => [...oldArray, item]);
            });
          }
        }
      });
    });

    feildValidation();
  };

  //field validation of companyMatchback and contactMatchback
  const feildValidation = (selectedItem) => {
    fieldMapping.forEach((fieldValueItems) => {
      //set companyMatchback value
      let CompanyMB = companyMatchback.map((companyMatchbackItem) => {
        if (
          fieldValueItems.name.toLowerCase().trim() ===
          companyMatchbackItem.fieldMap.toLowerCase().trim()
        ) {
          if (fieldValueItems.position === null) {
            companyMatchbackItem.value = false;
          } else {
            companyMatchbackItem.value = true;
            setDefaultIconCompanyMatchback(true);
          }
        }
        return companyMatchbackItem;
      });

      let additionalFields = companyMatchbackAdditionalFields.map(
        (companyMatchbackItem) => {
          if (
            fieldValueItems.name.toLowerCase().trim() ===
            companyMatchbackItem.fieldMap.toLowerCase().trim()
          ) {
            if (fieldValueItems.position === null) {
              companyMatchbackItem.value = false;
            } else {
              companyMatchbackItem.value = true;
              setDefaultIconCompanyMatchback(true);
              setDefaultIconContactMatchback(true);
            }
          }
          return companyMatchbackItem;
        }
      );
      if (CompanyMB !== undefined) {
        setCompanyMatchback(CompanyMB);
      }

      if (additionalFields !== undefined) {
        setCompanyMatchbackAdditionalFields(additionalFields);
      }

      //set contactMatchback value
      let ContactMB = contactMatchback.map((contactMatchbackItem) => {
        if (Array.isArray(contactMatchbackItem.fieldMap)) {
          contactMatchbackItem.fieldMap.forEach((fieldMap) => {
            if (
              fieldValueItems.name.toLowerCase().trim() ===
              fieldMap.fieldMapName.toLowerCase().trim()
            ) {
              if (fieldValueItems.position === null) {
                fieldMap.value = false;
              } else {
                fieldMap.value = true;
              }
            }
          });
        } else {
          if (
            fieldValueItems.name.toLowerCase().trim() ===
            contactMatchbackItem.fieldMap.toLowerCase().trim()
          ) {
            if (fieldValueItems.position === null) {
              contactMatchbackItem.value = false;
            } else {
              contactMatchbackItem.value = true;
              setDefaultIconContactMatchback(true);
            }
          }
        }
        return contactMatchbackItem;
      });
      if (ContactMB !== undefined) {
        setContactMatchback(ContactMB);
      }
    });
    // If first name and last name is set
    if (
      contactMatchback.filter((contactMatchbackItem) => {
        if (Array.isArray(contactMatchbackItem.fieldMap)) {
          return contactMatchbackItem.fieldMap.every((fieldMap) => {
            return fieldMap.value === true;
          });
        }
      }).length !== 0
    ) {
      contactMatchback.forEach((contactMatchbackItem) => {
        if (contactMatchbackItem.name === 'Contact First Name and Last Name') {
          contactMatchbackItem.value = true;
          setDefaultIconContactMatchback(true);
        }
      });
    } else {
      contactMatchback.forEach((contactMatchbackItem) => {
        if (contactMatchbackItem.name === 'Contact First Name and Last Name') {
          contactMatchbackItem.value = false;
        }
      });
    }
    // CheckDefaultValue();
    checkFieldBlockMap();
  };

  const CheckDefaultValue = () => {
    let comMB = defaultIconCompanyMatchback;
    let conMB = defaultIconContactMatchback;
    if (currentStep === 1) {
      if (comMB && conMB) {
        setStepIncomplete(false);
      } else {
        setStepIncomplete(true);
      }
    }
  };

  //new file or remove the file then clr set all data
  const removeAllSetData = () => {
    //reset all duplicate array
    setDuplicateElementArray([]);

    //to reset mapping field value
    fieldMapping.forEach((item) => {
      if (item.position !== null) {
        item.position = null;
        item.mappedBy = null;
      }
    });

    //to reset enrich mode value
    enrichModeValue.forEach((enrichModeItem) => {
      if (enrichModeItem.name === 'Email Verifier Run') {
        enrichModeItem.value = 'enl';
      }
      if (enrichModeItem.name === 'Company Match Process') {
        enrichModeItem.value = 'EXT';
      }
      if (enrichModeItem.name === 'Company Index Reach') {
        enrichModeItem.value = 'FUL';
      }
      if (enrichModeItem.name === 'Export Fields') {
        enrichModeItem.value = 'LTD';
      }
      // if (enrichModeItem.name === 'Enrich Type') {
      //   enrichModeItem.value = 'without-direct-dial';
      // }
    });

    //to reset companyMatchback value
    companyMatchback.forEach((companyMatchbackItem) => {
      companyMatchbackItem.value = false;
    });

    //to reset companyMatchback value
    contactMatchback.forEach((contactMatchbackItem) => {
      contactMatchbackItem.value = false;
      if (Array.isArray(contactMatchbackItem.fieldMap)) {
        contactMatchbackItem.fieldMap.forEach((fieldMap) => {
          fieldMap.value = false;
        });
      }
    });
    setDefaultIconCompanyMatchback(true);
    setDefaultIconContactMatchback(true);
  };

  //Next button method
  function nextStep() {
    let count = 1;
    // Ugly way to skip a step, this assumes only next step is to be skipped
    if (
      !props.steps.includes(currentStep + 1) &&
      currentStep < props.steps.length - 1
    ) {
      count++;
    }
    if (currentStep === props.steps[props.steps.length - 2]) {
      getTotalCreditsBalance();
    }
    // ugly way to call auto field mapping only when user drag the file and prevent to stop calling again
    if (currentStep === 0 && !isPrevBtnCall) {
      autoFieldMapping(headerData);
    }
    if (currentStep === 1) {
      let checkCompanyMatchback = companyMatchback.some(
        (companyMatchbackItem) => companyMatchbackItem.value === true
      );
      console.log(companyMatchback);
      console.log(companyMatchbackAdditionalFields);
      let checkAddtionalFields = companyMatchbackAdditionalFields.some(
        (companyMatchbackItem) => companyMatchbackItem.value === true
      );

      let checkContactMatchback = contactMatchback.some(
        (contactMatchbackItem) => contactMatchbackItem.value === true
      );
      if (!checkCompanyMatchback && !checkContactMatchback) {
        setDefaultIconCompanyMatchback(false);
        // }
        // if (!checkContactMatchback) {
        setDefaultIconContactMatchback(false);
      }

      console.log('Company ', checkCompanyMatchback);
      console.log('Contact ', checkContactMatchback);
      console.log('checkAddtionalFields ', checkAddtionalFields);
      if (
        checkCompanyMatchback ||
        checkContactMatchback ||
        checkAddtionalFields
      ) {
        setCurrentStep((prevCount) => prevCount + count);
      }
    } else {
      setCurrentStep((prevCount) => prevCount + count);
    }
  }

  //call to find credits balance
  const getTotalCreditsBalance = () => {
    setIsCallingCreditBalance(true);
    disableNext();
    //to set DD is enabled or not for any user
    let isDDenabled = true;
    // enrichModeValue.some(
    //   (enrichModeItem) =>
    //     enrichModeItem.value === 'with-direct-dial' && isAllowed('DIRECT_DIAL')
    // );
    //to call API for getCreditsBalance
    taskService
      .getCreditsBalance(totalRecords)
      .then((response) => {
        setIsCallingCreditBalance(false);
        if (response.data.creditsAvailable === 0) {
          setStepIncomplete(true);
          setIsCreditsAvailableZero(true);
          setAlertLowBalanceMessage(
            'No credits available. Add credits to submit task.'
          );
        } else {
          setStepIncomplete(false);
          setIsCreditsAvailableZero(false);
          setAlertLowBalanceMessage(
            'Low credits balance, all records may not be processed. Add credits to process all records.'
          );
        }
        setCreditsAvailable(response.data.creditsAvailable);
        setLowBalanceAlert(response.data.lowBalanceAlert);
      })
      .catch((err) => {
        // console.log('error in getCreditsBalance', err.response.status);
        setIsCallingCreditBalance(false);
        setStepIncomplete(true);
        setLowBalanceAlert(false);
        message.destroy();
        ShowOopsMsg();
      })
      .finally(() => {});
  };

  //Previous button method
  function prevStep() {
    //to enable next button to move summary steps
    if (currentStep === props.steps[props.steps.length - 1]) {
      setStepIncomplete(false);
    }
    // Ugly way to skip a step, this assumes only prev step is to be skipped
    if (!props.steps.includes(currentStep - 1)) {
      setCurrentStep((prevCount) => prevCount - 1);
    }
    setCurrentStep((prevCount) => prevCount - 1);

    // Ugly way to prevent call autofieldmapping again on next btn
    setIsPrevBtnCall(true);
  }

  //disable next button if data is not send from FileUpload
  const disableNext = () => {
    setStepIncomplete(true);
  };

  //enabled next button if condition satisfy in FileUpload
  const enableNext = () => {
    setStepIncomplete(false);
  };

  //display next/submit button in Modal footer
  const nextActionButton = () => {
    return currentStep != props.steps[props.steps.length - 1] ? (
      <Button
        className="footer-btn"
        disabled={stepIncomplete}
        key="submit"
        type="primary"
        onClick={nextStep}>
        Next <DoubleRightOutlined />
      </Button>
    ) : (
      <Button
        className="footer-btn"
        disabled={stepIncomplete}
        key="submit"
        type="primary"
        onClick={submitStep}
        loading={props.isLoading}>
        Submit
      </Button>
    );
  };

  //display steps menu in Modal title
  const stepItems = () => {
    return (
      <Steps
        current={currentStep}
        type="navigation"
        size="small"
        className="addTask-upload-steps">
        <Step
          // className="FileUploadWizard"
          title={<span>File Upload</span>}
          icon={<img src={FileUpload} />}
          key={0}
        />
        <span style={{ paddingBottom: '10px' }}>
          <RightOutlined style={{ color: 'var(--blue)', fontSize: '17px' }} />
          <RightOutlined style={{ color: 'var(--blue)', fontSize: '17px' }} />
        </span>
        {props.steps.includes(1) && ( // 2nd step field mapping
          <>
            <Step
              // className="FieldMappingWizard"
              title={<span>Field Mapping</span>}
              icon={
                <img
                  src={currentStep > 0 ? fieldMappingimg : DfieldMappingimg}
                />
              }
              key={1}
            />
            <span style={{ paddingBottom: '10px' }}>
              <RightOutlined
                style={{
                  color: currentStep > 0 ? 'var(--blue)' : '',
                  fontSize: '17px',
                }}
              />
              <RightOutlined
                style={{
                  color: currentStep > 0 ? 'var(--blue)' : '',
                  fontSize: '17px',
                }}
              />
            </span>
          </>
        )}
        {props.steps.includes(2) && ( // 3rd step Enrich Mode
          <>
            <Step
              title={<span>Enrich Mode</span>}
              icon={
                <img src={currentStep > 1 ? enrichModeImg : DenrichModeImg} />
              }
              key={2}
            />
            <span style={{ paddingBottom: '10px' }}>
              <RightOutlined
                style={{
                  color: currentStep > 1 ? 'var(--blue)' : '',
                  fontSize: '17px',
                }}
              />
              <RightOutlined
                style={{
                  color: currentStep > 1 ? 'var(--blue)' : '',
                  fontSize: '17px',
                }}
              />
            </span>
          </>
        )}
        {props.steps.includes(3) && ( // Final Summary step
          <Step
            // className="TaskSummaryWizard"
            style={{ paddingLeft: '0px' }}
            title="Task Summary"
            icon={
              <span style={{ width: '100%', textAlign: 'center' }}>
                <img src={currentStep > 2 ? taskSummaryImg : DtaskSummaryImg} />
              </span>
            }
            key={3}
          />
        )}
      </Steps>
    );
  };

  //submit file data to parent component
  function submitStep() {
    props.submitUploadFile(
      csvFile,
      taskName,
      fieldMapping,
      excelSheetName,
      newFileName,
      fileName,
      enrichModeRuleMapping
    );
  }

  //get data from fileupload and active next button
  const getStepOneValue = (values) => {
    setFileName(values[0]);
    setTaskName(values[1]);
    setCSVFile(values[2]);
    setFileData(values[3]);
    setTotalRecords(values[4]);
    setHeaderData(values[5]);
    setExcelSheetName(values[6]);
    setNewFileName(values[7]);
    setStepIncomplete(false);
    //auto mapping function is call
    autoFieldMapping(values[5]);
  };

  const checkFieldBlockMap = () => {
    let companyMB = [];
    let contactMB = [];
    companyMB = companyMatchback.filter((x) => x.value == true);
    contactMB = contactMatchback.filter((x) => x.value == true);

    if (companyMB.length > 0) {
      matchBackBlock[0].status = true;
      setMatchBackBlock(matchBackBlock);
    } else {
      matchBackBlock[0].status = null;
      setMatchBackBlock(matchBackBlock);
    }
    if (contactMB.length > 0) {
      matchBackBlock[1].status = true;
      setMatchBackBlock(matchBackBlock);
    } else {
      matchBackBlock[1].status = null;
      setMatchBackBlock(matchBackBlock);
    }
    // CheckDefaultValue();
  };

  return (
    <>
      <Card
        className="addTaskCardLayout"
        onCancel={props.closeUploadTask}
        maskClosable={false}
        bordered={false}
        actions={[
          nextActionButton(),
          <Button
            className="footer-btn"
            disabled={currentStep === 0}
            key="back"
            onClick={prevStep}
            type="primary">
            <DoubleLeftOutlined /> Prev
          </Button>,
          <Button
            type="link"
            key="cancel"
            onClick={props.closeUploadTask}
            className="footer-cancel-btn">
            Cancel
          </Button>,
        ]}
        title={[<div key="upload-header">{stepItems()}</div>]}
        width={'65%'}
        destroyOnClose={true}
        centered>
        <div>
          <FileUploadStep
            step={currentStep}
            dragFile={props.dragFile}
            removeAllSetData={removeAllSetData}
            disableNext={disableNext}
            enableNext={enableNext}
            submittedValues={getStepOneValue}
          />
          <FieldMappingStep
            step={currentStep}
            fileData={fileData}
            headerData={headerData}
            fields={fieldMapping}
            feildValidation={feildValidation}
            companyMatchback={companyMatchback}
            contactMatchback={contactMatchback}
            defaultIconCompanyMatchback={defaultIconCompanyMatchback}
            defaultIconContactMatchback={defaultIconContactMatchback}
            isDuplicate={isDuplicate}
            duplicateElementArray={duplicateElementArray}
            matchBackBlock={matchBackBlock}
          />
          <EnrichModeStep
            step={currentStep}
            enrichModeValue={enrichModeValue}
          />
          <TaskSummaryStep
            step={currentStep}
            fileName={fileName}
            taskName={taskName}
            excelSheetName={excelSheetName}
            totalRecords={totalRecords}
            mapFieldName={mapFieldName}
            enrichModeValue={enrichModeValue}
            isEnrichModeEnable={props.steps.includes(2)}
            isLoading={isCallingCreditBalance}
            creditsAvailable={creditsAvailable}
            lowBalanceAlert={lowBalanceAlert}
            alertLowBalanceMessage={alertLowBalanceMessage}
            isCreditsAvailableZero={isCreditsAvailableZero}
          />
        </div>
      </Card>
    </>
  );
}

AddTaskWizard.propTypes = {
  open: PropTypes.bool,
  closeUploadTask: PropTypes.func,
  submitUploadFile: PropTypes.func,
  steps: PropTypes.array,
  isLoading: PropTypes.bool,
  dragFile: PropTypes.array,
};

export default AddTaskWizard;
